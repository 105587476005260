export const MapStyles = {
      street: "streets-v12",
      light: "light-v11",
      dark: "dark-v11",
      satellite: "satellite-v9",
      satellite_street: "satellite-streets-v12"
  };
  // "mapbox://styles/mapbox/satellite-streets-v12"
 
  export const vector_layer_list = ['Userproject','NavLayer','State', 'District', 'Subdistrict', 
                                    'Village', 'Pincode','Pincode_label','Surveypolygons',
                                    'WaterBodies','Labels', 'ElevationLayer']

  export const WeatherParam = [
    {label: "Mean-temp", value:"Mean-temp"},
    {label: "Min-temp", value:"Min-temp"},
    {label: "Max-temp", value:"Max"},
    {label: "Temp-diff", value:"Temp_diff"},
    {label: "Feel-like", value:"Feellike"},
    {label: "Feel-like-diff", value:"Feel_like_diff"},
    {label: "Relative-humidity", value:"Relativehumidity"},
    {label: "Humidity-diff", value:"humidity_diff"},
    {label: "POP", value:"POP"},
    {label: "Rainfall mm", value:"Rainfall"},
    {label: "Wind speed", value:"wind speed"},
    {label: "Solar-intensity", value:"Solarintensity"},
    {label: "Weather-alert", value:"Weather_alert"}
  ]

  export const Indices = [
   {label:"None", value:"None"},
   {label:"Ndvi", value:"NDVI_RAINBOW"},
   {label:"Moisture_index", value:"MOISTURE_INDEX"},
   {label: 'Swir', value: 'SWIR'},
   {label: 'Savi', value: 'MSAVI2_L2A'},
   {label:"Agr-growth-stage", value:"AGR_GROWTH_STAGE"},
   {label:"Planet_ndvi", value:"NDVI_GREEN"},
   {label: "Vegetation-natural", value:"NDVI_VEGETATION_NATURAL_COLOURS"},
  //  {label: "bloom_index", value:"MAXIMUM_PEAK_HEIGHT_BLOOM_INDEX"},
   {label:"Surface-moisture", value:"NDWI"},
   {label: "Soil-moisture", value:"SOIL_MOISTURE_SAR"},
   {label: "True color", value:"CUTOM_TRUECOLOR"},
   {label: "Builtup-calssified", value:"BUILT_UP_CLASSIFIER_MASK"},
   
   {label: "Builtup-barren", value:"URBAN_CLASSIFIED"},
   {label: "Active-fire", value:"ACTIVE_FILE"},
   {label:"Atmospheric-vapor", value:"AWV"},
   {label: "Sar vegetation index", value:"SAR_VEGETATION_INDEX"},
  {label: "Uniform colormap", value:"UNIFORM_COLORMAP"},
  {label: "Agriculture", value:"AGRICULTURE"},
  {label: "Bare soil", value:"BARREN_SOIL"},
  {label: "City-highlights", value:"CITY_HIGHLIGHTS"},
  {label: "Burnt-area", value:"BURNT_AREA_MS"},
  
  ];
  export const Indices_url = {
    'AWV': 'https://creodias.sentinel-hub.com/ogc/wms',
    'MOISTURE_INDEX':'https://services.sentinel-hub.com/ogc/wms',
    'CITY_HIGHLIGHTS': 'https://services.sentinel-hub.com/ogc/wms',
    'NDWI':'https://services.sentinel-hub.com/ogc/wms', 
    'WATER_BODIES_MAPPING':'https://services.sentinel-hub.com/ogc/wms',
    'AGR_GROWTH_STAGE':'https://services.sentinel-hub.com/ogc/wms',
    'MAXIMUM_PEAK_HEIGHT_BLOOM_INDEX':'https://services.sentinel-hub.com/ogc/wms',
    'NDVI_RAINBOW':'https://services.sentinel-hub.com/ogc/wms',
    'NDVI': 'https://services.sentinel-hub.com/ogc/wms',
    'TRUE_COLOR': 'https://services.sentinel-hub.com/ogc/wms',
    'MOISTURE_INDEX': 'https://services.sentinel-hub.com/ogc/wms',
    'BUILT_UP_CLASSIFIER_MASK':'https://services.sentinel-hub.com/ogc/wms',
    'MSAVI2': 'https://services.sentinel-hub.com/ogc/wms',
    'MSAVI2_L2A': 'https://services.sentinel-hub.com/ogc/wms',
    'SWIR': 'https://services.sentinel-hub.com/ogc/wms',
    'NDVI_GREEN': 'https://services.sentinel-hub.com/ogc/wms',
    'NDVI_VEGETATION_NATURAL_COLOURS':'https://services.sentinel-hub.com/ogc/wms',
    'CUTOM_TRUECOLOR':'https://services.sentinel-hub.com/ogc/wms',
    'SAR_VEGETATION_INDEX':'https://services.sentinel-hub.com/ogc/wms',
    'SOIL_MOISTURE_SAR': 'https://services.sentinel-hub.com/ogc/wms',
    'URBAN_CLASSIFIED': 'https://services.sentinel-hub.com/ogc/wms',
    'UNIFORM_COLORMAP': 'https://services.sentinel-hub.com/ogc/wms',
    'ACTIVE_FILE': 'https://services.sentinel-hub.com/ogc/wms',
    'AGRICULTURE':'https://services.sentinel-hub.com/ogc/wms',
    'BARREN_SOIL':'https://services.sentinel-hub.com/ogc/wms',
    'BURNT_AREA_MS':'https://services.sentinel-hub.com/ogc/wms',
}  
  export const MapDefaultStyle = {
    style: MapStyles.dark
  };

  export const DefaultViewPort = {
    longitude: 78.83,
    latitude:  30.06,
    zoom: 14
  };


  export const CapacityType = [
    { label: "Solar", id: 1 },
    { label: "Wind", id: 2 },
    { label: "Hydel", id: 3 },
    { label: "Biomass", id: 4 },
   
  ];


  export const rater_layer_roi = [
    'UserROI',
    'Global'
    ]




  export function activerasterparam(activeglobalraster){
    let RasterArray = []
    let RasterId
    let url 
    if (activeglobalraster !== undefined && activeglobalraster !=='None')
    {
        var newArray = Indices.filter(function(item)
    
        {
        return (item.value === activeglobalraster);
        
        });
        RasterArray = newArray
        if(RasterArray!==undefined && RasterArray.length){
            RasterId =  RasterArray[0].value
            url = Indices_url[RasterId]
        } 
        
    }
    return {RasterId, url}
}












