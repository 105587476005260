import * as React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { bgColors } from '../../utils/bgColor';


const PlaneCard=({
    title, 
    subtitle,
    avatar_content,
    header_content,
    main_content,
    action_content,
    ...otherprops}) =>{
  return (
    <Card  {...otherprops}>
        <CardHeader {...otherprops}
          title={title}
          subheader={subtitle}
          avatar={React.Children.toArray(avatar_content).map(child => child)}
          action = {React.Children.toArray(header_content).map(child => child)}
        />
        <CardContent 
       {...otherprops}
      >
      {React.Children.toArray(main_content).map(child => child)}
      </CardContent>
      <CardActions disableSpacing 
       {...otherprops}
      >
      {React.Children.toArray(action_content).map(child => child)}

      </CardActions>
    </Card>
  );
}

export default PlaneCard

PlaneCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  avatar_content:PropTypes.node,
  header_content: PropTypes.node,
  main_content: PropTypes.node,
  action_content:PropTypes.node
 };
 
//  PlaneCard.defaultProps = {
//   avtar_txt: "AV",
//   title:'I am title',
//   subtitle:'I am subtitle'
// };

