import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { bgColors } from '../../utils/bgColor';

const BasicDialog=({id,title,open,handleClose,content,action_button}) =>{
    // const [open, setOpen] = React.useState(false);
  
    // const open = true
  
    // const handleClose = () => {
    //   setOpen(false);
    // };
  
    return (
      <React.Fragment>
     
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx = {{minHeight:'300px', minWidth:'300px', color:bgColors['info.light']}}
        >
          <DialogTitle id={id}>
            {title}
          </DialogTitle>
          <DialogContent>
          {React.Children.toArray(content).map(child => child)}
          </DialogContent>
          <DialogActions>
          {React.Children.toArray(action_button).map(child => child)}
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
  export default BasicDialog
  BasicDialog.propTypes = {
    title: PropTypes.string,
    content: PropTypes.node,
    action_button: PropTypes.node
   };

  BasicDialog.defaultProps = {
    title:'I am basic dialog',
  };



