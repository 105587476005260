// https://sameeraviraj.medium.com/how-to-add-multiple-draw-points-in-mapbox-gl-js-a40235003650#:~:text=First%2C%20select%20a%20drawing%20tool,picture%20to%20clear%20your%20mind.
// https://gist.github.com/ryanbaumann/f4a2e6970eedd1948aca182d0f184968
// https://docs.mapbox.com/mapbox-gl-js/example/style-switch/
import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import './mapcontainer.css'
import UseWindowDimensions from '../screensizecontainer/windowdimensions';
import { MapStyles } from './components/constants';
import { get_polygon_centroid } from './Container/polyCentroid';
import { setDrawFeature,setMapCentreCord} from '../../redux/actions/mapaction';
import { updateViewPort } from './Container/updateViewPort';
import { 
         openLayerControl,
         openAnalysisWindow,
         openUserProjectWindow, 
         openUserFolio
       } from '../../redux/actions/controlbuttonaction';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useDispatch, useSelector } from "react-redux";
import {CustomControl} from './CustomControl';
import  {DrawStyles}  from './CustomControl/DrawStyle';
import { DefaultViewPort } from './components/constants';
// import { WeatherLayer } from './Container/Geojasonlayer/Weatherlayer';
import { adminBoundry,Waterbody} from './Container/VectorLayer';
import { loadState } from '../../utils/Localstorage';
// import { UpdatePolyRaster, handleNoneLayerRoi } from './Container/Rasterlayer/Polyraster';
import { UpdatePolyRaster, handleNoneLayerRoi } from './Container/Rasterlayer/Polyraster';
import {updaterGlobalRaster,handleRoiChaangeGlobal} from './Container/Rasterlayer/SelectedRaster'
import VectorLayerVisibilty from './Container/VectorLayer/LayerVisibility';
import VillageRevPolyLayer from './Container/Geojasonlayer/VillageSurveyPoly/VillageSurveyPoly';
import { activerasterparam } from './components/constants';


export default function BaseMap({}) {
const dispatch = useDispatch()
const { height, width } = UseWindowDimensions();
const centrecord = useSelector(state => state.mapreducer.mapcentrecord)  
const selectedfeaturecords = useSelector(state=> state.mapreducer.selectedfeature.coords)
const selectedfeatureid = useSelector(state=> state.mapreducer.selectedfeature.id)
const viewportbound = useSelector(state => state.mapreducer.viewportbound)   
const layer_state = useSelector(state => state.controlbutton.openlayercontrol)
const mapStyleState = useSelector(state=>state.mapreducer.mapstyle)
const vectorlayervisibility =  useSelector(state => state.mapreducer.vectorlayer)
// const revpolygeojson = useSelector(state=> state.villagesurveypoly.data)
const revpolygeojson = useSelector(state=> state.villagesurveypoly)

const activeglobalraster =  useSelector(state => state.mapreducer.globalraster)
const base_url = activerasterparam(activeglobalraster).url
const rasterlayer = activerasterparam(activeglobalraster).RasterId
const rasterlayerdate = useSelector(state => state.mapreducer.ratserdate)
const opacity = 0.9
const activerasterroi = 'Global'

const  viewportlnglat = () => {
    let longitude = DefaultViewPort.longitude
    let latitude = DefaultViewPort.latitude
    if(centrecord!==undefined && centrecord.longitude, centrecord.latitude){
        longitude= centrecord.longitude
        latitude = centrecord.latitude
    }
    return {longitude,latitude}
    }

//    console.log(loadState('appState')) 
   
   function getLocalmapStyle(){
    let map_style = "satellite-streets-v12"
    if (loadState().mapstyle){
       const _style = loadState().mapstyle
       map_style = MapStyles[_style]
    }
    return map_style

   }


   function upDateLocalStyle(){
  
    let _style = 'satellite-streets-v12'
    if(loadState()!==undefined && loadState().mapstyle !==undefined){
        _style= MapStyles[loadState().mapstyle]
    }
    return _style
}
      
    // const [mapstyleref, setMapstyleref] = useState('satellite-streets-v12')
    const [mapstyleref, setMapstyleref] = useState("")
    
    useEffect(()=> {
        setMapstyleref(MapStyles[mapStyleState])
    },[mapStyleState])

    
    const [viewport, setViewport] = useState({
        longitude:viewportlnglat().longitude,
        latitude:viewportlnglat().latitude
    });

    const mapContainer = useRef(null);
    const map = useRef(null);
    useEffect( () => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN; 
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
    // const map = new mapboxgl.Map({
    container: mapContainer.current,
    style: 'mapbox://styles/mapbox/'+mapstyleref,
    center: [viewport.longitude, viewport.latitude],
    zoom: 14
    });

    const draw = new MapboxDraw({
    displayControlsDefault: false,
    controls: {
        polygon: false,
        line_string: false,
        point:false,
        trash: false
    },
    styles: DrawStyles

    });

    //    map.current.on('moveend', () => {
    //     const bounds = map.current.getBounds();
    //     dispatch(setViewportBound(bounds))});


    const layerControl = new CustomControl({
        className: "layer-control-button",
        title:"leyer-control",
        eventHandler: handleOpenLayerControl,
        
        })
        map.current.addControl(layerControl, "top-right")
        function handleOpenLayerControl(){
            dispatch(openLayerControl)
        }
    
    const AnlysisWindowControl = new CustomControl({
        className: "anlysiswindow-control-button",
        title:"anlysiswindow-control",
        eventHandler: handleOpenAnalysisWindow,
        
        })
        map.current.addControl(AnlysisWindowControl, "top-right")
        function handleOpenAnalysisWindow(){
            dispatch(openAnalysisWindow)
        }

    const SaveControl = new CustomControl({
        className: "save-feture-form-control-button",
        title:"anlysiswindow-control",
        eventHandler: handleOpenSave,
        
        })
        map.current.addControl(SaveControl, "top-right")
        function handleOpenSave(){
            dispatch(openUserProjectWindow)
        }

    const UserProojectControl = new CustomControl({
        className: "user-project-control-button",
        title:"user-prooject-control",
        eventHandler: handleOpenUserProject,
        
        })

    map.current.addControl(UserProojectControl, "top-right")
    function handleOpenUserProject(){
        dispatch(openUserFolio)
    }
    

    map.current.addControl(draw);  
    const PolyDraw = new CustomControl({
            className: "draw-polygon-button",
            title:"Draw-polygon",
            eventHandler: handlePolyDraw,
            
            })
        map.current.addControl(PolyDraw, "bottom-right")
        function handlePolyDraw(){
            draw.changeMode('draw_polygon')

        }
        const PointDraw = new CustomControl({
            className: "draw-point-button",
            title:"Draw-point",
            eventHandler: handlePointDraw,
            })

        map.current.addControl(PointDraw, "bottom-right")
            function handlePointDraw(){
            // draw.set({type: 'FeatureCollection', features: []});
            draw.changeMode('draw_point')
        }
        // let feture_id
        const setSelectedFeature = function(e) 
        {
           
        if (e.features && e.features.length && e.features[0].type === 'Feature' &&
            e.features[0].geometry.type === 'Polygon'
        ) {
            let points = []
            e.features[0].geometry.coordinates[0].forEach(element => {
                let _var = {x: element[0], y: element[1]}
                points.push(_var)
                
            });
            // console.log(e.features[0].geometry.coordinates)
            //   feture_id = e.features[0].id,
            dispatch(setDrawFeature({id: e.features[0].id,
                coords: e.features[0].geometry.coordinates
            }))
            dispatch(setMapCentreCord({
                longitude:get_polygon_centroid(points).x,
                latitude:get_polygon_centroid(points).y
            }))
        }
        return 
    }
    
        map.current.on('draw.selectionchange', setSelectedFeature);
        const PolyDelete = new CustomControl({
            className: "delete-geom-button",
            title:"Delete",
            eventHandler: handleDelete
            })
        map.current.addControl(PolyDelete, "bottom-right")
        function handleDelete(e){

            let allfeatures = draw.changeMode('simple_select').getAll()
            if (allfeatures.features.length >0){
                
                let feture_id = allfeatures.features[0].id;
                draw.delete(feture_id)
            }
        }

        map.current.on("style.load", function(){
            if(map && revpolygeojson )
            
            {adminBoundry(map)
            // WeatherLayer(map)
            VillageRevPolyLayer(map,revpolygeojson.data)
            }
        })
       
        
    },[]);
    
    
    const updateRevpoly = () => {
        if(map && revpolygeojson && map.current.getLayer('revenuepoly_layer'))
        { map.current.getSource('revenuepoly_layer').setData(revpolygeojson.data)}
    } 
    useEffect(() => {
        if(map && revpolygeojson )
            { updateRevpoly();}
         }, [revpolygeojson]);

    useEffect(()=> {
        VectorLayerVisibilty(map,vectorlayervisibility)
    },[vectorlayervisibility])


useEffect(()=> {
    updateViewPort(map,centrecord)
 },[centrecord])

useEffect(()=> {
    map.current.setStyle('mapbox://styles/mapbox/' + mapstyleref);
   
},[mapstyleref])



useEffect(()=> {
            
    if(map && rasterlayer && rasterlayerdate && base_url && activerasterroi==='Global')
        {   
            updaterGlobalRaster(map, rasterlayer, 
            rasterlayerdate, base_url, opacity)
        }
},[activeglobalraster,activerasterparam(activeglobalraster).RasterId,rasterlayerdate])


useEffect(()=> {
    if(activeglobalraster && activerasterroi !=='' )
    handleRoiChaangeGlobal(map,activerasterroi)
},[activerasterroi,map])

// handleRoiChaangeGlobal(map,rasterroi)


useEffect(()=> {
    if(activeglobalraster && activeglobalraster ==='None')
    handleNoneLayerRoi(map,activeglobalraster)
},[activeglobalraster,map])




useEffect(()=> {
    if(map && rasterlayer && rasterlayerdate && base_url 
        && selectedfeaturecords!==undefined 
        && selectedfeaturecords.length>0 
        && activerasterroi==='UserROI')
    {
        UpdatePolyRaster (map, 
            rasterlayer, 
            rasterlayerdate, 
            base_url, 
            opacity, 
            selectedfeaturecords)
    }
},[activeglobalraster,activerasterparam(activeglobalraster).RasterId,rasterlayerdate,activerasterroi,selectedfeaturecords])




 
return (
    <div id = "map" ref={mapContainer}  
        posistion = {"absolute"}
        style = {{ 
            height: height, width:width
        }}
    />
);
}

