
export const SET_SELECTED_MODEL  = "SET_SELECTED_MODEL"

export const setUserDefinedLfModel = (selectedmodel) => ({
    type: SET_SELECTED_MODEL,
    payload: selectedmodel
 })

 











