import {
    LF_MODEL_MASTER_REQUEST,
    LF_MODEL_MASTER_SUCCESS,
    LF_MODEL_MASTER_FAILURE
} from '../actions/lfmodelmasterAction'

const initialState = { 
    loading: false,
    lfmodelmaster: [],
    error: '',
}

const lfmodelmasterReducer = (state = initialState, action) => {

    switch (action.type) {
        case LF_MODEL_MASTER_REQUEST:
        return {
            ...state,
            loading:true

        }

        case LF_MODEL_MASTER_SUCCESS:
        return {
            ...state,
            loading:false,
            lfmodelmaster: action.payload,
            error: ''
            

        }
        case LF_MODEL_MASTER_FAILURE:
            return {
                ...state,
                loading: false,  
                lfmodelmaster: [],  
                error: action.payload  
            }
        default: return state
    }

}

export default lfmodelmasterReducer  