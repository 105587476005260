import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker}  from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

// import { DateField } from '@mui/x-date-pickers';

export const DateStrEvent = (event) => {
  const day = event["$D"].toString().padStart(2,0)
  const month = (event["$M"]+1).toString().padStart(2,0)
  const year = event["$y"].toString()
  const datestr = year +"-"+month+"-"+day
  return datestr
} 

const   DatePickerSmall = ({id,label, onChange,value,defaultvalue})=> {
 
  return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker 
          defaultvalue ={defaultvalue}
          value={value}
          format="YYYY-MM-DD"
          id={id}
          label={label}
          slotProps={{ textField: {size: 'small', fullWidth:false}}}
          onChange={onChange}
          renderInput={(params) => <TextField {...params} />}
        //   renderInput={(params) => <TextField {...params} fullWidth />}
        />
   </LocalizationProvider>
  );
}
export default DatePickerSmall


DatePicker.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string
  };
  
DatePicker.defaultProps = {
    id:"custom_date_picker",
    size:"small",

}
