import {
    API_START,
    API_END
  } from "../actions/types";

import {
  FETCH_SURVEYPOLY,
  FETCH_SURVEYPOLY_ERROR,
  SET_SURVEYPOLY
} from "../actions/fetchVillageSurveyPoly"

  const initialState = {
      loading: false,
      data: {type: 'FeatureCollection', 
              features: []},
      error: ''
  
  }
  
  
  export default function fetchVillageSurveyPolyReducer(state = initialState, action) {
    //console.log("action type => ", action.type);
    switch (action.type) {
    
      case SET_SURVEYPOLY:
        return { ...state, data: action.payload[0].json_build_object};
      case API_START:
        
        if (action.payload === FETCH_SURVEYPOLY) {
          return {
            ...state,
            loading: true,
            data:{},
            error: ""
          };
        }
        return(null)
        // break;
      case API_END:
        console.log(state)
        if (action.payload === FETCH_SURVEYPOLY) {
         
          return {
            ...state,
            loading: false,
          };
        }
        return(null)
        // break;
        case FETCH_SURVEYPOLY_ERROR:
          return {
            ...state,
            data: {},
            error: action.payload.message
          };

      default:
        return state;
    }
  }
  