import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';



const Actiontable =() =>{
    const [rows, setRows] = React.useState(data);
    const colums = ['']
    const handleChangeConnect = (id) => {
      console.log("The id is ", id);
      setRows(
        rows.map((row) => {
          if (row.id == id) {
            return { ...row, isConnected: !row.isConnected };
          } else return { ...row };
        })
      );
    };
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>

              <TableCell>Dessert (100g serving)</TableCell>
              <TableCell align="right">Calories</TableCell>
              <TableCell align="right">Fat&nbsp;(g)</TableCell>
              <TableCell align="right">Carbs&nbsp;(g)</TableCell>
              <TableCell align="right">Protein&nbsp;(g)</TableCell>
              <TableCell align="right">delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.Series_name}</TableCell>
                <TableCell align="right">{row.From_block}</TableCell>
                <TableCell align="right">{row.To_block}</TableCell>
                <TableCell align="right">{row.Weight}</TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color={row.isConnected ? "primary" : "secondary"}
                    onClick={() => {
                      handleChangeConnect(row.id);
                    }}
                  >
                    {row.isConnected ? "disconnect" : "connect"}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

export default Actiontable


function createData(id, name, calories, fat, carbs, protein, isConnected) {
    return { id, name, calories, fat, carbs, protein, isConnected };
  }
  
  const data = [
    createData(1, "Frozen yoghurt", 159, 6.0, 24, 4.0, true),
    createData(2, "Ice cream sandwich", 237, 9.0, 37, 4.3, false),
    createData(3, "Eclair", 262, 16.0, 24, 6.0, false),
    createData(4, "Cupcake", 305, 3.7, 67, 4.3, false),
    createData(5, "Gingerbread", 356, 16.0, 49, 3.9, false)
  ];
  
