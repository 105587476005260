import {forwardRef,useState} from 'react';
// import PropTypes from 'prop-types';
import ChartJSdragDataPlugin from './DragDataPlugin';
import { setDraggedState } from '../../../redux/actions/ChartActionDrag';
import { useDispatch,useSelector } from 'react-redux';
import { getDatasetAtEvent,getElementAtEvent } from 'react-chartjs-2';

import * as d3 from "d3";
import { drag } from "d3-drag";
import { select } from "d3-selection";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';

import { Bar} from 'react-chartjs-2';
import { bgColors } from '../../../utils/bgColor';
// import  Button  from '@mui/material/Button';
// import { primary } from '../../../common/colors';
ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
    // ChartJSdragDataPlugin
);



const DraggableBarChart =forwardRef((props,ref) =>{

  function drag(event,d) {
    d.x = event.x;
    d.y = event.y;
    d3.select(this).raise().attr("transform", d=> "translate("+[d.x,d.y]+")" )
  }

  // const drag_action_state = useSelector(state=>state.chartdrag_state)
  // console.log(drag_action_state)
  
  const {data,onClick,} = props
  const dispatch = useDispatch()
  const [state, setState ]= useState({
    index:'',
    datasetIndex:'',
    value:''
  })

  const options = {
  
    responsive: true,
    maintainAspectRatio : false,
    interaction: {
      mode: 'index',
      intersect: true,
    },
      plugins: {
          legend: 
              {
               
              labels: {
                usePointStyle: true,
                boxWidth: 20,
                color: bgColors.grey_300, 
                font: {
                  size: 14,
                },
              }
            },
            // dragData: true,
            dragData:{
              // onDragStart: (datasetIndex,index,value) =>{
              //   console.log(datasetIndex,index,value)
              // },
              // onDragStart: function (e) {
              //   console.log(e)
              // },
              onDrag: (event,datasetIndex,index,value) => {
                dispatch(setDraggedState({
                  index:index,
                  datasetIndex:datasetIndex,
                  value:value
                }))
                event.target.style.cursor='grabbing'

              },
              // onDragEnd: function (event, datasetIndex, index, value) {

              // }
            }

      },
     
      scales: {
        x:{
          stacked: true,
          grid: {
          display: true,
          color: bgColors.grey_800,
          },
          ticks: {
            color: bgColors.grey_300, 
            font: {
              size: 10,
            },
            stepSize: 4,
            beginAtZero: false
          }
    
        },
        y:{
          // stacked: true,
          grid: {
          display: true,
          color: bgColors.grey_800,
          },
          ticks: {
            color: bgColors.grey_300, 
            font: {
              size: 10,
            },
            stepSize: 500,
            beginAtZero: false
          }
    
        },

    // yAxes: {
    //   grid: {
    //     display: true,
    //     color: bgColors.grey_600,
    //   },
    //     ticks: {
    //       display: false,
    //       color: '#FFFFFF', 
    //       font: {
    //         size: 10,
    //       },
    //       beginAtZero: false,
    //       // min: -1,
    //       // stepSize: 1,
          
    //       callback: function(value) {
    //         return `${value}`
    //       }
    //       }
    //     }
      },
    }; 
  
  
  return (
    <div style ={{position: "relative",margin: "auto", height: '80Vh'}}>
       <Bar 
          ref={ref}
          options={options} 
          data={data}
          onClick={onClick}
          // redraw={true}
      />
  </div>
  )
})

export default DraggableBarChart






