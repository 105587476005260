import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef ,GridToolbar} from '@mui/x-data-grid';
import { v4 as uuidv4 } from 'uuid'
export default function BaseDatagrid({rows,columns,pageSize}) {
  return (
    <Box sx={{ height: '100Vh', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: pageSize,
            },
          },
        }}
        getRowId={() => uuidv4()}
        slots={{ toolbar: GridToolbar }}

        // pageSizeOptions={[5]}
        checkboxSelection
        // disableRowSelectionOnClick
      />
    </Box>
  );
}

