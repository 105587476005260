import {useEffect, useState}from 'react';
import { useSelector } from 'react-redux';
import { prepareMultiLineData } from '../../../components/ReactChart/MultiLine/linedata';
import { prepareMixedAreaChartData } from '../../../components/ReactChart/MultiLine/linedata';
import { TimeBlock } from '../../../container/dashboard.fn';
import { MultiLine } from '../../../components/ReactChart/MultiLine';
import { AreaChart } from '../../../components/ReactChart/AreaChart';
import { range } from '../../../utils/ArryOps';
const Isgs_summary_chart = ()=> {
const currentSch_state = useSelector(state => state.live_isgs_schedule)
let muliLineData
let linedatasets 
const key = 'name'
const value_field='schedule_mw'
const labels = TimeBlock()
// const labels = range(1,96,1)
const line_chart_element= ['NET_Total']
const line_area_element= []
const bar_chart_element =[]

if(currentSch_state && !currentSch_state.loading && currentSch_state.data){
    let _data = currentSch_state.data.net_schedule_db
    let filtered_array = []
    _data.forEach(element => {
        if(element.schedule_mw!=0){
            filtered_array.push(element)
        }
        
    });
    console.log(filtered_array)
    linedatasets =  prepareMixedAreaChartData(
        _data,key,value_field,line_area_element,
        line_chart_element,bar_chart_element
    )
    muliLineData = {labels,
        datasets: linedatasets
        }      
    }
   
    return(
        <>
        {currentSch_state && !currentSch_state.loading && currentSch_state.data &&
            <AreaChart
            data={muliLineData}
            /> 
        }
      </>

    )
}

export default Isgs_summary_chart

    




