
// import { configureStore } from '@reduxjs/toolkit'
import { configureStore} from "@reduxjs/toolkit";
// import throttle from 'lodash.throttle';
import controlbuttonReducer from './reducers/controlbuttonreducer'
import mapreducer from './reducers/mapreducer'
import feturepropreducer from './reducers/featurepropreducer'
import StaticDataReducer from "./reducers/StaticDataReducer";
import currentFolioReducer from './reducers/currentfolioreducer'
import authcontrolReducer from './reducers/authcontrolreducer'
import lfmodelmasterReducer from './reducers/lfmodelmasterReducer'
import discommasterReducer from './reducers/discommasterReducer' 
import gentariffReducer from './reducers/gentariffReducer'
import fetchScheduleDetailsReducer from './reducers/fetchScheduleDetailsReducer'
import authreducer from './reducers/authreducer'
import apiMiddleware from "../Apis/middleware/api";
import discomMasterReducer from "../Apis/reducers/discomMaster";
import eventListReducer from "../Apis/reducers/eventList";
import fetchApiReducer from "../Apis/reducers/fetchApiReducer";
import fetchWeatherSummaryReducer from "../Apis/reducers/fetchWeatherSummaryReducer";
import FormReducer from "./reducers/formreducer";
import fetchEventReducer from "../Apis/reducers/fetchEventReducer";
import postEventReducer from "../Apis/reducers/postEvent";
import fetchVillageSurveyPolyReducer from "../Apis/reducers/fetchVillageSurveyPoly";
import DayAhead_ISGS_ScheduleReducer from "../Apis/reducers/Day_Ahead_ISGS_Schedule";
import current_ISGS_ScheduleReducer from "../Apis/reducers/current_ISGS_Schedule";
import fetchDayAheadIEXPriceReducer from "../Apis/reducers/fetchDayAheadIEXPriceReducer";
import fetchRTMIEXPriceReducer from "../Apis/reducers/fetchRTMIEXPriceReducer";
import loadForecastReducer from "../Apis/reducers/fetchLoadForecast";
import fetchCurrentReSchedule from "../Apis/reducers/ReSchedule_current";
import fetchDayAheadReScheule from "../Apis/reducers/ReSchedule_DayAhead";
import internalGenUnitMasterReducer from "../Apis/reducers/InternalGenUnitMaster";
import rtDatatagmasterReducer from "../Apis/reducers/rtDatatagmaster";
import UiControlReducer from "./reducers/UiControlReducer";
import ForecastCompReducer from "./reducers/ForecastCompReducer";
import reduceDayAheadReSchSummary from "../Apis/reducers/ReSchedule_Summary_DayAhead";
import fetchDayAheadDeficitSurplus from "../Apis/reducers/fetchDayAheadDeficitSurplus";
import chartdragActionReducer from "../redux/reducers/ChartActionDragReducer";
import dayAheadScheOtimizerReducer from "../Apis/reducers/RunDayAheadSchOptimizerRequest";
import currentISGSReducer from "../Apis/reducers/current_ISGS_Schedule";
// import current_ISGS_ScheduleReducer from "../Apis/reducers/CurrentSchedule";
import liveIsgScheduleReducer from "../Apis/reducers/LiveIsgsSchedule";
import currenDrawlReducer from "../Apis/reducers/currenDrawlReducer";
import userDefinedLFmodel from "./reducers/LoadForecastigUserHybridModel";
import loadForecastchartdragActionReducer from "./reducers/LF_ChartActionDragReducer";
import loadForecastMasterReducer from "../Apis/reducers/LoadForecastingModelMaster";
import solargenDaForecastReducer from "../Apis/reducers/fetchDaSolargenForecast";
import windgenDaForecastReducer from "../Apis/reducers/fetchDaWindgenForecast";
import dayAheadSchDetailsReducer from "../Apis/reducers/fetchDayAheadSchDetails";
// import { saveState,loadState } from "../utils/localStorage";
// const persistedState = loadState();
export const store = configureStore({
  reducer: {
    controlbutton: controlbuttonReducer,
    mapreducer:mapreducer,
    featureprop:feturepropreducer,
    static_data: StaticDataReducer,
    // ddmaster: dropdownmasterreducer,
    currentfolio:currentFolioReducer,
    authcontrol:authcontrolReducer,
    authreducer: authreducer,
    discomMasterReducer: discomMasterReducer,
    eventList:eventListReducer,
    fetchApiReducer:fetchApiReducer,
    weatherSummary:fetchWeatherSummaryReducer,
    FormReducer:FormReducer,
    postEvent:postEventReducer,
    villagesurveypoly:fetchVillageSurveyPolyReducer,
    lfmodelmsterList: lfmodelmasterReducer,
    gentariffList: gentariffReducer,
    discommasterList: discommasterReducer,
    discomschedule: fetchScheduleDetailsReducer,
    fetchEventReducer: fetchEventReducer,
    live_isgs_schedule: current_ISGS_ScheduleReducer,
    day_ahead_isgs_schedule:DayAhead_ISGS_ScheduleReducer,
    day_ahead_iexprice:fetchDayAheadIEXPriceReducer,
    rtm_iexprice:fetchRTMIEXPriceReducer,
    loadforecast:loadForecastReducer,
    currentReSchedule:fetchCurrentReSchedule,
    dayaheadReSchedule:fetchDayAheadReScheule,
    internalGenUnitMaster:internalGenUnitMasterReducer,
    rt_data_tag:rtDatatagmasterReducer,
    uiControl:UiControlReducer,
    ForecastComp:ForecastCompReducer,
    dayaheadreschsummary:reduceDayAheadReSchSummary,
    dayaheaddeficitsurplus:fetchDayAheadDeficitSurplus,
    chartdrag_state: chartdragActionReducer,
    dayahead_sch_opt_state: dayAheadScheOtimizerReducer,
    current_isgs_state:currentISGSReducer,
    // current_ISGS_Schedule:current_ISGS_ScheduleReducer,
    liveIsgScheduleReducer:liveIsgScheduleReducer,
    current_isgs_drawl:currenDrawlReducer,
    userDefinedLFmodel:userDefinedLFmodel,
    loadForecastchartdragActionReducer:loadForecastchartdragActionReducer,
    load_forecast_model_master:loadForecastMasterReducer,
    da_solar_forecast_state:solargenDaForecastReducer,
    da_windgen_forecast_state: windgenDaForecastReducer,
    da_schdetails_state:dayAheadSchDetailsReducer
  },

  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     thunk: {
  //       extraArgument: apiMiddleware,
  //     },
  //     serializableCheck: false,
  //   }),
   
  // middleware: [...getDefaultMiddleware(), api]
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }).concat(apiMiddleware),
  // persistedState,
  // preloadedState: loadState().StaticDataReducer
  
 })


// store.subscribe(
//   debounce(() => {
//     saveState(store.getState().StaticDataReducer);
//   }, 800)
// );








