import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import TableChartIcon from '@mui/icons-material/TableChart';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';import BoltIcon from '@mui/icons-material/Bolt';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import WindPowerIcon from '@mui/icons-material/WindPower';
import InsightsIcon from '@mui/icons-material/Insights';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import LoginIcon from '@mui/icons-material/Login';
// import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
export const MenuList = [
    {
      icon: <HomeOutlinedIcon />,
      name: "Home",
      url: "./Homepage"
    },
    {icon:<TableChartIcon/>,
     name:"Positionmap",
     url:"Positionmap"
    },
    // {
    //   icon: <DashboardOutlinedIcon />,
    //   name: "Dashboard",
    //   url: "Dashboard"
    // },
    { 
      icon: <LinkOutlinedIcon />,
      name: "DataIntegration",
      url: "DataIntegration"

    },

    { 
      icon: <FileUploadOutlinedIcon />,
      name: "Upload csv",
      url: "DataImport"

    },
  

    { 
      icon: <EngineeringOutlinedIcon />,
      name: "Forecasting",
      url: "Forecasting"
    },
    { 
      icon: <LoginIcon />,
      name: "login",
      url: "login"
    },


    // { 
    //   icon: <IntegrationInstructionsOutlinedIcon />,
    //   name: "DataIntegration",
    //   children: [
    //     // {
    //     //   name: "Technical Analysis",
    //     //   children: [
    //         {
    //           name: "Market Data",
    //           url: "/marketdata",
    //           icon: <TrendingUpOutlinedIcon />,
    //         },
    //         {
    //           name: "Weather Data",
    //           url: "/weatherdata",
    //           icon: <ThunderstormOutlinedIcon />,
    //         },
    //         {
    //           name: "ISGS Data",
    //           url: "/isgs",
    //           icon: <ImportExportOutlinedIcon/>
    //         },
    //         {
    //           name: "Internal data",
    //           url: "/internal",
    //           icon: <RectangleOutlinedIcon/>
    //         }
    
    //   ]
    // },
    // {
    //   icon: <ModelTrainingIcon />,
    //   name: "Testcomp",
    //   url: "/Testcomp",
    //   // children: [
    //   //   // {
    //   //   //   name: "Technical Analysis",
    //   //   //   children: [
    //   //       {
    //   //         name: "Price forecast",
    //   //         url: "/priceForecast",
    //   //         icon: <InsightsIcon />,
    //   //       },
    //   //       {
    //   //         name: "Load Frecast",
    //   //         url: "/loadforecast",
    //   //         icon: <BoltIcon />,
    //   //       },
    //   //       {
    //   //         name: "Re-Forecast Wind",
    //   //         url: "/reforecastWind",
    //   //         icon: <WindPowerIcon/>
    //   //       },
    //   //       {
    //   //         name: "Re-Forecast solar",
    //   //         url: "/reforecastSolar",
    //   //         icon: <SolarPowerIcon/>
    //   //       }
    
    //   // ]
    // },

    // {
    //   icon: <ModelTrainingIcon />,
    //   name: "Testcomptest",
    //   url: "/Testcomptest",
    //   // children: [
    //   //   // {
    //   //   //   name: "Technical Analysis",
    //   //   //   children: [
    //   //       {
    //   //         name: "Price forecast",
    //   //         url: "/priceForecast",
    //   //         icon: <InsightsIcon />,
    //   //       },
    //   //       {
    //   //         name: "Load Frecast",
    //   //         url: "/loadforecast",
    //   //         icon: <BoltIcon />,
    //   //       },
    //   //       {
    //   //         name: "Re-Forecast Wind",
    //   //         url: "/reforecastWind",
    //   //         icon: <WindPowerIcon/>
    //   //       },
    //   //       {
    //   //         name: "Re-Forecast solar",
    //   //         url: "/reforecastSolar",
    //   //         icon: <SolarPowerIcon/>
    //   //       }
    
    //   // ]
    // },
   
  ];