
export const SET_DISPLAY_DATA_PLOT  = "SET_DISPLAY_DATA_PLOT"
export const OPEN_CSV_IMPORT = "OPEN_CSV_IMPORT"
export const CLOSE_CSV_IMPORT = "CLOSE_CSV_IMPORT"


export const setDisplayPlotData = (display_plot) => ({
   type: SET_DISPLAY_DATA_PLOT,
   payload: display_plot
})


export const openCsvImportDialog = {
   type: OPEN_CSV_IMPORT,
   payload: true
 };
 
 export const closeCsvImportDialog = {
   type: CLOSE_CSV_IMPORT,
   payload: false
 };
 








