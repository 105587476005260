import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
const ContextMenu = ({contextMenu,handleClose,child_component,handleContextMenu,options})=> {

  return (
    <div onContextMenu={handleContextMenu} 
    // style={{ cursor: 'context-menu', 
    // minHeight:'50px'}}
    >
       {child_component}
      <Menu
       sx={{minHeight:'100px',minWidth:'100px', borderRadius:'2px'}}
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {options.map((option,index)=> {
          return <MenuItem  key = {`${option}_${index}`} 
          onClick={handleClose}> 
          <ControlPointIcon 
          sx = {{Padding:'0.25rem'}}/>
          {option}
          </MenuItem>
        })}
      </Menu>
    
    </div>
  );
}

export default ContextMenu

// ContextMenu.propTypes = {
//   contextMenu: PropTypes.string,
//   handleClose: PropTypes.string,
//   avatar_content:PropTypes.node,
//   header_content: PropTypes.node,
//   main_content: PropTypes.node,
//   action_content:PropTypes.node
//  };