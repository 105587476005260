import {API} from "./types";
export const FETCH_DATA = "FETCH_DATA"
export const FETCH_DATA_ERROR = "FETCH_DATA_ERROR"
export const SET_DATA = "SET_DATA"

export function fetchData(url,method,query_param){
    return apiAction({
      url:url,
      method:method,
      data:query_param,
      onSuccess: setData,
      onFailure: (error) => ({
        type: FETCH_DATA_ERROR,
        payload: error
      }),
      label: FETCH_DATA
    });
  }


  function setData(data) {
    //let weather = firebase.database().ref('locations/1');
    // //weather.child('weather_forecast').set(json);
    // console.log(data);
    return {
      type: SET_DATA,
      payload: data
    };
  }


  
  function apiAction({
    url = "",
    method = "",
    data = null,
    accessToken = null,
    onSuccess = () => {},
    onFailure = () => {},
    label = "",
    headersOverride = null
  }) {
    return {
      type: API,
      payload: {
        url,
        method,
        data,
        accessToken,
        onSuccess,
        onFailure,
        label,
        headersOverride
      }
    };
  }




  