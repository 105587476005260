import {useEffect, useState} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import TableViewIcon from '@mui/icons-material/TableView';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress'
import Backdrop from '@mui/material/Backdrop'; 
import { BarChart } from '../../../../components/ReactChart/BarChart';
import { fetchDayAheadSchDetails } from '../../../../Apis/actions/fetchDayAheadSchDetails';
import { TimeBlock } from '../../../../container/dashboard.fn';
import { PlaneCard } from '../../../../UiComponent/CustomCard';
import { barChartData } from '../../../../components/ReactChart/MultiLine/linedata';
import BaseDatagrid from '../../../../components/Tables/BaseDatagrid';

import { style } from '../Layout/const';
const DayAheadEntitilement = () => {
    const dispatch = useDispatch()
    const da_sch_state = useSelector(state=> state.da_schdetails_state)
    console.log(da_sch_state)
    const selected_date = useSelector(state => state.ForecastComp)
    const [state, setState] = useState({
            displayPlot:false,
            loading:false,
            date: selected_date.forDate,
            dayahead_ent:[]
    })
 
    const toggleTableGraph=() =>{
        if(state.displayPlot === true) {
            setState({...state, displayPlot:false})
        } else if (state.displayPlot=== false) {
            setState({...state, displayPlot:true})
        }
    }
    useEffect(()=> {
        if(da_sch_state && da_sch_state.loading){
            setState({...state, isLoading:true})
        }
        if(da_sch_state && !da_sch_state.loading 
            && da_sch_state.data && da_sch_state.data.dayahead_ent){
                setState({...state, loading:false,
                    dayahead_ent:da_sch_state.data.dayahead_ent

                })
            }
    },[da_sch_state])

   let labels = TimeBlock()
   let plotdata
   let ent_table
   let ent_column

    if(state.dayahead_ent && state.dayahead_ent.length >0)
        {
        let all_units = [...new Set(state.dayahead_ent.map(q => q['unit_name']))]
        let datasets = []
        all_units.forEach(element => {
            let filtered_data = state.dayahead_ent.filter(item => (item.unit_name ===element))
            let mr_dict = {}
            mr_dict.label = element
            let _data = []
            labels.forEach((label,index) => {
                let _block_sch = filtered_data.filter(item => (item.block_no === index+1))
                let _sch_mw = _block_sch[0].entitlement_mw
                _data.push(_sch_mw)
            });
            mr_dict.data = _data
            datasets.push(mr_dict)
        }
        
    );
            
        let key = 'label'
        let final_plot_data = barChartData(datasets,key)

        let table_data = []
        datasets.forEach(element => {
            let _dict = {}
            _dict.unit_name=element.label
            let _data = element.data
            labels.forEach((element,index) => {
                _dict[element]=Math.round(_data[index])
            });
            table_data.push(_dict)

        });
           

        let _table_keys=Object.keys(table_data[0])
        let _table_columns = []
        _table_keys.forEach(element => {
            let _dict = {}
            _dict.field=element
            _dict.header=element
            if(element!=='unit_name'){
                _dict.editable= true
            }
            _table_columns.push(_dict)
        });
    ent_table = table_data  
    ent_column = _table_columns
    plotdata = {
        labels:labels,
        datasets:final_plot_data
    }
    }
    console.log(ent_table)
    const query_params = {
        date:selected_date.forDate
     }
     const LoadData =  async(e) => {
         await dispatch(fetchDayAheadSchDetails("GET", query_params))
     } 

     useEffect(()=> {
        dispatch(fetchDayAheadSchDetails("GET", query_params))
     },[dispatch])

    return(
        <>
            <PlaneCard 

        avatar_content = {
        <Stack direction ={'row'} 
        justifyContent="flex-start"
            alignItems="flex-start" 
     spacing={2} >
    {state.displayPlot && 
        <IconButton
        sx ={style.icon_style}
        onClick={toggleTableGraph}
        >
        <LegendToggleIcon/>
        </IconButton>
        }

    {!state.displayPlot && 
        <IconButton
        sx ={style.icon_style}
        onClick={toggleTableGraph}
        >
        <TableViewIcon/>
        </IconButton>
    }
    </Stack>
}
header_content={
    <>
    <Stack direction ={'row'} spacing={2} >
      <Button  variant = "contained" startIcon = <RefreshIcon/>
        onClick={LoadData}
            >
        Refresh-data
     </Button>

    </Stack>

    </>

}

main_content ={
    <>
     { da_sch_state && da_sch_state.loading &&
        <Backdrop
        sx={{ color: '#fff', zIndex: 10000 }}
        open={da_sch_state.loading}
        >
        <CircularProgress color="inherit" />
        </Backdrop>
    }
     {state.dayahead_ent && state.dayahead_ent.length >0 && state.displayPlot &&

        <BarChart
            data={plotdata}
        />
    } 

{state.dayahead_ent && state.dayahead_ent.length >0 && !state.displayPlot &&

<BaseDatagrid
    columns={ent_column}
    rows={ent_table}
    pageSize={16}
/>

}  
    </>
}/>

        </>
    )


}

export default DayAheadEntitilement