import * as React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const BasicAutoComplete=({menulabel,select_options,value,onChange})=> {
  return (
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <InputLabel id="demo-simple-select-autowidth-label">{menulabel}</InputLabel>
        <Select
          // id={id}
          // autoWidth
          // label={menulabel}
          // labelId={labelId}
          value={value}
          onChange={onChange}
        >
          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem> */}
          {select_options.map((item, index)=>{
             return <MenuItem key = {`${item}_${index}`} value={item}> {item} </MenuItem>
          })}
        </Select>
      </FormControl>
  );
}

export default BasicAutoComplete

BasicAutoComplete.propTypes = {
  menulabel:PropTypes.string,
  select_options:PropTypes.array,
  value:PropTypes.string, 
  onChange:PropTypes.func,
};


