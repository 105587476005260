import React, { useEffect, useRef, useState } from 'react' 

import { useDispatch, useSelector } from 'react-redux'
import { BaseCard } from '../../UiComponent/CustomCard'
import { DatePickerSmall} from '../../UiComponent/DatePicker'
import { currentDrawlAction } from '../../Apis/actions/currentDrawlAction'
import { fetchLiveIsgsSchedule } from '../../Apis/actions/LiveIsgsSchedule'
import { TimeBlock } from '../../container/dashboard.fn'
import { BarChart } from '../../components/ReactChart/BarChart'
import CircularProgress from '@mui/material/CircularProgress'
import Backdrop from '@mui/material/Backdrop'; 
import { UnfoldLessDoubleSharp } from '@mui/icons-material'

const chartColors = {
    isgs_drawl: 'rgb(255, 99, 132)',
    orange: 'rgb(255, 159, 64)',
    od_ud: 'rgb(255, 205, 86)',
    isgs_schedule: 'rgb(75, 192, 192)',
    blue: 'rgb(54, 162, 235)',
    purple: 'rgb(153, 102, 255)',
    grey: 'rgb(231,233,237)'
  };

const DashboardOdUd =()=> {
    const barChartref = useRef()
    const current_drawl = useSelector(state=> state.current_isgs_drawl)
    const current_sche = useSelector(state=> state.liveIsgScheduleReducer)
    // console.log(current_sche)
    const dispatch = useDispatch()
    const [state, setState]= useState({
        date:null,
        date_quenext:null,
        isgs_schedule_loading:false,
        isgs_drawal_loading:false,
        isloading:false,
        isgs_drawal:[],
        isgs_schedule:[],
        od_ud:[],
        plotdataset:[]
        
    })
const handleOnchageDate= (event) => {
   
    const day = event["$D"].toString().padStart(2,0)
    const month = (event["$M"]+1).toString().padStart(2,0)
    const year = event["$y"].toString()
    const datestr =  day +"-"+month+"-"+year
    const datestr_quenext =  year +"-"+month+"-"+day
    // const query_param = {date:datestr}
    // const query_param_quenext = {date:datestr_quenext}
    setState({...state,
        date:datestr,
        date_quenext:datestr_quenext})
    // dispatch(fetchLiveIsgsSchedule(query_param))
    // dispatch(currentDrawlAction(query_param_quenext))
}


useEffect(()=> {
    const query_param = {date:state.date}
    dispatch(fetchLiveIsgsSchedule(query_param))
},[state.date])

useEffect(()=> {
    const query_param = {date:state.date_quenext}
    dispatch(currentDrawlAction(query_param))
},[state.date_quenext])
    
const labels=TimeBlock()
let line_data ={
    labels:labels,
    datasets:[]

}

    

    if(current_drawl && !current_drawl.loading && current_sche && !current_sche.loading &&
        current_drawl.data && current_sche.data && current_drawl.data.length>0 && 
        current_sche.data.length >0
    ){
        let drawl_data = []
        let sch_data = []
        let od_ud = []
        labels.forEach((element,index) => {
            let _drawl = null
            let _sch=null
            let _od_ud = null
            if(current_sche.data[index]){
                _sch=current_sche.data[index]
            }
            sch_data.push(_sch)
            let filtered_drawl = current_drawl.data.filter(item => (item.block_no===index+1))
            if(filtered_drawl && filtered_drawl.length===1){
                _drawl = filtered_drawl[0].drawl_mw
            }
            drawl_data.push(_drawl)
            if(_sch >0 && _drawl >0){
                _od_ud = _sch - _drawl
            }
            od_ud.push(_od_ud)
        });
        let data_mat = {
            isgs_drawl:drawl_data,
            isgs_schedule:sch_data,
            od_ud:od_ud
        }
        let chart_component = Object.keys(data_mat)
        let datasets =[]
        chart_component.forEach(element => {
            // console.log(element)
            let _var_={}
            _var_.type='line'
            if(element==='od_ud')
                {
                    _var_.type='bar'
                }
                _var_.label=element
            // if(element!='od_ud'){
            //     _var_.stack='Stack_0'
            // }
            _var_.backgroundColor=chartColors[element]
            _var_.data=data_mat[element]
            _var_.borderColor = chartColors[element]
            _var_.pointRadius=1
            _var_.borderWidth=4
            datasets.push(_var_)
        });
        // console.log(datasets)
        line_data={
            labels:labels,
            datasets:datasets
        }
    
        
    }




// useEffect(()=>{
//     updateDataSets()

// },[current_drawl,current_sche])

   

    return (
        <BaseCard
        action_button={<DatePickerSmall
        onChange={handleOnchageDate}
        />}
        content={

            <>
                { current_drawl &&  current_drawl.loading || current_sche && current_sche.loading &&
                <Backdrop
                sx={{ color: '#fff', zIndex: 10000 }}
                open={state.isloading}
                >
                <CircularProgress color="inherit" />
                </Backdrop>
            }

                <BarChart
                ref = {barChartref}
                data={line_data}
                />
            
            </>
        
        
        
        }
        />
        
    )
}

export default DashboardOdUd

