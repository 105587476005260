


import axios from 'axios'
import { fetchrootpath } from '../../Apis/apirootpath'
// import { fetchapi } from '../../apis/apirootpath'


export const GEN_TARIFF_REQUEST = "GEN_TARIFF_REQUEST"
export const GEN_TARIFF_SUCCESS = "GEN_TARIFF_SUCCESS"
export const GEN_TARIFF_FAILURE = "GEN_TARIFF_FAILURE"

export const getGenTariff = () => async dispatch => {
    dispatch({type: GEN_TARIFF_REQUEST})
    try{ ;
        const res = await axios.get(`${fetchrootpath}generator_tariff`)
        // console.log(res.data)
        dispatch({
            type: GEN_TARIFF_SUCCESS,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: GEN_TARIFF_FAILURE,
            payload: console.log(e),
        })
    }

}