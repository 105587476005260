import React from 'react'
import { bgColors } from '../../../utils/bgColor';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import TypographyResp from '../../../UiComponent/TypographyResp';
const  CustomDiv = ({Title,Icon, children}) => {
  return (
    <Box
      overflow={'hidden'}
      sx={{
        marginLeft:'4rem',
        marginTop:'4rem',
        backgroundColor: bgColors.grey_900,
        color:bgColors.blue_grey_100,
        height:'100Vh',
        overflowY: "scroll",

      }}
      >
    <header style = {{alignItems:'center', fontFamily: 'sans-serif'}}>
      <TypographyResp variant='h5' fontFamily='sansarif' >
          {Icon}
          {Title}          
      </TypographyResp>
    </header>
    <div>
    <Divider light />
    </div>
      {React.Children.toArray(children).map(child => child)
    }
    </Box>
  );
}
export default CustomDiv


CustomDiv.propTypes = {
    header: PropTypes.node,
    children:PropTypes.node,
   
  };
  
CustomDiv.defaultProps = {
    header : "my custom div ",
};
