import React, { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import { MapStyles } from '../constants';  
import Typography  from '@mui/material/Typography';
import { bgColors } from '../../../../utils/bgColor';
import CustomdDialog from '../../../../UiComponent/CustomDialog/CustomDialog';
import { useSelector, useDispatch } from 'react-redux';
import { setGlobalRaster, setVectorLayer,setRasterRoi } from '../../../../redux/actions/mapaction';
import { closeLayerControl } from '../../../../redux/actions/controlbuttonaction';
import { AccordianCustom } from '../../../../UiComponent/AccordianCustom';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import { CustomSwitch } from '../../../../UiComponent/CustomSwitch';
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import { setMapStyle } from '../../../../redux/actions/mapaction';
import Box from '@mui/material/Box'
// import constan
import Button  from '@mui/material/Button';
import {Indices,boundry_labels,WeatherParam,vector_layer_list,rater_layer_roi} from "../../components/constants"
import { loadState,saveState } from '../../../../utils/Localstorage';
import { Card, CardContent, CardHeader } from '@mui/material';
import { fetchWeatherSummary } from '../../../../Apis/actions/fetchWeatherSummaryAction';


// import {}

// import { index_url } from '../../../HelperFunctions/SatIndexQueryParam';
const MapStyleControl=()=>{
  const dispatch = useDispatch()
  const styleControlState = useSelector(state=>state.controlbutton.openlayercontrol)
  // const activeGlobalRaster = useSelector(state => state.mapreducer.globalraster)
  const asctiveRoi = useSelector(state => state.mapreducer.rasterroi)


  const mapstyleslabel = Object.keys(MapStyles)
  const handleClose = () => {
    dispatch(closeLayerControl)
  }
  const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

const icon_style = {color: bgColors.whitesmoke, marginRight:'0.5rem', fontFamily:'roboto'}
const Grid_style = {color: bgColors.OffWhite, marginLeft:'2.25rem', 
                  background:'transparent'}
const accordianStyle = {  
                          color:bgColors.whitesmoke,
                          // backgroundColor:`${bgColors.blue_grey}10`,
                          background: 'transparent'
                        }
const paperstyle = {position:'fixed', top:'2.5rem', right:'-1rem',minWidth: '300px',
                    background: bgColors.black , 
                    borderRadius:'10px'                 
                   }
const boxStyle = {border: 2,
                  borderColor:`${bgColors.black}90`,
                  backgroundColor:`${bgColors.black}30`,
                  borderRadius: '10px',
                  marginBottom:'0.25rem'
                  }
const typoStyle = {fontSize: 16, fontFamily:'roboto',padding:'0.5rem'}
const [checked, setChecked] = useState(false)
const initialState = {
  State:false,
  District:false,
  Subdistrict:false,
  Village:false,
  Pincode:false,
  Surveypolygons: false,
  WaterBodies: false,
  Labels: false,
  Navayer:false,
  ElevationLayer:false,
  Userproject:false
}
const [state, setState] = useState(
  initialState
);

const handleVectorChange = (event) => {
  event.preventDefault()
  setState({
    ...state,
    [event.target.name]: event.target.checked,
  });
  dispatch(setVectorLayer({
    ...state,
    [event.target.name]: event.target.checked,
  }))

};

const [mapstyle, setMapstyle] = useState("satellite_street")
const handleMapStyleChnge = (event)=> {
  setMapstyle(event.target.value)
  dispatch(setMapStyle(event.target.value))
  saveState({...loadState(),mapstyle, mapstyle:event.target.value})
}
const [weatherparam, setWeatherparam] = useState("max_temp")
const handleWeatherChange = (event)=> {
  setWeatherparam(event.target.value)
}


const [rasterroi, setRasterroi] = React.useState(asctiveRoi);
  const HandleRoiChange = (event) => {
    setRasterroi(event.target.value);
    // dispatch(setRasterRoi(rasterroi))
  };
  useEffect(()=> {
    dispatch(setRasterRoi(rasterroi))
  },[rasterroi])
  // useEffect


const [index, setIndex] = useState("None")
const handlIndexChange = (event)=> {
  setIndex(event.target.value)
  dispatch(setGlobalRaster(event.target.value))
  dispatch(setMapStyle(event.target.value))
}


// useEffect(()=> {
//   dispatch(setGlobalRaster(index))
// },[index])
// useEffect(()=> {
//   dispatch(setMapStyle(index))
// },[index])


const handleActiveWetherParam =(event) => {
  setChecked((prev) => !prev);
  setState({
    ...state,
    [event.target.name]: !state.name
  });
}

// const handleActiveRasterlayer = (event) => {
//   dispatch(event.target.value)
//   // setValue(event.target.value);
// };
return (
  <CustomdDialog
    dialogTitle={"Layers"}
    open={styleControlState}
    handleClose={handleClose}
    PaperProps = {{sx:paperstyle}}
    checked = {checked}
    content = {
    <Box 
    // sx={{flexDirection: 'row' , justifyContent: 'space-between'}}
    >
    <Box sx={boxStyle}>
      <AccordianCustom
        id = {"Map settings"}
        sx = {accordianStyle}
        expanded= {expanded}
        handleChange={handleChange}
        title_name={"Map settings"}
        panel = {'panel1'}
        icon =  {<SettingsOutlinedIcon 
        sx = {icon_style}
        />}
        >
      <FormControl>
        <RadioGroup
            aria-labelledby="Raster-group-label"
            name="Raster-group"
            value={mapstyle}
            onChange={handleMapStyleChnge}
        > 
        <Grid container spacing={1} display='flex' direction={"column"} sx = {Grid_style}>
          {mapstyleslabel.map((item,index)=> 
              <FormControlLabel 
                value={item} 
                control={<Radio 
                // value = {mapstyle}
                // onChange={handleMapStyleChnge}
                />  } 
                label={
                  <Typography 
                    sx ={typoStyle}
                      >{item}
                  </Typography> 
                }
                key = {`${item}_${index}_radio`}
              />
              )
          }
          </Grid>
    </RadioGroup>
  </FormControl>
  </AccordianCustom>
  </Box>

{/*  set boundary and labels  */}
{/*  set boundary and labels  */}
{/*  set boundary and labels  */}
{/*  set boundary and labels  */}


<Box sx={boxStyle}>
      <AccordianCustom
        id = {"Boundry and labels"}
        sx = {accordianStyle}
        expanded= {expanded}
        handleChange={handleChange}
        title_name={"Boundry and labels"}
        panel = {'panel2'}
        icon =  {<PolylineOutlinedIcon 
        sx = {icon_style}
        />}
        >
      {/* <FormGroup sx = {{marginLeft:'2.5rem', color:bgColors.whitesmoke}}> */}
           <CustomSwitch
             options={vector_layer_list}
             onChange={handleVectorChange}
            //  checked={state.}
           />
    
       {/* </FormGroup> */}
  </AccordianCustom>
  </Box>
{/*  set weather  */}
{/*  set weather  */}
{/*  set weather  */}
{/*  set weather  */}

<Box sx={boxStyle}>
      <AccordianCustom
        id = {"Weather"}
        sx = {accordianStyle}
        expanded= {expanded}
        handleChange={handleChange}
        title_name={"Weather"}
        panel = {'panel3'}
        icon =  {<CloudOutlinedIcon 
        sx = {icon_style}
        />}
        >
      <FormControl>
        <RadioGroup
            aria-labelledby="Raster-group-label"
            name="Raster-group"
            value={weatherparam}
            onChange={handleWeatherChange}
            // value={styleref}
            // onChange={handleStyleChange}
        > 
        <Grid container spacing={1} display='flex' direction={"column"} sx = {Grid_style}>
          {WeatherParam.map((item,index)=> 
              <FormControlLabel value={item.value} control={<Radio />} label={
                <Typography 
                sx ={typoStyle}
                    >{item.label}
                </Typography> 
              }
              key = {`${item}_${index}_radio`}
              />
              )
          }
          </Grid>
    </RadioGroup>
  </FormControl>
  </AccordianCustom>
  
  </Box>
{/*  set incdex  */}
{/*  set incdex  */}
{/*  set incdex  */}
{/*  set incdex  */}
<Box sx={boxStyle}>
<Card>
            <CardHeader
           subheader= "Region of interest"
           
          /> 
           
            <CardContent>
          <FormControl >
        <RadioGroup
           key = "Raster-group-label"
            aria-labelledby="Raster-group-label"
            name="Raster-group"
            value={rasterroi}
            onChange={HandleRoiChange}
        > 
          <Grid container spacing={1} display='flex' direction={"row"} sx = {Grid_style}>
              {rater_layer_roi.map((item,index)=> 
                < FormControlLabel 
                key = {`${item}_${index}_radio`}
                value={item} 
                onChange={HandleRoiChange}  
                control={<Radio />} label={
                  <Typography 
                      sx ={typoStyle}
                      >
                        {item}
                  </Typography> 
                }
              />
              )
          }
          </Grid>
      </RadioGroup>
     </FormControl>
     </CardContent>
        </Card>

      <AccordianCustom
        id = {"Indices"}
        key= {"Indices"}
        sx = {accordianStyle}
        expanded= {expanded}
        handleChange={handleChange}
        title_name={"Indices"}
        panel = {'panel4'}
        icon =  {<LayersOutlinedIcon 
        sx = {icon_style}
      
        />}
        
        >
          
        
      <FormControl >
        <RadioGroup
           key = "Raster-group-label"
            aria-labelledby="Raster-group-label"
            name="Raster-group"
            value={index}
            onChange={handlIndexChange}
        > 
          <Grid container spacing={1} display='flex' direction={"column"} sx = {Grid_style}>
              {Indices.map((item,index)=> 
                < FormControlLabel 
                key = {`${item}_${index}_radio`}
                value={item.value} 
                onChange={handlIndexChange}
                control={<Radio />} label={
                  <Typography 
                      sx ={typoStyle}
                      >
                        {item.label}
                  </Typography> 
                }
              />
              )
          }
          </Grid>
     </RadioGroup>
    </FormControl>
  </AccordianCustom>
  </Box>
  </Box>
    }
    action = { 
      <div>
        <Button variant='outlined'

        // onClick={saveState}
        
        >
          Save setting
        </Button>
      </div>
    }
  />
);
}

export default MapStyleControl
