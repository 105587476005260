import {API} from "./types";
import { fetchrootpath } from "../apirootpath";
export const FETCH_DA_WINDGEN_FORECAST = "FETCH_DA_WINDGEN_FORECAST"
export const FETCH_DA_WINDGEN_FORECAST_ERROR = "FETCH_DA_WINDGEN_FORECAST_ERROR"
export const SET_DA_WINDGEN_FORECAST = "SET_DA_WINDGEN_FORECAST"


export function fetchDaWindgenForecast(method,query_param){
    const get_url = `${fetchrootpath}/da_windgen_forecast`
    return apiAction({
      url:get_url,
      method:method,
      data:query_param,
      onSuccess: setData,
      onFailure: (error) => ({
        type: FETCH_DA_WINDGEN_FORECAST_ERROR,
        payload: error
      }),
      label: FETCH_DA_WINDGEN_FORECAST
    });
  }


  function setData(data) {
    // console.log(data)
    //let weather = firebase.database().ref('locations/1');
    // //weather.child('weather_forecast').set(json);
    // console.log(data);
    return {
      type: SET_DA_WINDGEN_FORECAST,
      payload: data
    };
  }
  
  function apiAction({
    url = "",
    method = "",
    data = null,
    accessToken = null,
    onSuccess = () => {},
    onFailure = () => {},
    label = "",
    headersOverride = null
  }) {
    return {
      type: API,
      payload: {
        url,
        method,
        data,
        accessToken,
        onSuccess,
        onFailure,
        label,
        headersOverride
      }
    };
  }




  