import { TextField, Typography } from "@mui/material";
import axios from 'axios';
import dayjs from 'dayjs';
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useState } from "react";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { pythonapi } from "../../../Apis/apirootpath";
import { DatePickerValue,DateStrEvent } from "../../../UiComponent/DatePicker";
import { Tomorrow } from "../../../container/dashboard.fn";
import { AccordianCustom } from "../../../UiComponent/AccordianCustom";
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import CircularProgress from '@mui/material/CircularProgress';

const NN_model_da_solargen = () => {
    const [state, setState]=useState(
       {
        forDate:dayjs(Tomorrow),
        nn_lag:30,
        med_filter_window: 5,
        lamda:0.4,
        nn_count:4,
        request_forecast:false,
        set_forecast:[]
       }
    )
    const onDateChange = (event) => {
        // let date_str = DateStrEvent(event)
        // console.log(date_str)
        setState({...state,forDate:event})
    }

    const run_req = {
        params:{
          message:'run_script,',
          forDate:DateStrEvent(state.forDate),
          nn_lag:state.nn_lag,
          med_filter_window: state.med_filter_window,
          lamda:state.lamda,
          nn_count:state.nn_count
        }
      }
    
    const [expanded , setExpanded]= useState(false)
    const handleChangeAccordian = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };
    const GenerateDayaheadDAM_NN = async (e) => {
      setState({...state, request_forecast:true})
      // setExpanded(newExpanded ? panel : false)
      e.preventDefault();
      try {
      axios.get(`${pythonapi}/NN_scoring_da_solrgen_scoring`,
        run_req,
        { headers: {
            'Content-Type': 'application/json',
            'Accept-Charset': 'utf-8',
        }
          }
        )
        .then((res) => {
          // hidespinneActualNRweather()
          setState({...state,
            request_forecast:false,
            set_forecast:res.data})
           

          // alert(`Load forecast for the state of ${run_req.params.state} completed`)
        })
        .catch(error => {
          setState({...state, 
            request_forecast:false,
            errorMessage: error.message})
        })
        } 
        catch (e) {
          setState({...state, request_forecast:false})
          alert(`submit failed! ${e.message}`);
        }
    }

  
 
    return (
        <>
        <Stack direction="column" spacing={2} >
        <DatePickerValue
                    defaultValue={dayjs(Tomorrow)}
                    label="For Date"
                  onChange={onDateChange}
            />
        <TextField
            type="number"
            label={"lag-window"}
            size="small"
            value={state.nn_lag}
            onChange={(event)=> setState({...state, nn_lag:event.target.value})}
            />

         <TextField
            type="number"
            label={"lamda weight"}
            size="small"
            defaultValue={0.45}
            onChange={(event)=> setState({...state, lamda:event.target.value})}
            />

        <TextField
            type="number"
            label={"neighbour_count"}
            size="small"
            defaultValue={4}
            onChange={(event)=> setState({...state, nn_count:event.target.value})}  
            />
        <Button
            component="label"
            variant="contained"
            startIcon={<KeyboardArrowUpIcon />}
            onClick={GenerateDayaheadDAM_NN}
            endIcon={state.request_forecast && 
              <CircularProgress/>
            }
            >
            Run Scoring
        </Button>

        {/* {state.set_forecast &&  */}
          <AccordianCustom
          id = {"accordian seting-utility"}
          expanded= {expanded}
          handleChange={handleChangeAccordian}
          title_name={"ML State log"}
          panel = {'panel1'}
          icon =  {<AnalyticsOutlinedIcon 
          sx = {{marginRight:'1rem' }}
          />}
        > 
     
     {state.set_forecast}
   </AccordianCustom>
      
      
        
        </Stack>
        </>
    )
}
export default NN_model_da_solargen