
import {
    CLICK_SIGNUP_OPEN,
    CLICK_SIGNUP_CLOSE,
    CLICK_SIGNIN_OPEN,
    CLICK_SIGNIN_CLOSE,
    EMAIL_AUTH_OPEN,
    EMAIL_AUTH_CLOSE,
    CLICK_RESETPASSORD_REQ_OPEN,
    CLICK_RESETPASSORD_REQ_CLOSE
  } from "../actions/authcontrolaction"
// import { sidebarwith } from "../../components/layout/const";


const initialState = {
    openSignIn: true,
    openSignUp: false,
    openEmailVerification: false,
    openResetPasswordReq:false,
    
  }


const authcontrolReducer =  (state = initialState, action) => {
    switch (action.type) {
      case CLICK_SIGNUP_OPEN:
        // const state = initialState;
        return {
            ...initialState,
            openSignIn: false,
            openSignUp: true }
        case CLICK_SIGNUP_CLOSE:
            return {
                ...initialState,
                openSignIn: true,
                openSignUp: false,

            }
        case CLICK_SIGNIN_OPEN:
            return {
                ...initialState,
                openSignIn: true,
                openSignUp: false,
            }

        case CLICK_SIGNIN_CLOSE:
            return {
                ...initialState,
                openSignIn: false,
                openSignUp: true
            }

        case EMAIL_AUTH_OPEN:
            return {
                ...initialState,
                openEmailVerification:true,
                openSignIn: false
            }
        case EMAIL_AUTH_CLOSE:
            return {
                ...initialState,
                openEmailVerification:false,
            }

            case CLICK_RESETPASSORD_REQ_OPEN:
                return {
                    ...initialState,
                    openResetPasswordReq:true,
                    openSignIn: false,
                }
            case CLICK_RESETPASSORD_REQ_CLOSE:
                return {
                    ...initialState,
                    openResetPasswordReq:false,
                    openSignIn: true,
                }

      default:
        return state;
    }
  };

  export default authcontrolReducer  


 