import React, {useState, useEffect} from 'react'
import Button from '@mui/material/Button'
import Box  from '@mui/material/Box'
// import {set_statelist} from "./helperFn"
import { fetchDicomMaster } from '../../Apis/actions/discomMaster'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncAutoComplete } from '../../UiComponent/AsyncAutoComplete'
import Grid from '@mui/material/Grid'
// import { loadState} from '../../utils/localStorage'
import { setSelectedUtility } from '../../redux/actions/StaticDataAction'
import CircularProgress from '@mui/material/CircularProgress';
import { loadState,saveState,returnInitialState } from '../../utils/Localstorage'

const user_role = {
    role: "admin",
}

const user_organization = {
    discom:"UPCL",
    state:"UTTARAKHAND",
    beneficiary:"UTTARAKHAND_STATE",
    area:"N2",
    id:""
}

// store.subscribe(
//   debounce(() => {
//     saveState(store.getState().selected_utility);
//   }, 800)
// );

const SetUtility = () => {
    const dispatch = useDispatch()
    useEffect(()=>{
      dispatch(fetchDicomMaster())
    },[dispatch])



const [state,setState] = useState({
    loading:false,
    open: false,
    openStateList: false,
    openUtilityList: false,
    options: [],
    statelist: [],
    utilitylist:[]
})

function UpDateFormLsUtil(){
    let initialState = {
        selected_region:"",
        selected_state:"",
        selected_utility:""

    }
    if(loadState()!==undefined && loadState().selected_utility !==undefined){
        initialState.selected_region= loadState().selected_utility.selected_region
        initialState.selected_state = loadState().selected_utility.selected_state
        initialState.selected_utility = loadState().selected_utility.selected_utility
    }
    return initialState
}


const [selected_utility, setSelected_utility] = useState(UpDateFormLsUtil())
const onOpen = () => {
  setState({...state, open:true});
}
const onClose = () => {
setState({...state,open:false});;
}
const onOpenStatelist = () => {
  setState({...state, openStateList:true});
}
const onCloseStatelist = () => {
  setState({...state, openStateList:false});
}
const onOpenUtilitylist = () => {
  setState({...state, openUtilityList:true});
}
const onCloseUtilitylist = () => {
  setState({...state, openUtilityList:false});
}
const discom_master = useSelector(state => state.discomMasterReducer)
// console.log(discom_master)
function set_options(){
    setState({...state,loading:true})
    if(discom_master && discom_master.data)
    {
    let data = discom_master.data
    
    let region_master_label = []
    if (data.length >0 ) 
        {
        data.forEach((item) => 
        { 
        let hasRegion = region_master_label.some(e => e.label === item.region);
        if  (!hasRegion) {
            let _var = {'label':item.region}
            region_master_label.push(_var)}
        }
        )
        setState({...state, options:region_master_label, loading:false})
    }}
}

  function set_statelist(){
    if(discom_master){
    let data = discom_master.data
    let state_master_label = []
    if (data.length > 0 && selected_utility.selected_region !== "") 
          {data.forEach((item) => 
            { 
            let hasState = state_master_label.some(e => e.label === item.state);
            if  (!hasState && item.region === selected_utility.selected_region) 
              {let _var = {label:item.state}
              state_master_label.push(_var)}
            }
            )
            setState({...state, statelist:state_master_label})
          }
        }
      
    }

    function utility_list(){
        if(discom_master){
      let data = discom_master.data
      let utility_master_label = []
      if (data.length > 0 
        && selected_utility.selected_region !== "" 
        && selected_utility.selected_state !="") 
            {data.forEach((item) => 
              { 
              let hasUtility = utility_master_label.some(e => e.label === item.discon);
              if  (!hasUtility && item.region === selected_utility.selected_region 
                && item.state===selected_utility.selected_state) 
                {let _var = {label:item.discom}
                utility_master_label.push(_var)}
              }
              )
              setState({...state, utilitylist:utility_master_label})
            } 
        }
      }

 useEffect(()=> {
  if (state.open){
    set_options()
  }
 },[state.open])

 useEffect(()=> {
  if (selected_utility.selected_region !==""){
    set_statelist(discom_master,selected_utility.selected_region)
  }
 },
 [state.openStateList,selected_utility.selected_region]
 )


 useEffect(()=> {
  if (selected_utility.selected_region !=="" && selected_utility.selected_state!=="") {
    utility_list()
  }
 },
 [state.openUtilityList, 
  selected_utility.selected_region,
  selected_utility.selected_state]  
  ) 


    return (
        <Box>
        {state.loading === true && 
            <CircularProgress />
        }

      <Grid container  spacing={2} sx = {{alignItems:'center'}}>
        <Grid item>
            <AsyncAutoComplete
                 id = 'region_dd'
                 textfield_id = 'textfield_region_dd'
                options={state.options}
                loading={state.loading}
                open= {state.open}
                onOpen={onOpen}
                onClose={onClose}
                label = 'Select region'
                // defaultValue={selected_utility['selected_region']}
                value = {selected_utility.selected_region}
                onChange={(event, value) =>
                   {
                    setSelected_utility({...selected_utility, selected_region:value['label']})
             
                  }
                  }
            />
          </Grid>
          <Grid item>
            <AsyncAutoComplete
                id = 'state_dd'
                textfield_id = 'textfield_state_dd'
                options={state.statelist}
                loading={state.loading}
                open= {state.openStateList}
                onOpen={onOpenStatelist}
                onClose={onCloseStatelist}
                label = 'Select state'
                // defaultValue = {selected_utility.selected_state}
                value = {selected_utility.selected_state}

                onChange={(event, value) =>
                  {
                    setSelected_utility({...selected_utility, selected_state:value['label']})
                 }
                 }
            />
      </Grid>
      <Grid item>
        <AsyncAutoComplete
                id = 'utility_dd'
                textfield_id = 'textfield_utility_dd'
                options={state.utilitylist}
                loading={state.loading}
                open= {state.openUtilityList}
                onOpen={onOpenUtilitylist}
                onClose={onCloseUtilitylist}
                label = 'Select utility'
                value = {selected_utility.selected_utility}
                onChange={(event, value) =>
                  {
                  setSelected_utility({...selected_utility, selected_utility:value['label']})
    
                 }
                 }
            />
            </Grid>
            <Grid item>
              <Button
                  onClick={()=> {
                    dispatch(setSelectedUtility(selected_utility))
                    saveState({...loadState(),"selected_utility":selected_utility, "test_utility":selected_utility})
                  }
                 }
                  >
                  Submit
              </Button>
            </Grid>
</Grid>
 {/* }  */}
</Box>

    )
}

export default SetUtility














