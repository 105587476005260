import {range,FilterArrayKeyval} from "../../../utils/ArryOps";
// const colors = ['#D95319AA', '#EDB120AA', '#77AC30AA', '#4DBEEEAA', '#A2142FAA']
// const colors  =["#EF5350AA","#5C6BC0AA","#4DB6ACAA","#FBC02DAA","#D7CCC8AA","#B3E5FCAA","#C8E6C9AA"]
// const colors = ["#3da564bb","#56a977","#386f54","#8ec165","#c7ff87AA","#9bad7bAA", "#758754AA"]
export default function color_specs(specifier) {
    var n = specifier.length / 6 | 0, colors = new Array(n), i = 0;
    while (i < n) colors[i] = "#" + specifier.slice(i * 6, ++i * 6);
    return colors;
  }

//   const colors = [ "#F7DC6F35","#E74C3C35", "#EAECEE35" , "#1F618D35", "#EBF5FB25","#6495ED35"]

// const colors = ('#21618C', '#3498DB', '#16A085',  '#E5E7E9','#EC7063')

const colors = ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)", "rgba(255, 206, 86, 0.2)", 
       "rgba(75, 192, 192, 0.2)", "rgba(153, 102, 255, 0.2)","rgba(255, 159, 64, 0.2)"]

// const colors = ["F97E6DAA","F97E6DAA","64557BAA","1E2136AA"]
// // "rgba(255, 99, 132, 0.2)",
// "rgba(54, 162, 235, 0.2)",
// "rgba(255, 206, 86, 0.2)",
// "rgba(75, 192, 192, 0.2)",
// "rgba(153, 102, 255, 0.2)",

const makeRepeated = (arr, repeats) =>
Array.from({ length: repeats }, () => arr).flat();

function roundUp(num, precision) {
    precision = Math.pow(10, precision)
    return Math.ceil(num * precision) / precision
  }

export function prepareMultiLineData(data,key,value_field){
    const unique_series =[...new Set(data.map(q => q[key]))]
    let count = 0
   
    const length_keys = unique_series.length ;
    const number_of_num_color = length_keys / colors.length
    const num_color = makeRepeated(colors, roundUp(number_of_num_color, 0))
    let all_datasets = []
    unique_series.forEach((element,index) => {
        
        // console.log(element)
        let data_var = {}
        let filter = {
            key:element
        }
        let filtered_array = FilterArrayKeyval(data,[key],[element])                        
         let line_data = []
         let count = 0
         filtered_array.forEach((item,index) => {
            if(item[value_field]!==0){
                count += 1
            }
             let data = item[value_field]
            line_data.push(data)
         });
         if(count >0)

        {  
         data_var.type='line' 
         data_var.label=element
         data_var.data=line_data
         data_var.borderColor=num_color[index]
         data_var.backgroundColor=num_color[index]
         data_var.borderWidth= 3
         all_datasets.push(data_var)
        }
        });
    return all_datasets
} 

export function prepareAreaChartData(data,key,value_field){
    const unique_series =[...new Set(data.map(q => q[key]))]
    const length_keys = unique_series.length ;
    // const  num_color=color_specs(unique_series)
    const number_of_num_color = length_keys / colors.length
    const num_color = makeRepeated(colors, roundUp(number_of_num_color, 0))
    // const type ='line'
    let all_datasets = []
    unique_series.forEach((element,index) => {
        let data_var = {}
        let count = 0
        let filtered_array = FilterArrayKeyval(data,[key],[element])                        
         let line_data = []
         filtered_array.forEach((item,index) => {
            let _data = item[value_field]
            line_data.push(_data)
            if(item[value_field]!==0){
                count += 1
            }
            
         });
         if(count >0)
        { 
        data_var.fill= true
         data_var.label=element
         data_var.data=line_data
         data_var.borderColor=num_color[index]
         data_var.backgroundColor=num_color[index]
         data_var.borderWidth= 4
         data_var.stack='Stack 0'
         data_var.type= 'line'
         all_datasets.push(data_var)
        }
        });
    return all_datasets
} 



export function prepareMixedAreaChartData( data,key,
                                          value_field, 
                                          area_stacked_element, 
                                          non_staked_line,
                                          bar_chart_stacked,
                                          bar_comp_nonstacked)
    {
        const unique_series =[...new Set(data.map(q => q[key]))]
        const length_keys = unique_series.length ;
        const number_of_num_color = length_keys / colors.length
        const num_color = makeRepeated(colors, roundUp(number_of_num_color, 0))
        let all_datasets = []

        unique_series.forEach((element,index) =>{
            let data_var = {}
            let count = 0
            let filtered_array = FilterArrayKeyval(data,[key],[element]) 
            let line_data = []
            filtered_array.forEach((item,index) => {
                let _data = item[value_field]
                line_data.push(_data)
                if(item[value_field]!==0){
                    count += 1
                }
             });
             if(count >0){
                data_var.label=element
                data_var.data=line_data
                data_var.borderColor=num_color[index]
                data_var.backgroundColor=num_color[index]
                data_var.borderWidth= 3
                if(area_stacked_element && area_stacked_element.includes(element)){
                    data_var.fill= true
                    data_var.type= 'line'
                    data_var.stack= 'Stack_0'
                    // data_var.yAxisID= 'ystacked'
                 }
                 if(non_staked_line && non_staked_line.includes(element)){
                    data_var.fill= false
                    data_var.type= 'line'
                 }
                 if(bar_chart_stacked && bar_chart_stacked.includes(element)){
                    data_var.fill= false
                    data_var.type= 'bar'
                    data_var.stack= 'Stack_0'
                 }
                 if(bar_comp_nonstacked && bar_comp_nonstacked.includes(element)){
                    data_var.fill= false
                    data_var.type= 'bar'
                 }
                 all_datasets.push(data_var)

             }
        })
    
        return all_datasets
        
    }





export function barChartData(datasets,key){
    const unique_series =[...new Set(datasets.map(q => q[key]))]
    const length_keys = unique_series.length ;
    // const  num_color=color_specs(unique_series)
    const number_of_num_color = length_keys / colors.length
    const num_color = makeRepeated(colors, roundUp(number_of_num_color, 0))
    // const type ='line'
    let all_datasets = []
    datasets.forEach((element,index) => {
        let data_var ={}
        data_var.fill= true
        data_var.label=element.label
        data_var.data=element.data
        data_var.borderColor=num_color[index]
        data_var.backgroundColor=num_color[index]
        data_var.borderWidth= 4
        data_var.stack='Stack 0'
        //  data_var.yAxisID= 'ystacked'
        // data_var.type= 'line'
        all_datasets.push(data_var)
        
        });
    return all_datasets
} 










