import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types'
import { Paper } from '@mui/material';

const CustomdDialog=({open,handleClose,dialogTitle,content,action,PaperProps}) => {
//   const [open, setOpen] = React.useState(false);
//   const handleClickOpen = () => {
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(false);
//   };

  return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{ sx: { borderRadius: "10px" , padding:'2px', width:'350px', 
          justifyContent:'center', alignItems:'center'} }}
      >
        <DialogTitle id="customized-dialog-title">
        {React.Children.toArray(dialogTitle).map(child => child)}
        </DialogTitle>
        <DialogContent  >
        {React.Children.toArray(content).map(child => child)}
        </DialogContent>
        <DialogActions>
        {React.Children.toArray(action).map(child => child)}
        </DialogActions>
      </Dialog>
  );
}

export default CustomdDialog

CustomdDialog.propTypes = {
    open: PropTypes.bool,
    handleClose:PropTypes.func,
    dialogTitle: PropTypes.node,
    content: PropTypes.node,
    action: PropTypes.node,
    PaperProps: PropTypes.object
   };
   
// CustomdDialog.defaultProps = {
//     dialogTitle:'my custom dialog'
// };