import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CollapseFooterCard=({
    title, 
    subtitle,
    header_action,
    header_content,
    main_content,
    action_content,
    Collapsible_content_header,
    collapsible_content,...otherprops}) =>{
    const [expanded, setExpanded] = React.useState(false);
    const handleExpandClick = () => {
      setExpanded(!expanded);
   
    };

  return (
    <Card  {...otherprops}>
        <CardHeader {...otherprops}>
          title={title}
          subheader={subtitle}
          {React.Children.toArray(header_action).map(child => child)}
          <CardContent  
  
          {...otherprops}
         >

        {React.Children.toArray(header_content).map(child => child)}
        </CardContent >
        </CardHeader>
      <CardContent 
       {...otherprops}
      >
      {React.Children.toArray(main_content).map(child => child)}
      </CardContent>
      <CardActions disableSpacing 
       {...otherprops}
      >
      {React.Children.toArray(action_content).map(child => child)}

      <Typography textAlign={'center'} variant='h6'>
          {Collapsible_content_header}
      </Typography>
      
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
         
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent {...otherprops}>
        {React.Children.toArray(collapsible_content).map(child => child)}
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default CollapseFooterCard

CollapseFooterCard.propTypes = {
  title: PropTypes.string,
  header_action:PropTypes.node,
  subtitle: PropTypes.string,
  header_content: PropTypes.node,
  main_content: PropTypes.node,
  action_content:PropTypes.func,
  action_content: PropTypes.node,
  Collapsible_content_header:PropTypes.string,
  collapsible_content: PropTypes.node,
  // otherprops:PropTypes.
 };
 
//  CollapseFooterCard.defaultProps = {
//   avtar_txt: "AV",
//   title:'I am title',
//   subtitle:'I am subtitle'
// };

// title, 
//     subtitle,
//     header_action,
//     header_content,
//     main_content,
//     action_content,
//     collapsible_content,...otherprops

