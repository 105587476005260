import React, { useState , forwardRef} from "react";
import BaseMap from '../components/mapcomponents/basemap.js';
import {MapStyleControl} from "../components/mapcomponents/components/ControllerStyle";
import {AnalysisWindow} from '../components/mapcomponents/components/AnalysisWindow';
import {SaveUserProject} from '../components/mapcomponents/components/SaveUserProject';
import {CustomUserFolio} from '../components/mapcomponents/components/CustomeUserFolio';  
// import AsyncSelectSearch from '../components/AsyncSelectSearch/AsyncSelectSearch.js';
// import DateSlider from '../components/UiComponent/DateSlider/DateSlider.js';
import DateSlider from "../UiComponent/DateSlider/DateSlider.js";
import Toolbar from '@mui/material/Toolbar';
import { AppBar } from "../components/Layout/container/StyledAppBar.js";
import Searchbar from "../components/Layout/Searchbar/Searchbar.js";
import AsyncSelectSearch from "../components/AsyncSelectSearch/AsyncSelectSearch.js";
const Mappage = () => {  
  return (
    <>
      <MapStyleControl/>
      <AnalysisWindow/>
      <SaveUserProject/>
      <CustomUserFolio/>
      <DateSlider/>
      <BaseMap/>
     
    </>
  )
}

export default Mappage