

import { PageLayout } from './components/Layout/Pagelayout';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter, Routes, Route,Navigate } from 'react-router-dom'
import LayoutRoute from './components/Layout/LayoutRoute';

import Mappage from './Pages/Mappage';
import {Dashboard} from './Pages/Dashboard';

import { Positionmap } from './Pages/Dashboard/Positionmap';
import Forecasting from './Pages/Forecasting/Forecasting';
import { DataIntegration } from './Pages/DataIntegration';
import { DataImport } from './Pages/DataImports';
import TestApp from './Pages/TestApp';
import TestApptest from './Pages/TestApptest';
import AuthControl from './Pages/AuthControl';
import PrivateRoutes from './components/login/utils/PrivateRoutes';
import ResetPassword from './components/login/ResetPassword';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  
});
let basename_path = null;
var url = window.location.pathname.toLowerCase();
 if(url.indexOf("react") === -1){    // If string(react) not available
         basename_path = "/";
 }
  else{
    var array = url.split("react");
    basename_path = array[0]+"react/";
 }

//  console.log(sessionStorage.getItem())

const App = () => {


  return (
    <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    {/* <LayoutRoute
            exact
            path="/login"
            layout={PageLayout}
            component={props => (
              <AuthControl />
            )}
          /> */}
    
    <BrowserRouter basename={basename_path}>
    <PageLayout> 
        <Routes>
        <Route  element={<PrivateRoutes/>}>
              <Route path="/Homepage" element={<Mappage/>} />
              <Route path="/Positionmap" element={<Positionmap/>} />
              <Route path="/Dashboard" element={<Dashboard/>} />
              <Route path="/DataImport" element={<DataImport/>} /> 
              <Route path="/DataIntegration" element={<DataIntegration/>} /> 
              <Route path="/Forecasting" element={<Forecasting/>} /> 
              <Route path ="/Testcomp" element = {<TestApp/>} />
              <Route path ="/Testcomptest" element = {<TestApptest/>} />
          </Route>
          <Route element={<AuthControl/>} path="/login"/>
          <Route element={<ResetPassword/>} path="/ResetPassword/:reset_token"/> 

          {/* </PageLayout> */}
          {/* <Navigate to="/" /> */}
        </Routes>
        </PageLayout> 
     
      </BrowserRouter>
      </ThemeProvider>
  );
}
  


  

//     return (
//       <ThemeProvider theme={darkTheme}>
//       <CssBaseline />
//       <BrowserRouter basename={basename_path}>
//       <PageLayout>
//          <Routes>
//               <Route path="/Homepage" element={<Mappage/>} />
//               <Route path="/Positionmap" element={<Positionmap/>} />
//               <Route path="/Dashboard" element={<Dashboard/>} />
//               <Route path="/DataImport" element={<DataImport/>} /> 
//               <Route path="/DataIntegration" element={<DataIntegration/>} /> 
//               <Route path="/Forecasting" element={<Forecasting/>} /> 
//               <Route path ="/Testcomp" element = {<TestApp/>} />
//               <Route path ="/Testcomptest" element = {<TestApptest/>} />
//           </Routes>
//         </PageLayout>
//       </BrowserRouter>
//       </ThemeProvider>
//     );
// }

export default App;



