
export const UPDATE_DRAG_STATE  = "UPDATE_DRAG_STATE"
export const UPDATE_EDIT_MODE_STATE  = "UPDATE_EDIT_MODE_STATE"
export const UPDATE_UPCATE_CONTROL_POINT_ONE_STATE  = "UPDATE_UPCATE_CONTROL_POINT_ONE_STATE"
export const UPDATE_UPCATE_CONTROL_POINT_TWO_STATE  = "UPDATE_UPCATE_CONTROL_POINT_TWO_STATE"

export const setDraggedState = (draggedstate) => ({
    type: UPDATE_DRAG_STATE,
    payload: draggedstate
 })
 export const setEditMode = (edit_mode) => ({
    type: UPDATE_EDIT_MODE_STATE,
    payload: edit_mode
 })

 export const setControlPointOne = (control_point_one) => ({
    type: UPDATE_UPCATE_CONTROL_POINT_ONE_STATE,
    payload: control_point_one
 })

 export const setControlPointTwo = (control_point_two) => ({
    type: UPDATE_UPCATE_CONTROL_POINT_TWO_STATE,
    payload: control_point_two
 })














