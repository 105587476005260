import {useEffect, useState} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import TableViewIcon from '@mui/icons-material/TableView';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress'
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Backdrop from '@mui/material/Backdrop'; 
import { BarChart } from '../../../../../components/ReactChart/BarChart';
import { fetchDayAheadSchDetails } from '../../../../../Apis/actions/fetchDayAheadSchDetails';
import { TimeBlock } from '../../../../../container/dashboard.fn';
import { PlaneCard } from '../../../../../UiComponent/CustomCard';
import { barChartData } from '../../../../../components/ReactChart/MultiLine/linedata';
// import BaseDatagrid from '../../../../../components/Tables/BaseDatagrid';
import FullFeaturedCrudGrid from '../../../../../components/EditableDatagrid';
// import Controller from '../../../../../components/EditableDatagrid/Controller';

import { style } from '../../Layout/const';
const LadderBid = () => {
    const dispatch = useDispatch()
    const da_sch_state = useSelector(state=> state.da_schdetails_state)
    const selected_date = useSelector(state => state.ForecastComp)
    const [state, setState] = useState({
            displayPlot:false,
            loading:false,
            Transpose:false,
            date: selected_date.forDate,
            ladderbid_mat:[],
            mustrun_table:[],
            mustrun_column:[],
            transposed_table:[],
            transposed_colum:[],
            plotdata:{}
    })

 const handleSwitch = (event) => {
    if(state.Transpose === true) {
        setState({...state, Transpose:false})
    } else if (state.Transpose=== false) {
        setState({...state, Transpose:true})
    }
  }

  
    const toggleTableGraph=() =>{
        if(state.displayPlot === true) {
            setState({...state, displayPlot:false})
        } else if (state.displayPlot=== false) {
            setState({...state, displayPlot:true})
        }
    }
    useEffect(()=> {
        if(da_sch_state && da_sch_state.loading){
            setState({...state, isLoading:true})
        }
        if(da_sch_state && !da_sch_state.loading 
            && da_sch_state.data && da_sch_state.data.ladder_bid_salevol_mat){
                setState({...state, loading:false,
                    ladderbid_mat:da_sch_state.data.ladder_bid_salevol_mat

                })
            }
    },[da_sch_state])

    useEffect(()=> {
        let labels = TimeBlock()
        let plotdata
        let mustrun_table
        let mustrun_column
        let transpose_table 
        let transpose_table_column
//    console.log(state.ladderbid_mat)

    if(state.ladderbid_mat && state.ladderbid_mat.length >0)
        {
        let unit_list = [...new Set(state.ladderbid_mat.map(q => q['unit_name']))]
        let datasets = []
        let _transposed = []
        unit_list.forEach(element => {
            let filtered_data = state.ladderbid_mat.filter(item => (item.unit_name ===element))
            let mr_dict = {}
            mr_dict.label = element
            let _data = []
            labels.forEach((label,index) => {
                let _block_sch = filtered_data.filter(item => (item.block_no === index+1))
                let _sch_mw = _block_sch[0].sell_volume

                _data.push(_sch_mw)
            });
            mr_dict.data = _data
            datasets.push(mr_dict)
        } 
        
    );
    labels.forEach((label,index)=> {
        let _transposed_dict ={}
        _transposed_dict.block_no = label
        
        let _block_unit_sch = state.ladderbid_mat.filter(item => (item.block_no===index+1))
        _block_unit_sch.forEach(_unit_sch=> {
            _transposed_dict[_unit_sch.price] = _unit_sch.sell_volume
        })
        
        _transposed.push(_transposed_dict)
    })
    transpose_table =_transposed

    let _transpose_table_keys=Object.keys(transpose_table[0])
    let _transpose_table_columns_ = (['block_no']).concat(_transpose_table_keys.slice(0, -1))
    // console.log(_transpose_table_columns_)
    // console.log(_transpose_table_keys.slice(0, -1))
    let _transpose_table_columns = []
    _transpose_table_columns_.forEach(element => {
        let _dict = {}
        _dict.field=element
        _dict.header=element
        // if(element!=='bl'){
        //     _dict.editable= true
        // }
        _transpose_table_columns.push(_dict)
    });
   
    transpose_table_column = _transpose_table_columns
    // console.log(_transposed)

        let key = 'label'
        let final_plot_data = barChartData(datasets,key)

        let table_data = []
        datasets.forEach(element => {
            let _dict = {}
            _dict.price=element.label
            let _data = element.data
            labels.forEach((element,index) => {
                _dict[element]=Math.round(_data[index])
            });
            _dict.price=element.label
            table_data.push(_dict)

        });
           

        let _table_keys=Object.keys(table_data[0])
        let _table_columns = []
        _table_keys.forEach(element => {
            let _dict = {}
            _dict.field=element
            _dict.header=element
            if(element!=='unit_name'){
                _dict.editable= true
            }
            _table_columns.push(_dict)
        });
    mustrun_table = table_data  
    mustrun_column = _table_columns
    plotdata = {
        labels:labels,
        datasets:final_plot_data
    }
    }
    setState({...state, mustrun_table:mustrun_table, 
        mustrun_column:mustrun_column, plotdata:plotdata,
        transposed_colum: transpose_table_column,
        transposed_table:transpose_table

    })

    },[state.ladderbid_mat,state.Transpose])

   
    
    const query_params = {
        date:selected_date.forDate
     }
     const LoadData =  async(e) => {
         await dispatch(fetchDayAheadSchDetails("GET", query_params))
     } 

     useEffect(()=> {
        dispatch(fetchDayAheadSchDetails("GET", query_params))
     },[dispatch])

     const onSaveRow = (row)=> {
        console.log(row)

     }

     console.log(state.Transpose)
    return(
        <>
            <PlaneCard 

        avatar_content = {
        <Stack direction ={'row'} 
            justifyContent="flex-start"
            alignItems="flex-start" 
            spacing={2} >
    {state.displayPlot && 
        <IconButton
        sx ={style.icon_style}
        onClick={toggleTableGraph}
        >
        <LegendToggleIcon/>
        </IconButton>
        }

    {!state.displayPlot && 
        <IconButton
        sx ={style.icon_style}
        onClick={toggleTableGraph}
        >
        <TableViewIcon/>
        </IconButton>
    }
          <FormControlLabel required control={<Switch />} 
          value={state.Transpose}
          onChange={handleSwitch}
          label="Transpose" />
         </Stack>
}
header_content={
    <>
    <Stack direction ={'row'} spacing={2} >
      <Button  variant = "contained" startIcon = <RefreshIcon/>
        onClick={LoadData}
            >
        Refresh-data
     </Button>

    </Stack>

    </>

}

main_content ={
    <>
     { da_sch_state && da_sch_state.loading &&
        <Backdrop
        sx={{ color: '#fff', zIndex: 10000 }}
        open={da_sch_state.loading}
        >
        <CircularProgress color="inherit" />
        </Backdrop>
    }
    {state.ladderbid_mat && state.ladderbid_mat.length >0 && state.displayPlot &&

    <BarChart
        data={state.plotdata}
    />
} 

{state.ladderbid_mat && state.ladderbid_mat.length >0 && !state.displayPlot && !state.Transpose &&

  <FullFeaturedCrudGrid
            columns={state.mustrun_column}
            rows={state.mustrun_table}
            // onSaveRow={onSaveRow}
            // onDeleteRow={onDeleteRow}
            // createRowData={createRowData}
            loading={state.loading}
        />


}   

{state.ladderbid_mat && state.ladderbid_mat.length >0 && !state.displayPlot && state.Transpose && 

<FullFeaturedCrudGrid
          columns={state.transposed_colum}
          rows={state.transposed_table}
          onSaveRow={onSaveRow}
          // onDeleteRow={onDeleteRow}
          // createRowData={createRowData}
          loading={state.loading}
      />


}  
    </>
}/>

        </>
    )
}

export default LadderBid