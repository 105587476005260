import React,{useEffect, useState} from "react";
import PropTypes from 'prop-types';
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { bgColors } from "../../utils/bgColor";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Checkbox from '@mui/material/Checkbox';
import TablePagination from '@mui/material/TablePagination';
const BaseTable = (props)=> {
const { onSelectAllClick,  numSelected, rowCount,
    data, onClick,checkbox_column, action_column} =props;
  let columns
  const [rows, setRows] = useState(data);

  useEffect(()=>{
    if(data.length>0){
      setRows(data)
    }
  },[rows])

  if (rows && rows.length>0)
  {columns = Object.keys(rows[0])}

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(16);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  

  return (

    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 800 }}>
        <Table stickyHeader aria-label="sticky table"  size="small">
          <TableHead>
            {columns && columns.length >0 && 
            <TableRow>

              { checkbox_column && 
                     <TableCell
                     sx={{  width: 200, height:10}}
                     key = {'checkbox_colum'}
                     >
                       Remove rows
                     </TableCell>
                }

            {columns.map((column) => (
                <TableCell
                sx={{  width: 200, height:10 }}
                key = {column}
                >
                  {column}
                </TableCell>
              ))}

                { action_column && 
                     <TableCell
                     sx={{  width: 200, height:10}}
                     key = {'action_column'}
                     >
                       Action
                     </TableCell>
                }
            </TableRow>
            }
          </TableHead>
          { rows.length>0 && 
            <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
              <TableRow key={index}>
                  {checkbox_column &&
                    <TableCell 
                    key = {`${row}_${index}`}
                    >
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                        key = {`checkbox_${row}_${index}`}
                      />
                    </TableCell>
                }
                {columns.map((column) => (
                    
                                <TableCell
                                sx={{ color:bgColors.grey_300,  width: 100 }}
                                key = {column}
                                >
                                  {row[column]}
                                </TableCell>
                      ))}  
                {action_column &&
                    <TableCell 
                    key = {`action_column`}
                    >
                    <IconButton 
                    onClick={onClick}
                      // onClick={(index) => { rows.splice(index, 1); setRows([...rows]); }}
                      >
                        <DeleteForeverIcon/>
                    </IconButton>
                    </TableCell>
                }
              </TableRow>
            ))}
          </TableBody>

        } 
        </Table>
      </TableContainer>
       <TablePagination
       rowsPerPageOptions={[10, 25, 100]}
       component="div"
       count={rows.length}
       rowsPerPage={rowsPerPage}
       page={page}
       onPageChange={handleChangePage}
       onRowsPerPageChange={handleChangeRowsPerPage}
     />
     </Paper>
  );
}

export default BaseTable

BaseTable.propTypes = {
  checkbox_column:PropTypes.bool,
  action_column:PropTypes.bool,
  data: PropTypes.array,
  onClick:  PropTypes.func
}

BaseTable.defaultProps = {
    action_column:false
};
  