import React, {useEffect, useState, useCallback, useRef} from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';
import axios from 'axios'
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import PlaceIcon from '@mui/icons-material/Place';
import { bgColors } from "../../utils/bgColor";
import { ListItemButton, Paper } from "@mui/material";
import { useDispatch } from "react-redux";
import { setMapCentreCord } from "../../redux/actions/mapaction";
import { fetchVillageSurveyPoly } from "../../Apis/actions/fetchVillageSurveyPoly";
import { fetchrootpath } from "../../Apis/apirootpath";
// import IconButton from '@mui/material/IconButton';

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  marginLeft: 0,
  width: "100%"
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`
  }
}));

const AsyncSelectSearch=({handleDrawerOpen}) =>{
    const dispatch = useDispatch()
    // const centrecord = useSelector(state => state.mapreducer.mapcentrecord)  
    // console.log(centrecord)
    // const centrecord = (state => state.mapreducer.centrecord)
    // console.log(centrecord)
    const villagesearchurl = `${fetchrootpath}/searchvillage`
    const villagesurveypolyurl = `${fetchrootpath}/point_village/revpoly_json`
    const editRef = React.createRef();
    const  state = {
        values: [],
        touched: false,
        isOpen: false
      };
    const [changeText, setChangeText] = useState(true);
    const [searchstr, setSearchstr] = useState("");
    const [selected, setSelected] = useState("")
    const [data, setData] = useState({
        loading: false,
        options:[]
    })

    useEffect(() => {
        setData({...data, loading:true})
        const getData = setTimeout(() => {
            if(searchstr && searchstr.length>0)

            {
                try {
                    axios
            .get(villagesearchurl, {
                params: {'searchstr': searchstr},
                    },)
            .then((response) => {
            let label_data = []
                response.data.forEach((item,index) => {
                    let _label = [item.state_name, item.district_name,
                                item.subdistrict_name, item.village_name].join(',')
                    let _index = index
                    let _lnglat = item.village_centroid.coordinates
                    let label = {
                        id : _index,
                        label:_label,
                        long_lat: _lnglat
                    }
                  label_data.push(label)
                  setData({...data,
                    loading:false,
                    options:label_data})
            });
            })
            .catch(function(err){
              if (err.message === 'Network Error') {
               alert("network erro")
            }
            })
            
            ;
              } 
              catch (error) {
                  // console.log(error.response.data.message)
                    alert("Some error has occured ")
                    
                }
                

            
        }
        }, 2000)
        return () => clearTimeout(getData)
    },[searchstr])

  return (
    <div display='flex' direction = 'row'>
        <div>
      <AppBar
       size={"small"}
        position="static"
        sx={{ borderRadius:8,
          width:350,
          marginTop:1,
          justifyContent:'center',
          height:'45px'
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>

          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              value={selected}
              onChange={(e) => {
                // setSelected("")
                setSearchstr(e.target.value)
                setSelected()
            
            }}              
              onClick={()=> {setSelected("")}}
        
            />
          </Search>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="open drawer"
          >
            <NearMeOutlinedIcon/>
          </IconButton>
        </Toolbar>
      </AppBar>
      </div>
      {!data.loading  && data.options.length>0 && 
        <Paper sx={{minWidth: 
            '400px',marginLeft:'3rem',marginTop:'1rem',
                zIndex:1000, position:'absolute',
                color:bgColors.OffWhite, 
                borderRadius:'20px',
                background:`${bgColors.mettalicDarkGrey}90`
                }}>
            <List>
            {data.options.map((item,index) => 
                <ListItemButton
                id = {`${item.label}`}
                key={`${item.label}`}
                disableGutters
                onClick={()=> {
                    setSelected(item.label)
                    setData({...data, options:[]})
                    dispatch(setMapCentreCord({longitude:item.long_lat[0],latitude:item.long_lat[1]}))
                    dispatch(fetchVillageSurveyPoly(villagesurveypolyurl,"GET",
                    {longitude:item.long_lat[0],latitude:item.long_lat[1]}))
                }}
                >
                <IconButton aria-label="comment">
                 <PlaceIcon />
                </IconButton>
                <ListItemText primary={item.label} />
                </ListItemButton>
            )}
        </List>
    </Paper>
    } 
</div>
  );
}

export default AsyncSelectSearch
