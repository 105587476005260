// import React from 'react'
import mapboxgl from 'mapbox-gl';
const marker = new mapboxgl.Marker()
export function updateViewPort(map,centrecord){
    if(map.current)
       if (centrecord!==undefined && 
        centrecord.latitude!==null && 
        centrecord.longitude!==null)
       { 
        
        marker.remove();
        marker.setLngLat({lng: centrecord.longitude, lat: centrecord.latitude})
        .addTo(map.current);
        map.current.flyTo({
            center: [centrecord.longitude, centrecord.latitude],
            speed: 0.5
            });
       }
        
    
};


// export function updateViewPort(map,centrecord,marker){
//     if (map) {
//        if (centrecord!=undefined && 
//         centrecord.latitude!==null && 
//         centrecord.longitude!==null)
//        { 
       
//         marker.setLngLat({lng: centrecord.longitude, lat: centrecord.latitude})
//         map.flyTo({
//             center: [centrecord.longitude, centrecord.latitude],
//             speed: 0.5
//             });
//        }
        
//     }
// };