import React from 'react'
import { CustomDiv } from '../../../components/Layout/CustomDiv'
const Intraday = () => {
    return(
        <CustomDiv>
            "Intra-day component goes here"
        </CustomDiv>
    )
}

export default Intraday