import {
    API_START,
    API_END
  } from "../actions/types";

import {
  FETCH_CURRENT_RE_SCHEDULE,
  FETCH_CURRENT_RE_SCHEDULE_ERROR,
  SET_CURRENT_RE_SCHEDULE
} from "../actions/ReSchedule_current"

  const initialState = {
      loading: false,
      data: {},
      error: ''
  
  }
  
  
  export default function fetchCurrentReSchedule(state = initialState, action) {
    //console.log("action type => ", action.type);
    switch (action.type) {
    
      case SET_CURRENT_RE_SCHEDULE:
                  // console.log(action.payload)

        return { ...state, data: action.payload };
      case API_START:
        
        if (action.payload === FETCH_CURRENT_RE_SCHEDULE) {
          return {
            ...state,
            loading: true,
            data:{},
            error: ""
          };
        }
        return(null)
        // break;
      case API_END:
        if (action.payload === FETCH_CURRENT_RE_SCHEDULE) {
          return {
            ...state,
            loading: false,
          };
        }
        return(null)
        // break;
        case FETCH_CURRENT_RE_SCHEDULE_ERROR:
          return {
            ...state,
            data: {},
            error: action.payload.message
          };

      default:
        return state;
    }
  }
  