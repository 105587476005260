import { parsePhoneNumber } from "react-phone-number-input";
import { get } from "lodash";

// ******************************
export const validator = (values, fieldName) => {
  let errors = {};
  switch (fieldName) {
    case "user_name":
      validateNull(values.user_name, errors);
      break;
    case "first_name":
      validateNull(values.first_name, errors);
      break;
    case "last_name":
      validateNull(values.last_name, errors);
      break;
    case "organization_name":
      validateNull(values.organization_name, errors);
      break;
    case "email_id":
      validateEmail(values.email_id, errors);
      break;
    case "password":
      validatePassword(values.password, errors);
      break;
    case "phone_no":
      validatePhoneNumber(values.phone_no, errors);
      break;
    case "confirmpassword":
      validateConfirmPassword(values.confirmpassword,values.password, errors);
      break;
      case "emailphone":
        validateemailphone(values.emailphone, errors);
        break;
    default:
  }
  return errors;
};

// ******************************
export function getCountryCode(phoneNumber) {
  return get(parsePhoneNumber(phoneNumber), "country");
}

// ******************************
export function validatePhoneNumber(phone, errors) {
  let result = true;
  const phoneObject = parsePhoneNumber(phone);
  if (!phoneObject) {
    errors.phone = "Invalid Phonenumber";
    result = false;
  }
  return result;
}

export function validateemailphone(emailphone, errors){
  let result = true;
  if (!emailphone) {
    errors.emailphone = "email or phone is Required";
    result = false;
  } else 
  {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const emailObject = re.test(String(emailphone).toLowerCase());
    const phoneObject = parsePhoneNumber(emailphone);
    // result = re.test(String(emailphone).toLowerCase());
    if (!emailObject && !phoneObject) 
      { errors.emailphone = "Invalid email/phone address";
      result = false
      }
  }
  return result;
}

export function validateConfirmPassword(confirmpassword,password,errors) {
  let result = true;
  // const phoneObject = parsePhoneNumber(phone);
  if (!confirmpassword) {
    errors.confirmpassword = "password & confirmpassword is Required";
    result = false;
  }
  if (confirmpassword!==password)
  {
    errors.confirmpassword =  "password donot match";
    result = false;
  }

  return result;
}
// ******************************

export function validateNull(label_data, errors) {
  let result = true;
  if (!label_data) {
    errors.label_data = `${label_data} is Required`;
    result = false;
  } else {
    result = true;
  }
  return result;
}


// ******************************
export function validateEmail(email, errors) {
  let result = true;
  if (!email) {
    errors.email = "Email is Required";
    result = false;
  } else {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    result = re.test(String(email).toLowerCase());
    if (!result) errors.email = "Invalid Email address";
  }
  return result;
}
// ******************************
export function validatePassword(pass, errors) {
  let result = true;

  if (!pass) {
    errors.password = "Password is Required";
    result = false;
  } else {
    var upper = /(?=.*[A-Z])(?=.*[A-Z])(?=.*[^\w\d])/;
    result = upper.test(pass);

    if (!result) {
      errors.password = "Password must contain at least one upper case letter and an special character";
      result = false;
    } else if (pass.length < 8) {
      errors.password = "Your password has less than 8 characters.";
      result = false;
    }
  }

  return result;
}
