
import {  
    
    SET_USER_DATA

  
  } from "../actions/authaction";
    const initialState = {
        userdata:{}
    }
  
  
  const authreducer =  (state = initialState, action) => {
  switch (action.type) {
  case SET_USER_DATA:
  return {
    ...initialState,
    userdata: action.payload
            }

  //   case IS_LOGGED_IN:
  //   return {
  //       ...initialState,
  //           isLoggedin:true,
  //           userdata:action.payload
  //   }
  //   case CLICK_LOGOUT_BUTTON:
  //   return {
  //           ...initialState,
  //           isLoggedin:false,
  //           userdata:{}
  //   }
    default:
        return state;
    }
    };
  
  export default authreducer  
  