import React,{useState,useEffect} from 'react'
import PropTypes from 'prop-types';
import { PlaneCard } from '../../../UiComponent/CustomCard'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import TableViewIcon from '@mui/icons-material/TableView';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress'
import Backdrop from '@mui/material/Backdrop'; 
import RefreshIcon from '@mui/icons-material/Refresh';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import ModelPopup from './ModelPopup';
const style = {
    Button_style:
       { Button_Style : 
        {borderStyle: 'solid',
        borderWidth: '2px',
        borderRadius:'5px',
        minWidth:'100px',
        fontSize:'15px',
        marginLeft:'auto'
       }
      },
    icon_style:{
      height:'35px', 
      width:'35px'
    }
  }

const ForecastingPage = ({displayPlot,
                        toggleTableGraph,
                        HandleModelPopup,
                        options,
                        selectedmodel,
                        handleModelChange,
                        handleCommit,
                        handlOpenEditor,
                        refreshData,
                        isLoading,
                        page_content,
                        action_buttons
                     }) => {

  
    return(

        <>
       
        <PlaneCard
        avatar_content={
           <>
            <Stack direction ={'row'} spacing={2} >
            {displayPlot && 
                <IconButton
                sx ={style.icon_style}
                onClick={toggleTableGraph}
                >
                <LegendToggleIcon/>
                </IconButton>
                }

            {!displayPlot && 
                <IconButton
                sx ={style.icon_style}
                onClick={toggleTableGraph}
                >
                <TableViewIcon/>
                </IconButton>
            }
            <Select
                size='small'
                id="demo-select-small"
                value={selectedmodel}
                onChange={handleModelChange}
            >
               { options.map((item,index)=> {
                    return <MenuItem key = {`${item}_${index}`} value={item}>{item}</MenuItem>
                })}
          
            </Select>
            <Button  variant = "contained" startIcon = <PlayCircleFilledIcon/>
                onClick={HandleModelPopup}
                >
             Run Scoring
            </Button>
            </Stack>
           </>
    
        }
        header_content={
            <>
            <Stack direction ={'row'} spacing={2} >
          
            <IconButton onClick={handlOpenEditor}>
            <ModeEditOutlineOutlinedIcon/>
            </IconButton>
            <IconButton onClick={handleCommit}>
            <CloudUploadOutlinedIcon/>
            </IconButton>
              <IconButton  
                onClick={refreshData}
                    >
               <RefreshIcon/>
             </IconButton>

            </Stack>

            </>
        } 
        main_content={
            <>
             { isLoading &&
                <Backdrop
                sx={{ color: '#fff', zIndex: 10000 }}
                open={isLoading}
                >
                <CircularProgress color="inherit" />
                </Backdrop>
            }
            {React.Children.toArray(page_content).map(child => child)}
            </>
        }

        action_content={

            <Stack direction ={'row'} spacing={2} >
               
               {React.Children.toArray(action_buttons).map(child => child)}

            </Stack>
        }
        /> 

       
    </>
    )
}
export default ForecastingPage


ForecastingPage.propTypes = {
  displayPlot: PropTypes.bool,
  toggleTableGraph: PropTypes.func,
  run_model:PropTypes.func,
  options: PropTypes.array,
  selectedmodel: PropTypes.string,
  handleCommit:PropTypes.func,
  handleModelChange:PropTypes.func,
  refreshData:PropTypes.func,
  isLoading:PropTypes.bool,
  page_content:PropTypes.node,
  action_buttons:PropTypes.node
 };


