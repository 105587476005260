
import IexPriceUpload from './IexPriceUpload';
import IexPriceUploadRTM from './IexPriceUploadRTM';

import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const MarketDataTab =({options,selectedIndex}) => {  
      const state_dayahead = useSelector(state => state.day_ahead_iexprice)
      const state_current = useSelector(state => state.rtm_iexprice)
      const [state,setState ]= useState({
            day_ahead_loading:false,
            dayahead_data:[],
            realtime_loading: false,
            realtime_date: [],

           
      })

   useEffect(()=> {
      if(state_dayahead && state_dayahead.loading){
            setState({...state,
                  day_ahead_loading:state_dayahead.loading})
      }

      if   (state_dayahead && !state_dayahead.loading 
            && state_dayahead.data 
            )
            {
            setState({
                  ...state,
                  day_ahead_loading:false,
                  dayahead_data:state_dayahead.data
            }) 
            }

      if(state_current && state_current.loading){
            setState({...state,
                  realtime_loading:state_current.loading})
      }

      if(state_current && !state_current.loading 
            && state_current.data 
                  )
            {
            setState({
                  ...state,
                  realtime_loading:false,
                  realtime_date:state_current.data
            }) 
            }

      

   },[state_dayahead,state_current])


          return (
                  <>
                
                        {options[selectedIndex]===options[0] && 
                              <IexPriceUpload
                              isLoading={state.day_ahead_loading}
                              data_table={state.dayahead_data}                              
                              />
                        }
                        { options[selectedIndex]===options[1] && 
                              <IexPriceUploadRTM
                              isLoading={state.realtime_loading}
                              data_table={state.realtime_date}
                              />
                        }
                  
                  </>
          )
}

export default MarketDataTab



