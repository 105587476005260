import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

const  AsyncAutoComplete = (props) => {
  const {options,loading,open,onOpen,onClose,label,onChange,id,textfield_id,value} = props
  return (
    <Autocomplete
      id={id}
      sx={{ width: 300 }}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      // getOptionLabel={(option) => option.label}
      value={value}
      options={options}
      loading={loading}
      onChange= {onChange}
      renderInput={(params) => (
      <TextField
        id = {textfield_id}
        fullWidth
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default AsyncAutoComplete

AsyncAutoComplete.propTypes = {
  id: PropTypes.string,
  textfield_id: PropTypes.string,
  options: PropTypes.array,
  loading: PropTypes.bool,
  open:PropTypes.bool,
  onOpen:PropTypes.func,
  onClose:PropTypes.func,
  label: PropTypes.string,
  // defaultValue:PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func

};

AsyncAutoComplete.defaultProps = {
  id : "asyncselect",
  textfield_id : "asyncselect-text",
  options: [],
  loading:false,
  open:false,
  label: 'Async select',
  defaultValue: "",
};


// Top films as rated by IMDb users. http://www.imdb.com/chart/top

