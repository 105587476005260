import {
  SET_DISPLAY_DATA_PLOT,
  OPEN_CSV_IMPORT,
  CLOSE_CSV_IMPORT
}


from "../actions/UiControlAction"
 const initialState = {
  display_plot: false,
  openCsvImportDialog:false
}

 const UiControlReducer =  (state = initialState, action) => {
  // console.log(action['payload'])
   switch (action.type) {
    case SET_DISPLAY_DATA_PLOT:
    return {
        ...state,
        display_plot: action['payload']
    }
    case OPEN_CSV_IMPORT:
            return {
                ...initialState,
                openCsvImportDialog: true
            }

    case CLOSE_CSV_IMPORT:
      return {
          ...initialState,
          openCsvImportDialog: false
      }
 

    default:
       return state;
   }
 };

 export default UiControlReducer  


//  case CLICK_USERPROJECT_CLOSE_BUTTON:
//   return {
//       ...initialState,
//       userprojectwindow: false,
//       mapconatiner_margin_left: 0   
//   }