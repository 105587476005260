
import { randomNum } from '../../utils/demos';
import { getColor } from '../../utils/colors';
import axios from 'axios'

// const kleurs = ['#005073', '#66ABD5', '#FE794C', '#97BA28', '#65DD91', '#FE5741',
// '#EFA69F','#7833C2','#66ABD5','#851509','#B4D455','#E1F2A2','#0FF1CE','#AFEEEE','#E1F2A2']
const kleurs = ['rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)', 'rgba(255, 206, 86, 0.5)',
  'rgba(75, 192, 192, 0.5)', 'rgba(153, 102, 255, 0.5)', 'rgba(255, 159, 64, 0.5)']
              
function roundUp(num, precision) {
  precision = Math.pow(10, precision)
  return Math.ceil(num * precision) / precision
}

export const  getDiscomload = (predactual) =>
  {
    var DISCOOM_LOAD = []
    for (var i in predactual) {
      let _var_ = predactual[i].load
      DISCOOM_LOAD.push(_var_)
    }
      return DISCOOM_LOAD
  }
  
const makeRepeated = (arr, repeats) =>
  Array.from({ length: repeats }, () => arr).flat();


function roundToTwo(num) {
    return +(Math.round(num + "e+2")  + "e-2");
}

export const series_length = 96

export  const fetchData = async (url) => {
  const result = await axios(url);
  // return the result
  return result;
};
  
export const  range = (start, end) =>  {
    return (new Array(end - start + 1)).fill(undefined).map((_, i) => i + start);
}
export const color_seq = (start, end) => {
    let _range = range(start, end)
    let _color_map = []
    for (let i in _range) {
      let _val = _range[i]/end
      _color_map.push(_val)
    }
    return _color_map
}
  
export const getMyColor = (num_color) => {
  let n = (num_color* 0xfffff * 1000000).toString(16);
  return "#" + n.slice(0, 6);
}

export const widgetColor = (value) => {
   if (value < 55 ) {
      return 'success';
   } else if (value >= 55 & value<= 80) {
      return 'warning';
   } else {
      return 'danger';
   }
}

export const block_no = [...Array(96).keys()]

export const TimeBlock = () => {
    var x = 15; //minutes interval
    var times = []; // time array
    var tt = 0; // start time

  //loop to increment the time and push results in array
  for (var i=0;tt<24*60; i++) {
    var hh = Math.floor(tt/60); // getting hours of day in 0-24 format
    var mm = (tt%60); // getting minutes of the hour in 0-55 format
    times[i] = ("0" + (hh % 24)).slice(-2) + ':' + (("0" + mm).slice(-2)); // pushing data in array in [00:00 - 12:00 AM/PM format]
    tt = tt + x;
  }
      return times
}

 export const  clean = (obj) => {
  for (var propName in obj) {
    if (obj[propName] >= 0 || obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj
  }


export const  getFiteredArray = (array, key, value) => {
  return array.filter(function(e) {
    return e[key] === value;
  });
}

export const sanitisesubstract = (x, y) => {
  if (x<=0 || y <=0 ) {
    return NaN;
  }
  return x-y;
}

export const  getarraySum =(ary) =>{
  return ary.reduce(function(sum, value) {
    return sum + value;
  }, 0);
}


export const  sanitise = (x) => {
  if (isNaN(x)) {
    return NaN;
  }
  return x;
}


export const getarraykeys = (array)=> {
    var allkeys = [];
    for (var k in array) allkeys.push(k);
    return (allkeys)
  }

export const Get_lines = (_keys, data, row_start, row_end, ...moreData) => {
  const length_keys = _keys.length ;
  const number_of_num_color = length_keys / kleurs.length
  var num_color = makeRepeated(kleurs, roundUp(number_of_num_color, 0))

  // var num_color = color_seq(1, _keys.length + 1)
  let _line_data = []
  for (let i = row_start; i < _keys.length-row_end; i++){
    let _label = _keys[i]
    let _values = []
    for (let _item in data) {
      let _value_ =   roundToTwo(sanitise(data[_item][_label]))
      _values.push(_value_)
    }
    let _var = {
      label: _label,
      // backgroundColor: dynamicColors(),
      backgroundColor: num_color[i],
      borderColor: num_color[i],
      borderWidth: 2,
      pointRadius: 0,
      data: _values,
      borderDash: [30,20],
      // fill:false,
      ...moreData,
    }
    _line_data.push(_var)
  }
  return(_line_data)
}
// My function to interpolate between two colors completely, 
  
  function interpolateColor(color1, color2, factor) {
    if (arguments.length < 3) { 
        factor = 0.5; 
    }
    var result = color1.slice();
    for (var i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    return result;
};
function interpolateColors(color1, color2, steps) {
    var stepFactor = 1 / (steps - 1),
        interpolatedColorArray = [];

    color1 = color1.match(/\d+/g).map(Number);
    color2 = color2.match(/\d+/g).map(Number);

    for(var i = 0; i < steps; i++) {
        interpolatedColorArray.push(interpolateColor(color1, color2, stepFactor * i));
    }

    return interpolatedColorArray;
}
  

export const Get_lines_label_with_brackets = (_keys, data, row_start, row_end, ...moreData) => {
  const length_keys = _keys.length ;
  const number_of_num_color = length_keys / kleurs.length
  var num_color = makeRepeated(kleurs, roundUp(number_of_num_color, 0))

  // var num_color = color_seq(1, _keys.length + 1)
  // var num_color = RGB_TOHEX(_keys.length)
  let _line_data = []
  for (let i = row_start; i < _keys.length - row_end; i++){
    let _label = _keys[i]
    let _label_cleaned = _keys[i].split("(")[0]
    let _values = []
    for (let _item in data) {
      let _value_ = roundToTwo(sanitise(data[_item][_label]))
      _values.push(_value_)
    }
    let _var = {
      label: _label_cleaned,
      backgroundColor: num_color[i],
      borderColor: num_color[i],
      borderWidth: 2,
      pointRadius: 0,
      data: _values,
      borderDash: [30,20],
      // fill:false,
      ...moreData,
    }
    _line_data.push(_var)
  }
  return(_line_data)
}

export const get_line_data_table = (array, key_name, series_name) => {

  let all_line_data = []
  if (array.length >0)
{var _labels = [...new Set(array.map(q => q[key_name]))]

const length_keys = _labels.length ;
const number_of_num_color = length_keys / kleurs.length
var num_color = makeRepeated(kleurs, roundUp(number_of_num_color, 0))

// var num_color = color_seq(1, _labels.length + 1)
//  var num_color = RGB_TOHEX(_labels.length)
  
  for (let _label in _labels)
      {
        var _array = array.filter(function (e) {
          return e[key_name] == _labels[_label];
        });
      let all_values = []
      for (let _row in _array) {
        let _value = roundToTwo(_array[_row][series_name])
        all_values.push(_value)
      }
      let _line_data_set = {
        label: _labels[_label],
        backgroundColor: num_color[_label],
        borderColor:num_color[_label],
        pointRadius: 3,
        borderWidth: 1.5,
        fill: false,
        // borderDash: [20,10],
        data: all_values
      }
    all_line_data.push(_line_data_set)
  }}
    return all_line_data
  }

export const all_bubble_data = (ursSellers, keys_to_drop,generationcost ) => {
    let allkeys = getarraykeys(ursSellers[0])
    let filtered_key = getarraykeys(ursSellers[0])
    filtered_key = filtered_key.filter( function( el ) {
      return keys_to_drop.indexOf( el ) < 0;
    });
  const length_keys = filtered_key.length ;
  const number_of_num_color = length_keys / kleurs.length
  var num_color = makeRepeated(kleurs, roundUp(number_of_num_color, 0))

  let all_bubble_data = []
  for (let _label in filtered_key) {
      let _filtered_data = generationcost.filter(function(item){
      return item.unit_name === filtered_key[_label]
      });
    let cost = _filtered_data.map(q => q.total_cost)
    let data_mat = []
      for (let _data in ursSellers)
      {
        let x = ursSellers[_data][keys_to_drop[0]]
        // let y = cost[0]
        let y = ursSellers[_data][filtered_key[_label]]
        // let r = ursSellers[_data][filtered_key[_label]]
        let r = cost[0]
        if (y>0)
        {data_mat.push({ x, y, r })}
      }
    
    let _var_ = {
      label: [filtered_key[_label]],
      data: data_mat,
      backgroundColor: num_color[_label],
      hoverBackgroundColor: "#000000",
      hoverBorderColor: "#9966FF",
      hoverBorderWidth: 5,
      hoverRadius: 5,
      radius: 1,
      
    }
    all_bubble_data.push(_var_)
    
  }
    return all_bubble_data

  } 

export const keys_region = ['region', 'state', 'discom']
export const keys_model = ['state', 'discom', 'modelname']


export const reshape_json = (data, allKeys) => {
  
  var level_1 = [...new Set(data.map(q => q[allKeys[0]]))];
  let reshaped_data = []
  for (let i in level_1) {
    var level_2 = getFiteredArray(data, allKeys[0], level_1[i])
    let level_2_val_unique = [...new Set(level_2.map(q => q[allKeys[1]]))]
    var _test_ = []
    for (let j in level_2_val_unique) {
      var level_3 = getFiteredArray(level_2, allKeys[1], level_2_val_unique[j])
      var level_3_val_unique = [...new Set(level_3.map(q => q[allKeys[2]]))]

      var level_3f = []
      for (let k in level_3_val_unique)
      {
        var _test = {
          value: level_3_val_unique[k]
        }
        level_3f.push(_test)
      }
    
      var test2 = {
        
          value: level_2_val_unique[j],
          [allKeys[2]]:  level_3f
      }
      _test_.push(test2)

      var test_js = {
        value: level_1[i],
        [allKeys[1]]: _test_
      }
    }
    reshaped_data.push(test_js)
  }
    return reshaped_data
}


function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}
var curr = new Date();
curr.setDate(curr.getDate() );
export const Today = convert(curr.toISOString().substr(0, 10));

var curr = new Date();
curr.setDate(curr.getDate() + 1);
export const Tomorrow = convert(curr.toISOString().substr(0, 10));

export const  y = (predactual) =>
  {
    var DISCOOM_LOAD = []
    for (var i in predactual) {
      let _var_ = predactual[i].load
      DISCOOM_LOAD.push(_var_)
    }
      return DISCOOM_LOAD
  }


  
  export const  getPredload = (predactual) =>
  {
    var PRED_LOAD = []
    for (let i in predactual) {
      let _var_ = predactual[i].predload
      PRED_LOAD.push(_var_)
    }
      return PRED_LOAD
  }
  
  export const  getOd_ud = (predactual) => 
  {
    var OD_UD = []
    for (let i in predactual) {
      let _var_ = predactual[i].od_ud
      OD_UD.push(_var_)
    }
      return OD_UD
  }

  export const  getui_rate = (predactual) => 
  {
    var ui_rate = []
    for (let i in predactual) {
      let _var_ = predactual[i].uirate
      ui_rate.push(_var_)
    }
      return ui_rate
  }

  export const  getFrequency = (predactual) =>
  {
    let  _var = []
    for (let i in predactual) {
      let _var_ = predactual[i].frequency
      _var.push(_var_)
    }
      return _var
  }

export const getIexPrice = (iexprice) =>
  {
    var IEX_PRICE = []
    for (let i in iexprice) {
      let _var_ = iexprice[i].price
      IEX_PRICE.push(_var_)
    }
      return IEX_PRICE
}
    

export const  stringToColour = (str) => {
    var hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (let j = 0; j < 3; j++) {
        var value = (hash >> (j * 8)) & 0xEEE;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}


export const genPieData = () => {
  return {
    datasets: [
      {
        data: [randomNum(), randomNum(), randomNum(), randomNum(), randomNum()],
        backgroundColor: [
          getColor('primary'),
          getColor('secondary'),
          getColor('success'),
          getColor('info'),
          getColor('danger'),
        ],
        label: 'Dataset 1',
      },
    ],
    labels: ['isgs', 'internal', 'iex', 'stoa', 'URS'],
  };
};


 export const Arrays_sum = (array1, array2) => 
{
  const result = [];
  let ctr = 0;
  let x=0;
  if (array1.length === 0) 
   return "array1 is empty";
  if (array2.length === 0) 
   return "array2 is empty";   

 while (ctr < array1.length && ctr < array2.length) 
  {
    result.push(array1[ctr] + array2[ctr]);
    ctr++;
  }

 if (ctr === array1.length) 
 {
    for (x = ctr; x < array2.length; x++)   {
      result.push(array2[x]);
    }
  } 
  else
  {
  for (x = ctr; x < array1.length; x++) 
    {
      result.push(array1[x]);
    }
  }
  return result;
  }


 