import React from "react";
import { DialogFullScreen } from "../../../UiComponent/DialogFullScreen";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import { AccordianCustom } from "../../../UiComponent/AccordianCustom";
import {SetUtility} from "../../SetUtility";
import {SetEvents} from "../../SetEvents";
import { SetCounterparty } from "../../SetCounterparty";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import { useSelector } from "react-redux";

// import 
// import 


const DataSetttings=()=> {
    const discom = useSelector(state => state.static_data)
    const [expanded, setExpanded] = React.useState();
    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (


    <DialogFullScreen
    icon = {<SettingsOutlined/>}
    label = {"data-settings"}
    dialog_title="Static data settings"
    >

<AccordianCustom
    id = {"accordian seting-utility"}
    expanded= {expanded}
    handleChange={handleChange}
    title_name={"set-utility"}
    panel = {'panel1'}
    icon =  {<SettingsOutlinedIcon 
    sx = {{marginRight:'1rem' }}
    />}
    >
    <SetUtility/>
</AccordianCustom>
<AccordianCustom
    id = {"accordian seting-events"}
    expanded= {expanded}
    handleChange={handleChange}
    title_name={"set-event"}
    panel = {'panel2'}
    icon =  {<EventRepeatIcon 
    sx = {{marginRight:'1rem' }}
    />}
    >
 <SetEvents/>

</AccordianCustom>

<AccordianCustom
    id = {"accordian seting-ppas"}
    expanded= {expanded}
    handleChange={handleChange}
    title_name={"CounterParty"}
    panel = {'panel3'}
    icon =  {<WorkHistoryIcon 
    sx = {{marginRight:'1rem' }}
    />}
    >
{/* <SetCounterparty/> */}
</AccordianCustom>

<AccordianCustom
    id = {"accordian seting-ppas"}
    expanded= {expanded}
    handleChange={handleChange}
    title_name={"Trade"}
    panel = {'panel4'}
    icon =  {<WorkHistoryIcon 
    sx = {{marginRight:'1rem' }}
    />}
    >
{/* <SetCounterparty/> */}
</AccordianCustom>

</DialogFullScreen>

  )

}

export default DataSetttings