
export const SET_FEATURE_LOCATION = 'SET_FEATURE_LOCATION';
export const SET_FEATURE_CROP = 'SET_FEATURE_CROP';
export const SET_FEATURE_INDEX = 'SET_FEATURE_INDEX';
export const SET_FEATURE_INDEX_HIST = 'SET_FEATURE_INDEX_HIST';

export const setFeatureLocation= (featurelocation) => ({
    type: SET_FEATURE_LOCATION,
    payload: featurelocation
 })

 export const setFeatureCrop= (feturecrop) => ({
    type: SET_FEATURE_CROP,
    payload: feturecrop
 })



 export const setFeatureIndex= (fetureindex) => ({
    type: SET_FEATURE_INDEX,
    payload: fetureindex
 })

 export const setFeatureIndexHist= (fetureindexhist) => ({
   type: SET_FEATURE_INDEX_HIST,
   payload: fetureindexhist
})




 