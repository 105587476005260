import {API} from "./types";
import { fetchrootpath } from "../apirootpath";

export const FETCH_WEATHERSUMMARY = "FETCH_WEATHERSUMMARY"
export const FETCH_WEATHERSUMMARY_ERROR = "FETCH_WEATHERSUMMARY_ERROR"
export const SET_WEATHERSUMMARY = "SET_WEATHERSUMMARY"
export  function fetchWeatherSummary(method,query_param) {
  return apiAction({   
    url:`${fetchrootpath}/weathersummary`,
    method:method,
    data:query_param,
     onSuccess: setWeatherSummary,
    onFailure: (error) => ({
      type: FETCH_WEATHERSUMMARY_ERROR,
      payload: error
    }),
    label: FETCH_WEATHERSUMMARY
  });
}



  function setWeatherSummary(data) {
    //let weather = firebase.database().ref('locations/1');
    //weather.child('weather_forecast').set(json);
    return {
      type: SET_WEATHERSUMMARY,
      payload: data
    };
  }


  
  function apiAction({
    url = "",
    method = "GET",
    data = null,
    accessToken = null,
    onSuccess = () => {},
    onFailure = () => {},
    label = "",
    headersOverride = null
  }) {
    return {
      type: API,
      payload: {
        url,
        method,
        data,
        accessToken,
        onSuccess,
        onFailure,
        label,
        headersOverride
      }
    };
  }




  