import React from 'react'
import {CustomDiv} from '../../../../components/Layout/CustomDiv'
const PositionMap = () => {
    return(
        <CustomDiv>
            "PositionMap component goes here"
        </CustomDiv>
    )
}

export default PositionMap