import {
    SET_FOLIO,
    ADD_TO_FOLIO,
    DELETE_FROM_FOLIO
} from '../actions/currentfolioaction'

const initialState = {
    currentfolio: []
  }

function updatefolio(folioitems,payload){
    folioitems.map((item)=> {
            if(item.state === payload.state && 
                item.district === payload.district && 
                item.subdistrict === payload.subdistrict && 
                item.village === payload.village && 
                item.survey_no ===  payload.survey_no)
                {
                    return item
                }
            else{
                return (
                    {...item,
                    payload
                    })
            }
    })

}
function deleteitem(folioitems,payload){
    return folioitems.filter((item) =>           
    item.id !== payload.id)
}

const currentFolioReducer =  (state = initialState, action) => {
    switch (action.type) {
      case SET_FOLIO:
        return {
          ...state,
          currentfolio: action['payload']
        }
        case ADD_TO_FOLIO:
            return{
                ...state,
                currentfolio:updatefolio(state, action['payload'])
            }
        
        case DELETE_FROM_FOLIO:
          return {
            ...state,
            currentfolio: deleteitem(state.currentfolio,action['payload'])
          }
      default:
        return state;
    }
  };

  export default currentFolioReducer  