import React,{useEffect, useState} from 'react';
import {DatePickerSmall} from '../../UiComponent/DatePicker';
import Button from '@mui/material/Button';
// import CustomdDialog from '../UiComponent/CustomDialog';
import CustomdDialog from '../../UiComponent/CustomDialog/CustomDialog';
import { bgColors } from '../../utils/bgColor';
import { BasicSelect } from '../../UiComponent/BasicSelect';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { useDispatch,useSelector} from 'react-redux';
import { loadState } from '../../utils/Localstorage';
import { postEventList } from '../../Apis/actions/postEvent';
import { Typography } from '@mui/material';
import { postrootpath } from '../../Apis/apirootpath';
 
const DateStrEvent = (event) => {


    const day = event["$D"].toString().padStart(2,0)
    const month = event["$M"].toString().padStart(2,0)
    const year = event["$y"].toString()
    const datestr = year +"-"+month+"-"+day
    return datestr
}



const paperstyle = {minWidth: '300px',
                    background: bgColors.black , 
                    borderRadius:'10px'                 
                   }
const boxStyle = {border: 2,
                  borderColor:`${bgColors.black}90`,
                  backgroundColor:`${bgColors.black}30`,
                  borderRadius: '10px',
                  marginBottom:'0.25rem'
                  }

const AddCounterpartyForm = ({open,handleClose}) => {

  // let postEventResponse = {
  //   loading: false,
  //   data:{},
  //   error:""
  // }

  const dispatch = useDispatch()
  const eventNameList = useSelector(state=> state.eventList)
  const postEventResponse = useSelector(state=> state.postEvent)

  const DateStrEvent = (event) => {
    const day = event["$D"].toString().padStart(2,0)
    const month = (event["$M"]+1).toString().padStart(2,0)
    const year = event["$y"].toString()
    const datestr = year +"-"+month+"-"+day
    return datestr
}  
console.log(loadState())

const today = dayjs();
const [evenname, setEventname] = useState([])

const [eventList, setEventList] = useState({
    date:'',
    name:'',
    state:''
})

function setInitialState(){
  if (loadState() !==undefined && loadState().selected_utility !==undefined){
    eventList.state = loadState().selected_utility.selected_state
  }
  else {
    eventList.state = ''
  }

}
useEffect(()=> {
  if (loadState() !==undefined && loadState().selected_utility !==undefined)
   {setInitialState()}

},[loadState()])

// console.log(eventList)

const handleOnchageDate=(event) => {
  const datestr = DateStrEvent(event)
  setEventList({...eventList,date:datestr})
  // if(eventNameList.loading===false && eventNameList.data.length>0)
  if(eventNameList)
  {
    const _options = []
    eventNameList.data.forEach(element => {
      _options.push(element.name)
      
    });
    setEventname(_options)
  }
 
}


const hanldeEventSelection = (e) => {
 const event_name = e.target.value
 setEventList({...eventList,name:event_name})
}

const handleSubmit = (e) => {
  e.preventDefault();
  const url = `${postrootpath}/commit_Trade`
  const postdata = {
    date:eventList.date,
    name:eventList.name,
    state:eventList.state
  }

  // fetchData(url,method,query_param)
  dispatch(postEventList(url, "POST", postdata))
  // dispatch(fetchData(url, "POST", postdata))
  
}

// console.log(eventList)
    return (
        <CustomdDialog
        dialogTitle={"Add event"}
        open={open}
        handleClose={handleClose}
        PaperProps = {{sx:paperstyle}}
        content = { <Box>
                      <Stack direction={'column'} spacing={2}>
                      <DatePickerSmall
                        id="Date"
                        label='Date'
                        // value={selecteddate}
                        onChange={handleOnchageDate}
                      />
                       <BasicSelect
                         
                          id = "eventnamelistdd"
                          value={eventList.name}
                          options={evenname}
                          handleChange ={hanldeEventSelection}
                          input_label="Select Event"
                          labelId="Select Event label id"
                          label = "Select Event"
                       
                       />
                         <TextField
                          id="outlined-read-only-input"
                          label="Province"
                          defaultValue={eventList.state}
                          InputProps={{
                            readOnly: true,
                          }}
                        />

                       </Stack>

                    </Box>}
    action = { 
        <div>
          <Button variant='outlined'
             onClick={handleSubmit}
          >
            Submit
          </Button>
          {postEventResponse && 
          <Typography sx = {{maxWidth:350, justifyContent:'center'}}>
            {postEventResponse.data.message}
          </Typography>
          }
         
         
        </div>
      }
        
        />
        
    )
}

export default AddCounterpartyForm

// import React from "react";
// import Form from "./components/Form";
// import Tables from './components/Tables'

// function App() {
//   return (
//     <React.Fragment>
//       <Form />
//       <Tables />
//     </React.Fragment>
//   );
// }

// export default App;