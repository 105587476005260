import { authrootpath } from "../../../Apis/apirootpath";
import {STATE_LOGIN, PostData } from "../PostData";
// const baseURL = authrootpath
const authstate = STATE_LOGIN
// const baseURL = process.env.REACT_APP_AUTH_PATH
// const baseURL = postrootpath
async function Loginuser(state){
const _PostData = PostData(state, authstate)
console.log(`${authrootpath}/autheticate_user`)

    try {
      const res = await fetch(`${authrootpath}/autheticate_user`, {
        method: "post",
        headers: {
          // "Content-Type": "application/x-www-form-urlencoded"
          // "Content-Type": "application/x-www-form-urlencoded" // seems to be working with nginx
          "Content-Type": "application/json"
        },
        body: JSON.stringify(_PostData),

        // body: new UrlSearchParams(..._PostData).toString(), // this seems to be working with nginx
      })
  
      if (!res.ok) {
        const message = `An error has occured: ${res.status} - ${res.statusText}`;
        throw new Error(message);
      }
      if(res.ok){
      const data = await res.json();
      // console.log(data)
      // const data = await res.text();
      return data
      }
      
    } catch (err) {
      // toast.error(err.message.toString(), {
      //     position: "top-right",
      //   });
      alert(err.message)
   
      
    }
  }
export default Loginuser