
function VillageRevPolyLayer(map,features){
    const geojson = {
        type: 'FeatureCollection',
        features: features,
        };
        map.current.addSource('revenuepoly_layer', {
                type: 'geojson',
                data: geojson
            }
        )
        map.current.addLayer({
            'id': 'revenuepoly_layer',
            'type': 'line',
            'source': 'revenuepoly_layer',
            'paint': {
                'line-color': '#eeeeee',
                'line-width': 1
            },
            'layout': {
                'visibility': 'visible',
            },
            'filter': ['==', '$type', 'Polygon']
        });
        
        map.current.addLayer({
            'id': 'revenue_details',
            'type': 'symbol',
            'source': 'revenuepoly_layer',
            'layout': {
                'text-field': ['get', 'survey_no'],
                'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
                'text-radial-offset': 0,
                'symbol-z-order':"auto",
                'text-justify': 'auto',
                "text-size": 16
            },
            paint: {
                "text-color": "yellow"
            }
        });

        // map.on('click', 'revenuepoly_layer', (e) => {
        //     console.log(e.features.properties)
         
          // });

      //   map.addLayer({
      //     'id': 'revenuepoly_layer_fill',
      //     'type': 'fill',
      //     'source': 'revenuepoly_layer',
      //     'paint': {
      //       'fill-outline-color': bgColors.green_500,
      //       'fill-color': bgColors.green_500,
      //       'fill-opacity': 0.0
      //     },
      //     'layout': {
      //         'visibility': 'visible',
      //     },
      //     'filter': ['==', '$type', 'Polygon']
      // });

      // map.on('click', 'revenuepoly_layer_fill', (e) => {
      //   const features = map.queryRenderedFeatures(e.point);
      //   console.log(features)
      // })



      //   map.on('click', (e) => {
      //     // Change the cursor style as a UI indicator.
      //             const bbox = [
      //                 [e.point.x - 5, e.point.y - 5],
      //                 [e.point.x + 5, e.point.y + 5]
      //                 ];
                  
      //             const selectedFeatures = map.queryRenderedFeatures(bbox, {
      //               layers: ['revenuepoly_layer']
      //             });
      //             // console.log(selectedFeatures)
      //           if (selectedFeatures !== undefined && selectedFeatures.length > 0 ) {
      //               map.setFilter('revenuepoly_layer_highlighted', [
      //               // 'in',
      //               // 'id',
      //             selectedFeatures[0].properties.survey_no
      //               ]);
                 
      //         }
      //             });

}

export default VillageRevPolyLayer

// export function UpdateVillageRevPolyLayer(map,revpolygeojaon){

// if(map && revpolygeojaon)
//    {map.getSource('revenuepoly_layer').setData(revpolygeojaon)}

// } 





