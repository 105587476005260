import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import Backdrop from '@mui/material/Backdrop'; 
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Toolbar  from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import TableViewIcon from '@mui/icons-material/TableView';
import { BarChart } from '../../../../components/ReactChart/BarChart';
import { TimeBlock } from '../../../../container/dashboard.fn';
import { barChartData } from '../../../../components/ReactChart/MultiLine/linedata';
import { PlaneCard } from '../../../../UiComponent/CustomCard';
import { style } from '../Layout/const';
import BaseDatagrid from '../../../../components/Tables/BaseDatagrid';

const OptimizedSchedule = () => {
    const opt_state = useSelector(state=> state.dayahead_sch_opt_state)
    const [state,setState]=useState({
        displayPlot: true
    })
    const toggleTableGraph=() =>{
        if(state.displayPlot === true) {
            setState({...state, displayPlot:false})
        } else if (state.displayPlot=== false) {
            setState({...state, displayPlot:true})
        }
      }
    // if(!opt_state.data ){
    //     alert("please run the optimizer first")
    //     return
    // }
    // console.log(opt_state)

    let labels = TimeBlock()
    let plotdata
    let schedule_table
    let schedule_columns
    if (!opt_state.loading && opt_state.data && opt_state.data.merit_order_schedule ){
        let merit_order =  opt_state.data.merit_order_schedule.all_blockmerit_order
        let re_schedule= opt_state.data.merit_order_schedule.re_schedule
        let mustrun_schedule = opt_state.data.merit_order_schedule.mustrun_schedule
        let all_mustRun_units =  [...new Set(mustrun_schedule.map(q => q['unit_name']))]
        let non_must_run_units = [...new Set(merit_order[0].merit_order.
                                    final_schedule.map(q => q['unit_name']))]
        // console.log(merit_order)
    //     console.log(non_must_run_u nits)
        let datasets = []
        let re_data = []
        labels.forEach((element,index) => {
            let _block_re = re_schedule.filter(item => (item.block_no === index+1))
            let _re_schedule = _block_re[0].schedule_mw
            re_data.push(_re_schedule)
        });
        let _re_dict = {}
        _re_dict.label='re_schedule'
        _re_dict.data =re_data
        datasets.push(_re_dict)
        all_mustRun_units.forEach(element => {
            let mr_dict = {}
            mr_dict.label = element
            let _data = []
            labels.forEach((label,index) => {
                let _block_sch = mustrun_schedule.filter(item => (item.block_no === index+1))
                let _sch_mw = _block_sch[0].schedule_mw
                _data.push(_sch_mw)
            });
            mr_dict.data = _data
            datasets.push(mr_dict)
        });

      non_must_run_units.forEach(element => {
            let nmr_dict = {}
            nmr_dict.label=element
            let _data_sch_nmr=[]
            labels.forEach((label,index) => {
                let _block_sch_nmr = merit_order.filter(item => (item.block_no===index+1))
                let _sch_nmr_unit = _block_sch_nmr[0].merit_order.final_schedule.
                                filter(item => (item.unit_name===element))
                let _sch_nmr_unit_mw = _sch_nmr_unit[0].total_schedule
                _data_sch_nmr.push(_sch_nmr_unit_mw)
            });
           
            let _non_zero_count = 0
            _data_sch_nmr.forEach(element => {
                if(element!=0)
               { _non_zero_count += 1}
            });
            

            if(_non_zero_count >0)
           { nmr_dict.data=_data_sch_nmr
            datasets.push(nmr_dict)}
        });
        let key = 'label'
        let final_plot_data = barChartData(datasets,key)
        // let schedule_units = Object.keys(datasets)
        // console.log(TimeBlock)
        
        let table_data = []
        datasets.forEach(element => {
            let _dict = {}
            _dict.unit_name=element.label
            let _data = element.data
            labels.forEach((element,index) => {
                _dict[element]=Math.round(_data[index])
            });
            table_data.push(_dict)

        });
        
        
        let _schedule_keys=Object.keys(table_data[0])
        let _schedule_columns = []
        _schedule_keys.forEach(element => {
            let _dict = {}
            _dict.field=element
            _dict.header=element
            if(element!=='unit_name'){
                _dict.editable= true
            }
            _schedule_columns.push(_dict)
        });
        schedule_table = table_data
        // console.log(schedule_table)
        schedule_columns = _schedule_columns
        plotdata = {
            labels:labels,
            datasets:final_plot_data
        }
        
    }
   
//    console.log(schedule_columns)
    return(
        <PlaneCard 

        avatar_content = {
        <Stack direction ={'row'} 
        justifyContent="flex-start"
            alignItems="flex-start" 
            spacing={2} >
            {state.displayPlot && 
                <IconButton
                sx ={style.icon_style}
                onClick={toggleTableGraph}
                >
                <LegendToggleIcon/>
                </IconButton>
                }

            {!state.displayPlot && 
                <IconButton
                sx ={style.icon_style}
                onClick={toggleTableGraph}
                >
                <TableViewIcon/>
                </IconButton>
            }
            </Stack>
        }
        
        main_content ={
            <>
             {/* { opt_state && opt_state.loading &&
                <Backdrop
                sx={{ color: '#fff', zIndex: 10000 }}
                open={opt_state.loading}
                >
                <CircularProgress color="inherit" />
                </Backdrop>
            }
             {opt_state && !opt_state.loading && opt_state.data && opt_state.data.merit_order_schedule && state.displayPlot &&

            <BarChart
                data={plotdata }
            />
            } 

        {opt_state && !opt_state.loading && opt_state.data && opt_state.data.merit_order_schedule && !state.displayPlot &&

        <BaseDatagrid
            columns={schedule_columns}
            rows={schedule_table}
            pageSize={16}
        />

        }  */}
            </>
        }/>
       
    )
}

export default OptimizedSchedule