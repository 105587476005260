

// const rooturl ="http://localhost"
// export const opt_processapipath= `${rooturl}:6010/opt`
// export const fetchrootpath = `${rooturl}:6020/fetch`
// export const authrootpath =  `${rooturl}:6030/auth`
// export const postrootpath =  `${rooturl}:6040/post` 
// export const tilerootpath =  `${rooturl}:6050/tiles`
// export const externalapirootpath = `${rooturl}:6060/externalapi`
// export const pythonapi = `${rooturl}:6070/pythonapi`




const rooturl ="http://35.207.240.157"
export const opt_processapipath= `${rooturl}/opt`
export const fetchrootpath = `${rooturl}/fetch`
export const authrootpath =  `${rooturl}/auth`
export const postrootpath =  `${rooturl}/post` 
export const tilerootpath =  `${rooturl}/tiles`
export const externalapirootpath = `${rooturl}/externalapi`
export const pythonapi = `${rooturl}/pythonapi`