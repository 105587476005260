export const SET_FOLIO  = "SET_FOLIO"
export const ADD_TO_FOLIO  = "ADD_TO_FOLIO"
export const DELETE_FROM_FOLIO  = "DELETE_FROM_FOLIO"

export const setFolio= (selectedpolyset) => ({
    type: SET_FOLIO,
    payload: selectedpolyset
 })

 export const deleteFolioItem = (selectedpoly) => ({
    type: DELETE_FROM_FOLIO,
    payload: selectedpoly
 })


 export const addToFolio = (selectedpolyset) => ({
   type: ADD_TO_FOLIO,
   payload: selectedpolyset
  });

  