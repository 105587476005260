import React,{ useCallback, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress'
import Backdrop from '@mui/material/Backdrop'; 
import { SimpleCard } from '../../../UiComponent/CustomCard'; 
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import TableViewIcon from '@mui/icons-material/TableView';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { Today } from '../../../container/date.fn';
import { BaseTable } from '../../../components/Tables';
import CachedIcon from '@mui/icons-material/Cached';
import { setDisplayPlotData } from '../../../redux/actions/UiControlAction';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Stack } from '@mui/material';
const style = {
  Button_style:
     { Button_Style : 
      {borderStyle: 'solid',
      borderWidth: '2px',
      borderRadius:'5px',
      minWidth:'100px',
      fontSize:'15px',
      marginLeft:'auto'
     }
    },
  icon_style:{
    height:'35px', 
    width:'35px'
  }
}


const DataIntPage = ({isLoading,
                      LoadData,
                      data_table,
                      keep_action,
                      name_action,
                      action_icon,
                      define_action,
                    chart_child
                    }) => {
  const display_plot = useSelector(state => state.uiControl.display_plot)
  // console.log(display_plot)
  const dispatch = useDispatch()
  const [state, setState]= useState({
    display_plot:false
  })
  
    function toggleTableGraph() {
      if(state.display_plot === true) {
        setState({...state,display_plot:false});
        dispatch(setDisplayPlotData(false))

      } else if (state.display_plot === false) {
        setState({...state,display_plot:true});
        dispatch(setDisplayPlotData(true))
      }
    }

    // function dispatch_action(){
    //   if(state.display_plot === true){
    //     dispatch(setDisplayPlotData(false))
    //   }
    //   if(state.display_plot === false){
    //     dispatch(setDisplayPlotData(true))
    //   }
    // }




    // useEffect(()=> {
    //   dispatch_action()
    // },[dispatch,state])
  
    return(
      
      <SimpleCard
        icon_button={
        <>
         <Stack direction={'row'} spacing={2} alignItems={'center'}>   
        {state.display_plot && 
        <IconButton
          sx ={style.icon_style}
          onClick={toggleTableGraph}
        >
          <LegendToggleIcon/>
          </IconButton>
        }

        {!state.display_plot && 
          <IconButton
          sx ={style.icon_style}
          onClick={toggleTableGraph}
          >
            <TableViewIcon/>
          </IconButton>
        }
        
        <List>
        <TextField
            id="outlined-read-only-input"
            label="For date"
            size='small'
            value={Today}
            // defaultValue={Tomorrow}
            InputProps={{
            readOnly: true,
            }}
         variant="outlined"
          />
        </List>
        {/* <List >
        <Button size ={"large"} variant ="outlined" sx={style.Button_style}
          onClick={()=> dispatch(openCsvImportDialog(true))}   
          startIcon={<FileUploadIcon/>}

          >
          Import Data
         </Button>
         </List>
    */}
        <List >
        <Button size ={"large"} variant ="outlined" sx={style.Button_style}
          onClick={LoadData}  startIcon={<CachedIcon/>}
          >
          Fetch data
         </Button>
         </List>
   
   {keep_action && 
      <List >
      <Button  size ={"large"} variant='outlined'   sx={style.Button_style} 
        startIcon={action_icon}
        onClick={define_action}
        >
        {name_action}
      </Button>
    </List>
   }
        
        </Stack>    
        </>
        
      }
      content={
        <>
        { isLoading &&
             <Backdrop
             sx={{ color: '#fff', zIndex: 10000 }}
             open={isLoading}
             >
             <CircularProgress color="inherit" />
             </Backdrop>
        }
        { !isLoading && data_table && data_table.length > 0 && !display_plot &&
          <BaseTable
          action_column = {false}
          data = {data_table}
          /> 
          }
         { !isLoading && data_table && data_table.length > 0 && display_plot &&
           React.Children.toArray(chart_child).map(child => child)
         }
        </>

      }
      />
    )
}

export default DataIntPage

DataIntPage.propTypes = {
  isLoading:PropTypes.bool,
  LoadData:PropTypes.func,
  data_table:PropTypes.array,
  keep_action: PropTypes.bool,
  name_action:PropTypes.string,
  action_icon:PropTypes.element,
  define_action:PropTypes.func,
  chart_data:PropTypes.array,
 
 };