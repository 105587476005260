import React from "react";
import { DragnAndDrop } from "../../../UiComponent/DragAndDrop";
import {PlaneCard} from "../../../UiComponent/CustomCard";
import Grid from "@mui/material/Grid";
import UploadIcon from '@mui/icons-material/Upload';
import Button  from "@mui/material/Button";
import Stack from "@mui/material/Stack";

const  UploadCsv=({onFilesSelected,child_table}) => {
  return (
    // <CustomDiv>
        <PlaneCard
          sx ={{justifyContent:'center', Padding:'5rem'}}
          main_content={
            <Grid     
                container spacing={1} 
                direction="row"
                justifyContent="center"
                alignItems="center">

            <Grid item xs={6} md={4}>
            <DragnAndDrop 
                width="300px"
                onFilesSelected={onFilesSelected} />
            </Grid>

            <Grid item xs={6} md={8}>
            {child_table}
            </Grid>
          
          </Grid>
         
        }
        />
      )
  }
  
export default UploadCsv


