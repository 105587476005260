import {
    GET_GENSCH_REQUEST,
    GEN_GENSCH_SUCCESS,
    GEN_GENSCH_FAILURE
} from '../actions/fetchScheduleDetailsAction'

const initialState = {
    loading: false,
    schedule: [],
    error: '',
}

const fetchScheduleDetailsReducer = (state = initialState, action) => {

    switch (action.type) {
        case GET_GENSCH_REQUEST:
        return {
            ...state,
            loading:true

        }

        case GEN_GENSCH_SUCCESS:
        return {
            ...state,
            loading:false,
            schedule: action.payload,
            error: ''
            

        }
        case GEN_GENSCH_FAILURE:
            return {
                ...state,
                loading: false,  
                schedule: [],  
                error: action.payload  
            }
        default: return state
    }

}

export default fetchScheduleDetailsReducer  