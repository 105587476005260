import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
const PopupMenuChart=({anchorPosition,options,handleClose}) =>{
  return (
      <Menu
        open={Boolean(anchorPosition)}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
      >
         <Paper sx={{ width: 320, maxWidth: '100%' }}>
            <MenuList>
            {options.map((option,index)=> {
            return <MenuItem key = {`${option}_${index}`} onClick={handleClose}>{option}</MenuItem>
            })}
        </MenuList>
        {/* <Button variant="contained" 
          onClick={handleClose}
          >
          commit
        </Button> */}
        </Paper>
      </Menu>
  
  );
}
export default PopupMenuChart

