import {UPDATE_DRAG_STATE,
  UPDATE_EDIT_MODE_STATE,
  UPDATE_PIVOT1_STATE,
  UPDATE_PIVOT2_STATE

} from "../actions/LF_ChartActionDrag"


const initialState = {
   index: null,
   datasetIndex: null,
   value: null,
   edit_mode:'Shift',
   control_point_1:0,
   control_point_2:95
 }

 const loadForecastchartdragActionReducer =  (state = initialState, action) => {
   switch (action.type) {
     case UPDATE_DRAG_STATE:
       return {
         ...state,
         index: action['payload']['index'],
         datasetIndex: action['payload']['datasetIndex'],
         value: action['payload']['value'],
       }
       case UPDATE_EDIT_MODE_STATE:
        return {
          ...state,
          edit_mode: action['payload']
        }

        case UPDATE_PIVOT1_STATE:
        return {
          ...state,
          control_point_1: action['payload']
        }

        case UPDATE_PIVOT2_STATE:
        return {
          ...state,
          control_point_2: action['payload']
        }

     default:
       return state;
   }
 };

 export default loadForecastchartdragActionReducer  


