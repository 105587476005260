import React ,{forwardRef, useEffect, useState}from 'react';
import { bgColors } from '../../../utils/bgColor';
import ChartJSdragDataPlugin from '../DraggableBarChart.js/DragDataPlugin';
import { setDraggedState } from '../../../redux/actions/ChartActionDrag';
import { useDispatch } from 'react-redux';
import { getDatasetAtEvent,getElementsAtEventForMode } from 'react-chartjs-2';
import * as d3 from "d3";
import { drag } from "d3-drag";
import { select } from "d3-selection";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'chart.js';
import { Line} from 'react-chartjs-2';
import PropTypes from 'prop-types';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartJSdragDataPlugin
);

const MultiLine=forwardRef((props,ref) =>{
  const {data,onClick} = props
  // const [onDragState, setOnDragState]= useState({
  //   index:null,
  //   datasetIndex:null,
  //   value:null
  // })

  const dispatch = useDispatch()
  // useEffect(()=> {
  //   dispatch(setDraggedState(onDragState))
  // },[dispatch])
  // const {data,onClick,} = props
  const options = {
  
    responsive: true,
    maintainAspectRatio : false,
    interaction: {
      mode: 'index',
      intersect: true,
    },
      plugins: {
          legend: 
              {
               
              labels: {
                usePointStyle: true,
                boxWidth: 20,
                color: '#FFFFFF', 
                font: {
                  size: 14,
                },
              }
            },
            // dragData: true,
            dragData:{
              onDragStart: (event) =>{
                // console.log(event)
              },
              onDragStart: function (e) {
                // console.log(e)
              },
              onDrag: (event,datasetIndex,index,value) =>{
                dispatch(setDraggedState({
                  index:index,
                  datasetIndex:datasetIndex,
                  value:value
                }))
                event.target.style.cursor='grabbing'
              },
              onDragEnd: function (event, datasetIndex, index, value) {
                
                //  dispatch(setDraggedState({
                //   index:index,
                //   datasetIndex:datasetIndex,
                //   value:value
                // }))
                
              }
            }
            



      },
     
      scales: {
        x:{
          stacked: true,
          ticks: {
            color: '#FFFFFF', 
            font: {
              size: 10,
            },
            stepSize: 4,
            beginAtZero: true
  

          },
          gridLines: {
            color: bgColors.grey_500
          },
    
        },
        y: {
          stacked: false,
          display: true,
          gridLines: {
            color: bgColors.grey_500
          },
         
        position: 'right',
        ticks: {
          color: '#FFFFFF', 
          font: {
            size: 10,
          },
          stepSize: 500,
          beginAtZero: false,
          // miny:-2000,
          // maxy:150000
        },
        
        }
      },
    }; 

   

  return (
    <div style={{ position: "relative", 
      margin: "auto",
      height: '70Vh',
      }}>
    
    <Line 
      ref ={ref}
      options={options} 
      data={data} 
      onClick={onClick}
      />
      </div>
      );
})

export default MultiLine




