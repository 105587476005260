

import { useState,useEffect } from "react";
const KEY = "state";
export function loadState() {
  try {
    const serializedState = localStorage.getItem(KEY);
    if (!serializedState) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
}

export async function saveState(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(KEY, serializedState);
  } catch (e) {
    // Ignore
  }
}


// export function loadState(){
//     let all_state = localStorage.getItem('state')
//     if (all_state ===undefined)
//     return
//     let parsedval = JSON.parse(all_state)
//     return parsedval

//     }


export function returnInitialState(storageKey) {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(storageKey);
      // Parse stored json or if none return an empty object
      return item ? JSON.parse(item) : {};
    } catch (error) {
      // If error also return an empty object
      console.log(error);
      return {};
    }
  }

  function getStorageValue(key, defaultValue) {
    // getting stored value
    const saved = localStorage.getItem(key);
    const initial = JSON.parse(saved);
    return initial || defaultValue;
  }
  
  export const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
      return getStorageValue(key, defaultValue);
    });
  
    useEffect(() => {
      // storing input name
      localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);
  
    return [value, setValue];
  };
