import {useRef} from 'react';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,

} from 'chart.js';
import './BarChart.css';
import { customTooltips } from './CustomTooltip';
import { Bar} from 'react-chartjs-2';
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);
Tooltip.positioners.myCustomPositioner = function(elements, eventPosition) {
  // A reference to the tooltip model
  const tooltip = this;

  /* ... */

  return {
      x: 0,
      y: 0
      // You may also include xAlign and yAlign to override those tooltip options.
  };
};

export const options = {
  responsive: true,
  maintainAspectRatio : false,
  interaction: {
    mode: 'index',
    intersect: true,
  },
    plugins: {
        legend: {
            labels: {
              usePointStyle: true,
              boxWidth: 10,
              color: '#FFFFFF', 
              font: {
                size: 10,
              },
            }
          },
          tooltip: {
            enabled: true,
            intersect: true,
             position: 'myCustomPositioner'
            },
   

      dragData:{
        onDragStart: (event) =>{
          // console.log(event)
        },
        onDragStart: function (e) {
          // console.log(e)
        },
        onDrag: (event,datasetIndex,index,value) =>{
          // var start_index = Math.min(drag_action_state.control_point_1,
          // drag_action_state.control_point_2)
          // var end_index = Math.max(drag_action_state.control_point_1,
          //   drag_action_state.control_point_2)  
          // console.log(event.x, event.y)
          // console.log(d3.select("#chart").append('svg'))
          // event.target.style.cursor='grabbing'
          // console.log(datasetIndex, index, value)
          // let data_val = data.datasets[datasetIndex]
          // let xy_mat = []
        //   data_val.points.forEach(function (points) {
        //     xy_mat.push( points.x, points.y,points.value)
        // });
        // console.log(data['datasets'][datasetIndex]['data'])

        },
        onDragEnd: function (event, datasetIndex, index, value) {
          console.log(datasetIndex, index, value)
        }
      }
    },
   
    scales: {
      x:{
        stacked: true,
        ticks: {
          color: '#FFFFFF', 
          font: {
            size: 10,
          },
          stepSize: 4,
          beginAtZero: true

        }
  
      },
   
    },
  };


const BarChart =({ref,data}) =>{
  
 
  return (

    <div style={{ position: "relative", 
    margin: "auto",
    height: '80vh',

    }}>
    <Bar 
      redraw={true}
      ref={ref}
      options={options} 
      data={data}/>
    </div>
  )
 
  
}

export default BarChart

BarChart.propTypes={
    data:PropTypes.array

}




