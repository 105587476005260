

import TimelineIcon from '@mui/icons-material/Timeline';
import { GiGreenPower } from "react-icons/gi";
import { AiOutlineImport } from "react-icons/ai";
import { CgInternal } from "react-icons/cg";
import { IoMdTime } from "react-icons/io";
import { MdOutlineScheduleSend } from "react-icons/md";
import { ImPowerCord } from "react-icons/im";


export const  menu_list = {
    'Load Data':['Load Data'],
    'Market Data': ['Day-ahead', 'RTM'],
    'ISGS-Gen': ['Current Schedule', 'Current summary', "Day-ahead Entitlement"],
    'Internal-gen':['Current Schedule',"Day-ahead Entitlement"],
    'Re-Gen': ["Day-ahead", 'Real-time'],
    'Weather Data':['Weather Data']
}

export const  menu_list_forecast = {
    'Demand':['Day-ahead','Intra-day','Medium-term'],
    'Wind-generation': ['Day-ahead','Intra-day','Medium-term'],
    'Solar-generation': ['Day-ahead','Intra-day','Medium-term'],
}


export const MenuListHiearchical = [
    {   icon: <ImPowerCord />,
        name: "Load Data",
        to: "./LoadData",
    },
    { 
      icon: <TimelineIcon />,
      name: "Market Data",
      to: "./MarketData",
      children:[
            {   icon:<IoMdTime/>,
                name: 'RTM',
                to: "./IexPriceUpload",
            },
            {   icon:<MdOutlineScheduleSend/>,
                name: 'Day-ahead',
                to: "./IexPriceUploadRTM",
            }]
      },
      { 
        icon: <GiGreenPower />,
        name: "Re-Gen",
        to: "./ReGenertion",
        children:[
              {   icon:<MdOutlineScheduleSend/>,
                  name: 'Day-ahead',
                  to: "./IexPriceUpload",
              },
              {  icon:<IoMdTime/>,
                  name: 'Real-time',
                  to: "./IexPriceUploadRTM",
            }]
        },

       { 
        icon: <AiOutlineImport/>,
        name: "ISGSData",
        to: "./IsgsTab",
        children:[
          {   icon:<IoMdTime/>,
              name: 'Current Schedule',
              to: "./ScheduleDetail",
              // icon:
  
          },
          {   icon:<MdOutlineScheduleSend/>,
              name: 'Current summary',
              to: "./ScheduleSummary",
              // icon:
              
          },
          { icon:<MdOutlineScheduleSend/>,
            name: 'Day-ahead Entitlement',
            to: "./DayAheadEntitlement",
            // icon:
            },
        
        ]
      },
      { 
        icon: <CgInternal />,
        name: "Internal-gen",
        to: "./InternalGenerationTab",
        children:[
          {   icon:<IoMdTime/>,
              name: 'Current Schedule',
              to: "./ScheduleDetail",
              
          },
          { 
              name: 'Current summary',
              to: "./ScheduleSummary",
              
          },
          { 
            name: 'Day-ahead Entitlement',
            to: "./DayAheadEntitlement"
            },
        
        ]
      },
      
  ];



