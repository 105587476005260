import {useDispatch, useSelector } from "react-redux";
import { fetchCurrent_ISGS_Schedule} from '../../../Apis/actions/current_ISGS_Schedule';
import { DataIntPage } from "../Layout";
import Isgs_summary_chart from "./IsgsSummaryPlot";

const ScheduleSummary = ({isLoading,data_table}) => {
    const show_plot = useSelector(state=> state.uiControl.display_plot)
    // console.log(show_plot)

    const dispatch = useDispatch()
    const LoadData =  async(e) => {
        await dispatch(fetchCurrent_ISGS_Schedule())
    }  
    return ( 
      
        <DataIntPage
            isLoading={isLoading}
            data_table={data_table}
            LoadData = {LoadData}
            chart_child ={<Isgs_summary_chart/>}
            >
        </DataIntPage>
    )
}
export default ScheduleSummary