

import { useEffect, useState } from "react";
import Papa from "papaparse";
import Button from "@mui/material/Button";
import axios from 'axios'
import { useSelector,useDispatch } from "react-redux";
import { UploadCsv } from "../UploadCsv";
import { BaseTable } from "../../../components/Tables";
import { PlaneCard } from "../../../UiComponent/CustomCard";
import { fetchInternaGenUnitMaster } from "../../../Apis/actions/InternalGenUnitMaster";
import { postrootpath } from "../../../Apis/apirootpath";
const  IexPriceUploadRTM=() => {
  const [files, setFiles] = useState([]);
  const [state, setState] = useState({
        isloading:'false',
        data:[],
        internal_gen_master:[],
        post_data:[],
  })
  function parseCsv(files){
    setState({...state,isloading:true})
    // Papa.parse(event.target.files[0], {
    Papa.parse(files[0],   
        {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const rowsArray = [];
          const valuesArray = [];
          // Iterating data to get column name and their values
          results.data.map((d) => {
            rowsArray.push(Object.keys(d));
            // console.log(Object.keys(d))
            valuesArray.push(Object.values(d));
          });

          setState({...state,
            isloading:false,

            data:results.data
          
          });
    
        },
      });

  }

console.log(state.data)
useEffect(()=> {
    if(files && files.length >0){
        parseCsv(files)
    }
  },[files])



function reshapeData(){
    let all_data = []
    let filter_var = ['region', 'state', 'discom', 'pool', 'date',
                    'buyer', 'block_no', 'rev_no']
    let all_var=Object.keys(state.data[0])
    let unit_list = all_var.filter(item => !filter_var.includes(item))
    // console.log(unit_list)
    state.data.forEach(element => {
      // console.log(element)
      //  let date = new Date(element['date'])
      //   console.log(element['date'])
        unit_list.forEach(item => { 
            let _dict = {}
            _dict['region_name']=element['region']
            _dict['state_name']=element['state']
            _dict['discom']=element['discom']
            _dict['pool']="INTERNAL"
            _dict['buyer']=element['buyer']
            _dict['seller']=item
            _dict['date']=element['date']
            _dict['rev_no']=element['rev_no']
            _dict['block_no']=element['block_no']
            _dict['EntOnBar']=element[item]
            _dict['ReqOnBar']=element[item]
            // if (element[item["unit_name"]])
            //     {_dict['EntOnBar']=element[item]}
            //   {_dict['EntOnBar']=element[item]}
            // _dict['ReqOnBar']=0
            // if (element[item["unit_name"]])
            //   {_dict['ReqOnBar']=element[item]}
                // {_dict['ReqOnBar']=element[item["unit_name"]]}
            all_data.push(_dict)
            // console.log(_dict)
        });
      //  console.log(_dict)
      //  console.log(all_data)
        
    });
    return all_data
  
}

useEffect(()=> {
  if(files && state.data && state.data.length >0)
   { reshapeData()}

},[files,state.data])

// console.log(state.all_data)
  

  const commitLoadData = async (e) => {
  const data_to_post = reshapeData()

    // console.log(data_to_post)
    // console.log(data_to_post)
    e.preventDefault();
    console.log(data_to_post)
    try { axios.post(`${postrootpath}/commitEntitlementInternal`, data_to_post,
          {
            dataType: 'json',
            async: true,
          }).then(function (response) {
            if(response.data.status ==="Success")
             { alert(`No of rows inserted: ${response.data.records}`)}
          })
          .catch(err => {
            alert(`Error message: ${err}}`);
        
        })

    }
    catch (e) {
      // setError(e)
      alert(`submit failed! ${e.message}`);
    }
}
  
  return (
    <PlaneCard
        main_content={
        <UploadCsv
            onFilesSelected={setFiles}
            child_table={
            <>
            {!state.isloading && state.data && state.data.length>0 && 

                <BaseTable
                    checkbox_column={false}
                    action_column = {false}
                    data = {state.data}
                />
             }
            </>
            }
        />}
        action_content={
            <Button sx = {{marginLeft:'auto'}}
            onClick={commitLoadData}
            >
                commit
            </Button>
        }
        
        />
      )
  }
  
export default IexPriceUploadRTM





