export function range(start, end, increment) {
  var array = [];
  var current = start;

  increment = increment || 1;
  if (increment > 0) {
      while (current <= end) {
          array.push(current);
          current += increment;
      }
  } else  {
      while (current >= end) {
          array.push(current);
          current += increment;
      }
  }
  return array;
}



export function FilterUniqueKeyVal(object_array, key_list){
    const result = [...new Set(object_array.map(o => JSON.stringify(o, 
        key_list)))].map(o => JSON.parse(o));

    return result
}


export function FilterArrayKeyval(data,keys_list, val_list){

    var result = data.filter(function(e) {
        return keys_list.every(function(a) {
          return val_list.includes(e[a])
        })
      })
    return result
}


export function groupBySum(test_data,
    index_var, 
    index_name,
    gr_var_name,
    value_var)
    {
    let all_data = []
    let group_by = [...new Set(test_data.map(q => q[gr_var_name]))]
    index_var.forEach(block => {
        group_by.forEach(element => {
        let _var_ ={}
        let total = 0
        let _FilterArrayKeyval = FilterArrayKeyval(test_data,[index_name,gr_var_name],
                                        [block,element])
        _var_[index_name]=block
        _var_[element]  =''
        _var_[value_var]  =''
        _FilterArrayKeyval.forEach(item => {
            total +=item[value_var]
            if(item[index_name]===block){
                 _var_[gr_var_name] = element
                 _var_[value_var] = total
            }
        });
        all_data.push(_var_)
        });
     });
    return (all_data)
}


export const pick_object_keys = (obj, arr) =>
    Object.fromEntries(Object.entries(obj).filter(([k]) => arr.includes(k)));
  
export const omit_object_keys = (obj, arr) =>
    Object.fromEntries(Object.entries(obj).filter(([k]) => !arr.includes(k)));