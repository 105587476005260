
export const SET_DRAW_FEATURE  = "SET_DRAW_FEATURE"
export const SET_MAP_CENTER_CORD  = "SET_MAP_CENTER_CORD"
export const SET_VILLAGEREV_POLY  = "SET_VILLAGEREV_POLY"
export const SET_NAV_ROUTE = "SET_NAV_ROUTE"
export const SET_NAV_ROUTE_DATA = "SET_NAV_ROUTE_DATA"

export const SET_FILTER = "SET_FILTER"
export const SET_BLOBAL_RASTER = "SET_BLOBAL_RASTER"
export const SET_RASTER_ROI = "SET_RASTER_ROI"
export const SET_RASTER_DATE = "SET_RASTER_DATE"

export const SET_VECTOR_LAYER = "SET_VECTOR_LAYER"
export const SET_MAP_STYLE = "SET_MAP_STYLE"
export const SET_START_CORD = "SET_START_CORD"
export const SET_END_CORD = "SET_END_CORD"
export const SET_VILLSEARCH_STR = "SET_VILLSEARCH_STR"
export const SET_VIEWPORT_BOUND = "SET_VIEWPORT_BOUND"

export const setDrawFeature= (drawfeature) => ({
    type: SET_DRAW_FEATURE,
    payload: drawfeature
 })

 export const setVillSearchStr = (selectedvillage) => ({
   type: SET_VILLSEARCH_STR,
   payload: selectedvillage
})

 export const setMapCentreCord = (centercord) => ({
    type: SET_MAP_CENTER_CORD,
    payload: centercord
 })

 export const setStartCord = (startcord) => ({
   type: SET_START_CORD,
   payload: startcord
})

export const setEndCord = (endcord) => ({
   type: SET_END_CORD,
   payload: endcord
})


 export const setVillagerevpoly = (villagerevpoly) => ({
   type: SET_VILLAGEREV_POLY,
   payload: villagerevpoly
  });

  export const setNavRoute = (navroute) => ({
   type: SET_NAV_ROUTE,
   payload: navroute
  });

  export const setNavRouteData = (data) => ({
   type: SET_NAV_ROUTE_DATA,
   payload: data
  });



  export const setFilterOption = (filter) => ({
   type: SET_FILTER,
   payload: filter
  });

  export const setGlobalRaster = (raster) => ({
   type: SET_BLOBAL_RASTER,
   payload: raster
  });

  export const setRasterRoi = (roi) => ({
   type: SET_RASTER_ROI,
   payload: roi
  });

  export const setRasterDate= (date) => ({
   type: SET_RASTER_DATE,
   payload: date
  });

  export const setVectorLayer = (vectorlayer) => ({
   type: SET_VECTOR_LAYER,
   payload: vectorlayer
  });

  export const setMapStyle = (mapstyle) => ({
   type: SET_MAP_STYLE,
   payload: mapstyle
  });

  export const setViewportBound = (viewportbound) => ({
   type: SET_VIEWPORT_BOUND,
   payload: viewportbound
  });




 






 