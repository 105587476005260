import React, {useEffect, useState } from 'react'
import DatePickerYear from '../../UiComponent/DatePicker/DatePickerYear'
import { fetchEvent } from '../../Apis/actions/fetchEvent'
// import {  } from '../../Apis/actions/fetchApiAction'
import { useDispatch,useSelector } from 'react-redux'
import { Button, IconButton, Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import {TableAction} from '../TableAction'
import dayjs from 'dayjs';
import { createTheme } from '@mui/material/styles'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import TextField from '@mui/material/TextField'
// import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AddEventForm from './AddEventForm'
import { fetchEventList } from '../../Apis/actions/eventList'
import { loadState } from '../../utils/Localstorage'
import { fetchrootpath } from '../../Apis/apirootpath'

const newTheme = (theme) => createTheme({
    ...theme,
    components: {
        borderRadius: 20,
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            borderRadius: 20,
            // borderWidth: 1,
            // border: '1px solid',
          }
        }
      }
    }
  })

const SetEvents = () => {
    const dispatch = useDispatch()
    const event_table = useSelector(state => state.fetchEventReducer)
    // console.log(event_table)

    const selected_state = loadState().selected_utility.selected_state

    // console.log(selected_state)

    let current_year =  dayjs(new Date());
    const [year, setYear] = useState(current_year['$y'])
    const onChangeYear = (event) => {
        setYear(event['$y'])
        }
    const [data, setData] = useState({
        columns: [],
        rows: []
    })

    // console.log(eventData)

    const ArryAObjkey = () => {
        let column_keys
        if (event_table && event_table.data.length>0)
        {column_keys =  Object.keys(event_table.data[0])}
        return column_keys 
    }

    const getColumns =() => {
        if (event_table && event_table.data.length>0)
       { const allKeys = ArryAObjkey()
        const _columns = []
        allKeys.forEach(element => {
            let _var = {
                id: element,
                label: element,
                minWidth:170,
            }
            _columns.push(_var)
         
        });
        return _columns}
    }

    const  onSubmit = async(e) => {
        e.preventDefault()
        const query_param = {year:year,
                            state: selected_state}
        const url = `${fetchrootpath}/annualevents`
        const method ="GET"
        await dispatch(fetchEvent(url,method,query_param))

    }

    useEffect(()=> {
        if (event_table && event_table.data.length>0)
        {
            console.log(data)
            setData({...data, 
            columns:getColumns(),
            rows:event_table.data
            })
        }

    },[event_table])
    const getActionRows = () => {
        let all_rows = []
        data.rows.forEach(item=> {
            let _action_rows = {
                date: item.date.split('T')[0],
                name: item.name,
                state: item.state,
               
            }
            all_rows.push(_action_rows)
        })
        return all_rows
    }

    const [openForm, setOpenForm] = useState({
        addEventFormOpen: false,
        editEventFormOpen: false,
        deleteEventFormOpen:false
    })

    const handleFormOpen = () => {
        setOpenForm({...openForm,
            addEventFormOpen:true,
            editEventFormOpen:false,
            deleteEventFormOpen:false
        
        })
        dispatch(fetchEventList().selected_state)
    }

    
    return (
        <>
        <Stack direction={"row"} spacing={2}>
            <DatePickerYear
                // value = {state.year}
                label={"year"}
                id={"event_year"}
                defaultValue={current_year}
                onChange={onChangeYear}
            // onChange={onChangeYear}
            />
            <Button variant='outlined'
                onClick={onSubmit}
            >
            Submit
            </Button>
            </Stack>

            <Grid  container sx = {{marginTop:'1rem', 
                marginBottom:'1rem', 
                justifyContent:"space-between",
                justifyItems:'center'
            
            }} > 
            {/* <Stack direction={"row"} spacing={2}> */}
            <Grid item>
            <TextField id="outlined-basic" variant="outlined"   sx= {{flex: 1, minWidth:'400px'}}
                InputProps={{
                    sx: {borderRadius: 2},
                    startAdornment: (
                    <InputAdornment position="start">
                        <SearchOutlinedIcon />
                    </InputAdornment>
                    ),
                }}
            />
            </Grid>
            <Grid item >
            <Stack 
            // direction={'columns'} 
            spacing={2} sx={{justifyContent:'center',alignItems:'center'}}>
            <Typography >
               Add event
            </Typography>
           <IconButton 
           onClick={handleFormOpen}
        //    onClick ={()=> setOpenForm({
        //     ...openForm,
        //     addEventFormOpen:true,
        //     editEventFormOpen:false,
        //     deleteEventFormOpen:false
        //    })}
           
         
           >
            <AddCircleOutlineOutlinedIcon/>

           </IconButton>
           </Stack>
           </Grid>
           {/* </Stack> */}
            </Grid>


        <div  id = 'base_table' style={{marginTop:'1rem'}}>

        {/* {} */}

            <TableAction
                columns={data.columns}
                rows={ getActionRows()
                } 
                pagesize = {10}
                // children ={ <IconButton 
                //     onClick={(event)=> console.log(event) }
                //     >  <DeleteOutline/> </IconButton>}
                // children1 ={ <IconButton> <EditOutlinedIcon/> </IconButton>}
                />


        </div>

        <AddEventForm
        open={openForm.addEventFormOpen}
        handleClose={()=> setOpenForm({
            ...openForm,
            addEventFormOpen:false})}
        
        />

            {/* <TableForm/> */}

        </>
    )
}

export default SetEvents