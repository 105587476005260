import {useRef} from 'react';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,

} from 'chart.js';
import { Bar} from 'react-chartjs-2';
// import  Button  from '@mui/material/Button';
// import { primary } from '../../../common/colors';
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio : false,
  interaction: {
    mode: 'index',
    intersect: true,
  },
    plugins: {
        legend: {
            labels: {
              usePointStyle: true,
              boxWidth: 20,
              color: '#FFFFFF', 
              font: {
                size: 14,
              },
            }
          },
      // title: {
      //   display: true,
      //   text: 'Chart.js Bar Chart - Stacked',
      // },
    },
   
    scales: {
      x:{
        stacked: true,
        ticks: {
          color: '#FFFFFF', 
          font: {
            size: 10,
          },
          stepSize: 4,
          beginAtZero: true

        }
  
      },
      y: {
        stacked: false,
        display: true,
       
      position: 'right',
      ticks: {
        color: '#FFFFFF', 
        font: {
          size: 10,
        },
        stepSize: 1000,
        beginAtZero: false,
        callback: function(value) {
          return `${value}`
        }
      },
      
      },
      ystacked: {
        stacked: true,
        display: true,
        
      position: 'left',
      ticks: {
        color: '#FFFFFF', 
        font: {
          size: 10,
        },
        
        stepSize: 1000,
        beginAtZero: false,
        callback: function(value) {
          return `${value}`
        }
        

      },
      
      },
    },
  };


const AreaChart =({data}) =>{
  

  const AreaChartRef = useRef(); 
  return (

    <div style={{ position: "relative", 
    margin: "auto",
    height: '60Vh',

    }}>
    <Bar 
    redraw={true}
    ref={AreaChartRef}
    options={options} 
    data={data}/>
    </div>
  )
 
  
}

export default AreaChart

AreaChart.propTypes={
    data:PropTypes.array

}




