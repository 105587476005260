import {
    API_START,
    API_END
  } from "../actions/types";

  import { SET_DAYAHEAD_SCH,
    RUN_DAYAHEAD_SCH_OPT,
    RUN_DAYAHEAD_SCH_OPT_ERROR} from "../actions/RunDayAheadSchOptimizerRequest"
    
  const initialState = {
      loading: false,
      data: {},
      error: ''
  }
  
  export default function dayAheadScheOtimizerReducer(state = initialState, action) {
    //console.log("action type => ", action.type);
    switch (action.type) {
    
      case SET_DAYAHEAD_SCH:
        return { ...state, data: action.payload };
      case API_START:
        
        if (action.payload === RUN_DAYAHEAD_SCH_OPT) {
          return {
            ...state,
            loading: true,
            data:{},
            error: ""
          };
        }
        return(null)
        // break;
        
        
      case API_END:
        if (action.payload === RUN_DAYAHEAD_SCH_OPT) {
          return {
            ...state,
            loading: false,
          };
        }
        return(null)
        // break;
        case RUN_DAYAHEAD_SCH_OPT_ERROR:
          return {
            ...state,
            data: {},
            error: action.payload.message
          };

      default:
        return state;
    }
  }
  