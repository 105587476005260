import React from "react";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
// import {bgColors} from "../utils/bgColor"


const styles = {
    media: {
      height: '50px',
      width:'50px',
      padding: '3%', 
      alignItems:"center",
      justifyContent:"center",
      margin: "auto"
    }
};

function AuthFormHeader(){
    return (
        <>
        <CardMedia
        component="img"
        // image='logo192.png'
        image = '../logo192.png'
        style={styles.media} 
             />   
        <Typography 
            style={{margin:'auto', fontSize: 18, 
                                fontStyle: 'italic',
                                fontWeight: 'bold',
                                padding: '2%',
                                //  fontFamily:'roboto',
                                 fontFamily: 'serif',
                                //  fontFamily: 'serif',
                                //  color:bgColors.grey_700
                                 }}> 
                {"EnergyAtlas"}
            </Typography>
        
        </>
    )

}

export default AuthFormHeader


