import {API} from "./types";
import { fetchrootpath } from "../apirootpath";
import { opt_processapipath } from "../apirootpath";
export const FETCH_DA_SCHDETAILS = "FETCH_DA_SCHDETAILS"
export const FETCH_DA_SCHDETAILS_ERROR = "FETCH_DA_SCHDETAILS_ERROR"
export const SET_DA_SCHDETAILS = "SET_DA_SCHDETAILS"

export function fetchDayAheadSchDetails(method,query_param){
    const get_url = `${opt_processapipath}/DayAheadSchDetails`
    return apiAction({
      url:get_url,
      method:method,
      data:query_param,
      onSuccess: setData,
      onFailure: (error) => ({
        type: FETCH_DA_SCHDETAILS_ERROR,
        payload: error
      }),
      label: FETCH_DA_SCHDETAILS
    });
  }


  function setData(data) {
    // console.log(data)
    //let weather = firebase.database().ref('locations/1');
    // //weather.child('weather_forecast').set(json);
    // console.log(data);
    return {
      type: SET_DA_SCHDETAILS,
      payload: data
    };
  }
  
  function apiAction({
    url = "",
    method = "",
    data = null,
    accessToken = null,
    onSuccess = () => {},
    onFailure = () => {},
    label = "",
    headersOverride = null
  }) {
    return {
      type: API,
      payload: {
        url,
        method,
        data,
        accessToken,
        onSuccess,
        onFailure,
        label,
        headersOverride
      }
    };
  }




  