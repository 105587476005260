import {
    API_START,
    API_END
  } from "../actions/types";

import {
  FETCH_LOADFORECAST,
  FETCH_LOADFORECAST_ERROR,
  SET_LOADFORECAST
} from "../actions/fetchLoadForecast"

  const initialState = {
      loading: false,
      data: {},
      error: ''
  
  }
  
  
  export default function loadForecastReducer(state = initialState, action) {
    //console.log("action type => ", action.type);
    switch (action.type) {
    
      case SET_LOADFORECAST:
        return { ...state, data: action.payload };
      case API_START:
        
        if (action.payload === FETCH_LOADFORECAST) {
          return {
            ...state,
            loading: true,
            data:{},
            error: ""
          };
        }
        return(null)
        // break;
      case API_END:
        if (action.payload === FETCH_LOADFORECAST) {
          return {
            ...state,
            loading: false,
          };
        }
        return(null)
        // break;
        case FETCH_LOADFORECAST_ERROR:
          return {
            ...state,
            data: {},
            error: action.payload.message
          };

      default:
        return state;
    }
  }
  