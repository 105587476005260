import { tilerootpath } from "../../../../Apis/apirootpath"
const waterbodyurl = `${tilerootpath}/india_waterpoly`
export default function Waterbody(map,WaterBodies,opacity){

  map.current.on('load', () => {

  const source_id = "india_waterpoly"
  const layer_id =  "india_waterpoly"
  let visibility = 'visible'
  if(WaterBodies===false){
    visibility = 'none'
  }
  if (map) {
  if (map.current.getLayer(layer_id)) {
    map.current.removeLayer(layer_id);
  }
  if (map.current.getSource(source_id)) {
     map.current.removeSource(source_id);
  }
    map.current.addSource(`${source_id}`, {
    type: 'vector',
    "tiles": [`${waterbodyurl}/{z}/{x}/{y}.mvt`],
    "tolerance": 1,
    'minzoom': 7
    // 'maxzoom': 20,
  });
  map.current.addLayer({
    'id': `${source_id}`,
    'source': `${layer_id}`,
     'source-layer': `${layer_id}`,
    "type": "fill",
    'layout': {
      'visibility': visibility,
    },
    'paint': {
      "fill-color": "#00ffff",
      'fill-opacity': opacity
    }
  });
}
})
}

export function handleWaterbodylayer(map,WaterBodies){
  const source_id = "india_waterpoly"
  const layer_id =  "india_waterpoly"
  if(WaterBodies===false){
    if (map.current.getLayer(layer_id)) {
        map.current.removeLayer(layer_id);
      }
    if (map.current.getSource(source_id)) {
         map.current.removeSource(source_id);
      }
  }
}
