
import {API} from "./types";
import { fetchrootpath } from "../apirootpath";
export const FETCH_EVENTLIST = "FETCH_EVENTLIST"
export const FETCH_EVENTLIST_ERROR = "FETCH_EVENTLIST_ERROR"
export const SET_EVENTLIST = "SET_EVENTLIST"
  
export function fetchEventList() {
    return apiAction({
      url:
        `${fetchrootpath}/Eventname`,
      onSuccess: setEventList,
      onFailure: (error) => ({
        type: FETCH_EVENTLIST_ERROR,
        payload: error
      }),
      label: FETCH_EVENTLIST
    });
  }


  function setEventList(data) {
    //let weather = firebase.database().ref('locations/1');
    //weather.child('weather_forecast').set(json);
    //console.log(data);
    return {
      type: SET_EVENTLIST,
      payload: data
      // payload:{
      //   data: {
      //     type: data.type,
      //     features: data.features
      //   }
      // }
    };
  }


  
  function apiAction({
    url = "",
    method = "GET",
    data = null,
    accessToken = null,
    onSuccess = () => {},
    onFailure = () => {},
    label = "",
    headersOverride = null
  }) {
    return {
      type: API,
      payload: {
        url,
        method,
        data,
        accessToken,
        onSuccess,
        onFailure,
        label,
        headersOverride
      }
    };
  }




  



  