export const initial_dataset = [
  { type:'line',
    label: 'Dataset1',
    data: [100,200,400,500,300,400,350,100,200,400,500,300,400,350],
    // pointRadius: 10,
    borderColor: 'rgb(255, 99, 132)',
    backgroundColor: 'rgba(255, 99, 132, 0.5)',
    yAxisID: 'ystacked',
    fill:false
  },
  {type:'line',
    label: 'Dataset2',
    data: [200,100,300,400,400,500,450,200,100,300,400,400,500,450],
    borderColor: 'rgb(53, 162, 235)',
    backgroundColor: 'rgba(53, 162, 235, 0.5)',
    yAxisID: 'ystacked',
    // pointRadius: 10,
    fill:false
  },
]