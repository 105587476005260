import {
    API
    // CURRENT_DRAWL,
    // CURRENT_DRAWL_ERROR,
    // SET_CURRENT_DRAWL,

  } from "./types";
import { fetchrootpath } from "../apirootpath";


export const CURRENT_DRAWL = "CURRENT_DRAWL"
export const CURRENT_DRAWL_ERROR = "CURRENT_DRAWL_ERROR"
export const SET_CURRENT_DRAWL = "SET_CURRENT_DRAWL"
export function currentDrawlAction(query_param) {
    return apiAction({
      url:`${fetchrootpath}/current_drawl`,
      data:query_param,
      onSuccess: setData,
      onFailure: (error) => ({
        type: CURRENT_DRAWL_ERROR,
        payload: error
      }),
      label: CURRENT_DRAWL
    });
  }


function setData(data) {
    //let weather = firebase.database().ref('locations/1');
    //weather.child('weather_forecast').set(json);
    // console.log(data)
    return {
      type: SET_CURRENT_DRAWL,
      payload: data
    };
  }


  
  function apiAction({
    url = "",
    method = "GET",
    data = null,
    accessToken = null,
    onSuccess = () => {},
    onFailure = () => {},
    label = "",
    headersOverride = null
  }) {
    return {
      type: API,
      payload: {
        url,
        method,
        data,
        accessToken,
        onSuccess,
        onFailure,
        label,
        headersOverride
      }
    };
  }




  