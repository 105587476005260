
export function FormattedDate(date){
  let to_year = date.toLocaleString("default", { year: "numeric" });
  let to_month = date.toLocaleString("default", { month: "2-digit" });
  let to_day = date.toLocaleString("default", { day: "2-digit" });
  let formattedToDate = to_year + "-" + to_month + "-" + to_day;
  return formattedToDate
}

export function getWeekMonthbetweenDates(startDate, endDate) {
  //first the function and variable definitions
  let dates = []
  let year_weeks = []
  const addDays = function (days) {
          var date = new Date(this.valueOf());
          date.setDate(date.getDate() + days);
          return date;
      };
  //now our Sunday check
  let currentDate = startDate
  if (currentDate.getDay() > 0) {
      // console.log('not a sunday...adjusting')
      currentDate.setDate(currentDate.getDate() - currentDate.getDay());
  }
   
  while (currentDate <= endDate) {
    let endWeekDate = addDays.call(currentDate, 6);
    dates.push({begin: currentDate, 
                 end: endWeekDate});
                 year_weeks.push({
      year: currentDate.getFullYear(),
      week: currentDate.getWeek()
     })
    
    currentDate = addDays.call(currentDate, 7);
   }
  return ({dates, year_weeks});
  };



export function getWeekbetweenDates(startDate, endDate) {
  //first the function and variable definitions
  let dates = []
  let year_weeks = []
  const addDays = function (days) {
          var date = new Date(this.valueOf());
          date.setDate(date.getDate() + days);
          return date;
      };
  //now our Sunday check
  let currentDate = startDate
  if (currentDate.getDay() > 0) {
      // console.log('not a sunday...adjusting')
      currentDate.setDate(currentDate.getDate() - currentDate.getDay());
  }
   
  while (currentDate <= endDate) {
    let endWeekDate = addDays.call(currentDate, 6);
    dates.push({begin: currentDate, 
                 end: endWeekDate});
      year_weeks.push({
      year: currentDate.getFullYear(),
      week: currentDate.getWeek()
     })
    
    currentDate = addDays.call(currentDate, 7);
   }
  return ({dates, year_weeks});
  };





export function getMonthbetweenDate(startDate, endDate) {
  var start      = [startDate.toLocaleString("default", { year: "numeric" }),
                    startDate.toLocaleString("default", { month: "2-digit" }),
                    startDate.toLocaleString("default", { day: "2-digit" })
                  ];

  var end      = [endDate.toLocaleString("default", { year: "numeric" }),
                  endDate.toLocaleString("default", { month: "2-digit" }),
                  endDate.toLocaleString("default", { day: "2-digit" })
                  ];
  // var end        = endDate.split('-');
  var startYear  = parseInt(start[0]);
  var endYear    = parseInt(end[0]);
  var dates      = [];

  for(var i = startYear; i <= endYear; i++) {
    var endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
    var startMon = i === startYear ? parseInt(start[1])-1 : 0;
    for(var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
      var month = j+1;
      var displayMonth = month < 10 ? '0'+month : month;
      // dates.push([i, displayMonth, '01'].join('-'));
      dates.push([i, String(displayMonth)]);
    }
  }
  return dates;
}


export function addDays(date, number) {
  const newDate = new Date(date);
  return new Date(newDate.setDate(newDate.getDate() + number));
  }
export function FromTodatelist(list_length){
  let date_list = []
  let _fromdate
  let _todate
  for( let i = 0; i < list_length; i ++){
      _fromdate = FormattedDate(addDays(new Date(), -(i)*30))
      _todate = FormattedDate(addDays(new Date(), -(i-1)*30))
      date_list.push([_fromdate,_todate])
      date_list.sort()
  }
  return date_list
}

export function currentRasterDate(){
let currentperiod = []
let _fromdate
let _todate
_fromdate = FormattedDate(addDays(new Date(), -30))
_todate = FormattedDate(addDays(new Date(), 0))
currentperiod= [_fromdate,_todate]
return currentperiod
}


