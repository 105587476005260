export const SET_USER_DATA = "SET_USER_DATA"
// export const IS_LOGGED_IN = 'IS_LOGGED_IN'
// export const CLICK_LOGOUT_BUTTON = 'CLICK_LOGOUT_BUTTON'


// function login_state(userdata){
//     if(userdata.auth){
//         return true
//     }
//     return false
// }



export const setUserData = (userdata) => ({
    type: SET_USER_DATA,
    payload: userdata,

 })


//  export const setLoggedin = (userdata) =>({
//     type: IS_LOGGED_IN,
//     payload:login_state(userdata)
//  })

//  export const setLoggedout = {
//     type: CLICK_LOGOUT_BUTTON,
//     payload: true
//  }








