import {API} from "./types";
import { fetchrootpath } from "../apirootpath";
export const FETCH_MODELMASTER = "FETCH_MODELMASTER"
export const FETCH_MODELMASTER_ERROR = "FETCH_MODELMASTER_ERROR"
export const SET_MODELMASTER = "SET_MODELMASTER"
  
export function fetchLFModelmaster() {
  // console.log(`${fetchrootpath}/EntDropDown_region`)
    return apiAction({
      url:`${fetchrootpath}/loadForecastModelmaster`,
      onSuccess: setModelMaster,
      onFailure: (error) => ({
        type: FETCH_MODELMASTER_ERROR,
        payload: error
      }),
      label: FETCH_MODELMASTER
    });
  }


  function setModelMaster(data) {
    //let weather = firebase.database().ref('locations/1');
    //weather.child('weather_forecast').set(json);
    console.log(data);
    return {
      type: SET_MODELMASTER,
      payload: data
      // payload:{
      //   data: {
      //     type: data.type,
      //     features: data.features
      //   }
      // }
    };
  }

  
  function apiAction({
    url = "",
    method = "GET",
    data = null,
    accessToken = null,
    onSuccess = () => {},
    onFailure = () => {},
    label = "",
    headersOverride = null
  }) {
    return {
      type: API,
      payload: {
        url,
        method,
        data,
        accessToken,
        onSuccess,
        onFailure,
        label,
        headersOverride
      }
    };
  }




  