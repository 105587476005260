import * as React from 'react';
import { PropTypes } from 'prop-types'
import TextField from '@mui/material/TextField';
const NumericTextField = ({label,name,value,onChange,min,max}) => {

  return (

    <TextField
    label={label}
    inputProps={{
      type: 'number',
      placeholder: 'placeholder',
      value,
      min:min,
      max:max
    }}
    onChange= {onChange}
    />
  )

}
export default NumericTextField

NumericTextField.propTypes = {
  label: PropTypes.string,
  name:PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};

NumericTextField.defaultProps = {
  label:"cutom numeric input",
  name: "input"

}
