import * as React from 'react';
import { createTheme } from '@mui/material/styles';
export const theme = createTheme({
    typography: {
    title: {
        fontSize: 30,
        },

    subtitle1: {
      fontSize: 24,
    },
    subtitle2: {
      fontSize: 18,
      },
    subtitle3: {
      fontSize: 12,
      },
    body1: {
      fontWeight: 500,
      },
    button: {
      fontWeight: 500,
    },
    },
  divider: {
      base: {
      }
    }
  
  });
  