import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress'
import { Paper } from '@mui/material';
// import Backdrop from '@mui/material/Backdrop'; 

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),

  },
}));

const  ModelPopup = ({modal_title,isLoading,open,handleClose,modal_content,modal_action}) => {
  return (
    // <React.Fragment>
    <Paper >
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{ sx: { borderRadius: "10px" } }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}  id="customized-dialog-title">
            <Stack >
            {modal_title}
          { isLoading &&
                // <Backdrop
                // sx={{ color: '#fff', zIndex: 10000 }}
                // open={isLoading}
                // >
                <CircularProgress color="inherit" />
                // </Backdrop>
            }


            </Stack>
         
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        { isLoading &&
                // <Backdrop
                // sx={{ color: '#fff', zIndex: 10000 }}
                // open={isLoading}
                // >
                <CircularProgress color="inherit" />
                // </Backdrop>
            }
        <DialogContent dividers>


        {React.Children.toArray(modal_content).map(child => child)}
         
        </DialogContent>
        <DialogActions>
        {React.Children.toArray(modal_action).map(child => child)}
        </DialogActions>
      </BootstrapDialog>
      </Paper>
  );
}

export default ModelPopup



ModelPopup.propTypes = {
    modal_title: PropTypes.string,
    isLoading: PropTypes.bool,
    open:PropTypes.bool,
    handleClose: PropTypes.func,
    modal_content:PropTypes.node,
    modal_action:PropTypes.node
   };

ModelPopup.defaultProps = {
 open:false,
};
