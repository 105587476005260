
import ReGenLive from './ReGenLive';
import ReGenDayAhead from './ReGenDayAhead';

import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

   // const dispatch  = useDispatch()  
    // const LoadData =  async(e) => {
    //   await dispatch(fetchCurrent_ISGS_Schedule())
    // } 

const ReGenTab =({options,selectedIndex}) => 
      {  
      const state_dayahead = useSelector(state => state.dayaheadReSchedule)
      const state_current = useSelector(state => state.currentReSchedule)
      const [state,setState ]= useState({
            day_ahead_loading:false,
            dayahead_data:{},
            Relive_loading: false,
            realtime_date: {},

           
      })

   useEffect(()=> {
      if(state_dayahead && state_dayahead.loading){
            setState({...state,
                  day_ahead_loading:state_dayahead.loading})
      }

      if   (state_dayahead && !state_dayahead.loading 
            && state_dayahead.data 
            )
            {
            setState({
                  ...state,
                  day_ahead_loading:false,
                  dayahead_data:state_dayahead.data
            }) 
            }

      if(state_current && state_current.loading){
            setState({...state,
                  Relive_loading:state_current.loading})
      }

      if(state_current && !state_current.loading 
            && state_current.data 
                  )
            {
            setState({
                  ...state,
                  Relive_loading:false,
                  realtime_date:state_current.data
            }) 
            }

      

   },[state_dayahead,state_current])

  
  




          return (
                  <>
                
                        {options[selectedIndex]===options[0] && 
                              <ReGenDayAhead
                              isLoading={state.day_ahead_loading}
                              data_table={state.dayahead_data.schedule_mat}
                              data_post={state.dayahead_data.schedule_db}
                              
                              />
                        }
                        { options[selectedIndex]===options[1] && 
                              <ReGenLive
                              isLoading={state.Relive_loading}
                              data_table={state.realtime_date.schedule_mat}
                              data_post={state.realtime_date.schedule_db}
                              />
                        }
                  
                  </>
          )
}

export default ReGenTab


