import {
    API
    // LIVE_ISGS_SCHEDULE,
    // LIVE_ISGS_SCHEDULE_ERROR,
    // SET_LIVE_ISGS_SCHEDULE,

  } from "./types";
import { externalapirootpath } from "../apirootpath";

export const LIVE_ISGS_SCHEDULE = "LIVE_ISGS_SCHEDULE"
export const LIVE_ISGS_SCHEDULE_ERROR = "LIVE_ISGS_SCHEDULE_ERROR"
export const SET_LIVE_ISGS_SCHEDULE = "SET_LIVE_ISGS_SCHEDULE"

export function fetchLiveIsgsSchedule(query_param) {
    return apiAction({
      url:`${externalapirootpath}/liveSchedulesummary`,
      data:query_param,
      onSuccess: setData,
      onFailure: (error) => ({
        type: LIVE_ISGS_SCHEDULE_ERROR,
        payload: error
      }),
      label: LIVE_ISGS_SCHEDULE
    });
  }


  function setData(data) {
    //let weather = firebase.database().ref('locations/1');
    //weather.child('weather_forecast').set(json);
    // console.log(data)
    return {
      type: SET_LIVE_ISGS_SCHEDULE,
      payload: data
    };
  }


  
  function apiAction({
    url = "",
    method = "GET",
    data = null,
    accessToken = null,
    onSuccess = () => {},
    onFailure = () => {},
    label = "",
    headersOverride = null
  }) {
    return {
      type: API,
      payload: {
        url,
        method,
        data,
        accessToken,
        onSuccess,
        onFailure,
        label,
        headersOverride
      }
    };
  }




  