import {
    API_START,
    API_END
  } from "../actions/types";

  import { SET_LIVE_ISGS_SCHEDULE,
    LIVE_ISGS_SCHEDULE,
    LIVE_ISGS_SCHEDULE_ERROR} from "../actions/LiveIsgsSchedule"
    
  const initialState = {
      loading: false,
      data: {},
      error: ''
  }
  
  export default function liveIsgScheduleReducer(state = initialState, action) {
    //console.log("action type => ", action.type);
    switch (action.type) {
    
      case SET_LIVE_ISGS_SCHEDULE:
        return { ...state, data: action.payload };
      case API_START:
        
        if (action.payload === LIVE_ISGS_SCHEDULE) {
          return {
            ...state,
            loading: true,
            data:{},
            error: ""
          };
        }
        return(null)
        // break;
        
        
      case API_END:
        if (action.payload === LIVE_ISGS_SCHEDULE) {
          return {
            ...state,
            loading: false,
          };
        }
        return(null)
        // break;
        case LIVE_ISGS_SCHEDULE_ERROR:
          return {
            ...state,
            data: {},
            error: action.payload.message
          };

      default:
        return state;
    }
  }
  