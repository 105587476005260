
import DayAheadEnInternal from './DayAheadEnInternal'
import LiveScheduleInternal from './LiveScheduleInternal'


import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const InternalGenerationTab =({options,selectedIndex}) => 
      {  
        const state_genmaster = useSelector(state=> state.internalGenUnitMaster)
        const [state, setState]= useState({
          genmaster_loading:false,
          genmaster_data:[]

        })
        useEffect(()=> {
          if(state_genmaster && state_genmaster.loading){
            setState({...state, genmaster_loading:state_genmaster.loading})
          }
          if(state_genmaster && !state_genmaster.loading && state_genmaster.data){
            setState({...state, 
              genmaster_loading:state_genmaster.loading,
              genmaster_data:state_genmaster.data
            
            })
          }

        },[state_genmaster])
  
          return (
                  <>
                
                        {options[selectedIndex]===options[0] && 
                              <LiveScheduleInternal/>
                        }
                        { options[selectedIndex]===options[1] && 
                              <DayAheadEnInternal/>
                        }
                  
                  </>
          )
}

export default InternalGenerationTab


