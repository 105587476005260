import React,{useRef} from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { bgColors } from '../../utils/bgColor';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import IconButton from '@mui/material/IconButton';
const SimpleCard=({icon_button, title, subtitle,action_button,
  content,action}) =>{
  const Ref = useRef(null);
  const enterFullscreen = () => {
    const elem = Ref.current;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { // Firefox
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { // Chrome, Safari, and Opera
      elem.webkitRequestFullscreen();
    }
  };
  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  };
  const handleFullscreen = () => {
    if (!document.fullscreenElement) {
      enterFullscreen();
    } else {
      exitFullscreen();
    }
  };


  return (
    <Card ref={Ref} 
    sx={{color:bgColors.OffWhite,background:bgColors.grey_800}}
    >
      <CardHeader 
      sx={{maxHeight:'4rem', justifyContent:'center', alignItems:'center', padding:"0"}}
         avatar={React.Children.toArray(icon_button).map(child => child)}
        action ={
          <>
          {React.Children.toArray(action_button).map(child => child)}
          <IconButton onClick={handleFullscreen}>
            {!document.fullscreenElement ?  <FullscreenIcon/> : <FullscreenExitIcon/> }
          </IconButton>
        </>
        }
        title={title}
        subheader={subtitle}
      />
      <CardContent>
      {React.Children.toArray(content).map(child => child)}
      </CardContent>
      <CardActions disableSpacing 
      // sx={{background:bgColors.grey_800, height:'3rem'}}
      >
      {React.Children.toArray(action).map(child => child)}
      </CardActions>
    </Card>
  );
}

export default SimpleCard

SimpleCard.propTypes = {
  avtaricon: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  action_button: PropTypes.node,
  content: PropTypes.node,
  action: PropTypes.node
 };
 



