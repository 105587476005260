// import React from 'react';
// import { useSelector } from 'react-redux';

// function getVisibility(visibility){
//     if(visibility === true){
//         return 'visible'
//     }
//     else {
//         return 'none'
//     }
// }

function VectorLayerVisibilty(map, layerstate){

    map.current.on('idle', () => {

    if(!map.current.getLayer('state_boundry')
       || !map.current.getLayer('state_boundry_highlighted')
       || !map.current.getLayer('district_boundry')
       || !map.current.getLayer('district_boundry_highlighted')
       || !map.current.getLayer('subdistrict_boundry')
       || !map.current.getLayer('subdistrict_boundry_highlighted')
       || !map.current.getLayer('village_boundry')
       || !map.current.getLayer('village_boundry_highlighted'))
    {
        return;
    }
       

    let stateboundry_visibility = 'none'
    let districtboundry_visibility = 'none'
    let subdistrictboundry_visibility = 'none'
    let villageboundry_visibility = 'none'
    let routelayer_visibility = 'none'
    let Userproject_visibility= 'none'
    let pincode_visibility= 'none'
    let pincode_label_visibility= 'none'
    // routelayer
    
    
    if(layerstate["State"] === false)
        {
        stateboundry_visibility = 'none'
        }
    else{
        stateboundry_visibility = 'visible'
    }
    if(layerstate["District"] === false){
        districtboundry_visibility = 'none'
    }
    else{
        districtboundry_visibility = 'visible'
    }

    if(layerstate["Subdistrict"] === false){
        subdistrictboundry_visibility = 'none'
    }
    else{
        subdistrictboundry_visibility = 'visible'
    }

    if(layerstate["Village"] === false){
        villageboundry_visibility = 'none'
    }
    else{
        villageboundry_visibility = 'visible'
    }
    // if(layerstate.Village === false){
    //     villageboundry_visibility = 'none'
    // }
    // else{
    //     villageboundry_visibility = 'visible'
    // }
    if(layerstate.NavLayer === false){
        routelayer_visibility = 'none'
    }
    else{
        routelayer_visibility = 'visible'
    }
    if(layerstate.Userproject === false){
        Userproject_visibility = 'none'
    }
    else{
        Userproject_visibility = 'visible'
    }

    if(layerstate.Pincode === false){
        pincode_visibility = 'none'
    }
    else{
        pincode_visibility = 'visible'
    }

    if(layerstate.Pincode_label === false){
        pincode_label_visibility = 'none'
    }
    else{
        pincode_label_visibility = 'visible'
    }

    
    // map.current.on('load', () => {

        map.current.setLayoutProperty('state_boundry', 'visibility', stateboundry_visibility)
        map.current.setLayoutProperty('state_boundry_highlighted', 'visibility', stateboundry_visibility)
        map.current.setLayoutProperty('district_boundry', 'visibility', districtboundry_visibility)
        map.current.setLayoutProperty('district_boundry_highlighted', 'visibility', districtboundry_visibility)
        map.current.setLayoutProperty('subdistrict_boundry', 'visibility', subdistrictboundry_visibility)
        map.current.setLayoutProperty('subdistrict_boundry_highlighted', 'visibility', subdistrictboundry_visibility)
        map.current.setLayoutProperty('village_boundry', 'visibility', villageboundry_visibility)
        map.current.setLayoutProperty('village_boundry_highlighted', 'visibility', villageboundry_visibility)
        // map.current.setLayoutProperty('routelayer', 'visibility', routelayer_visibility)
        // map.current.setLayoutProperty('routelayer_point', 'visibility', routelayer_visibility)
        // map.current.setLayoutProperty('Userproject', 'visibility', Userproject_visibility)
        // map.current.setLayoutProperty('pincode', 'visibility', pincode_visibility)
        // map.current.setLayoutProperty('pincode_label', 'visibility', pincode_label_visibility)
    // })
})
}

export default VectorLayerVisibilty

// if (map.getLayer('routelayer')) {
//     map.getSource('routelayer').setData(geojson);
//   }