
import React from 'react'
import mapboxgl from 'mapbox-gl';

function SelectedRaster (map, rasterlayer, rasterlayerdate, base_url, opacity){
  const layers = map.current.getStyle().layers;
  let firstSymbolId;
  for (const layer of layers) {
  if (layer.type === 'fill') {
  firstSymbolId = layer.id;
  break;
  }
  }
  
  const visibility = 'visible'
  if(rasterlayer==='None'){
    visibility = 'None'
  }
 
  map.current.addSource("selectedraster-source", {
        'type': 'raster',
          'tiles': [
            `${base_url}/${process.env.REACT_APP_SENTINELHUB_INSTANCEID}?version=1.1.1&service=WMS&request=GetMap&srs=EPSG:3857&layers=${rasterlayer}&TIME=${rasterlayerdate[0]}/${rasterlayerdate[1]}&bbox={bbox-epsg-3857}&width=256&height=256&showLogo=false`
          ],
          'tileSize': 256
        });
        map.current.addLayer({
          'id': "selectedraster",
          'source':"selectedraster-source",
          'minzoom':10,
          'layout': {
        
                'visibility':visibility,
              },
          'type': "raster",
          'paint': {
            "raster-resampling": "nearest",
            "raster-opacity": opacity
          },
          
        },
        firstSymbolId
          // 'revenuepoly_layer'
          
        );  

}



export function  updaterGlobalRaster(map, rasterlayer, rasterlayerdate, base_url, opacity){
const source_id = "selectedraster-source"
const layer_id = "selectedraster"
if (map) {

  if (map.current.getLayer(layer_id)) {
      map.current.removeLayer(layer_id);
    }
  if (map.current.getSource(source_id)) {
       map.current.removeSource(source_id);
  }
  
    SelectedRaster(map, rasterlayer, rasterlayerdate, base_url, opacity)
}
}

// export function handleNoneLayer(map,rasterlayer){
//   const source_id = "selectedraster-source"
//   const layer_id =  "selectedraster"
//   if(rasterlayer==='None'){
//     if (map.getLayer(layer_id)) {
//         map.removeLayer(layer_id);
//       }
//     if (map.getSource(source_id)) {
//          map.removeSource(source_id);
//       }
//   }
// }


export function handleRoiChaangeGlobal(map,rasterroi){
  const layer_id = "selectedraster"
  if(map.current.getLayer(layer_id) &&  rasterroi!=='UserROI')
      {
        map.current.setLayoutProperty('selectedraster', 'visibility', 'none')
      }
}

// export function handleNoneLayer(map,rasterlayer){
//   const source_id = "selectedraster-source"
//   const layer_id = "selectedraster"

//   if(rasterlayer==='None'){
//     if (map.getLayer(layer_id)) {
//         map.removeLayer(layer_id);
//       }
//     if (map.getSource(source_id)) {
//          map.removeSource(source_id);
//       }
//   }

// }


// export function handleRoiChaangeGlobal(map,rasterroi){
//   const source_id = "selectedraster-source"
//   const layer_id = "selectedraster"

//   if(rasterroi!=='Global'){
//     if (map.getLayer(layer_id)) {
//         map.removeLayer(layer_id);
//       }
//     if (map.getSource(source_id)) {
//          map.removeSource(source_id);
//       }
//   }

// }








