

import axios from "axios";
import {useEffect, useState} from "react";
import { DataIntPage } from "../Layout";
import { fetchDayAheadIEXPrice } from '../../../Apis/actions/fetchDayAheadIEXPrice';
import { fetchRTMIEXPrice } from '../../../Apis/actions/fetchRTMIEXPrice';

import { pythonapi } from "../../../Apis/apirootpath";
import { useDispatch, useSelector } from "react-redux";
import LinkIcon from '@mui/icons-material/Link';
import IEXRTMPlot from "./IEXDayAheadPlot";
// import { useSelector } from "react-redux";

const IexPriceUpload = ({data_table}) => {
    const  date = useSelector(state=> state.static_data.selected_date)
    const [state, setState] = useState({
      date: date,
      loading:false,
      iex_price: [],
      errorMessageload:{},
      
  })

    useEffect(()=> {
      if(date)
      setState({...state,date:date})
      
    },[date])
    const dispatch = useDispatch()
    let query_params = {
      date:state.date
    }

    const LoadData=async ()=> {
      let method = "GET"
      let data = query_params
       dispatch(fetchDayAheadIEXPrice(method,data))
       dispatch(fetchRTMIEXPrice(method,data))
    }


 
  const run_req = {
    params:
      { 
        message: "run_script",
      }
  } 

  const DownloadIexDAPrice = async (e) => {
    setState({...state, loading:true})
      e.preventDefault();
      try {
      axios.get(`${pythonapi}/fetchIexPrice`,
        run_req,
      { headers: {
          'Content-Type': 'application/json',
          'Accept-Charset': 'utf-8',
          // 'User-Agent': my_useragent,
      }
      }
      )
        .then((res) => {
          // console.log(res.data)
          setState({...state,
            loading:false,
                iex_price:res.data})
              alert(`Iex day ahead price upload completed`)
        })
        .catch(error => {
          state({...state, 
            loading:false,
            errorMessageload: error.message})
        })
  
      } catch (e) {
        setState({...state, loading:false})
        alert(`submit failed! ${e.message}`);
      }
      return state
    }

    return (    
        <DataIntPage
            isLoading={state.loading}
            data_table={data_table}
            LoadData = {LoadData}
            keep_action = {true}
            name_action={"Fetch IEX"}
            action_icon={<LinkIcon/>}
            define_action={DownloadIexDAPrice}
            chart_child={<IEXRTMPlot/>}
        >
        </DataIntPage>
    )

}
export default IexPriceUpload


