
export const UPDATE_DRAG_STATE  = "UPDATE_DRAG_STATE"
export const UPDATE_EDIT_MODE_STATE  = "UPDATE_EDIT_MODE_STATE"
export const UPDATE_PIVOT1_STATE  = "UPDATE_PIVOT1_STATE"
export const UPDATE_PIVOT2_STATE  = "UPDATE_PIVOT2_STATE"

export const setLfCurveDragState = (draggedstate) => ({
    type: UPDATE_DRAG_STATE,
    payload: draggedstate
 })
 export const setEditMode = (edit_mode) => ({
    type: UPDATE_EDIT_MODE_STATE,
    payload: edit_mode
 })

 export const setControlPointOne = (control_point_one) => ({
    type: UPDATE_PIVOT1_STATE,
    payload: control_point_one
 })

 export const setControlPointTwo = (control_point_two) => ({
    type: UPDATE_PIVOT2_STATE,
    payload: control_point_two
 })














