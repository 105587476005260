
import {STATE_SIGNUP, PostData } from "../PostData";
import { authrootpath } from "../../../Apis/apirootpath";
const authstate = STATE_SIGNUP
const URL = `${authrootpath}/registeruser`
// console.log(URL)

async function Registeruser( state){
const _PostData = PostData(state, authstate)
console.log(_PostData)
    try {
      const res = await fetch(URL, {
        method: "post",
        headers: {
            // "Content-Type": "application/x-www-form-urlencoded"
          "Content-Type": "application/json"
        },
        body: JSON.stringify(_PostData),
      })
  
      if (!res.ok) {
        const message = `An error has occured: ${res.status} - ${res.statusText}`;
        throw new Error(message);
      }
      if(res.ok){
      const data = await res.json();
      alert(data.message)
      }
     
      
    } catch (err) {
      // _error.push(err.message);
      alert(err.message)
    }
  }
export default Registeruser