
import {Sidebar} from "../../components/Layout";
import Box  from '@mui/material/Box';

export const PageLayout = ({ children }) => (

  // uncomment display = "flex" to make side bar puch the main content
  <Box 
      overflow = {'hidden'}
      // marginLeft= {9}
      // height="100Vh" 
      // display="flex"    
      // width = '100Vw'
      >
     
      <Sidebar/>
      {/* <div style = {{height:'90%', width:'90%'}}> */}
        
         {children}
         {/* </div> */}
         
   
  </Box>
);





