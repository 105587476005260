import { parsePhoneNumber } from "react-phone-number-input";
export const STATE_SIGNUP = 'SiginUp'
export const STATE_LOGIN = 'SignIn'
export const STATE_FORGOT_PASSWORD = 'ForgotPassword'
export const STATE_RESET_PASSWORD = 'ResetPassword'


export function isEmail(email, errors) {
    let result = true;
    if (!email) {
      errors.email = "Email is Required";
      result = false;
    } else {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      result = re.test(String(email).toLowerCase());
      if (!result) {
        result = false;
      }
    }
    return result;
  }

export function isPhoneNumber(phone, errors) {
    let result = true;
    const phoneObject = parsePhoneNumber(phone);
    if (!phoneObject) {
        result = false;
    }
 return result;
}

export const PostData = (state,authState)=> {
    let postData 
    if(authState === STATE_SIGNUP){
      postData = {
        user_name: state.user_name,
        first_name: state.first_name,
        last_name: state.last_name,
        email_id: state.email_id,
        organization_name:state.organization_name,
        phone_no: state.phone_no,
        password:state.password
      }
    }
    if(authState === STATE_LOGIN){
      if(isEmail(state.emailphone))
      {
        postData  = {
          id_type: 'email_id',
          email_id: state.emailphone,
          password:state.password,
        }
      }

      if(isPhoneNumber(state.emailphone))
      {
        postData  = {
          id_type: 'phone_no',
          phone_no: state.emailphone,
          password:state.password,
        }
      }
      
    }
    if(authState === STATE_FORGOT_PASSWORD){
      if(isEmail(state.emailphone))
      {
        postData  = {
          id_type:'email_id',
          email_id: state.emailphone
        }
      }

      if(isPhoneNumber(state.emailphone))
      {
        postData  = {
          id_type:'phone_no',
          phone_no: state.emailphone
        }
      }
    }

    if(authState === STATE_RESET_PASSWORD){
      if(isEmail(state.emailphone))
      {
        postData  = {
          id_type:'email_id',
          email_id: state.emailphone,
          password: state.password,
          confirmpassword: state.confirmpassword
        }
      }

      if(isPhoneNumber(state.emailphone))
      {
        postData  = {
          id_type:'phone_no',
          phone_no: state.emailphone,
          password: state.password,
          confirmpassword: state.confirmpassword
        }
      }
    }
    return postData
  }

// function  handlePostAuhData(authState){


// }