import * as React from 'react';
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import  Box from '@mui/material/Box';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const  DialogFullScreen =({icon, children,label,dialog_title}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    <IconButton
      edge="start"
      color="inherit"
      aria-label="open"
      onClick={handleClickOpen}
      >
     {icon}
     <Typography  fontSize={14}>
     {label}
     </Typography>
     
    </IconButton>
    
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {dialog_title}
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        <Box>
            {children}
        </Box>
      </Dialog>
      </>

  );
}

export default DialogFullScreen

DialogFullScreen.propTypes = {
  id: PropTypes.string,
  icon:PropTypes.node,
  label: PropTypes.string,
  dialog_title: PropTypes.node,
  children: PropTypes.node,
  // textfield:PropTypes.object,
};

DialogFullScreen.defaultProps = {
  id: "custom_dialog",
  label: "setting",
  dialog_title: "I am full size dailogue",
  // textfield: {size:"Small"}
 
}