import React from "react";
import TextField from '@mui/material/TextField';

// const getFlagUrl = countryCode => {
//   return `https://www.countryflags.io/${countryCode.toLowerCase()}/flat/24.png`;
// };

const PhoneNumber = ({
  errors,
  state,
  handleChange,
  handleBlur
//   countryCode
}) => {
  return (
    <TextField
      required
      fullWidth
      size = 'small'
      name="phone_no"
      label="Phone Number"
      error={errors.phone_no ? true : false}
      defaultValue={state.phone_no}
      onChange={handleChange}
      helperText={errors.phone_no}
      onBlur={handleBlur}
    />
  );
};

export default PhoneNumber;
