import React,{useState} from 'react'
import PropTypes from 'prop-types'; 
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDispatch } from 'react-redux';
import { bgColors } from '../../../utils/bgColor';
import { DatePickerSmall } from '../../../UiComponent/DatePicker';
import HandleMenu from './HandleMenu';
import { CustomDiv } from '../../../components/Layout/CustomDiv';
import { setDate } from '../../../redux/actions/StaticDataAction';
import { loadState,saveState } from '../../../utils/Localstorage';
import { Box} from '@mui/material';
import { Today } from '../../../container/date.fn';
import dayjs from "dayjs";
const style = {
    borderStyle: 'solid',
    borderWidth: '2px',
    borderRadius:'5px',
    borderColor: bgColors.primary_light,
    minWidth:'100px',
    fontSize:'15px',
    marginRight:'0.5rem'
  }
const DataIntpageLayout =({ 
                            options_parent,
                            open_parent,
                            handleClickListItem_parent,
                            anchorEl_parent,
                            handleClose_parent,
                            selectedIndex_parent,
                            handleMenuItemClick_parent,
                            options,
                            open,
                            handleClickListItem, 
                            anchorEl,
                            handleClose,
                            selectedIndex,
                            handleMenuItemClick,
                            child_comp
                          }) => {
                      
const dispatch = useDispatch()
// const date = useSelector(state => state.static_data.selected_date)
const selected_date = new Date();
// if(loadState() && loadState().selected_date){
//   selected_date=loadState().selected_date
// }
const [state, setState] = useState({
    date:selected_date
});

const handleDateChange=newValue=> {
  const year = newValue['$y']
  const month = newValue['$M']+1
  const day = newValue['$D']
  const month_str = month.toString().padStart(2, '0')
  const day_str = day.toString().padStart(2, '0')
  const year_str = year.toString()
  const datestr = `${year_str}-${month_str}-${day_str}`
      setState({...state,
        forDate:  newValue})
        dispatch(setDate(datestr))
        saveState({...loadState(),"selected_date":datestr})
}



return (
      <CustomDiv>
        <Box>
        <AppBar position='relative' sx = {{minHeight:'3.5rem', padding:'0'}}>
          <Toolbar  sx = {{marginLeft:'auto', 
                          alignItems:"flex-end",
                          justifyContent:"space-between"
                          }} 
                        >
          <List >
           
            <DatePickerSmall
              defaultvalue={dayjs(Today)}
              key ={"date"}
              label={"Date"}
              value={dayjs(state.date)}
              onChange={handleDateChange}
              />
         </List>

          <List sx={{marginLeft: '0.5rem' }}>
            <ListItemButton
              id="lock-parent"
              key="lock-parent"
              aria-haspopup="listbox"
              aria-controls="lock-menu"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClickListItem_parent}
              sx={style}
            > 
            {options_parent[selectedIndex_parent]}
            {open_parent ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </ListItemButton>
          </List>
          <List
            component="nav"
            aria-label="Device settings"
          >
            <ListItemButton
              id="lock-button"
              key="lock-button"
              aria-haspopup="listbox"
              aria-controls="lock-menu"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClickListItem}
              sx={style}
            > 
            {options[selectedIndex]}
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </ListItemButton>
            </List>

          

          </Toolbar>
        </AppBar>

        <HandleMenu
        anchorEl={anchorEl_parent}
        options={options_parent}
        open={open_parent}
        handleClose={handleClose_parent}
        selectedIndex={selectedIndex_parent}
        handleMenuItemClick={handleMenuItemClick_parent}
      />

      <HandleMenu
        anchorEl={anchorEl}
        options={options}
        open={open}
        handleClose={handleClose}
        selectedIndex={selectedIndex}
        handleMenuItemClick={handleMenuItemClick}
      />
    </Box>
    <Box>

    {React.Children.toArray(child_comp).map(child => child)}

    </Box>

     </CustomDiv>
     )
}

export default DataIntpageLayout
DataIntpageLayout.propTypes = {
  options:PropTypes.array,
  options_parent:PropTypes.array,
  toolbar_comp:PropTypes.node,
  open: PropTypes.bool,
  open_parent:PropTypes.bool,
  handleClickListItem:PropTypes.func,
  handleClickListItem_parent:PropTypes.func,
  anchorEl: PropTypes.any,
  anchorEl_parent:PropTypes.any,
  handleClose: PropTypes.func,
  handleClose_parent: PropTypes.func,
  selectedIndex:PropTypes.any,
  selectedIndex_parent:PropTypes.any,
  handleMenuItemClick:  PropTypes.func,
  handleMenuItemClick_parent:  PropTypes.func,
  child_comp:PropTypes.node

};




