
import {useDispatch } from "react-redux";
import { fetchDayAhead_ISGS_Schedule } from '../../../Apis/actions/Day_Ahead_ISGS_Schedule';
import { DataIntPage } from "../Layout";
import { postrootpath } from "../../../Apis/apirootpath";
import axios from "axios";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import { axiospost } from "../../../Apis/Request/postrequest";
const DayAheadEntitlement = ({isLoading,data_table, data_post}) => {

    const dispatch = useDispatch()
    const LoadData =  async(e) => {
        await dispatch(fetchDayAhead_ISGS_Schedule())
    }  
    const url = `${postrootpath}/commitEntitlement`
      const commitDayAheadEntitlement = async (e) => {
        const data_to_post = data_post
        e.preventDefault();
        try { axios.post(`${postrootpath}/commitEntitlement`, data_to_post,
              {
                dataType: 'json',
                async: true,
              }).then(function (response) {
                if(response.status ===200 && response.data.status ==="Success")
                  { alert(`No of rows inserted: ${response.data.records}`)}
                 if(response.status ===400 && response.data.status ==="Success")
                   { alert(`server could not be found`)}
              })
              .catch(function (error) {
                if (!error.response) {

                  alert('Error: Network Error')
                }
                else {
                  alert( `Error:${error.response.data.message}`)
                }
            })
        
        }
        catch (e) {
          // setError(e)
          alert(`submit failed! ${e.message}`);
        }
    }
    return (    
        <DataIntPage
            isLoading={isLoading}
            data_table={data_table}
            LoadData = {LoadData}
            define_action={commitDayAheadEntitlement}
            // define_action={()=> axiospost(url,data_post)}
            data_post={data_post}
            keep_action={true}
            name_action = {"commit"}
            action_icon={<CloudUploadIcon/>}
            >
        </DataIntPage>
    )
}
export default DayAheadEntitlement