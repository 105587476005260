

export const  menu_list = {
    
    'Day-Ahead':['Position map',  
                  'Entitilement',
                  'Must Run schedule', 
                  'Min Tech limit',
                  'Total Min Schedule',
                  'mustsch_positionmap',
                  'Merit Order schedule',
                  'Total Schedule',
                  'Optimized Scheudle', 
                  'Trade'],
    'Intra-day':['Position map','Optimized Scheudle', 'Trade']

}

export const style = {
    Button_style:
       { Button_Style : 
        {borderStyle: 'solid',
        borderWidth: '2px',
        borderRadius:'5px',
        minWidth:'100px',
        fontSize:'15px',
        marginLeft:'auto'
       }
      },
    icon_style:{
      height:'35px', 
      width:'35px'
    }
  }




