import {SET_SELECTED_UTILITY,
  SET_PERIOD,
  SET_DATE,
  SET_FORDATE,
}

from "../actions/StaticDataAction"
import dayjs from "dayjs";

const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD');
const today = dayjs().format('YYYY-MM-DD') ;
// console.log(tomorrow,today)

const initialState = {
   selected_region: "SOUTH",
   selected_state: "KARNATAKA",
   selected_utility: "KARNATAKA",
   selected_period: "2024",
   selected_date: today,
   selected_fordate: today,
 }


 const StaticDataReducer =  (state = initialState, action) => {
   switch (action.type) {
     case SET_SELECTED_UTILITY:
       return {
         ...state,
         selected_region: action['payload']['selected_region'],
         selected_state: action['payload']['selected_state'],
         selected_utility: action['payload']['selected_utility'],
       }

       case SET_PERIOD:
        return {
          ...state,
          selected_period: action['payload'],
        }
        case SET_DATE:
        return {
          ...state,
          selected_date: action['payload'],
        }
        case SET_FORDATE:
          return {
            ...state,
            selected_fordate: action['payload'],
          }

     default:
       return state;
   }
 };

 export default StaticDataReducer  


