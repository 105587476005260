
// user defined model:  model and its weight for blocks
// loadforcast state:  redux state for loading load forecast data for date. 
import { range } from "../../../utils/ArryOps"
import { bgColors } from "../../../utils/bgColor"
export function HybridForecast(user_defined_model,dam_price_forecaststate){
   let user_hybrid_forecast = []
   let hybrid_data = []
   let user_hybrid_forecast_var = {}

   if(user_defined_model && dam_price_forecaststate 
    && user_defined_model.userdefined_series.length>0 && 
    !dam_price_forecaststate.loading && dam_price_forecaststate.data 
    && dam_price_forecaststate.data.length>0){
   
      let block_no = range(1,96)
      let forecast_mat = dam_price_forecaststate.data
    
      block_no.forEach(block => {
        let _hybrid_var = {}
        _hybrid_var.block_no=block
        _hybrid_var.model_name='user_hybird'
        let _filtered_forecast = forecast_mat.filter(item=> (item.block_no ===block))
        let user_defined_weight = user_defined_model.userdefined_series
        let _forecast = 0
        user_defined_weight.forEach(element => {
          let model_name = element.series_name
          let from_block= parseFloat(element.from_block)
          let to_block= parseFloat(element.to_block)
          let weight = parseFloat(element.weight)
          let _filtered_model_forecast = _filtered_forecast.filter(item=> (item.model_name===model_name))
          let _forecast_mw = parseFloat(_filtered_model_forecast[0].dam_price_forecast)
          if(block >=from_block && block<=to_block){
            _forecast+= weight*_forecast_mw
          }
        });
        hybrid_data.push(_forecast)
        _hybrid_var.dam_price_forecast = _forecast
        user_hybrid_forecast.push(_hybrid_var)
      })
   }
   user_hybrid_forecast_var.label= 'user_hybrid'
   user_hybrid_forecast_var.type= 'line'
   user_hybrid_forecast_var.data = hybrid_data
   user_hybrid_forecast_var.backgroundColor= bgColors["info.light"]
   user_hybrid_forecast_var.borderColor= bgColors["info.light"]
   user_hybrid_forecast_var.borderWidth=3

   return { 'hybrid_forecast': user_hybrid_forecast,
            'HybridForecastPlotData': user_hybrid_forecast_var
             }
}


   