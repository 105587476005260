
export const CLICK_SIGNUP_OPEN = "CLICK_SIGNUP_OPEN"
export const CLICK_SIGNUP_CLOSE = "CLICK_SIGNUP_CLOSE"
export const CLICK_SIGNIN_OPEN = "CLICK_SIGNIN_OPEN"
export const CLICK_SIGNIN_CLOSE = "CLICK_SIGNIN_CLOSE"
export const EMAIL_AUTH_OPEN = "EMAIL_AUTH_OPEN"
export const EMAIL_AUTH_CLOSE = "EMAIL_AUTH_CLOSE"

export const CLICK_RESETPASSORD_REQ_OPEN = "CLICK_RESETPASSORD_OPEN"
export const CLICK_RESETPASSORD_REQ_CLOSE = "CLICK_RESETPASSORD_CLOSE"



export const openSignUp = {
    type: CLICK_SIGNUP_OPEN,
    payload: true
  };
  
  export const closeSignUp = {
    type: CLICK_SIGNUP_CLOSE,
    payload: false
  };
  
  export const openSignIn = {
    type: CLICK_SIGNIN_OPEN,
    payload: true
  };
  
  export const closeSignIn = {
    type: CLICK_SIGNIN_CLOSE,
    payload: false
  };


  export const openEmailAuth = {
    type: EMAIL_AUTH_OPEN,
    payload: true
  };

  export const closeEmailAuth = {
    type: EMAIL_AUTH_CLOSE,
    payload: false
  };

  export const openResetPasswordReq = {
    type: CLICK_RESETPASSORD_REQ_OPEN,
    payload: true
  };
  export const closeResetPasswordReq = {
    type: CLICK_RESETPASSORD_REQ_CLOSE,
    payload: false
  };


 
  
  
  