import {useEffect, useState} from 'react'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useSelector,useDispatch } from 'react-redux';
import { PositionMapPage } from '../Layout'
import { AreaChart } from '../../../../components/ReactChart/AreaChart';
import { fetchDayAheadDeficitSurplus } from '../../../../Apis/actions/fetchDayAheadDeficitSurplus';
import { prepareMixedAreaChartData } from '../../../../components/ReactChart/MultiLine/linedata';
import { TimeBlock } from '../../../../container/dashboard.fn';
import ModelPopup from '../Layout/ModelPopup'
import OptimizerSettings from './OptimizerSettings';
import { BarChart } from '../../../../components/ReactChart/BarChart';
import BaseDatagrid from '../../../../components/Tables/BaseDatagrid';
const PositionMap = () => {
    const dispatch = useDispatch()
    const deficit_surplus_state = useSelector(state=> state.dayaheaddeficitsurplus)
    const selected_date = useSelector(state => state.ForecastComp)
    // console.log(selected_date.forDate)
    // window.onload = function(){
    //     var ctx = document.getElementById("").getContext("2d");
    // }
    // console.log(window.onload)  
    const [state, setState] = useState({
            displayPlot:false,
            openOptimizer:false,
            loading:false,
            date: selected_date.for_date,
            model_name:'user_hybrid',
            deficit_surpls:[]
    })
    const onClickOpenOptimizer = () => {
        setState({...state, openOptimizer:true})
    }
    const onClickCloseOptimizer = () => {
        setState({...state, openOptimizer:false})
    }

    const toggleTableGraph=() =>{
        if(state.displayPlot === true) {
            setState({...state, displayPlot:false})
        } else if (state.displayPlot=== false) {
            setState({...state, displayPlot:true})
        }
    }
    useEffect(()=> {
        if(deficit_surplus_state && deficit_surplus_state.loading){
            setState({...state, isLoading:true})
        }
        if(deficit_surplus_state && !deficit_surplus_state.loading 
            && deficit_surplus_state.data){
                setState({...state, loading:false,
                    deficit_surpls:deficit_surplus_state.data
                })
            }
    },[deficit_surplus_state])


   const key = 'series_name'
   const value_field = 'value_mw'
   const labels = TimeBlock()
   
   let linedatasets
   let mixedcharttypesets
   let updatedMixedcharttypesets =[]
   let muliLineData  
   let mixedChartData 
   let schedulesummary_table
   let schedulesummary_columns
   const non_staked_line= ['load_forecast','total_entitlement']
   const area_stacked_element= ['ISGS','WIND','SOLAR','INTERNAL']
   const bar_chart_stacked =[]
   const bar_comp_nonstacked=['deficit_surplus']



    if(state.deficit_surpls && state.deficit_surpls.length >0)
        {
        
        mixedcharttypesets =  prepareMixedAreaChartData(
            state.deficit_surpls,key,value_field,area_stacked_element,
            non_staked_line,bar_chart_stacked,bar_comp_nonstacked
        )  
        // console.log(mixedcharttypesets)

        mixedcharttypesets.forEach(element => {
            if(element.label==='deficit_surplus'){
                let bg_color=[]
                let _data = element.data
                for (let i =0; i <=_data.length; i++){
                    if(parseFloat(_data[i])<=0){
                        bg_color.push("rgba(255, 99, 132, 0.5)")
                    }
                    else{
                        bg_color.push("rgba(75, 192, 192, 0.5)")
                    }
                }
                element.backgroundColor=bg_color
                element.borderColor=bg_color
            }
        });

        let table_data = []
        mixedcharttypesets.forEach(element => {
            let _dict = {}
            _dict.unit_name=element.label
            let _data = element.data
            labels.forEach((element,index) => {
                _dict[element]=Math.round(_data[index])
            });
            table_data.push(_dict)

        });
        
        
        let _schedule_summary_keys=Object.keys(table_data[0])
        let _schedule_summary_columns = []
        _schedule_summary_keys.forEach(element => {
            let _dict = {}
            _dict.field=element
            _dict.header=element
            // if(element!=='unit_name'){
            //     _dict.editable= true
            // }
            _schedule_summary_columns.push(_dict)
        });
        schedulesummary_table = table_data
        // console.log(schedule_table)
        schedulesummary_columns = _schedule_summary_columns
        mixedChartData={
            labels,
            datasets:mixedcharttypesets
        }
        
    }

    const query_params = {
        date:selected_date.forDate,
        user_email:'awadhesh.kumar@quenext.com',
        state_name: 'karnataka',
        utility_name:'karnataka',
        model_name:'user_hybrid'
     }
//    console.log(query_params )
     const LoadData =  async(e) => {
         await dispatch(fetchDayAheadDeficitSurplus("GET", query_params))

     } 

    return(
        <>
            <PositionMapPage
             displayPlot={state.displayPlot}
             toggleTableGraph={toggleTableGraph}
             action_label={"Optimize"}
             onClickNewAction={onClickOpenOptimizer}
             refreshData={LoadData}
             isLoading={state.loading}
             page_content={
                <>
                {!state.loading && state.deficit_surpls && state.deficit_surpls.length >0 &&  state.displayPlot &&
                    <div style={{height:'100Vh'}}>
                     <BarChart  
                    
                     data = {mixedChartData} 
        
                     />
     
                   </div>
                     
                    }
                {!state.loading && state.deficit_surpls && state.deficit_surpls.length >0 &&  !state.displayPlot &&
                <BaseDatagrid
                columns={schedulesummary_columns}
                rows={schedulesummary_table}
                pageSize={16}
                />

             }


                   
                </>

             }
             
            />
            {/* <ModelPopup
            open={state.openOptimizer}
            handleClose={onClickCloseOptimizer}
            modal_title={
                
                <div style={{marginTop:'1rem'}}>
                <FilterAltIcon/>
                        Schedule optimizer
                </div>
                    
            }
            modal_content={
               
                <OptimizerSettings/>
            }
            /> */}
        </>
    )


}

export default PositionMap