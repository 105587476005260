
export const edit_options = ['Shift','Pivot']
export const pivot_options=["Cnt-point-I", "Cnt-point-II"]

export function pivot_shift(delta,index,pivot1,dragpointindex){
    let delta_shift = 0
    let abs_dist
    if(
      index <= Math.max(dragpointindex,pivot1) 
      && index >= Math.min(dragpointindex,pivot1) 
      && index!==dragpointindex 
      )
    {abs_dist = Math.abs(index-pivot1)/Math.abs(dragpointindex-pivot1 )
     delta_shift = delta*abs_dist
    }
    if(dragpointindex===pivot1){
      delta_shift=0 
    }
    return delta_shift
  }
  
export function parallel_shift(delta,index,pivot1,pivot2,dragpointindex){
    let delta_shift = 0
    if(index <= Math.max(pivot1,pivot2) 
      && index >= Math.min(pivot1,pivot2) 
      && index!==dragpointindex  
      && dragpointindex>= Math.min(pivot1,pivot2) 
      && dragpointindex<= Math.max(pivot1,pivot2)
    )
    {delta_shift = delta}
    return delta_shift
  }


  export function DragDropUpdate(chart,dragpointindex,datasetIndex,delta,pivot1,pivot2,
    selected_edit_mode){
    let updated_data = []
    if(chart && dragpointindex){
        let _data = chart.data.datasets[datasetIndex].data
        _data.forEach((element,index) => {
          let _updated_data = element
          let parallel_delta = 0
          // let pivot1 = state.control_point_1
          // let pivot2 = state.control_point_2
          if( selected_edit_mode =="Shift" && pivot1 && pivot2 )
          {  parallel_delta = parallel_shift(delta,index,pivot1,pivot2,dragpointindex)
             _updated_data = parseFloat(element) + parallel_delta
          }
          if( selected_edit_mode =="Pivot" && pivot1)
          { let pivot_delta = 0
            let pivot_point = pivot1
            pivot_delta =  pivot_shift(delta,index,pivot_point,dragpointindex)
            _updated_data = parseFloat(element)+pivot_delta
          }
          updated_data.push(_updated_data)
        })
    }
    return updated_data
}

