import React, {useState} from "react";
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import UseForm from "./UseForm";
import {validator} from "./Validator";
import { CustomDialog } from "../../UiComponent/CustomDialog";
// import {bgColors} from "../utils/bgColor";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import UseWindowDimensions from '../screensizecontainer/windowdimensions';
import AuthFormHeader from "./AuthFormHeader";
import UpdatePassword from "./Services/UpdatePassword";


// import { STATE_SIGNUP} from "./PostData";

const ResetPassword = ()=> {
    const {height, width} = UseWindowDimensions()
    const initState = {
      // phone_no:"",
      emailphone:"",
      // email_id:"",
      password: "",
      confirmpassword: "",
    };

      const submit = () => {
        return ("Submited")
      };

      const {
        handleChange,
        handleSubmit,
        handleBlur,
        state,
        errors,
        countryCode
      } = UseForm({
        initState,
        callback: submit,
        validator
      });

      let isValidForm =
        Object.values(errors).filter(error => typeof error !== "undefined")
      .length === 0;

    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordHide = () => {
         setShowPassword((show) => !show);
    };    
    
    const submitUpdatePassword = (e) => {
      e.preventDefault()
       UpdatePassword(state)
    }

  return (
    <Box sx = {{height:height, width:width, borderRadius:10}}>    
    <CustomDialog
    // onClose={() => dispatch(closeUserFolio)}
    aria-labelledby="customized-dialog-title"
    // open={openUserFolio}
    open={true}
    content={
      <>
       <AuthFormHeader/>
        <form onSubmit={handleSubmit}>
        <Grid container spacing={2} display={"flex"} direction={"column"}>
            <Grid item>
            <TextField
                required
                fullWidth
                size = 'small'
                label="Email /Phone no"
                name="emailphone"
                //   className={margin}
                defaultValue={state.emailphone}
                onChange={handleChange}
                error={errors.emailphone ? true : false}
                helperText={errors.emailphone}
                onBlur={handleBlur}
            />
            </Grid>
        <Grid item>
        <TextField
            required
            fullWidth
            name = "password"
            label = "Password"
            type = { showPassword ? "text" : "password" }
            id = "password"
            size = "small"
            autoComplete = "current-password"
            onChange = {handleChange}
            error={errors.password ? true : false}
            helperText={errors.password}
            // defaultValue = {state.password }
            onBlur={handleBlur}
            InputProps = {
            {
                endAdornment: ( 
                <InputAdornment position="end"> {
                    showPassword ? ( 
                    <Visibility className = "cursor_pointer"
                    onClick = {
                        togglePasswordHide
                    }
                    />
                    ) : ( 
                    <VisibilityOff onClick = {
                        togglePasswordHide
                    }
                    />)} 
                </InputAdornment>
                ),}}
                />
            </Grid>
            <Grid item>
          <TextField
            required
            fullWidth
            name = "confirmpassword"
            label = "Confirmpassword"
            type="password"
            // type = { showPassword ? "text" : "confirmpassword" }
            id = "confirmpassword"
            size = "small"
            autoComplete = "current-confirmpassword"
            onChange = {handleChange}
            error={errors.confirmpassword ? true : false}
            helperText={errors.confirmpassword}
            onBlur={handleBlur}
              />
          </Grid>
        </Grid>
        </form>
      </>
   
  }
  action={
    <Grid container spacing={2} display={"flex"} direction={"column"}>
    <Grid item>
          <Button
            fullWidth
            size= 'small'
            disabled={!isValidForm}
            type="submit"
            variant="contained"
            color="primary"
            onClick={submitUpdatePassword}
          //   className={margin}
          >
          Update password
        </Button>
        </Grid>
       <Grid container display={'flex'} spacing={1} direction={'column'}>
      </Grid>
      </Grid>

  }
      />
</Box>

)}
export default ResetPassword
