import React, {useState} from 'react';
import Box from '@mui/material/Box';
import { DataIntpageLayout } from './Layout';
import WeatherUpload from './WeatherUpload';
import { MarketDataTab } from './MarketData';
import { IsgsTab } from './ISGSData';
import { ReGenTab } from './ReGenertion';
import { LoadDataUpload } from './LoadData';
import {menu_list} from './Layout/const';
import { DateStrEvent } from '../../UiComponent/DatePicker/DatePickerSmall';
import { InternalGenerationTab } from './InternalGeneration';
export default function DataIntegration() {
  const parent_options = Object.keys(menu_list)
  const initial_parent = parent_options[0]
  const initial_child_option= menu_list[initial_parent]
  // const initial_child =  menu_list[initial_parent][0]
  const [state, setState]= useState({
    anchorEl:null,
    anchorEl_parent:null,
    selectedIndex_parent: 0,
    options:initial_child_option,
    selectedIndex: 0,
    date: null
  })
  const handleOnchageDate=(event) => {
    const datestr = DateStrEvent(event)
     setState({...state, date:datestr})
    // dispatch(setState({...date,fordate}))

  }
  const open_parent = Boolean(state.anchorEl_parent);
  const open = Boolean(state.anchorEl);
  const handleClickListItem_parent = (event) => {
    setState({...state, 
      anchorEl_parent:event.currentTarget
    })
  };

  const handleMenuItemClick_parent = (event, index) => {
    setState({...state,
      selectedIndex_parent:index,
      anchorEl_parent:null,
      options:menu_list[parent_options[index]]

    })
  };
  const handleClose_parent = () => {
    setState({
      ...state, anchorEl_parent:null
    })
  };
  const handleClickListItem = (event) => {
    setState({...state, 
      anchorEl:event.currentTarget
    })
  };
  const handleMenuItemClick = (event, index) => {
    setState({...state,
      selectedIndex:index,
      anchorEl:null

    })
  };
  const handleClose = () => {
    setState({
      ...state, setAnchorEl:null
    })
  };
return (


  <>
   
      <DataIntpageLayout
        options_parent={parent_options}
        open_parent = {open_parent}
        handleClickListItem_parent={handleClickListItem_parent}
        anchorEl_parent={state.anchorEl_parent}
        handleClose_parent={handleClose_parent}
        selectedIndex_parent={state.selectedIndex_parent}
        handleMenuItemClick_parent={handleMenuItemClick_parent}
        options={state.options}
        open = {open}
        handleClickListItem={handleClickListItem}
        anchorEl={state.anchorEl}
        handleClose={handleClose}
        selectedIndex={state.selectedIndex}
        handleMenuItemClick={handleMenuItemClick}
        date={state.date}
        handleOnchageDate={handleOnchageDate}
        child_comp={
          <>
              {parent_options[state.selectedIndex_parent] === parent_options[0] && 
              
              <LoadDataUpload/>
              
          }  
       
          {
            parent_options[state.selectedIndex_parent] ===  parent_options[1] && 
              <MarketDataTab
                selected_parent={parent_options[state.selectedIndex_parent]}
                options={state.options}
                selectedIndex={state.selectedIndex}
              />
          }  
            { 
              parent_options[state.selectedIndex_parent] === parent_options[2] && 
              <IsgsTab
                selected_parent={parent_options[state.selectedIndex_parent]}
                options={state.options}
                selectedIndex={state.selectedIndex}
              />
            }  
            {/* {selectedParent === parent_options[3] && 
              <IsgsTab/>
            }   */}
            {parent_options[state.selectedIndex_parent] === parent_options[3] && 
              <InternalGenerationTab
                selected_parent={parent_options[state.selectedIndex_parent]}
                options={state.options}
                selectedIndex={state.selectedIndex}
              />
            } 

            {parent_options[state.selectedIndex_parent] === parent_options[4] && 
              <ReGenTab
                selected_parent={parent_options[state.selectedIndex_parent]}
                options={state.options}
                selectedIndex={state.selectedIndex}
              />
            }  
             {parent_options[state.selectedIndex_parent] === parent_options[5] && 
              <WeatherUpload/>
            }  
            </>

        }
      />
      <Box>
       
  
  </Box>
    </>
  )
}