

import ScheduleDetail from './ScheduleDetail'; //current 
import ScheduleSummary from './ScheduleSummary'; //current schedule summary
import DayAheadEntitlement from './DayAheadEntitlement'; //day ahead
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const IsgsTab =({options,selectedIndex}) => 
      {  
      const currentSch = useSelector(state => state.live_isgs_schedule)
      const dayAheadSch = useSelector(state => state.day_ahead_isgs_schedule)
      
      // console.log(dayAheadSch.data.schedule_detail_db)


      

      const [state,setState ]= useState({
            currentSch_loading:false,
            currentSch_data:{},
            dayAheadEnt_loading:false,
            dayAheadEnt_data:{},
            dayAheadSchDetail_data:{}
      })
   

//      console.log(state.dayAheadEnt_data)




   useEffect(()=> {
      if(currentSch && currentSch.loading){
            setState({...state,
              currentSch_loading:currentSch.loading})
      }

      if(currentSch && !currentSch.loading 
            && currentSch.data )
            {
            setState({
                  ...state,
                  currentSch_loading:false,
                  currentSch_data:currentSch.data
            }) 
            }

      if(dayAheadSch && dayAheadSch.loading){
            setState({...state,
            dayAheadEnt_loading:dayAheadSch.loading})
            }
        if(dayAheadSch && !dayAheadSch.loading 
            && dayAheadSch.data)
            {
            setState({
                  ...state,
                  dayAheadEnt_loading:false,
                  dayAheadEnt_data:dayAheadSch.data
            }) 
            } 
        

   },[currentSch,dayAheadSch])

   

          return (
                  <>
                
                            {options[selectedIndex]===options[0] && 
                                  <ScheduleDetail
                                  isLoading={state.currentSch_loading}
                                  data_table={state.currentSch_data.schedule_detail_mat}
                                  
                                  />
                            }
                            { options[selectedIndex]===options[1] && 
                                  <ScheduleSummary
                                  isLoading={state.currenSummary_loading}
                                  data_table={state.currentSch_data.net_schedule_mat}
                                  />
                            }
                              { options[selectedIndex]===options[2] && 
                                <DayAheadEntitlement
                                isLoading={state.dayAheadEnt_loading}
                                data_table={state.dayAheadEnt_data.ent_detail_mat}
                                data_post={state.dayAheadEnt_data.ent_detail_db}
                                />
                              }
                  
                  </>
          )
}

export default IsgsTab


