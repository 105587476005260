
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';

export default function DatePickerValue({label,defaultValue,onChange}) {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={label}
          format="YYYY-MM-DD"
          defaultValue={defaultValue}
          onChange={onChange}
        />
     
    </LocalizationProvider>
  );
}
DatePickerValue.propTypes = {
  defaultValue:PropTypes.instanceOf(Date),
  label: PropTypes.string,
  onChange: PropTypes.func
};



