import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Card from '@mui/material/Card';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import { MapStyles } from '../constants';
import Typography  from '@mui/material/Typography';
import { bgColors } from '../../../../utils/bgColor';
import CustomdDialog from '../../../../UiComponent/CustomDialog/CustomDialog';
import { useSelector, useDispatch } from 'react-redux';
import { closeAnalysisWindow } from '../../../../redux/actions/controlbuttonaction';
import { AccordianCustom } from '../../../../UiComponent/AccordianCustom';
import Box from '@mui/material/Box'
// import constan
import Button  from '@mui/material/Button';
import { CardHeader, Stack } from '@mui/material';
import {location,location_parameters, proximity_param, irrigated,performanceScore,NDVI,Season} from "./contants"
// import { CheckBox } from '@mui/material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { couldStartTrivia } from 'typescript';
import { MultiLine } from '../../../ReactChart/MultiLine';
// import MultiLine from '../../../ReactChart/MultiLine';
import { kleurs,makeRepeated,roundUp} from '../ChartColor';
import { Basetable } from '../../../Table';
// import Croptable from './Croptable';
// import {}

// import { index_url } from '../../../HelperFunctions/SatIndexQueryParam';
const AnalysisWindow=()=>{
const dispatch = useDispatch()
const analysisWindowState = useSelector(state=>state.controlbutton.analysiswindow)
const mapstyleslabel = Object.keys(MapStyles)
const handleClose = () => {
  dispatch(closeAnalysisWindow)
}

const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

const icon_style = {color: bgColors.whitesmoke, marginRight:'0.5rem', fontFamily:'roboto'}
const Grid_style = {color: bgColors.OffWhite, marginLeft:'2.25rem', 
                  background:'transparent'}
const accordianStyle = {  
                          color:bgColors.whitesmoke,
                          // backgroundColor:`${bgColors.blue_grey}10`,
                          background: 'transparent'
                        }
// const paperstyle = {position:'fixed', top:'2.5rem', right:'-1rem',minWidth: '300px',
//                     background: bgColors.black , 
//                     borderRadius:'10px'                 
//                    }

const paperstyle = {top:'2.5rem', right:'-1rem',
background: bgColors.black , 
borderRadius:'10px'                 
}
const boxStyle = {border: 2,
                  borderColor:`${bgColors.black}90`,
                  backgroundColor:`${bgColors.black}30`,
                  borderRadius: '10px',
                  marginBottom:'0.25rem'
                  }
const typoStyle = {fontSize: 16, fontFamily:'roboto',padding:'0.5rem'}
const [checked, setChecked] = useState(false)

const ndvi_time_label = () => {
  let all_labels = []
  NDVI.properties['2020-2021'].forEach((item)=> {
    
    all_labels.push(item.month)
  })
  return all_labels
}


// console.log(ndvi_time_label())

// console.log(ndvi_series_key)

const ndvi_plot_data = () => {
  const labels = ndvi_time_label()
  let data = {
    labels,
    datasets:[]

  }
  const ndvi_series_key = Object.keys(NDVI.properties) 
  const length_label = ndvi_series_key.length
  const number_of_num_color = length_label / kleurs.length
  var num_color = makeRepeated(kleurs, roundUp(number_of_num_color, 0))
  let _all_color = []
  for (let i = 0; i <= 2; i++){
    let _all_color_var = num_color[i]
    _all_color.push(_all_color_var)
  }
   
  let _datasets = []
  ndvi_series_key.forEach((item,index) => {
    let ts_data =  NDVI.properties[item]
    let _ts_data = []
    ts_data.forEach(
      (item)=> 
    _ts_data.push(item.value)
    )
    let _data = {
      label:item,
      data: _ts_data,
      borderColor: _all_color[index],
      backgroundColor: _all_color[index],
      yAxisID: 'y',
      fill:false
    }
    _datasets.push(_data)
  })
  
  data.datasets = _datasets
  return data
}



const data = performanceScore
const columns = Object.keys(data[0])



return (
  <CustomdDialog
  key = {"analysis_report"}
    dialogTitle={   
    
    <Typography key = {"analysis_report_filedview"} variant='title'>
    
      FieldView
      </Typography>
      
    }
    open={analysisWindowState}
    handleClose={handleClose}
    PaperProps = {{sx:paperstyle}}
    checked = {checked}
    content = {
    <Box 
    key = {"globe-zoom-filed-view-container"}
    // sx={{flexDirection: 'row' , justifyContent: 'space-between'}}
    >

{/* Locations details start  */}
 <Card key = {"globe-zoom-filed-view-container-card"}>
 <CardHeader
    key = {"globe-zoom-filed-view-container-cardheader"}
    title="location details"
    />
  <Grid  key = {"location_state"} container direction={'row'} justifyContent={'space-between'}>
  <Typography
  key = {"locations_typo_state"}
  >
    state:
  </Typography>
  <Typography
  key = {"locations_typo_state_name"}
  >
    {location.state_name}
  </Typography>

  </Grid>
  <Grid key = {"location_district"} container direction={'row'} justifyContent={'space-between'}>
  <Typography
  key = {"locations_typo_district"}
  >
    district:
  </Typography>
  <Typography
  key = {"locations_typo_district_name"}
  >
    {location.district_name}
  </Typography>

  </Grid>
  <Grid 
   key = {"locations_typo_subdistrict_grid"}
  container direction={'row'} justifyContent={'space-between'}>
  <Typography
   key = {"locations_typo_subdistrict"}
  >
    Subdistrict:
  </Typography>
  <Typography
    key = {"locations_typo_subdistrict_name"}
  >
    {location.subdistrict_name}
  </Typography>

  </Grid>

  <Grid 
   key = {"locations_typo_village_grid"}
  container direction={'row'} justifyContent={'space-between'}>
  <Typography
  key = {"locations_typo_village"}
  >
    Village:
  </Typography>
  <Typography
    key = {"locations_typo_village_name"}
  >
    {location.village_name}
  </Typography>
  </Grid>
  <Grid 
  key = {"locations_typo_pincome_grid"}
  container direction={'row'} justifyContent={'space-between'}>
  <Typography
   key = {"locations_typo_pincome"}
  >
    Pincode:
  </Typography>
  <Typography
     key = {"locations_typo_pincode_label"}

  >
    {location.pincode}
  </Typography>

  </Grid>
  <Grid container direction={'row'} justifyContent={'space-between'}>
  <Typography>
    Vellage-Centroid:
  </Typography>
  <Typography>
    lng: {location.Vilage_centroid[0]}
  </Typography>
  <Typography>
    lat: {location.Vilage_centroid[1]}
  </Typography>
  </Grid>
  <Grid container direction={'row'} justifyContent={'space-between'}>
  <Typography>
    Polygon-Centroid:
  </Typography>
  <Typography>
    lng: {location.Poly_centroid[0]}
  </Typography>
  <Typography>
    lat: {location.Poly_centroid[1]}
  </Typography>
  </Grid>
  </Card>
  {/* Locations details start  */}
   {/* component irrigated / rainfed start  */}
  <Card>
    <CardHeader
    title="Irrigated or rainfed"
    />
      <FormControl>
     
        <RadioGroup
            aria-labelledby="Raster-group-label"
            name="Raster-group"
        > 
        <Grid container spacing={1} display='flex' direction={"row"} sx = {Grid_style}>
          {irrigated.map((item,index)=> 
              <FormControlLabel 
                key = {`${item}"_"${index}`}
                value={item} 
                control={<Radio 
                // value = {mapstyle}
                // onChange={handleMapStyleChnge}
                />  } 
                label={
                  <Typography 
                    sx ={typoStyle}
                      >{item}
                  </Typography> 
                }
              />
              )
          }
        </Grid>
    </RadioGroup>
   
  </FormControl>
  </Card>
   {/* component irrigated / rainfed end  */}
   {/* component Cropping intensity start  */}
  <Card>
    <CardHeader
      title="Cropping intensity"
      // subheader="September 14, 2016"
    />
     
    {/* <FormGroup> */}
    {Season.map((item,index) => 
     <FormControlLabel 

     control={<Checkbox key ={index}defaultChecked />
    }
      label={item} />
    )}
  {/* component Cropping intensity end  */}
    {/* component vegetation plot start  */}
  </Card>
  <Card>
    <CardHeader
      title="vegetation cycle"
      // subheader="September 14, 2016"
    />
     
     <MultiLine
     data = {ndvi_plot_data()}
     /> 

 
  </Card>
  {/* component vegetation plot end  */}
  <Basetable
  columns={columns}
  data = {data}
  />
  </Box>
     
    }
    action = 
    
    
    { <>
    <Stack direction={'column'}>
    <Stack direction={'row'}>
      <Button variant='outlined'

      // onClick={saveState}
      
      >
        Share Report
      </Button>
      <Button variant='outlined'

// onClick={saveState}

>
  Donwload Report
</Button>
</Stack>
      
      <AccordianCustom
        id = {"Map settings"}
        sx = {accordianStyle}
        expanded= {expanded}
        handleChange={handleChange}
        // title_name={"d"}
        panel = {'panel1'}
        // icon =  {<SettingsOutlinedIcon 
        // sx = {icon_style}
        // />}
        >
      <div>
        {/* <Card> */}
        <Typography variant={'body'}>
          Performance Score:  the score is  divided into 5 groups where score 5 means super-prime
          Super-prime: 5
          Prime: 4
          Average: 3
          Subprime:2
          Poor:1
          No-crop/Failed-crop: 0
        </Typography>
      </div>
      </AccordianCustom>
      </Stack>
      </>
    }
  />
  
);
}

export default AnalysisWindow
