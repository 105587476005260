

import Dayahead from './Dayahead';
import Intraday from './Intraday';
import MediumTerm from './Mediumterm';
const LoadForecasting =({options,selectedIndex}) => 
      {  
          return (
                  <>
                
                  {options[selectedIndex]===options[0] && 
                        <Dayahead
                        
                        />
                  }
                  { options[selectedIndex]===options[1] && 
                        <Intraday
                        />
                  }
                  { options[selectedIndex]===options[2] && 
                        <MediumTerm

                        />
                  }
                  
             </>
          )
}

export default LoadForecasting


