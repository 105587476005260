import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {theme} from "../Theme"
import CssBaseline from '@mui/material/CssBaseline';
import { Divider } from '@mui/material';
export default function TypographyResp({children,variant}) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Typography variant="title">
        {children}
      </Typography>
      <Divider>
        
      </Divider>
    </ThemeProvider>
  );
}

