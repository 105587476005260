import * as React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  // position: "absoloute",

  borderRadius: theme.shape.borderRadius,
  marginLeft: 0,
  width: "100%"
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`
  }
}));

const Searchbar=({handleDrawerOpen}) =>{

  return (
      <AppBar
       size={"small"}
        position="static"
        sx={{ borderRadius:8, 
          // backgroundColor:"white", 
          // color: "black" ,
          // marginLeft:40,
          width:350,
        //   height:'90%',
          // marginLeft:10,
          marginTop:1,
          justifyContent:'center',
          height:'45px'
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            // sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>

          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="open drawer"
          >
            <NearMeOutlinedIcon/>
          </IconButton>
        </Toolbar>
      </AppBar>
  );
}

export default Searchbar
