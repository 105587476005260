
// user defined model:  model and its weight for blocks
// loadforcast state:  redux state for loading load forecast data for date. 
import { range } from "../../../utils/ArryOps"
import { bgColors } from "../../../utils/bgColor"
export function HybridForecast(user_defined_model,da_wind_forecast_state){
   let user_hybrid_forecast = []
   let hybrid_data = []
   let user_hybrid_forecast_var = {}

   if(user_defined_model && da_wind_forecast_state 
    && user_defined_model.userdefined_series.length>0 && 
    !da_wind_forecast_state.loading && da_wind_forecast_state.data 
    && da_wind_forecast_state.data.length>0){
   
      let block_no = range(1,96)
      let forecast_mat = da_wind_forecast_state.data
      // let quenext_forecast= forecast_mat.filter(item=> (item.series_name ==='qnext_forecast'))
      // console.log(user_defined_model)
      block_no.forEach(block => {
        let _hybrid_var = {}
        _hybrid_var.block_no=block
        _hybrid_var.model_name='user_hybrid'
        let _filtered_forecast = forecast_mat.filter(item=> (item.block_no ===block))
        let user_defined_weight = user_defined_model.userdefined_series
        // console.log(user_defined_weight)
        let _forecast = 0
        user_defined_weight.forEach(element => {
          let model_name = element.series_name
          // console.log(model_name)

          let from_block= parseFloat(element.from_block)
          let to_block= parseFloat(element.to_block)
          let weight = parseFloat(element.weight)
          let _filtered_model_forecast = _filtered_forecast.filter(item=> (item.series_name===model_name))

          // console.log(_filtered_model_forecast)
          let _forecast_mw = parseFloat(_filtered_model_forecast[0].windgen_mw)
          if(block >=from_block && block<=to_block){
            _forecast+= weight*_forecast_mw
          }
        });
        hybrid_data.push(_forecast)
        _hybrid_var.windgen_mw = _forecast
        user_hybrid_forecast.push(_hybrid_var)
      })
   }
   user_hybrid_forecast_var.label= 'user_hybrid'
   user_hybrid_forecast_var.type= 'line'
   user_hybrid_forecast_var.data = hybrid_data
   user_hybrid_forecast_var.backgroundColor= bgColors["info.light"]
   user_hybrid_forecast_var.borderColor= bgColors["info.light"]
   user_hybrid_forecast_var.borderWidth=3

   return { 'hybrid_forecast': user_hybrid_forecast,
            'HybridForecastPlotData': user_hybrid_forecast_var
             }
}


   