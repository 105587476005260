import {
    API_START,
    API_END
  } from "../actions/types";

import {
  GET_DAYAHEAD_REGEN_SUMMARY,
  GET_DAYAHEAD_REGEN_SUMMARY_ERROR,
  SET_DAYAHEAD_REGEN_SUMMARY
} from "../actions/ReSchedule_Summary_DayAhead"

  const initialState = {
      loading: false,
      data: {},
      error: ''
  
  }
  
  
  export default function reduceDayAheadReSchSummary(state = initialState, action) {
    //console.log("action type => ", action.type);
    switch (action.type) {
    
      case SET_DAYAHEAD_REGEN_SUMMARY:
                  // console.log(action.payload)

        return { ...state, data: action.payload };
      case API_START:
        
        if (action.payload === GET_DAYAHEAD_REGEN_SUMMARY) {
          return {
            ...state,
            loading: true,
            data:{},
            error: ""
          };
        }
        return(null)
        // break;
      case API_END:
        if (action.payload === GET_DAYAHEAD_REGEN_SUMMARY) {
          return {
            ...state,
            loading: false,
          };
        }
        return(null)
        // break;
        case GET_DAYAHEAD_REGEN_SUMMARY_ERROR:
          return {
            ...state,
            data: {},
            error: action.payload.message
          };

      default:
        return state;
    }
  }
  