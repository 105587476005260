
import { bgColors } from "../../../login/utils/bgColor";
import mapboxgl from 'mapbox-gl';
import { tilerootpath } from "../../../../Apis/apirootpath";
const LineColor = bgColors.grey_300
const adminboundryurl = `${tilerootpath}/boundrylayer`


const adminBoundry = (map) => {

  if (map.current.getLayer('state_boundry')) {
      map.current.removeLayer('state_boundry');
    }

    if (map.current.getLayer('state_boundry_highlighted')) {
      map.current.removeLayer('state_boundry_highlighted');
    }

    if (map.current.getLayer('state_boundry_highlighted')) {
      map.current.removeLayer('state_boundry_highlighted');
    }
    if (map.current.getLayer('district_boundry')) {
      map.current.removeLayer('district_boundry');
    }

    if (map.current.getLayer('district_boundry_highlighted')) {
      map.current.removeLayer('district_boundry_highlighted');
    }
    if (map.current.getLayer('subdistrict_boundry')) {
      map.current.removeLayer('subdistrict_boundry');
    }

    if (map.current.getLayer('subdistrict_boundry_highlighted')) {
      map.current.removeLayer('subdistrict_boundry_highlighted');
    }

    if (map.current.getLayer('village_boundry')) {
      map.current.removeLayer('village_boundry');
    }

    if (map.current.getLayer('village_boundry_highlighted')) {
      map.current.removeLayer('village_boundry_highlighted');
    }
    if (map.current.getLayer('pincode')) {
      map.current.removeLayer('pincode');
    }
    if (map.current.getLayer('pincode_highlighted')) {
      map.current.removeLayer('pincode_highlighted');
    }

  
  if (map.current.getSource('boundrylayer')) {
      map.current.removeSource('boundrylayer');
    }

  
  let visibility = 'none'
  

  // map.current.on('load', () => {
      const popup = new mapboxgl.Popup({
            offset: -10,
            closeButton: false,
            // closeOnClick: false,
            closeOnMove: true,
            maxWidth: "auto"
      })

       map.current.addSource('boundrylayer', {
            type: 'vector',
            "tiles": [`${adminboundryurl}/{z}/{x}/{y}.mvt`],
            "tolerance": 1000,
            // 'maxzoom': 8,
       });






        map.current.addLayer({
              'id': 'state_boundry',
              'type': 'fill',
              'source': 'boundrylayer',
              'source-layer': 'state_boundry',
              'minzoom':4,
              'layout': {
        
                'visibility': visibility,
              },
              'paint': {
                  'fill-outline-color':bgColors.grey_300,
                  'fill-color': 'transparent',
                  }
        }
        );
  
        // map.addLayer({
        //   'id': 'state_boundry_outline',
        //   'type': 'line',
        //   'source': 'boundrylayer',
        //   'source-layer': 'state_boundry',
        //   'minzoom':4,
        //   'layout': {
        //   // Make the layer visible by default.
        //   'visibility': 'visible',
        //   },
        //   'paint': {
        //   'line-color': LineColor,
        //   'line-width': 1
        //   }
        // });
  
    map.current.addLayer(
        {
          'id': 'state_boundry_highlighted',
          // 'type': 'fill',
          'type': 'line',
          'source': 'boundrylayer',
          'source-layer': 'state_boundry',
          'minzoom':4,
          'layout': {
        
            'visibility': visibility,
          },
          'paint': {
            'line-color': bgColors.yellow_500,
            'line-width': 2
            // 'fill-outline-color': bgColors.green_500,
            // 'fill-color': bgColors.grey_500,
            // 'fill-opacity': 0.3
          },
          'filter': ['in', 'id', '']
          },
        // // Place polygons under labels.
        // 'india_village-label'
      );


      map.current.addLayer({
        'id': 'district_boundry',
        'type': 'fill',
        'source': 'boundrylayer',
        'source-layer': 'district_boundry',
        'minzoom': 7,
        'layout': {
        
          'visibility': visibility,
        },
        'paint': {
            'fill-outline-color':LineColor,
            'fill-color': 'transparent',
            }
  });

  // map.addLayer({
  //   'id': 'district_boundry_outline',
  //   'type': 'line',
  //   'source': 'boundrylayer',
  //   'source-layer': 'district_boundry',
  //   'minzoom': 7,
  //   'layout': {
  //   // Make the layer visible by default.
  //   'visibility': 'visible',
  //   },
  //   'paint': {
  //   'line-color': LineColor,
  //   'line-width': 1
  //   }
  // });

map.current.addLayer(
  {
    'id': 'district_boundry_highlighted',
    // 'type': 'fill',
    'type': 'line',
    'source': 'boundrylayer',
    'source-layer': 'district_boundry',
    'minzoom': 7,
    'layout': {
        
      'visibility': visibility,
    },
    'paint': {
      'line-color': bgColors.yellow_500,
      'line-width': 2
      // 'fill-outline-color': bgColors.green_500,
      // 'fill-color': bgColors.grey_500,
      // 'fill-opacity': 0.3
    },
    'filter': ['in', 'id', '']
    },
  // // Place polygons under labels.
  // 'india_village-label'
);


map.current.addLayer({
  'id': 'subdistrict_boundry',
  'type': 'fill',
  'source': 'boundrylayer',
  'source-layer': 'subdistrict_boundry',
  'minzoom': 10,
  'layout': {
    'visibility': visibility,
  },
  'paint': {
      'fill-outline-color':LineColor,
      'fill-color': 'transparent',
      }
});

// map.addLayer({
// 'id': 'subdistrict_boundry_outline',
// 'type': 'line',
// 'source': 'boundrylayer',
// 'source-layer': 'subdistrict_boundry',
// 'minzoom': 10,
// 'layout': {
// // Make the layer visible by default.
// 'visibility': 'visible',
// },
// 'paint': {
// 'line-color': LineColor,
// 'line-width': 1
// }
// });

map.current.addLayer(
{
'id': 'subdistrict_boundry_highlighted',
// 'type': 'fill',
'type': 'line',
'source': 'boundrylayer',
'source-layer': 'subdistrict_boundry',
'minzoom': 10,
'layout': {
  'visibility': visibility,
},
'paint': {
  'line-color': bgColors.yellow_500,
  'line-width': 2

// 'fill-outline-color': bgColors.green_500,
// 'fill-color': bgColors.grey_500,
// 'fill-opacity': 0.3
},
'filter': ['in', 'id', '']
},
// // Place polygons under labels.
// 'india_village-label'
);



map.current.addLayer({
  'id': 'village_boundry',
  'type': 'fill',
  'source': 'boundrylayer',
  'source-layer': 'village_boundry',
  'minzoom': 12,
  'layout': {
        
    'visibility': visibility,
  },
  'paint': {
      'fill-outline-color':LineColor,
      'fill-color': 'transparent',
      // 'line-width': 1
      }
}

);

// map.addLayer({
// 'id': 'village_boundry_outline',
// 'type': 'line',
// 'source': 'boundrylayer',
// 'source-layer': 'village_boundry',
// 'minzoom': 12,
// 'layout': {
// // Make the layer visible by default.
// 'visibility': 'visible',
// },
// 'paint': {
// 'line-color': LineColor,
// 'line-width': 1
// }
// });

map.current.addLayer(
{
'id': 'village_boundry_highlighted',
'type': 'line',
'source': 'boundrylayer',
'source-layer': 'village_boundry',
'minzoom': 12,
'layout': {
  'visibility': visibility,
},
'paint': {
  'line-color': bgColors.yellow_500,
  'line-width': 2
// 'fill-outline-color': bgColors.green_500,
// 'fill-color': bgColors.green_500,
// 'fill-opacity': 0.3
},
'filter': ['in', 'id', '']
},
// // Place polygons under labels.
// 'india_village-label'
);


map.current.addLayer({
  'id': 'pincode',
  'type': 'fill',
  'source': 'boundrylayer',
  'source-layer': 'pincode',
  'minzoom': 9,
  'layout': {
        
    'visibility': visibility,
  },
  'paint': {
      'fill-outline-color':LineColor,
      'fill-color': 'transparent',
      // 'line-width': 1
      }
}

);

// map.current.addLayer({
// 'id': 'village_boundry_outline',
// 'type': 'line',
// 'source': 'boundrylayer',
// 'source-layer': 'village_boundry',
// 'minzoom': 12,
// 'layout': {
// // Make the layer visible by default.
// 'visibility': 'visible',
// },
// 'paint': {
// 'line-color': LineColor,
// 'line-width': 1
// }
// });

map.current.addLayer(
{
'id': 'pincode_highlighted',
'type': 'line',
'source': 'boundrylayer',
'source-layer': 'pincode',
'minzoom': 9,
'layout': {
        
  'visibility': visibility,
},
'paint': {
  'line-color': bgColors.yellow_500,
  'line-width': 2
// 'fill-outline-color': bgColors.green_500,
// 'fill-color': bgColors.green_500,
// 'fill-opacity': 0.3
},
'filter': ['in', 'id', '']
},
// // Place polygons under labels.
// 'india_village-label'
);
  
  

  map.current.on('click', (e) => {
    // Change the cursor style as a UI indicator.
            const bbox = [
                [e.point.x - 5, e.point.y - 5],
                [e.point.x + 5, e.point.y + 5]
                ];
            
            const selectedFeatures = map.current.queryRenderedFeatures(bbox, {
              layers: ['state_boundry','district_boundry', 'subdistrict_boundry', 'village_boundry','pincode']
            });
            // console.log(selectedFeatures)
          if (selectedFeatures !== undefined && selectedFeatures.length > 0 ) {
              map.current.setFilter('village_boundry_highlighted', [
              'in',
              'id',
            selectedFeatures[0].properties.id
              ]);
            }
            if (selectedFeatures !== undefined && selectedFeatures.length > 0 ) {
              map.current.setFilter('pincode_highlighted', [
              'in',
              'id',
            selectedFeatures[0].properties.id
              ]);
            }

            if (selectedFeatures !== undefined && selectedFeatures.length > 0 ) {
              map.current.setFilter('subdistrict_boundry_highlighted', [
              'in',
              'id',
            selectedFeatures[0].properties.id
              ]);
            }
            if (selectedFeatures !== undefined && selectedFeatures.length > 0 ) {
              map.current.setFilter('district_boundry_highlighted', [
              'in',
              'id',
            selectedFeatures[0].properties.id
              ]);
            }
            if (selectedFeatures !== undefined && selectedFeatures.length > 0 ) {
              map.current.setFilter('state_boundry_highlighted', [
              'in',
              'id',
            selectedFeatures[0].properties.id
              ]);
            }

            });

  // })
  
}

export default adminBoundry