import PositionMap from './PositionMap';
import OptimizedSchedule from './OptimizedSchedule';
import Trade from './Trade';

const IntraDaytab =({options,selectedIndex}) => 
      {  


   

          return (
                  <>
                
                            {options[selectedIndex]===options[0] && 
                                  <PositionMap
                                  
                                  />
                            }
                            { options[selectedIndex]===options[1] && 
                                  <OptimizedSchedule
                                  />
                            }
                              { options[selectedIndex]===options[2] && 
                                <Trade
                                />
                              }
                  
                  </>
          )
}

export default IntraDaytab


