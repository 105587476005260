
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useToken } from './useToken'

const PrivateRoutes = () => {
  const token = useToken()

return (
    token.token ? <Outlet/> : 
    <Navigate to='/login'/>
  )
}

export default PrivateRoutes