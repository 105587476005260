import {UPDATE_DRAG_STATE,
  UPDATE_EDIT_MODE_STATE,
  UPDATE_UPCATE_CONTROL_POINT_ONE_STATE,
  UPDATE_UPCATE_CONTROL_POINT_TWO_STATE

} from "../actions/ChartActionDrag"


const initialState = {
   index: null,
   datasetIndex: null,
   value: null,
   edit_mode:'Shift',
   control_point_1:0,
   control_point_2:95
 }

 const chartdragActionReducer =  (state = initialState, action) => {
   switch (action.type) {
     case UPDATE_DRAG_STATE:
       return {
         ...state,
         index: action['payload']['index'],
         datasetIndex: action['payload']['datasetIndex'],
         value: action['payload']['value'],
       }
       case UPDATE_EDIT_MODE_STATE:
        return {
          ...state,
          edit_mode: action['payload']
        }

        case UPDATE_UPCATE_CONTROL_POINT_ONE_STATE:
        return {
          ...state,
          control_point_1: action['payload']
        }

        case UPDATE_UPCATE_CONTROL_POINT_TWO_STATE:
        return {
          ...state,
          control_point_2: action['payload']
        }

     default:
       return state;
   }
 };

 export default chartdragActionReducer  


