import {useDispatch } from "react-redux";
import axios from "axios";
import { fetchCurrentReSchedule } from "../../../Apis/actions/ReSchedule_current";
import { DataIntPage } from "../Layout";
import { postrootpath } from "../../../Apis/apirootpath";
import ReGenLivePlot from "./ReGenLivePlot";

const ReGenLive = ({isLoading,data_table,data_post}) => {
    const dispatch = useDispatch()
    const LoadData =  async(e) => {
        await dispatch(fetchCurrentReSchedule())
    } 
    const commitSchedule = async (e) => {
        const data_to_post = data_post
        e.preventDefault();
        try { axios.post(`${postrootpath}/commitIntraDayRe`, data_to_post,
              {
                dataType: 'json',
                async: true,
              }).then(function (response) {
                if(response.data.status ==="Success")
                 { alert(`No of rows inserted: ${response.data.records}`)}
              })
              .catch(err => {
                alert(`Error message: ${err}}`);
            })
        }
        catch (e) {
          // setError(e)
          alert(`submit failed! ${e.message}`);
        }
    } 
    return (    
        <DataIntPage
            isLoading={isLoading}
            data_table={data_table}
            LoadData = {LoadData}
            CommitData={commitSchedule}
            data_post={data_post}
            chart_child ={<ReGenLivePlot/>}
        >
        </DataIntPage>
    )
}
export default ReGenLive
