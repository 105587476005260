import React, { useState,useEffect } from 'react';

// import Signin
// import Signin from '../Components/login/Signin';
// import { signup } from '../../../ems-server/controllers/auth.controller';
// import Signup from "../components/login/Signup"
import Signin from '../components/login/Signin';
import Signup from "../components/login/Signup"
// import ResetPasswordRequest from '../components/login/ResetPasswordRequest';
import ResetPasswordRequest from '../components/login/ResetPasswordRequest';
import { openSignUp, openSignIn,openResetPasswordReq} from '../redux/actions/authcontrolaction';
import { useSelector, useDispatch } from 'react-redux';
// import { useToken } from '../components/utils/useToken';


const AuthControl = () => {
    const dispatch = useDispatch()
    const authcontrol = useSelector(state => state.authcontrol)
    const [authUiState, setAuthUiState] = useState(authcontrol)
    useEffect(()=> {
        setAuthUiState(authcontrol)
    },[authcontrol])

    return(
        <>
        <Signin
        openSignin={authUiState.openSignIn}
        onCLickOpenSignup= {()=> dispatch(openSignUp)}
        onCLickOpenResetPassword ={()=> dispatch(openResetPasswordReq)}
        />
        <Signup
        openSignup={authUiState.openSignUp}
        onCLickOpenSignin = {()=> dispatch(openSignIn)}
        />
        <ResetPasswordRequest 
        openResetPasswordReq = {authUiState.openResetPasswordReq}
        onCLickOpenSignin= {()=> dispatch(openSignIn)}
        />
        
        </>
    )

}

export default AuthControl