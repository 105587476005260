export const kleurs = ['rgba(255, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)', 'rgba(255, 206, 86, 0.5)',
              'rgba(75, 192, 192, 0.5)', 'rgba(153, 102, 255, 0.5)', 'rgba(255, 159, 64, 0.5)']


export const makeRepeated = (arr, repeats) =>
    Array.from({ length: repeats }, () => arr).flat();





export function roundUp(num, precision) {
  precision = Math.pow(10, precision)
  return Math.ceil(num * precision) / precision
}

function roundToTwo(num) {
    return +(Math.round(num + "e+2")  + "e-2");
}
  

export const  range = (start, end) =>  {
    return (new Array(end - start + 1)).fill(undefined).map((_, i) => i + start);
}
export const color_seq = (start, end) => {
    let _range = range(start, end)
    let _color_map = []
    for (let i in _range) {
      let _val = _range[i]/end
      _color_map.push(_val)
    }
    return _color_map
}
  
export const getMyColor = (num_color) => {
  let n = (num_color* 0xfffff * 1000000).toString(16);
  return "#" + n.slice(0, 6);
}

export const widgetColor = (value) => {
   if (value < 55 ) {
      return 'success';
   } else if (value >= 55 & value<= 80) {
      return 'warning';
   } else {
      return 'danger';
   }
}

export const block_no = [...Array(96).keys()]

export const TimeBlock = () => {
    var x = 15; //minutes interval
    var times = []; // time array
    var tt = 0; // start time

  //loop to increment the time and push results in array
  for (var i=0;tt<24*60; i++) {
    var hh = Math.floor(tt/60); // getting hours of day in 0-24 format
    var mm = (tt%60); // getting minutes of the hour in 0-55 format
    times[i] = ("0" + (hh % 24)).slice(-2) + ':' + (("0" + mm).slice(-2)); // pushing data in array in [00:00 - 12:00 AM/PM format]
    tt = tt + x;
  }
      return times
}
