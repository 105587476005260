import {  
    
    SET_EVENT_TO_EDIT,
    SET_EVENT_TO_DELETE,
    SET_EVENT_TO_ADD

  
  } from "../actions/formaction";
    const initialState = {
        eventtodelet:{},
        eventtoedit:{},
        eventtoadd:{},
    }

 const FormReducer =  (state = initialState, action) => {
    switch (action.type) {
      case SET_EVENT_TO_DELETE:
        return {
          ...state,
          eventtodelet: action['payload']
        }
 
        case SET_EVENT_TO_EDIT:
         return {
           ...state,
           eventtoedit: action['payload'],
         }
         case SET_EVENT_TO_ADD:
         return {
           ...state,
           eventtoadd: action['payload'],
         }
      default:
        return state;
    }
  };
 
  export default FormReducer  
 