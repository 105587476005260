
import { useEffect, useState } from "react";
import Papa from "papaparse";
import Button from "@mui/material/Button";
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { fetchRtTagMaster } from "../../../Apis/actions/rtDatatagmaster";
import { UploadCsv } from "../UploadCsv";
import { BaseTable } from "../../../components/Tables";
import { PlaneCard } from "../../../UiComponent/CustomCard";
import { postrootpath } from "../../../Apis/apirootpath";
import axios from "axios";
const  LoadDataUpload=() => {
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(fetchRtTagMaster())
  },[dispatch])

const rt_data_tag_state = useSelector(state=> state.rt_data_tag)

// console.log(rt_data_tag_state)

  const [files, setFiles] = useState([]);
  const [state, setState] = useState({
        isloading:'false',
        rt_data_tag_isloadding:false,
        rt_data_tag_master:[],
        data:[],
        data_to_post:[]

  })

  useEffect(()=> {
    if(rt_data_tag_state && rt_data_tag_state.loading){
        setState({...state,rt_data_tag_isloadding:true})
    }
    if(rt_data_tag_state && !rt_data_tag_state.loading && rt_data_tag_state.data){
        setState({...state,
            rt_data_tag_isloadding:false,
            rt_data_tag_master:rt_data_tag_state.data

        })
    }
  },[rt_data_tag_state])

  console.log(rt_data_tag_state)

  function parseCsv(files){
   
    // Papa.parse(event.target.files[0], {
    Papa.parse(files[0],   
        {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const rowsArray = [];
          const valuesArray = [];
          // Iterating data to get column name and their values
          results.data.map((d) => {
            rowsArray.push(Object.keys(d));
            valuesArray.push(Object.values(d));
          });

          setState({...state,
            isloading:false,
            data:results.data});
    
        },
      });

  }

useEffect(()=> {
    
    if(files && files.length >0){
        setState({...state, isloading:true})
        parseCsv(files)
        // setState({...state, isloading:fa})
    }
  },[files])



  function reshapeData(){
    if(!state.isloading && state.data && !state.rt_data_tag_isloadding && state.rt_data_tag_master)
    {let all_data = []
    let tag_master=state.rt_data_tag_master
    console.log(tag_master)
     state.data.forEach(element => {
        let _date_time = element['Date & Time']
        let date = moment(_date_time).format('YYYY-MM-DD')
        let check = moment(_date_time);
        let year = check.format('YYYY')
        let month   = check.format('MM');
        let day   = check.format('DD');
        let hour = check.hour();
        let minute = check.minute();
        console.log(minute)
        let datetime_str=`${year}${month}${day}${hour}${minute}`
        let date_str = `${date}`
        let block_no = Math.ceil((parseFloat(hour)*60 + parseFloat(minute)+0.0000001)/15)
        tag_master.forEach(item => { 
          // console.log(item)
            let series_name = String(item.series_name)
            // console.log(series_name)
            let element_val = 0
            let element_id=''
            if (element[series_name]){
                element_val= element[series_name]
            }
            element_id = item.id

            let _dict = {}
            _dict.datetime = datetime_str
            _dict.id = element_id
            _dict.date = date_str
            _dict.hour = hour
            _dict.minute = minute
            _dict.value_mw=element_val
            _dict.block_no=block_no
            console.log(_dict)
            all_data.push(_dict)
        });
    });
    return all_data
}
}



useEffect(()=> {
    reshapeData()   
},[files,state.data]) 

// console.log(state.data_to_post)

  const commitLoadData = async (e) => {
    const data_to_post = reshapeData()
    // console.log(data_to_post)
    e.preventDefault();
    try { axios.post(`${postrootpath}/commit_minutewise_dump`, 
            data_to_post,
          {
            dataType: 'json',
            async: true,
          }).then(function (response) {
            if(response.data.status ==="Success")
                // {console.log(response.data)}
             { alert(`No of rows inserted: ${response.data.records}`)}
          })
          .catch(err => {
            alert(`Error message: ${err}}`);
            
        })
    
    }
    catch (e) {
      // setError(e)
      alert(`submit failed! ${e.message}`);
    }
}
  
  return (
    <PlaneCard
        main_content={
        <UploadCsv
            onFilesSelected={setFiles}
            child_table={
            <>
               {/* { state.isloading &&
                    <Backdrop
                    sx={{ color: '#fff', zIndex: 10000 }}
                    open={state.isloading}
                    >
                    <CircularProgress color="inherit" />
                    </Backdrop>
                } */}
            {!state.isloading && state.data && state.data.length>0 &&
                <BaseTable
                    checkbox_column={false}
                    action_column = {false}
                    data = {state.data}
                />
             }
            </>
            }
        />
       }
        action_content={
           
            <Button sx = {{marginLeft:'auto'}}
            onClick={commitLoadData}
            >
                commit
            </Button>
      }
        
        />
    )
  }
  
export default LoadDataUpload
