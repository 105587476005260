import React from 'react'
import {CustomDiv} from '../../../../components/Layout/CustomDiv'
const OptimizedSchedule = () => {
    return(
        <CustomDiv>
            "Optimized schedule goes here"
        </CustomDiv>
    )
}

export default OptimizedSchedule