
import PositionMap from './PositionMap';
import DayAheadEntitilement from './DayAheadEntitilement';
import OptimizedSchedule from './OptimizedSchedule';
import MustRunSchedule from './MustRunSchedule';
import MinTechLimit from './MinTechLimit';
import TotalMinSchedule from './TotalMinSchedule';
import MeritorderSchedule from './MeritorderSchedule';
import MinSchPosmap from './MinSchPosmap';
import TradeTab from './TradeTab';


const DayAheadtab =({options,selectedIndex}) => 
      {  
          return (
                  <>
                
                            {options[selectedIndex]===options[0] && 
                                  <PositionMap
                                  
                                  />
                            }
                              {options[selectedIndex]===options[1] && 
                                  <DayAheadEntitilement
                                  
                                  />
                            }
                             {options[selectedIndex]===options[2] && 
                                  <MustRunSchedule
                                  
                                  />
                            }
                              {options[selectedIndex]===options[3] && 
                                  <MinTechLimit
                                  
                                  />
                            }
                               {options[selectedIndex]===options[4] && 
                                  <TotalMinSchedule
                                  
                                  />
                            }
                             {options[selectedIndex]===options[5] && 
                                  <MinSchPosmap
                                  
                                  />
                            }
                                {options[selectedIndex]===options[6] && 
                                  <MeritorderSchedule
                                  
                                  />
                            }
                            
                            { options[selectedIndex]===options[7] && 
                                  <OptimizedSchedule
                                  />
                            }
                              {/* { options[selectedIndex]===options[8] && 
                                <TradeTab
            
                                />
                              } */}
                                { options[selectedIndex]===options[9] && 
                                <TradeTab
            
                                />
                              }
                         
                  
                  </>
          )
}

export default DayAheadtab


