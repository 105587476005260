import {
    DISCOM_MASTER_REQUEST,
    DISCOM_MASTER_SUCCESS,
    DISCOM_MASTER_FAILURE
} from '../actions/discommasterAction'

const initialState = {
    loading: false,
    discommaster: [],
    error: '',
}

const discommasterReducer = (state = initialState, action) => {

    switch (action.type) {
        case DISCOM_MASTER_REQUEST:
        return {
            ...state,
            loading:true

        }

        case DISCOM_MASTER_SUCCESS:
        return {
            ...state,
            loading:false,
            discommaster: action.payload,
            error: ''
            

        }
        case DISCOM_MASTER_FAILURE:
            return {
                ...state,
                loading: false,  
                discommaster: [],  
                error: action.payload  
            }
        default: return state
    }

}

export default discommasterReducer  