import { useSelector } from 'react-redux';
import { MultiLine } from '../../../components/ReactChart/MultiLine';
import { TimeBlock } from '../../../container/dashboard.fn';
import { FilterArrayKeyval, range } from '../../../utils/ArryOps';
import { prepareMultiLineData } from '../../../components/ReactChart/MultiLine/linedata';
const RegenDayAheadPlot = ()=> {
    const labels = TimeBlock()
    const state_dayahead = useSelector(state => state.dayaheadReSchedule) 
    let plotdata
    let test_data
    let all_data =[]
    let lineDatasets
    if(state_dayahead && !state_dayahead.loading && state_dayahead.data){
        test_data = state_dayahead.data.schedule_db
        const group_by = [...new Set(test_data.map(q => q['energy_pool']))]
        const block_no = range(1,96,1)
        block_no.forEach(block => {
                
                group_by.forEach(element => {
                    let _var_ ={}
                    let c = {}  
                    let total = 0
                    let _FilterArrayKeyval = FilterArrayKeyval(test_data, ['block_no','energy_pool'],
                    [block,element])
                    _var_['block_no']=block
                    _var_['energy_pool']=''
                    _var_['schedule_mw']  =''
                    _FilterArrayKeyval.forEach(item => {
                            total +=item['schedule_mw']
                            if(item['block_no']===block){
                                _var_['energy_pool']=element
                                _var_['schedule_mw']=total
            
                            }
                            
                        
                    });
                    all_data.push(_var_)
                });
            
        });
        }
        if(state_dayahead && !state_dayahead.loading && state_dayahead.data && all_data )
        { 
            
            lineDatasets = prepareMultiLineData(all_data,'energy_pool','schedule_mw')
            plotdata = {
                labels:labels,
                datasets:lineDatasets
                }
        }
    
    return(
        <>
       {state_dayahead && !state_dayahead.loading && state_dayahead.data && all_data && plotdata && plotdata.datasets && 
        <MultiLine
        data={plotdata}
        /> 

       } 
      </>

    )
}

export default RegenDayAheadPlot

    




