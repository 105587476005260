import React, {useState} from "react";
import TextField from '@mui/material/TextField';
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import FacebookSharpIcon from '@mui/icons-material/FacebookSharp';
import GoogleIcon from '@mui/icons-material/Google';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import UseForm from "./UseForm";
import {validator} from "./Validator";
// import { PostData, STATE_LOGIN} from "./PostData";

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import { CustomDialog } from "../../UiComponent/CustomDialog";
import UseWindowDimensions from '../screensizecontainer/windowdimensions';
import AuthFormHeader from "./AuthFormHeader"
import { DialogActions} from "@mui/material";
import Loginuser from "./Services/Loginuser"
import { setUserData,} from "../../redux/actions/authaction";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { Navigate } from 'react-router-dom'


// export const authstate = STATE_LOGIN
const styles = {
    media: {
      height: '100px',
      width:'100px',
      padding: '0%', 
      alignItems:"center",
      justifyContent:"center",
      alignItems:"center",
      margin: "2rem"
    }
};

function Signin(props){
const dispatch = useDispatch()
// const navigate = useNavigate()
const userdata = useSelector(state => state.authreducer.userdata)
// console.log(userdata)
const{ openSignin , onCLickOpenSignup, onCLickOpenResetPassword}= props
const {height, width} = UseWindowDimensions()
const initState = {
      emailphone:"",
      password: "",
    };
const submit = () => {
  return ("Submited")
};

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    state,
    errors,
    countryCode
  } = UseForm({
    initState,
    callback: submit,
    validator
  });

  let isValidForm =
    Object.values(errors).filter(error => typeof error !== "undefined")
  .length === 0;

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordHide = () => {
        setShowPassword((show) => !show);
  };
  const [signinData , setSigninData] = useState({})
  const submitSigninData = async (e) => {
    e.preventDefault();
    let response_data = await Loginuser(state)
    sessionStorage.setItem('user', JSON.stringify(response_data))
    dispatch(setUserData(response_data))
    setSigninData(response_data)
  
  }


  if (signinData && signinData.auth)
    {return (<Navigate to='/'/>)}
  
  return (
    // <Box sx = {{height:height, width:width, borderRadius:10}}>    
    <CustomDialog
    open={openSignin}
    content={
      <>
        <AuthFormHeader/>
        <form onSubmit={handleSubmit}>
        <Grid container spacing={2} display={"flex"} direction={"column"}>
            <Grid item>
            <TextField
                required
                fullWidth
                size = 'small'
                label="Email /Phone no"
                name="emailphone"
                //   className={margin}
                defaultValue={state.emailphone}
                onChange={handleChange}
                error={errors.emailphone ? true : false}
                helperText={errors.emailphone}
                onBlur={handleBlur}
            />
            </Grid>
      <Grid item>
      <TextField
          required
          fullWidth
          name = "password"
          label = "Password"
          type = { showPassword ? "text" : "password" }
          id = "password"
          size = "small"
          autoComplete = "current-password"
          onChange = {handleChange}
          error={errors.password ? true : false}
          helperText={errors.password}
          // defaultValue = {state.password }
          onBlur={handleBlur}
          InputProps = {
          {
              endAdornment: ( 
              <InputAdornment position="end"> {
                  showPassword ? ( 
                  <Visibility className = "cursor_pointer"
                  onClick = {
                      togglePasswordHide
                  }
                  />
                  ) : ( 
                  <VisibilityOff onClick = {
                      togglePasswordHide
                  }
                  />)} 
              </InputAdornment>
              ),}}
              />
          </Grid>
        </Grid>
        </form>
 
      </>
    }
    action={
    <DialogActions>
    <Grid container spacing={2} display={"flex"} direction={"column"}>
    <Grid item>
            <Button
              fullWidth
              size= 'small'
              disabled={!isValidForm}
              type="submit"
              variant="contained"
              color="primary"
              onClick={submitSigninData}
            //   className={margin}
            >
            Login
          </Button>
        </Grid>
         <Grid container display={'flex'} spacing={1} direction={'column'}>
        <Grid item> 
        <Grid container  display={'flex'} direction={'column'}
         justifyContent={'centre'}
         alignItems="center"
        >
         <Grid item>
          
              <Link href="#signup" 
              onClick={onCLickOpenSignup}
              >
               <Typography 
        sx ={{
            fontSize: 14, 
             fontWeight:'bold',
             fontFamily:'serif',
             padding:'0.25rem'
             }}
          >{"Signup"}
        </Typography> 
         </Link>
        </Grid>
        <Grid item>
          
              <Link href="#reset-password" 
              onClick={onCLickOpenResetPassword}
              >
               <Typography 
        sx ={{
            fontSize: 14, 
             fontWeight:'bold',
             fontFamily:'serif',
             padding:'0.25rem'
             }}
          >{"Reset-Password"}
        </Typography> 
         </Link>
        </Grid>
          </Grid>
          </Grid>
          </Grid>
          <Grid container justifyContent="center">            
          <Button  
          endIcon={<FacebookSharpIcon />}>
            Login with 
          </Button>
          <Button  endIcon={<GoogleIcon />}>
            Login with 
          </Button>
          </Grid>  
          </Grid>
    </DialogActions>

    }
    
    />

)}


export default Signin
