
export const SET_SELECTED_UTILITY  = "SET_SELECTED_UTILITY"
export const SET_PERIOD  = "SET_PERIOD"
export const SET_DATE  = "SET_DATE"
export const SET_FORDATE  = "SET_FORDATE"
export const SET_DISPLAY_DTA_PLOT  = "SET_DISPLAY_DTA_PLOT"

export const setSelectedUtility = (selectedutility) => ({
    type: SET_SELECTED_UTILITY,
    payload: selectedutility
 })

export const setAnalysisPeriod = (selectedperiod) => ({
   type: SET_PERIOD,
   payload: selectedperiod
})

export const setDate = (selecteddate) => ({
   type: SET_DATE,
   payload: selecteddate
})


export const setForDate = (selectedfordate) => ({
   type: SET_FORDATE,
   payload: selectedfordate
})


export const setDisplayPlotData = (element) => ({
   type: SET_DISPLAY_DTA_PLOT,
   payload: element
})








