import React, {useEffect, useState } from "react"
import { BasicAutoComplete } from "../../../UiComponent/BasicAutoComplete"
import Stack  from "@mui/material/Stack"
import AddCircleIcon from '@mui/icons-material/AddCircle'; 
import { NumericTextField } from "../../../UiComponent/NumberInput";
import { Button, IconButton } from "@mui/material"
import { TableActionDelete } from "../../../components/Tables";
import { BasicDialog } from "../../../UiComponent/BasicDialog";
import { setUserDefinedLfModel } from "../../../redux/actions/LoadForecastigUserHybridModel";
import { useDispatch } from "react-redux";
let all_series = []
const CreateNew = ({open,handleClose,
                    // SenduserDefinedSeries,
                    model_option}) => {

const dispatch = useDispatch()

const [state, setState] = useState({
    selectedSeries: '',
    from_block:'',
    to_block:'',
    weight: '',
    userdefinedSeries: []

})
// console.log(state)
const setSelectedSeries = (e) => {
    setState({...state, selectedSeries: e.target.value })
}
const onchangeFromBlock = (e) => {
    setState({...state,from_block:e.target.value})
}
const onchangeToBlock = (e) => {
    setState({...state,to_block:e.target.value})
}
const onchangeWeight = (e) => {
    setState({...state,weight:e.target.value})
}

function upsert(array, element) { // (1)
    const i = array.findIndex(e => e.series_name === element.series_name);
    if (i > -1) array[i] = element; // (2)
    else array.push(element);
    }

const commitUserCurve = () => {
    let _var = {series_name: state.selectedSeries,
                from_block: state.from_block,
                to_block: state.to_block,
                weight: state.weight,

            }
    upsert(all_series, _var)
    return all_series
}
const commit_series  = () =>{
    setState({...state, userdefinedSeries: commitUserCurve()})
}
const deleteRow=(index) => { 
    let rows = state.userdefinedSeries
    rows.splice(index, 1); 
    setState({...state,userdefinedSeries:[...rows]}); }



    return(
       <BasicDialog
        open={open}
        handleClose={handleClose}
        title={"Edit plot" }
        content={
            <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2} sx={{padding:'0.5rem'}}>
            <BasicAutoComplete
            menulabel= {"select series"}
            select_options = {model_option}
            onChange={setSelectedSeries}
            />
            
            <NumericTextField
            label="From block"
            value={state.from_block}
            onChange={onchangeFromBlock}
            />
            <NumericTextField
            label="To block"
            value={state.to_block}
            onChange={onchangeToBlock}
            />
            <NumericTextField
            label="Weight"
            value={state.weight}
            onChange={onchangeWeight}
            />

            <IconButton onClick = {commit_series}>
            <AddCircleIcon />
                </IconButton> 
            </Stack>
            {state.userdefinedSeries.length > 0 && 
            <TableActionDelete
            data = {state.userdefinedSeries}
            //  rows = {state.userdefinedSeries}
            onClick = {deleteRow}
            /> 
            }
            </Stack>

        }
        action_button={
            <Button size= "medium" variant="contained"
            onClick={() => {dispatch(setUserDefinedLfModel(state.userdefinedSeries))}}
            >
               commit
            </Button>
        }
        />

       
           
    
    )
}

export default CreateNew