
import {
    CLICK_MENU_OPEN_BUTTON,
    CLICK_MENU_CLOSE_BUTTON,
    CLICK_USERPROJECT_OPEN_BUTTON,
    CLICK_USERPROJECT_CLOSE_BUTTON,
    CLICK_ANALYSIS_OPEN_BUTTON,
    CLICK_ANALYSIS_CLOSE_BUTTON,
    CLICK_NAVIGATION_OPEN_BUTTON,
    CLICK_NAVIGATION_CLOSE_BUTTON,
    CLICK_SURVEY_SEARCH_OPEN_BUTTON,
    CLICK_SURVEY_SEARCH_CLOSE_BUTTON,
    CLICK_CURRENT_FOLIO_OPEN_BUTTON,
    CLICK_CURRENT_FOLIO_CLOSE_BUTTON,
    OPEN_NAV_DETAILS_DIALOGUE,
    CLOSE_NAV_DETAILS_DIALOGUE,
    OPEN_USER_FOLIO,
    CLOSE_USER_FOLIO,
    OPEN_SEARCH_VILLAGE,
    CLOSE_SEARCH_VILLAGE,
    OPEN_RASTER_LAYER_CONTROL,
    CLOSE_RASTER_LAYER_CONTROL,
    OPEN_LAYER_CONTROL,
    CLOSE_LAYER_CONTROL
  
  } from "../actions/controlbuttonaction"
// import { sidebarwith } from "../../components/layout/const.js"
// const shiftwidth = sidebarwith
import { sidebarwith } from "../../components/Layout/container/const";

const shiftwidth = 0
const initialState = {
    openSidebarMenu: true,
    menubar: false,
    userprojectwindow: false,
    analysiswindow: false,
    searchwindow: false,
    navigationwindow: false,
    conatiner_margin_left:0,
    surveysearchdialog:false,
    currentfoliodialog:false,
    navdetailsdialog:false,
    userfoliodialog:false,
    opensearch:false,
    openrasterlayercontrol:false,
    openlayercontrol:false
    
  }


const controlbuttonReducer =  (state = initialState, action) => {
    switch (action.type) {

      case CLICK_MENU_OPEN_BUTTON:
        // const state = initialState;
        return {
            ...state,
            menubar: true,
            conatiner_margin_left: shiftwidth
        }
        case CLICK_MENU_CLOSE_BUTTON:
            return {
                ...initialState,
                menubar: false,
                conatiner_margin_left: 0  
            }
        case CLICK_USERPROJECT_OPEN_BUTTON:
            return {
                ...initialState,
                userprojectwindow: true,
                mapconatiner_margin_left: 0   
            }

        case CLICK_USERPROJECT_CLOSE_BUTTON:
            return {
                ...initialState,
                userprojectwindow: false,
                mapconatiner_margin_left: 0   
            }

        case CLICK_ANALYSIS_OPEN_BUTTON:
            return {
                ...initialState,
                analysiswindow: true
                // mapconatiner_margin_left: sidebarwith  
            }
        
        case CLICK_ANALYSIS_CLOSE_BUTTON:
            return {
                ...initialState,
                analysiswindow: false
                // mapconatiner_margin_left: 0 
            }
            
        case CLICK_NAVIGATION_OPEN_BUTTON:
            return {
                ...initialState,
                navigationwindow: true,
                mapconatiner_margin_left: shiftwidth 
            }

        case CLICK_NAVIGATION_CLOSE_BUTTON:
            return {
                ...initialState,
                navigationwindow: false,
                mapconatiner_margin_left: 0   
            }
        case CLICK_SURVEY_SEARCH_OPEN_BUTTON:
            return {
                ...initialState,
                surveysearchdialog: true,
            }
        case CLICK_SURVEY_SEARCH_CLOSE_BUTTON:
            return {
                ...initialState,
                surveysearchdialog: false,
            }

        case CLICK_CURRENT_FOLIO_OPEN_BUTTON:
            return {
                ...initialState,
                currentfoliodialog: true,
            }
        case CLICK_CURRENT_FOLIO_CLOSE_BUTTON:
            return {
                ...initialState,
                currentfoliodialog: false,
            }

        case OPEN_NAV_DETAILS_DIALOGUE:
            return {
                ...initialState,
                navdetailsdialog: true,
            }
        case CLOSE_NAV_DETAILS_DIALOGUE:
            return {
                ...initialState,
                navdetailsdialog: false,
            }

        case OPEN_USER_FOLIO:
            return {
                ...initialState,
                userfoliodialog: true,
            }
        case CLOSE_USER_FOLIO:
            return {
                ...initialState,
                userfoliodialog: false,
            }

        case OPEN_SEARCH_VILLAGE:
            return {
                ...initialState,
                opensearch: true,
            }
        case CLOSE_SEARCH_VILLAGE:
            return {
                ...initialState,
                opensearch: false,
            }
        case OPEN_RASTER_LAYER_CONTROL:
            return {
                ...initialState,
                openrasterlayercontrol: true,
            }
        case CLOSE_RASTER_LAYER_CONTROL:
            return {
                ...initialState,
                openrasterlayercontrol: false,
            }
            case OPEN_LAYER_CONTROL:
                return {
                    ...initialState,
                    openlayercontrol: true,
                }
            case CLOSE_LAYER_CONTROL:
                return {
                    ...initialState,
                    openlayercontrol: false,
                }
           
       

      default:
        return state;
    }
  };

  export default controlbuttonReducer  


 