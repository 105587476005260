import {SET_SELECTED_MODEL} from "../actions/LoadForecastigUserHybridModel"

 const initialState = {
  userdefined_series: []
}

 const userDefinedLFmodel =  (state = initialState, action) => {
   switch (action.type) {
    case SET_SELECTED_MODEL:
    return {
        ...state,
        userdefined_series: action['payload']
    }

    default:
       return state;
   }
 };

 export default userDefinedLFmodel  


