

function convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
}

var _today = new Date();
// const date = new Date()  
const _Today = convertTZ(_today, "Asia/Calcutta")
let day_today = String(_Today.getDate()).padStart(2, '0')
let month_todday = String(_Today.getMonth() + 1).padStart(2, '0')
let year_today = _Today.getFullYear()

export const Today = `${year_today}` + "-" + `${month_todday}` + "-" + `${day_today}`

var date = new Date();
date.setDate(date.getDate() + 1);
const _Tomorrow = convertTZ(date, "Asia/Calcutta")
let day_tomorrow = String(_Tomorrow.getDate()).padStart(2, '0')
let month_tomorrow =  String(_Tomorrow.getMonth() + 1).padStart(2, '0')
let year_tomorrow = _Tomorrow.getFullYear()

export const Tomorrow = `${year_tomorrow}` + "-" + `${month_tomorrow}` + "-" + `${day_tomorrow}`

var _yesterday_date = new Date();
date.setDate(_yesterday_date.getDate() + 1);
const _yesterday = convertTZ(date, "Asia/Calcutta")
let day_yesterday = String(_yesterday.getDate()).padStart(2, '0')
let month_yesterday =  String(_yesterday.getMonth() + 1).padStart(2, '0')
let year_yesterday = _yesterday.getFullYear()

export const Yesterday = `${year_yesterday}` + "-" + `${month_yesterday}` + "-" + `${day_yesterday}`
