import React, { useState } from 'react'
import Button  from '@mui/material/Button'
import Stack  from '@mui/material/Stack'
import axios from 'axios'
import { pythonapi } from '../../Apis/apirootpath'

import CircularProgress from '@mui/material/CircularProgress'
import { CustomDiv } from '../../components/Layout/CustomDiv'

const WeatherUpload = () => {

  const [state,setState] = useState({
    actual_wetaher_loading:false,
    weather_forecast_loading:false,
    actual_weather: [],
    weather_forecast:[],
    errorMessage:{}
  })
    const run_req = {
        params:
          { 
            state: 'Karnataka',
            discom:'KARNATAKA',
            message: "run_script",
           
          }
      }
   

 const FetchWeatherActual = async (e) => {
    setState({...state, actual_wetaher_loading:true})
    e.preventDefault();
    try {
    axios.get(`${pythonapi}/fetchWeatherActual`,
      run_req,
    { headers: {
        'Content-Type': 'application/json',
        'Accept-Charset': 'utf-8',
        // 'User-Agent': my_useragent,
    }
    }
    )
      .then((res) => {
        // hidespinneActualNRweather()
        setState({...state,
          actual_wetaher_loading:false,
          weather_forecast:res.data})
        alert(`weather history upload for the state of ${run_req.params.state} completed`)
      })
      .catch(error => {
         setState({...state, 
          actual_wetaher_loading:false,
          errorMessage: error.message})
      })

    } catch (e) {
      setState({...state, actual_wetaher_loading:false})
    alert(`submit failed! ${e.message}`);
    }
  }

  const FetchWetherForecast = async (e) => {
    setState({...state, weather_forecast_loading:true})
    e.preventDefault();
    try {
    axios.get(`${pythonapi}/fetchWetherForecast`,
      run_req,
    { headers: {
        'Content-Type': 'application/json',
        'Accept-Charset': 'utf-8',
        // 'User-Agent': my_useragent,
    }
    }
    )
      .then((res) => {
        // hidespinneActualNRweather()
        setState({...state,
          weather_forecast_loading:false,
          weather_forecast:res.data})
        alert(`weather forecast upload for the state of ${run_req.params.state} completed`)
      })
      .catch(error => {
         setState({...state, 
          weather_forecast_loading:false,
          errorMessage: error.message})
      })

    } catch (e) {
      setState({...state, 
        weather_forecast_loading:false})
    alert(`submit failed! ${e.message}`);
    }
  }
    return(
        <CustomDiv>
        <Stack spacing={10} >
        <Button variant="outlined" sx = {{maxWidth:'350px'}}
        onClick={FetchWeatherActual}
        > 
        { state.actual_wetaher_loading && 
          <CircularProgress sx = {{marginRight:'0.5rem'}}/>
        }
            Fetch actual Weather 
        </Button>

        <Button variant="outlined" sx = {{maxWidth:'350px'}}
        onClick={FetchWetherForecast}
        > 
         { state.weather_forecast_loading && 
          <CircularProgress sx = {{marginRight:'0.5rem'}}/>
        }
            Fetch Weather Forecast 
        </Button>
        </Stack>
        </CustomDiv>
        
    )


}

export default WeatherUpload