
export const CLICK_MENU_OPEN_BUTTON =  "CLICK_MENU_OPEN_BUTTON"
export const CLICK_MENU_CLOSE_BUTTON = "CLICK_MENU_CLOSE_BUTTON"
export const CLICK_ANALYSIS_OPEN_BUTTON = "CLICK_ANALYSIS_OPEN_BUTTON"
export const CLICK_ANALYSIS_CLOSE_BUTTON = "CLICK_ANALYSIS_CLOSE_BUTTON"
export const CLICK_NAVIGATION_OPEN_BUTTON = "CLICK_NAVIGATION_OPEN_BUTTON"
export const CLICK_NAVIGATION_CLOSE_BUTTON = "CLICK_NAVIGATION_CLOSE_BUTTON"
export const CLICK_USERPROJECT_OPEN_BUTTON = "CLICK_USERPROJECT_OPEN_BUTTON"
export const CLICK_USERPROJECT_CLOSE_BUTTON = "CLICK_USERPROJECT_CLOSE_BUTTON"
export const CLICK_SURVEY_SEARCH_OPEN_BUTTON= "CLICK_SURVEY_SEARCH_OPEN_BUTTON"
export const CLICK_SURVEY_SEARCH_CLOSE_BUTTON= "CLICK_SURVEY_SEARCH_CLOSE_BUTTON"
export const CLICK_CURRENT_FOLIO_OPEN_BUTTON= "CLICK_CURRENT_FOLIO_OPEN_BUTTON"
export const CLICK_CURRENT_FOLIO_CLOSE_BUTTON= "CLICK_CURRENT_FOLIO_CLOSE_BUTTON"
export const OPEN_NAV_DETAILS_DIALOGUE= "OPEN_NAV_DETAILS_DIALOGUE"
export const CLOSE_NAV_DETAILS_DIALOGUE= "CLOSE_NAV_DETAILS_DIALOGUE"
export const SET_SELECTED_VILLAGE= "SET_SELECTED_VILLAGE"
export const OPEN_USER_FOLIO = "OPEN_USER_FOLIO"
export const CLOSE_USER_FOLIO = "CLOSE_USER_FOLIO"
export const OPEN_SEARCH_VILLAGE = "OPEN_SEARCH_VILLAGE"
export const CLOSE_SEARCH_VILLAGE = "CLOSE_SEARCH_VILLAGE"

export const OPEN_RASTER_LAYER_CONTROL = "OPEN_VECTOR_LAYER_CONTROL"
export const CLOSE_RASTER_LAYER_CONTROL = "CLOSE_VECTOR_LAYER_CONTROL"

export const OPEN_LAYER_CONTROL = "OPEN_LAYER_CONTROL"
export const CLOSE_LAYER_CONTROL = "CLOSE_LAYER_CONTROL"


export const OPEN_SAVE_FETURE_CONTROL = "OPEN_SAVE_FETURE_CONTROL"
export const CLOSE_SAVE_FETURE_CONTROL = "CLOSE_SAVE_FETURE_CONTROL"

export const openLayerControl = {
  type: OPEN_LAYER_CONTROL,
  payload: true
};

export const closeLayerControl = {
  type: CLOSE_LAYER_CONTROL,
  payload: true
};

export const openMenu = {
  type: CLICK_MENU_OPEN_BUTTON,
  payload: true
};

export const closeMenu = {
  type: CLICK_MENU_CLOSE_BUTTON,
  payload: false
};

export const openAnalysisWindow = {
  type: CLICK_ANALYSIS_OPEN_BUTTON,
  payload: true
};



export const closeAnalysisWindow = {
  type: CLICK_ANALYSIS_CLOSE_BUTTON,
  payload: false
};


export const openSaveFetureControl = {
  type: OPEN_SAVE_FETURE_CONTROL,
  payload: true
};


export const closeSaveFetureControl = {
  type: CLOSE_SAVE_FETURE_CONTROL,
  payload: false
};

export const openNavigationWindow = {
  type: CLICK_NAVIGATION_OPEN_BUTTON,
  payload: true
};

export const closeNavigationWindow = {
  type: CLICK_NAVIGATION_CLOSE_BUTTON,
  payload: false
};

export const openUserProjectWindow = {
  type: CLICK_USERPROJECT_OPEN_BUTTON,
  payload: true
};

export const closeUserProjectWindow = {
  type: CLICK_USERPROJECT_CLOSE_BUTTON,
  payload: false
};

export const openSurveySearchDialog = {
  type: CLICK_SURVEY_SEARCH_OPEN_BUTTON,
  payload: true
};

export const closeSurveySearchDialog = {
  type: CLICK_SURVEY_SEARCH_CLOSE_BUTTON,
  payload: false
};


export const openCurrentFolioDialog = {
  type: CLICK_CURRENT_FOLIO_OPEN_BUTTON,
  payload: true
};

export const closeCurrentFolioDialog = {
  type: CLICK_CURRENT_FOLIO_CLOSE_BUTTON,
  payload: false
};

export const openNavDetailDialog = {
  type: OPEN_NAV_DETAILS_DIALOGUE,
  payload: true
};

export const closeNavDetailDialog = {
  type: CLOSE_NAV_DETAILS_DIALOGUE,
  payload: false
};



export const openUserFolio = {
  type: OPEN_USER_FOLIO,
  payload: true
};

export const closeUserFolio = {
  type: CLOSE_USER_FOLIO,
  payload: false
};


export const openSearch = {
  type: OPEN_SEARCH_VILLAGE,
  payload: true
};

export const closeSearch = {
  type: CLOSE_SEARCH_VILLAGE,
  payload: true
};


export const openRasterLayerControl = {
  type: OPEN_RASTER_LAYER_CONTROL,
  payload: true
};

export const closeRasterLayerControl = {
  type: CLOSE_RASTER_LAYER_CONTROL,
  payload: true
};









  

