import {
    API
    // FETCH_CURRENT_SCHEDULE,
    // FETCH_CURRENT_SCHEDULE_ERROR,
    // SET_CURRENT_SCHEDULE,

  } from "./types";
import { externalapirootpath } from "../apirootpath";

export const FETCH_CURRENT_SCHEDULE = "FETCH_CURRENT_SCHEDULE"
export const FETCH_CURRENT_SCHEDULE_ERROR = "FETCH_CURRENT_SCHEDULE_ERROR"
export const SET_CURRENT_SCHEDULE = "SET_CURRENT_SCHEDULE"

export function fetchCurrent_ISGS_Schedule() {
    return apiAction({
      url:
        `${externalapirootpath}/RealTimeSchedule`,
      onSuccess: setCurrentScheduleData,
      onFailure: (error) => ({
        type: FETCH_CURRENT_SCHEDULE_ERROR,
        payload: error
      }),
      label: FETCH_CURRENT_SCHEDULE
    });
  }


  function setCurrentScheduleData(data) {
    //let weather = firebase.database().ref('locations/1');
    //weather.child('weather_forecast').set(json);
    // console.log(data)
    return {
      type: SET_CURRENT_SCHEDULE,
      payload: data
    };
  }


  
  function apiAction({
    url = "",
    method = "GET",
    data = null,
    accessToken = null,
    onSuccess = () => {},
    onFailure = () => {},
    label = "",
    headersOverride = null
  }) {
    return {
      type: API,
      payload: {
        url,
        method,
        data,
        accessToken,
        onSuccess,
        onFailure,
        label,
        headersOverride
      }
    };
  }




  