
export const SET_FOR_DATE  = "SET_FOR_DATE"

export const setForDate = (forDate) => ({
   type: SET_FOR_DATE,
   payload: forDate
})








