import {API} from "./types";
import { opt_processapipath } from "../apirootpath";
// import { fetchrootpath } from "../apirootpath";
export const RUN_DAYAHEAD_SCH_OPT = "RUN_DAYAHEAD_SCH_OPT"
export const RUN_DAYAHEAD_SCH_OPT_ERROR = "RUN_DAYAHEAD_SCH_OPT_ERROR"
export const SET_DAYAHEAD_SCH = "SET_DAYAHEAD_SCH"
export function optimizeDayAheadSchedule(method,query_param){
    return apiAction({
      url:`${opt_processapipath}/DayAheadOptSchedule`,
      method:method,
      data:query_param,
      onSuccess: setData,
      onFailure: (error) => ({
        type: RUN_DAYAHEAD_SCH_OPT_ERROR,
        payload: error
      }),
      label: RUN_DAYAHEAD_SCH_OPT
    });
  }


  function setData(data){
    // console.log(data)
    //let weather = firebase.database().ref('locations/1');
    // //weather.child('weather_forecast').set(json);
    // console.log(data);
    return {
      type: SET_DAYAHEAD_SCH,
      payload: data
    };
  }
  
  function apiAction({
    url = "",
    method = "",
    data = null,
    accessToken = null,
    onSuccess = () => {},
    onFailure = () => {},
    label = "",
    headersOverride = null
  }) {
    return {
      type: API,
      payload: {
        url,
        method,
        data,
        accessToken,
        onSuccess,
        onFailure,
        label,
        headersOverride
      }
    };
  }




  