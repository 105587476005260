import React from "react";
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import UseForm from "./UseForm";
import {validator} from "./Validator";
import UseWindowDimensions from '../screensizecontainer/windowdimensions';
import { CustomDialog } from "../../UiComponent/CustomDialog";
import AuthFormHeader from "./AuthFormHeader"
// import { STATE_SIGNUP} from "./PostData";
// import Registeruser from "./Services/Registeruser"
import Resetpasswordlink from "./Services/Resetpasswordlink";
const ResetPasswordRequest = (props)=> {
  // const baseURL = postrootpath
  const {openResetPasswordReq}= props
    const {height, width} = UseWindowDimensions()
    const initState = {
      // user_name:"",
      // first_name:"",
      // last_name:"",
      // organization_name:"",
      // phone_no:"",
      // email_id:"",
      emailphone:"",
      // password: "",
      // confirmpassword: "",
    };

      const submit = () => {
        return ("Submited")
      };

      const {
        handleChange,
        handleSubmit,
        handleBlur,
        state,
        errors,
        countryCode
      } = UseForm({
        initState,
        callback: submit,
        validator
      });

      let isValidForm =
        Object.values(errors).filter(error => typeof error !== "undefined")
      .length === 0;

    
    // let _error = []
    // let _result = []
    const submitResetpasssword = (e) => {
      e.preventDefault()
      Resetpasswordlink(state)
    }


  return (
    <Box sx = {{height:height, width:width, borderRadius:10}}>    
    <CustomDialog
    open={openResetPasswordReq}

    scroll="paper"
  
    content={
      <>
       <AuthFormHeader/>
       <form onSubmit={handleSubmit}>
        <Grid container spacing={2} display={"flex"} direction={"column"}>
            <Grid item>
            <TextField
                required
                fullWidth
                size = 'small'
                label="Email /Phone no"
                name="emailphone"
                //   className={margin}
                defaultValue={state.emailphone}
                onChange={handleChange}
                error={errors.emailphone ? true : false}
                helperText={errors.emailphone}
                onBlur={handleBlur}
            />
            </Grid>
        </Grid>
        </form>
      </>
    }
    action={
      <Grid container spacing={2} display={"flex"} direction={"column"}>
      <Grid item>
            <Button
              fullWidth
              size= 'small'
              disabled={!isValidForm}
              type="submit"
              variant="contained"
              color="primary"
              onClick={submitResetpasssword}
            //   className={margin}
            >
            get link to reset-password
          </Button>
          </Grid>
         <Grid container display={'flex'} spacing={1} direction={'column'}
         justifyContent={'center'} alignItems={'center'} padding={'2'}
         >
  
        </Grid>
        </Grid>

    }
   />
</Box>

)}
export default ResetPasswordRequest
