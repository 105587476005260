import { CustomDiv } from "../../../components/Layout/CustomDiv"
const LiveScheduleInternal = () => {
    return (
        <CustomDiv> 
            
        day ahead live scheudle goes here 

        </CustomDiv>
    )
}

export default LiveScheduleInternal