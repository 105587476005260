import {useDispatch } from "react-redux";
import axios from 'axios'
import { fetchDayAheadReScheule } from "../../../Apis/actions/ReSchedule_DayAhead";
import { DataIntPage } from "../Layout";
import { postrootpath } from "../../../Apis/apirootpath";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RegenDayAheadPlot from "./RegenDayAheadPlot";
// import { axiospost } from "../../../Apis/Request/postrequest";
const ReGenDayAhead = ({isLoading,data_table,data_post}) => {
    const dispatch = useDispatch()
    const LoadData =  async(e) => {
        await dispatch(fetchDayAheadReScheule())
    }  
    // const url = `${postrootpath}/commitReDayahead`
    const commitSchedule = async (e) => {
        const data_to_post = data_post
        e.preventDefault();
        try { axios.post(`${postrootpath}/commitReDayahead`, data_to_post,
              {
                dataType: 'json',
                async: true,
              }).then(function(response){
           
                if(response.status ===200 && response.data.status ==="Success")
                 { alert(`No of rows inserted: ${response.data.records}`)}
                if(response.status ===400 && response.data.status ==="Success")
                  { alert(`server could not be found`)}
                }
              )
              .catch(function (error) {
                if (!error.response) {

                  alert('Error: Network Error')
                }
                else {
                  alert( `Error:${error.response.data.message}`)
                }
            })
        }
        catch (e) {
          // setError(e)
          alert(`submit failed! ${e.message}`);
        }
    }
    return (    
        <DataIntPage
            isLoading={isLoading}
            data_table={data_table}
            LoadData = {LoadData}
            keep_action={true}
            name_action = {"commit"}
            define_action={commitSchedule}
            // define_action={()=> axiospost(url,data_post))}
            data_post={data_post}
            action_icon={<CloudUploadIcon/>}
            chart_child ={<RegenDayAheadPlot/>}
            >
        </DataIntPage>
    )
}
export default ReGenDayAhead
