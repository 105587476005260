import { useSelector } from 'react-redux';
import { MultiLine } from '../../../components/ReactChart/MultiLine';
import { TimeBlock } from '../../../container/dashboard.fn';
import { range } from '../../../utils/ArryOps';
import { prepareMultiLineData } from '../../../components/ReactChart/MultiLine/linedata';
// import { groupBySum } from "../../../utils/ArryOps";

const IEXRTMPlot = ()=> {
    const labels = TimeBlock()
    const block_no = range(1,96,1)
    const state_current = useSelector(state => state.rtm_iexprice)
    let plotdata
    let test_data
    let all_data =[]
    let lineDatasets
    let key = 'MCP'
    
   
    if(state_current && state_current.data ){
        test_data = state_current.data
        test_data.forEach(element => {
            let _var={}
            _var['series']=key
            _var['Iex_price']=element[key]
            all_data.push(_var)
        });
    }
    if(state_current && state_current.data ){
        test_data = state_current.data
        test_data.forEach(element => {
            let _var={}
            _var['series']=`${key}_RTM`
            _var['Iex_price']=element[key]
            all_data.push(_var)
        });
    }

    if(state_current && !state_current.loading && state_current.data
        
        
        && all_data )
        { 
            lineDatasets = prepareMultiLineData(all_data,'series','Iex_price')
            plotdata = {
                labels,
                datasets:lineDatasets
                }
        }


    // console.log(all_data)
    

    return(
        <>
        
    {state_current && !state_current.loading && state_current.data && plotdata && plotdata.datasets && 
        <MultiLine
        data={plotdata}
        /> 
       }  
      </>

    )
}

export default IEXRTMPlot

    




