import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker}  from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';



const   DatePickerFullWidth = (props)=> {
    const {id,label, onChange,value,} = props
  return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker 
          value={value}
          format="YYYY-MM-DD"
          id={id}
          label={label}
          slotProps={{ textField: {size: 'large', fullWidth:true}}}
          onChange={onChange}
        //   renderInput={(params) => <TextField {...params} fullWidth />}
          
        />
   </LocalizationProvider>
  );
}
export default DatePickerFullWidth


DatePicker.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string
  };
  
DatePicker.defaultProps = {
    id:"custom_date_picker",

}
