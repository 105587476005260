import { bgColors } from "../../../login/utils/bgColor";
import { tilerootpath } from "../../../../Apis/apirootpath";
// import { setQueryVectorPoly } from "../../../redux/actions/dropdownmasterAction";
// import mapboxgl from 'mapbox-gl';
// import React from 'react';
// import SimplePopup from '../components/PopupDialog/SimplePopup';
// import SimplePopup from "../../../components/PopupDialog/SimplePopup";
// import ReactDOM from "react-dom" 
let adminlabelsurl = `${tilerootpath}/adminlabel`
const Labels = (map) => {


       map.addSource('adminlabel', {
        type: 'vector',
        "tiles": [`${adminlabelsurl}/{z}/{x}/{y}.mvt`],
        // "tolerance": 1000,
        
        // 'maxzoom': 16,
       });

       map.addLayer({
        'id': 'state_name',
        'type': 'symbol',
        'source': 'adminlabel',
        'source-layer': 'state_name',
        'minzoom': 4,
        // 'maxzoom': 6,
        'layout': {
           'visibility': 'visible',
        'text-field': ['get', 'state_name'],
          // 'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        'text-variable-anchor': ['bottom'],
        'text-radial-offset': 0.5,
        'text-justify': 'auto',
        "text-size": 18
        },
         paint: {
                "text-color": bgColors.grey_100
            },
      });
      
      map.addLayer({
        'id': 'district_name',
        'type': 'symbol',
        'source': 'adminlabel',
        'source-layer': 'district_name',
        'minzoom': 7,
        // 'maxzoom': 12,
        'layout': {
           'visibility': 'visible',
        'text-field': ['get', 'district_name'],
          // 'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        'text-variable-anchor': ['bottom'],
        'text-radial-offset': 0.5,
        'text-justify': 'auto',
        "text-size": 16
        },
         paint: {
                "text-color": bgColors.grey_100
            },
      });

      map.addLayer({
        'id': 'pincode_label',
        'type': 'symbol',
        'source': 'adminlabel',
        'source-layer': 'pincode_label',
        'minzoom': 8,
        // 'maxzoom': 12,
        'layout': {
           'visibility': 'visible',
        'text-field': ['get', 'pincode'],
          // 'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        'text-variable-anchor': ['bottom'],
        'text-radial-offset': 0.5,
        'text-justify': 'auto',
        "text-size": 12
        },
         paint: {
                "text-color": bgColors.grey_100
            },
      });
      map.addLayer({
        'id': 'subdistrict_name',
        'type': 'symbol',
        'source': 'adminlabel',
        'source-layer': 'subdistrict_name',
        'minzoom': 10,
        // 'maxzoom': 12,
        'layout': {
           'visibility': 'visible',
        'text-field': ['get', 'subdistrict_name'],
          // 'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        'text-variable-anchor': ['bottom'],
        'text-radial-offset': 0.5,
        'text-justify': 'auto',
        "text-size": 15
        },
         paint: {
                "text-color": bgColors.grey_100
            },
      });

      map.addLayer({
        'id': 'village_name',
        'type': 'symbol',
        'source': 'adminlabel',
        'source-layer': 'village_name',
        'minzoom': 12,
        // 'maxzoom': 12,
        'layout': {
           'visibility': 'visible',
        'text-field': ['get', 'village_name'],
          // 'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        'text-variable-anchor': ['bottom'],
        'text-radial-offset': 0.5,
        'text-justify': 'auto',
        "text-size": 14
        },
         paint: {
                "text-color": bgColors.grey_100
            },
      });

      
}

export default Labels


// const mapDispatchToProps = dispatch => {
//   return {
//      setQueryVectorPoly: e => {
//     dispatch(setQueryVectorPoly(e));
//     },
//     // setRasterLayer: style => dispatch(setStyle(style)),
//   };
// };

// export default connect(
//  null,
//   mapDispatchToProps
// )(Subdistrict);