import React from 'react'
import { CustomDiv } from '../../../../components/Layout/CustomDiv'
const Trade = () => {
    return(
        <CustomDiv>
            "Trade component goes here"
        </CustomDiv>
    )
}

export default Trade