import React from 'react'
import { CustomDiv } from '../../../components/Layout/CustomDiv'
const Mediumterm = () => {
    return(
        <CustomDiv>
            "medium-term component goes here"
        </CustomDiv>
    )
}

export default Mediumterm