
// import MixedChart from "../components/ReactChart/MixedChart";
import {MultiLine} from "../../components/ReactChart/MultiLine";
import { styled } from '@mui/material/styles';
import Paper from "@mui/material/Paper";
import {AreaChart} from "../../components/ReactChart/AreaChart";
import CustomDiv from "../../components/Layout/CustomDiv/CustomDiv";
import { BaseCard } from "../../UiComponent/CustomCard";
import { Grid, Typography } from "@mui/material";
import TimelineIcon from '@mui/icons-material/Timeline';
import { range } from "../../utils/ArryOps";
import { Widget } from "../../UiComponent/Widgets";
import { GiLightningFrequency } from "react-icons/gi";
import { bgColors } from "../../utils/bgColor";
import DashboardOdUd from "./DashboardOdUd";
// import AppCandle from "../components/D3charts/CandleStickChart/AppCandle";
import AppCandle from "../../components/D3charts/CandleStickChart/AppCandle";
import ScatterChart from "../../components/ReactChart/ScatterChart/ScatterChart";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Dashboard = () => {

 

const key = 'model_name'
const value_field = 'load_forecast'
const labels = range(1,96,1)


  return (
      <CustomDiv>
     
      <Grid direction="row" container 
      spacing={{ xs: 0.5, sm:0.5, md: 0.5 }} 
      padding={'0.5rem'}        
                > 
       
          <Grid item xs={12} md={3} sm={3}>
        <Widget 
      
        Title= {<GiLightningFrequency color={bgColors.red_800}/>}
        subtitle1={"overdrawl max"}
        />
       </Grid>
        <Grid item xs={12} md={3} sm={3}> 
        <Widget/>
        </Grid >
        <Grid item xs={12} md={3} sm={3}>
        <Widget/>
        </Grid>
        <Grid item xs={12} md={3} sm={3}>
        <Widget/>
        </Grid>

{/* all the plots  */}

        <Grid item xs={12} md={6} sm={4}>
        <DashboardOdUd/>
        </Grid>

        {/* <Grid item xs={12} md={6} sm={4}>
        <ScatterChart/>
        </Grid> */}

        <Grid item xs={12} md={6} sm={4}>
        <DashboardOdUd/>
        </Grid>


{/* all the plots  */}
        <Grid item 
        xs={12} md={6} sm={4}
        >
          
       <BaseCard
       sx={{maxHeight:'250px'}}
        avtaricon={<TimelineIcon/>}
        title={
          <Typography variant="h5">
             Real time dashboard
          </Typography>
        
        }
        subtitle= {"OD/UD"}
        // action_button={ }
        header_content={<Typography> 
        test test test test test test test 
      </Typography>}
      content={
        <AppCandle/>
      }
       
       />
    
      </Grid>
     
    </Grid>
    </CustomDiv>
  )
}
export default Dashboard

