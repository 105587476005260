
import { externalapirootpath } from "../../Apis/apirootpath"
export const SET_GENSCH_DETAILS = "SET_GENSCH_DETAILS"
export const FETCH_GENSCH_DETAILS = "FETCH_GENSCH_DETAILS"
export const API = "API"

export const GET_GENSCH_REQUEST = "GET_GENSCH_REQUEST"
export const GEN_GENSCH_SUCCESS = "GEN_GENSCH_SUCCESS"
export const GEN_GENSCH_FAILURE = "GEN_GENSCH_FAILURE"

const fetch_url = `${externalapirootpath}/FetchISGSCurrentSchedule`

export function fetchScheduleDetails() {
  return apiAction({
    url: fetch_url,
    onSuccess: setScheduleDetails,
    onFailure: () => console.log("Error occured loading articles"),
    label: FETCH_GENSCH_DETAILS
  });
}

function setScheduleDetails(data){
  return {
    type: SET_GENSCH_DETAILS,
    payload: data
  };
}

function apiAction({
  url = "",
  method = "GET",
  data = null,
  accessToken = null,
  onSuccess = () => {},
  onFailure = () => {},
  label = "",
  headersOverride = null
}) {
  return {
    type: API,
    payload: {
      url,
      method,
      data,
      // accessToken,
      onSuccess,
      onFailure,
      label,
      headersOverride
    }
  };
}