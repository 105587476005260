import React,{useRef,useState} from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import { bgColors } from '../../utils/bgColor';
import IconButton from "@mui/material/IconButton";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import DownloadIcon from '@mui/icons-material/Download';

const BaseCard=({ 
                  needDonwload,
                  handleDownload,
                  needFullScreen,
                  title, 
                  subtitle,
                  action_button,
                  content,
                  action,
                  ...otherprop
  }) =>{

  const [showFullScreen, SetShowFullScree] = useState(true)

  const Ref = useRef(null);
  const enterFullscreen = () => {
    const elem = Ref.current;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { // Firefox
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { // Chrome, Safari, and Opera
      elem.webkitRequestFullscreen();
    }
  };
  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  };
  const handleFullscreen = () => {
    if (!document.fullscreenElement) {
      enterFullscreen();
      SetShowFullScree(false)
    } else {
      exitFullscreen();
      SetShowFullScree(true)
    }
  };
  return (

    <Card ref={Ref} sx={{color:bgColors.OffWhite,background:bgColors.grey_800,
      minHeight:'400px',
      minWidth:'400px'

    }}
  //  { ...otherprop}
    >
      <CardHeader sx={{maxHeight:'4rem', p:0}}
       { ...otherprop}
        action ={
          <>
          {React.Children.toArray(action_button).map(child => child)}
          {needDonwload && 
          <IconButton  onClick={handleDownload}>
            
          <DownloadIcon/>
          </IconButton>
          }
          
          {needFullScreen &&
           <IconButton onClick={handleFullscreen}>
            {showFullScreen && 
             <FullscreenIcon/>
            }
            {!showFullScreen && 
             <FullscreenExitIcon/>
            }
          
           </IconButton>
            }
         
          
          </>
        }
        title={title}
        subheader={subtitle}
        
      />
      <CardContent >
      {React.Children.toArray(content).map(child => child)}
      </CardContent>
      <CardActions disableSpacing 
      // sx={{background:bgColors.grey_800, height:'3rem'}}
      
      >
      {React.Children.toArray(action).map(child => child)}
      </CardActions>
    </Card>
  );
}

export default BaseCard

BaseCard.propTypes = {
  needDonwload:PropTypes.bool,
  handleDownload:PropTypes.func,
  needFullScreen:PropTypes.bool,
  avtaricon: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  action_button: PropTypes.node,
  content: PropTypes.node,
  action: PropTypes.node
 };
 
 BaseCard.defaultProps = {
  needDonwload:true,
  needFullScreen:true
};



