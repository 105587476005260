import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { bgColors } from '../../utils/bgColor';
import PropTypes from 'prop-types';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,

  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? `${bgColors.Bluegrotto}` : `${bgColors.Bluegrotto}`,
  },
}));

// const Img = styled('img')({
//   margin: 'auto',
//   display: 'block',
//   maxWidth: '100%',
//   maxHeight: '100%',
// });

const Widget=({buttonbase,Title,subtitle1,
  body1,body2,value,Iamlink,Toprightcornerelement}) => {
  return (
    <Paper
      sx={{
        p: 2,
        margin: 'auto',
        minWidth: 250,
        maxHeight: 200,
        borderRadius:2,
        flexGrow: 1,
        // overflow: 'auto',
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? `${bgColors.grey_800}` : `${bgColors.OffWhite}` ,
      }}
    >
     
        <Grid item>
         {buttonbase}
        {/* </Grid>
        <Grid item xs={12} sm container> */}
          <Grid item xs container direction="column" spacing={1}>
            <Typography gutterBottom variant="title" component="div" margin={'0.25rem'}>
            {/* <ThumbUpIcon/> */}
                  {Title}  

                </Typography>
            <Grid item xs>
              <Typography gutterBottom variant="subtitle1" component="div">
                {/* {`Benchmark Rate=${dsmbenchmark}`} */}
                {subtitle1}  
              </Typography>
              <Typography variant="body2" gutterBottom>
            {body1}
              </Typography>
              <Typography variant="body2" gutterBottom>
              {body2}
              </Typography>
              <Typography variant="body2" color="text.secondary">
              <BorderLinearProgress variant="determinate" value={value} />
              {/* value={normalise(props.value)} */}
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ cursor: 'pointer' }} variant="body2">
                {Iamlink}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" component="div">
              {Toprightcornerelement}
            </Typography>
          </Grid>
        </Grid>

      {/* <BorderLinearProgress variant="determinate" value={50} /> */}
    </Paper>
  );
}
export default Widget
Widget.propTypes = {
  buttonbase: PropTypes.element,
  Title:PropTypes.any,
  subtitle1: PropTypes.any,
  body1: PropTypes.any,
  body2: PropTypes.any,
  Toprightcornerelement:PropTypes.any,
  Iamlink:PropTypes.string
};

Widget.defaultProps = {
    // buttonbase: PropTypes.element,
    Title:"Titel",
    subtitle1:" could be any",
    body1: "Say something",
    body2:"how about od/ud",
    Toprightcornerelement:"key number",
    Iamlink:"I am clickable"
 
}



