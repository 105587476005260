import {
    API_START,
    API_END
  } from "../actions/types";

import {
  FETCH_WEATHERSUMMARY,
  FETCH_WEATHERSUMMARY_ERROR,
  SET_WEATHERSUMMARY
} from "../actions/fetchWeatherSummaryAction"



  const initialState = {
      loading: false,
      data: {}
      // data: {
      //   type: "",
      //   features: []
      // },
      // error: ''
  
  }
  
  
  export default function fetchWeatherSummaryReducer(state = initialState, action) {
    switch (action.type) {
    
      case SET_WEATHERSUMMARY:
        return { ...state, 
                loading: false,
                data:action.payload  
                    // { type:action.payload["type"],
                    //   features:action.payload["features"]}
                    
                    };
      case API_START:
        
        if (action.payload === FETCH_WEATHERSUMMARY) {
          return {
            ...state,
            loading: true,
            data:{},
            error: ""
          };
        }
        return(null)
        // break;
      case API_END:
        if (action.payload === FETCH_WEATHERSUMMARY) {
          return {
            ...state,
            loading: false,
          };
        }
        return(null)
        // break;
        case FETCH_WEATHERSUMMARY_ERROR:
          return {
            ...state,
            data: {},
            error: action.payload.message
          };

      default:
        return state;
    }
  }
  