import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import PropTypes from 'prop-types';
const HandleMenu = ({anchorEl,options,open,handleClose,selectedIndex,handleMenuItemClick}) => {
    return (

      
      <>
      {options && options.length > 0 && 

        < Menu
        sx ={{minWidth:'400px'}}
        position="relative"
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
        role: 'listbox',
        sx: { width: anchorEl && anchorEl.offsetWidth , 
            minWidth:'200px' }
        }}
  >
    {options.map((option, index) => (
      <MenuItem
        key={option}
        selected={index === selectedIndex}
        onClick={(event) => handleMenuItemClick(event, index)}
        sx = {{fontSize: '12px', justifyContent:'center'}}
      >
        {/* {options && option[selectedIndex]} */}
        {option}
      </MenuItem>
    ))}
    </Menu>
      }
    </>
       
    )

}

export default HandleMenu
HandleMenu.propTypes = {
    anchorEl: PropTypes.any,
    options: PropTypes.array,
    open:PropTypes.bool,
    selectedIndex:PropTypes.any,
    handleMenuItemClick: PropTypes.func,
    handleClose: PropTypes.func,
  };
  