
import {  
  SET_DRAW_FEATURE, 
  SET_MAP_CENTER_CORD, 
  SET_VILLAGEREV_POLY,SET_NAV_ROUTE,
  SET_FILTER,
  SET_BLOBAL_RASTER,
  SET_RASTER_ROI,
  SET_RASTER_DATE,
  SET_VECTOR_LAYER,
  SET_MAP_STYLE,
  SET_START_CORD, 
  SET_NAV_ROUTE_DATA,
  SET_END_CORD,
  SET_VILLSEARCH_STR,
  SET_VIEWPORT_BOUND

} from "../actions/mapaction";

import { currentRasterDate } from "../../HelperFunctions/CalendarFucntions"
const currentrasterperiod = currentRasterDate()
const initialState = {
// feature_id: '',
selectedvillage: '',
selectedfeature: { 
        id:'',
        type:'',
        coords:[],
        },
mapcentrecord: {
      longitude: 78.83,
      latitude:  30.06
  },
startcord:{
  longitude: 77.45406699,
  latitude:  20.61132242
},
mapcentrecordstr:{
centrecordstr: ''
},
villagerevpoly:{}, 
navroute:{
distance:0,
duration: 0,
route:[],
steps: []
},
navroutedata: {},
filter:{
year:'',
season:'',
crop:''
},
globalraster:'',
rasterroi:'',
ratserdate:currentrasterperiod,
vectorlayer : {
  State:false,
  District:false,
  Subdistrict:false,
  Village:false,
  Pincode:false,
  Surveypolygons: false,
  WaterBodies: false,
  Labels: false,
  NavLayer: false,
},
mapstyle:'satellite',
viewportbound: {
  sw:{
    latitude:"",
    longitude:""
  },
  ne:{
    latitude:"",
    longitude:""
  },
}
}

const mapreducer =  (state = initialState, action) => {
switch (action.type) {
case SET_DRAW_FEATURE:
return {
  ...state,
  selectedfeature:{
                    id: action['payload']['id'],
                    type: action['payload']['type'],
                    coords:action['payload']['coords']
                }
}

case SET_VILLSEARCH_STR:

return {
        ...state,
        selectedvillage:action['payload']
}

case SET_MAP_CENTER_CORD:

return {
        ...state,
          mapcentrecord:{longitude:action['payload']['longitude'],
          latitude:action['payload']['latitude']
          }
}
case SET_START_CORD:

return {
        ...state,
          startcord:{longitude:action['payload']['longitude'],
          latitude:action['payload']['latitude']
          }
}
case SET_END_CORD:

  return {
          ...state,
            endcord:{longitude:action['payload']['longitude'],
            latitude:action['payload']['latitude']
            }
  }
case SET_VILLAGEREV_POLY:
  return {
    ...state,
    villagerevpoly: action['payload']
  }
case SET_NAV_ROUTE:
  return {
    ...state,
    navroute: action['payload']
  }
case SET_NAV_ROUTE_DATA:
  return {
    ...state,
      navroutedata: {
      distance:  action['payload']['distance'],
      duration:  action['payload']['distance'],
      route: action['payload']['route'],
      steps: action['payload']['steps']
    }
  }
case SET_FILTER:
  return {
    ...state,
    filter: {
      year: action['payload']['year'],
      season: action['payload']['season'],
      crop: action['payload']['crop'],
    }
  }
case SET_BLOBAL_RASTER:
  return {
    ...state,
    globalraster: action['payload']
  }

  case SET_RASTER_ROI:
    return {
      ...state,
      rasterroi: action['payload']
    }
    case SET_RASTER_DATE:
      return {
        ...state,
        ratserdate: action['payload']
      }

case SET_VECTOR_LAYER:
  return {
    ...state,
    vectorlayer: action['payload']
  }
case SET_MAP_STYLE:
  return {
    ...state,
    mapstyle: action['payload']
  }
case SET_VIEWPORT_BOUND:
return {
  ...state,
  viewportbound: {
      sw:{
        latitude:action['payload']['_sw']['lat'],
        longitude:action['payload']['_sw']['lng'],
      },
      ne:{
        latitude:action['payload']['_ne']['lat'],
        longitude:action['payload']['_ne']['lng'],

      }
    
  }
}
default:
return state;
}
};

export default mapreducer  
