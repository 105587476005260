import {STATE_RESET_PASSWORD, PostData } from "../PostData";
const authstate = STATE_RESET_PASSWORD
const baseURL = process.env.REACT_APP_AUTH_PATH
async function UpdatePassword( state){
const _PostData = PostData(state, authstate)
    try {
      const res = await fetch(`${baseURL}update_password`, {
        method: "post",
        headers: {
            // "Content-Type": "application/x-www-form-urlencoded"
          "Content-Type": "application/json"
        },
        body: JSON.stringify(_PostData),
      })
  
      if (!res.ok) {
        const message = `An error has occured: ${res.status} - ${res.statusText}`;
        throw new Error(message);
      }
      if(res.ok){
      const data = await res.json();
      
      alert(data.message)
      }
     
      
    } catch (err) {
      // _error.push(err.message);
      alert(err.message)
    }
  }
export default UpdatePassword