import {API} from "./types";
import { fetchrootpath } from "../apirootpath";
export const FETCH_INTERNL_GEN_UNITMASTER = "FETCH_INTERNL_GEN_UNITMASTER"
export const FETCH_INTERNL_GEN_UNITMASTER_ERROR = "FETCH_INTERNL_GEN_UNITMASTER_ERROR"
export const SET_INTERNAL_GEN_UNITMASTER = "SET_INTERNAL_GEN_UNITMASTER"
  
export function fetchInternaGenUnitMaster() {
  // console.log(`${fetchrootpath}/Internal_gen_master`)
    return apiAction({
      url:`${fetchrootpath}/Internal_gen_master`,
      onSuccess: setDicomMaster,
      onFailure: (error) => ({
        type: FETCH_INTERNL_GEN_UNITMASTER_ERROR,
        payload: error
      }),
      label: FETCH_INTERNL_GEN_UNITMASTER
    });
  }


  function setDicomMaster(data) {
    //let weather = firebase.database().ref('locations/1');
    //weather.child('weather_forecast').set(json);
    //console.log(data);
    return {
      type: SET_INTERNAL_GEN_UNITMASTER,
      payload: data
      // payload:{
      //   data: {
      //     type: data.type,
      //     features: data.features
      //   }
      // }
    };
  }


  
  function apiAction({
    url = "",
    method = "GET",
    data = null,
    accessToken = null,
    onSuccess = () => {},
    onFailure = () => {},
    label = "",
    headersOverride = null
  }) {
    return {
      type: API,
      payload: {
        url,
        method,
        data,
        accessToken,
        onSuccess,
        onFailure,
        label,
        headersOverride
      }
    };
  }




  