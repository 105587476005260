import React, { useState , forwardRef} from "react";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
// import { NavLink as RouterLink } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuList } from './container/MenuList';
// import {Main} from "./StyledMain"

import {Main} from "./container/StyledMain"
import {DrawerHeader, Drawer} from "./container/StyledDrawerHeader"
import { useDispatch, useSelector } from "react-redux";
import { AppBar } from "./container/StyledAppBar";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import {DataSetttings} from "./DataSettings";
import { AsyncSelectSearch } from "../AsyncSelectSearch";

export default function Sidebar(props) {
const {children_component} = props
const dispatch = useDispatch()
const  menuState= useSelector((state) => state.controlbutton.openSidebarMenu)
const theme = useTheme();
const [open, setOpen] = useState(false);
const handleDrawerOpen = (e) => {
  e.preventDefault()
  setOpen(!open);
};
const handleDrawerClose = (e) => {
  e.preventDefault()
  setOpen(false);
};

  const [menu, setMenu] = useState({});
  const { className, ...rest } = props;
  const handleClick = (item) => {
    let newData = { ...menu, [item]: !menu[item] };
    setMenu(newData);
  };

  const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 0 }}>
      <RouterLink {...props} />
    </div>
  ));

  const handleMenu = (children, level = 0) => {
    return children.map(({ children, name, url, icon,links }) => {
      if (!children) {
        return (
          <List component="div" disablePadding key={name}>
            <ListItem
              // className={classes.item}
              disableGutters
              style={{ padding: "0px" }}
              key={name}
            >
              <ListItemButton
                component={CustomRouterLink}
                to={url}
              >
              <ListItemIcon>{icon}</ListItemIcon>
                {name}
              </ListItemButton>
            </ListItem>
          </List>
        );
      }
      return (
        <div key={name}>
          <ListItem
            // className={classes.item}
            disableGutters
            key={name}
            onClick={() => handleClick(name)}
          >
            <ListItemButton>
             <ListItemIcon>{icon}</ListItemIcon>
              {name} 
              {menu[name] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemButton>
          </ListItem>
          <Collapse in={menu[name] ? true : false} timeout="auto" unmountOnExit>
            {handleMenu(children, 1)}
          </Collapse>
        </div>
      );
    });
  };

  

  return (
    <Box 
    sx={{ position:'relative',display: 'flex'}}
    >
      {/* <CssBaseline /> */}

      <AppBar position="absolute" open={open}  
      // sx = {{MarginTop:'10rem'}}
      // sx={{ 
      // backgroundColor: bgColors.grey_50,
      // color:bgColors.grey_900,
      // zIndex:'1000',
      // MarginLeft:'10rem'

      // }}
        >
        <Toolbar  
        sx ={{alignItems:"flex-end", 
            
            justifyContent:"space-between",
        }}
         >
            <AsyncSelectSearch
            handleDrawerOpen = {handleDrawerOpen}
            />
        <Stack direction="row" spacing={2}>
          <DataSetttings/>
          <Button >
            Login
          </Button>
          </Stack>
        </Toolbar>
      </AppBar> 
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
       
        <Divider />
          <List {...rest} >
          { handleMenu(MenuList)}
          </List>
        
      </Drawer>
        <Main open={open} className="Container">
          {children_component}
        </Main>
    </Box>
  );
}