import {useEffect, useState} from 'react'
import axios from 'axios';
import { pythonapi } from '../../../Apis/apirootpath';
import {useDispatch,useSelector } from 'react-redux';
import { fetchRTMIEXPrice } from '../../../Apis/actions/fetchRTMIEXPrice';
import { fetchDayAheadIEXPrice } from '../../../Apis/actions/fetchDayAheadIEXPrice';
import { DataIntPage } from '../Layout';
import LinkIcon from '@mui/icons-material/Link';
import IEXRTMPlot from './IEXRTMPlot';

const IexPriceUploadRTM = ({isLoading,data_table}) => {
  const dispatch = useDispatch()
  const  date = useSelector(state=> state.static_data.selected_date)
  
    const [state, setState] = useState({
      date: date,
      loading:false,
      iex_price: [],
      errorMessageload:{},
      
  })

    useEffect(()=> {
      if(date)
      setState({...state,date:date})
      
    },[date])

    let query_params = {
      date:state.date
    }
  const LoadData=async()=> {
    let method = "GET"
    let data = query_params
    await dispatch(fetchRTMIEXPrice(method,data))
    await dispatch(fetchDayAheadIEXPrice(method,data))

  }
 

    const run_req = {
        params:
          { 
            message: "run_script",
          }
      }
  
    const DownloadIexRtmPrice = async (e) => {
      e.preventDefault();
      setState({...state, loading:true})
      try {
        axios.get(`${pythonapi}/fetchIexRtmPrice`,
        run_req,
      { headers: {
          'Content-Type': 'application/json',
          'Accept-Charset': 'utf-8',
          // 'User-Agent': my_useragent,
      }
      }
      )
        .then((res) => {
          // hidespinneActualNRweather()
          setState({...state,
            loading:false,
                iex_price:res.data})
          alert(`Iex rtm price upload completed`)
        })
        .catch(error => {
            setState({...state, 
            loading:false,
            errorMessageload: error.message})
        })
  
      } catch (e) {
        setState({...state, loading:false})
        alert(`submit failed! ${e.message}`);
      }
    }




    

    // const data_table_iex = () => {
    //   if(!data_state.loading  && data_state 
    //     && data_state.price_table_data 
    //     && data_state.price_table_data.length>0){
    //     const date_block =FilterUniqueKeyVal(data_state.price_table_data,['date','block_no'])
    //     let data_table = []
    //     console.log(data_state)
    //       date_block.forEach(element => {
    //         let row_object = {date: element.date, block_no:element.block_no}
    //         let filtered_array = FilterArrayKeyval(data_state.price_table_data,['date','block_no'], 
    //             [element.date,
    //              element.block_no ])
    //         filtered_array.forEach(element => {
    //           row_object[element.area]=element.price
    //         });
    //         data_table.push(row_object)
    //     });
    //     return data_table
    //     }
    //   }
    
  return(
    <DataIntPage
      isLoading={state.loading}
      data_table={data_table}
      LoadData = {LoadData}
      keep_action = {true}
      name_action={"Fetch RTM"}
      action_icon={<LinkIcon/>}
      define_action={DownloadIexRtmPrice}
      chart_child={<IEXRTMPlot/>}
    >
    </DataIntPage>
  )
}

export default IexPriceUploadRTM