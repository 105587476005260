import { useSelector } from 'react-redux';
import { MultiLine } from '../../../components/ReactChart/MultiLine';
import { TimeBlock } from '../../../container/dashboard.fn';
import { FilterArrayKeyval, range } from '../../../utils/ArryOps';
import { prepareMultiLineData } from '../../../components/ReactChart/MultiLine/linedata';
import { groupBySum } from "../../../utils/ArryOps";

const ReGenLivePlot = ()=> {
    const labels = TimeBlock()
    const block_no = range(1,96,1)
    const state_current = useSelector(state => state.currentReSchedule)
    let plotdata
    let test_data
    let all_data
    let lineDatasets
    if(state_current && !state_current.loading && state_current.data ){
        test_data = state_current.data.schedule_db
        all_data =groupBySum(test_data,
                                    block_no, 
                                    'block_no',
                                    'energy_pool',
                                    'schedule_mw'
                                )}

        if(state_current && !state_current.loading && state_current.data && all_data )
        { 
              lineDatasets = prepareMultiLineData(all_data,'energy_pool','schedule_mw')
                plotdata = {
                    labels:labels,
                    datasets:lineDatasets
                    }
        }
    return(
        <>
       {state_current && !state_current.loading && state_current.data && plotdata && plotdata.datasets && 
        <MultiLine
        data={plotdata}
        /> 
       } 
      </>

    )
}

export default ReGenLivePlot

    




