import React,{useEffect, useState} from "react";
import PropTypes from 'prop-types';
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { bgColors } from "../../utils/bgColor";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const tableHeaderStyling = {
  padding: "0px 0px",
  fontSize: 12,
  borderBottom: "1px solid",
  borderBottomColor: bgColors.grey_300,
  backgroundColor: bgColors.grey_800
};

const tableBodyStyling = {
  backgroundColor: bgColors.grey_300,
  color: bgColors.grey_800,
  fontSize: 20
}

const TableActionDelete = ({data, onClick})=> {
  let columns
  const [rows, setRows] = useState(data);
 
  useEffect(()=>{
    if(data.length>0){
      setRows(data)
    }
  },[rows,data])


  if (rows && rows.length>0)
  {columns = Object.keys(rows[0])}

  return (
      <TableContainer
        component={Paper}
      >
        <Table sx={{ tableLayout: "auto" }}>
          <TableHead>
            {columns && columns.length >0 && 
            <TableRow>

            {columns.map((column) => (
                <TableCell
                sx={{  width: 100 }}
                >
                  {column}
                </TableCell>
              ))}

               <TableCell
                sx={{  width: 100 }}
                >
                  Delete
                </TableCell>


            </TableRow>
            
            }
            
            

          </TableHead>
          { rows.length>0 && 
            <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                {columns.map((column) => (
                    
                                <TableCell
                                sx={{ color:bgColors.grey_300,  width: 100 }}
                                >
                                  {row[column]}
                                </TableCell>
                      ))}  

                <TableCell >
                  <IconButton 
                  onClick={onClick}
                    // onClick={(index) => { rows.splice(index, 1); setRows([...rows]); }}
                    >
                      <DeleteForeverIcon/>
                      </IconButton>
                  </TableCell>
              </TableRow>
            ))}
          </TableBody>

        } 
          
        </Table>
      </TableContainer>
  );
}

export default TableActionDelete
TableActionDelete.propTypes = {
  data: PropTypes.array,
  onClick:  PropTypes.func
}
  
//  TableActionDelete.defaultProps = {
//   data:[]
// };