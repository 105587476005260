import React, { useRef, useState } from "react";
import * as d3 from "d3";
import "./styles.css";
import UseWindowDimensions from "../../screensizecontainer/windowdimensions";
import Chart from "./Chart";
import { useDimensions } from "./Responsive";
export default function AppCandle() {
  // const {height,width} = useDimensions
  const targetRef = useRef()
  const dimension = useDimensions(targetRef)
  const chart_width = dimension.width;
  const chart_height = dimension.height;
  // const chart_width = 700;
  // const chart_height = 300;

  let bars_displayed = 40;
  let last_bar_displayed = 0;
  const randomOne = (weight = 1) => {
    return (Math.random() + Math.random() - 1) * weight;
  };
  const generateData = () => {
    const length = Math.round(Math.random() * 90) + 10;

    // initial values
    const seed_close = Math.random() * 150 + 50;
    let previous_close = seed_close;
    let previous_volume = Math.random() * 300 + 10;
    let trend = Math.floor(Math.random() * 2) * 2 - 1;

    // calculate each bar
    return d3.range(length).map((item, i) => {
      const open = previous_close * (1 + randomOne(0.1));
      const close = open * (1 + randomOne(0.2) * trend);
      const high = Math.max(open, close) * (1 + randomOne(0.1));
      const low = Math.min(open, close) * (1 - randomOne(0.1));
      const volume = previous_volume * (1 + randomOne(0.5));

      previous_close = close;
      trend = Math.floor(Math.random() * 2) * 2 - 1;

      return {
        time: i,
        open,
        high,
        low,
        close,
        volume
      };
    });
  };

  const [data, setData] = useState(generateData());
  const changeData = () => {
    setData(generateData);
  };

  // console.log(Object.keys(data[0]))

  const legendData = Object.keys(data[0])
  const legendData1 = legendData.filter(item => item !== 'time');
  console.log(legendData1)
  // const data_on_chart = data.slice()
  
  const pick = (obj, arr) =>
    Object.fromEntries(Object.entries(obj).filter(([k]) => arr.includes(k)));
  
  const omit = (obj, arr) =>
    Object.fromEntries(Object.entries(obj).filter(([k]) => !arr.includes(k)));

  const selectedItems  = ['open','high']

  let chartData = []
  data.forEach(element => {
    chartData.push(pick(element, selectedItems))
    
  });
  // console.log(chartData)

  return (
    <div 
    ref={targetRef}
    // className="App"
    >
      <div className="content">
        <div>
          <button onClick={changeData}>New Data</button>
        </div>
        <div>
          <Chart data={data} width={chart_width} height={chart_height} />
        </div>
        <span>STOCK</span>
      </div>
    </div>
  );
}
