import {
    GEN_TARIFF_REQUEST,
    GEN_TARIFF_SUCCESS,
    GEN_TARIFF_FAILURE
} from '../actions/gentariffAction'

const initialState = {
    loading: false,
    gentariff: [],
    error: '',
}

const gentariffReducer = (state = initialState, action) => {

    switch (action.type) {
        case GEN_TARIFF_REQUEST:
        return {
            ...state,
            loading:true

        }

        case GEN_TARIFF_SUCCESS:
        return {
            ...state,
            loading:false,
            gentariff: action.payload,
            error: ''
            

        }
        case GEN_TARIFF_FAILURE:
            return {
                ...state,
                loading: false,  
                gentariff: [],  
                error: action.payload  
            }
        default: return state
    }

}

export default gentariffReducer  