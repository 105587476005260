import React, {useState} from "react";
import PhoneNumber from "./PhoneNumber";
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import UseForm from "./UseForm";
import {validator} from "./Validator";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import UseWindowDimensions from '../screensizecontainer/windowdimensions';
import AuthFormHeader from "./AuthFormHeader"
import Registeruser from "./Services/Registeruser"
import CustomdDialog from "../../UiComponent/CustomDialog/CustomDialog";
const Signup=(props)=> {
  const {openSignup, onCLickOpenSignin}= props
    const {height, width} = UseWindowDimensions()
    const initState = {
      user_name:"",
      first_name:"",
      last_name:"",
      organization_name:"",
      phone_no:"",
      email_id:"",
      password: "",
      confirmpassword: "",
    };

      const submit = () => {
        return ("Submited")
      };

      const {
        handleChange,
        handleSubmit,
        handleBlur,
        state,
        errors,
        countryCode
      } = UseForm({
        initState,
        callback: submit,
        validator
      });

      let isValidForm =
        Object.values(errors).filter(error => typeof error !== "undefined")
      .length === 0;

    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordHide = () => {
         setShowPassword((show) => !show);
    };    
    // let _error = []
    // let _result = []
    const submitSignupData = (e) => {
      e.preventDefault()
       Registeruser(state)
    }


  return (
     
    <CustomdDialog
    open={openSignup}
    content={
      <>
      <AuthFormHeader/>
        <form onSubmit={handleSubmit}>
        <Grid container spacing={2} display={"flex"} direction={"column"}>
            <Grid item>
            <TextField
                required
                fullWidth
                size = 'small'
                label="User name"
                name="user_name"
                //   className={margin}
                defaultValue={state.user_name}
                onChange={handleChange}
                error={errors.user_name ? true : false}
                helperText={errors.user_name}
                onBlur={handleBlur}
            />
            </Grid>
            <Grid item>
            <TextField
                required
                fullWidth
                size = 'small'
                label="First name"
                name="first_name"
                //   className={margin}
                defaultValue={state.user_name}
                onChange={handleChange}
                error={errors.user_name ? true : false}
                helperText={errors.user_name}
                onBlur={handleBlur}
            />
            </Grid>
            <Grid item>
            <TextField
                required
                fullWidth
                size = 'small'
                label="Last name"
                name="last_name"
                //   className={margin}
                defaultValue={state.user_name}
                onChange={handleChange}
                error={errors.user_name ? true : false}
                helperText={errors.user_name}
                onBlur={handleBlur}
            />
            </Grid>
        <Grid item>
        <TextField
            required
            fullWidth
            size = 'small'
            label="Organization"
            name="organization_name"
            //   className={margin}
            defaultValue={state.organization_name}
            onChange={handleChange}
            error={errors.organization_name ? true : false}
            helperText={errors.organization_name}
            onBlur={handleBlur}
        />
        </Grid>
          <Grid item>
          <TextField
            required
            fullWidth
            size = 'small'
            label="Email_id"
            name="email_id"
            defaultValue={state.email_id}
            onChange={handleChange}
            error={errors.email_id ? true : false}
            helperText={errors.email_id}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item>
        <PhoneNumber
          errors={errors}
          state={state}
          handleChange={handleChange}
          handleBlur={handleBlur}
          countryCode={countryCode}
        />
        </Grid>    
      <Grid item>
      <TextField
          required
          fullWidth
          name = "password"
          label = "Password"
          type = { showPassword ? "text" : "password" }
          id = "password"
          size = "small"
          autoComplete = "current-password"
          onChange = {handleChange}
          error={errors.password ? true : false}
          helperText={errors.password}
          // defaultValue = {state.password }
          onBlur={handleBlur}
          InputProps = {
          {
              endAdornment: ( 
              <InputAdornment position="end"> {
                  showPassword ? ( 
                  <Visibility className = "cursor_pointer"
                  onClick = {
                      togglePasswordHide
                  }
                  />
                  ) : ( 
                  <VisibilityOff onClick = {
                      togglePasswordHide
                  }
                  />)} 
              </InputAdornment>
              ),}}
              />
          </Grid>

          <Grid item>

          <TextField
          required
          fullWidth
          name = "confirmpassword"
          label = "Confirmpassword"
          type="password"
          // type = { showPassword ? "text" : "confirmpassword" }
          id = "confirmpassword"
          size = "small"
          autoComplete = "current-confirmpassword"
          onChange = {handleChange}
          error={errors.confirmpassword ? true : false}
          helperText={errors.confirmpassword}
          onBlur={handleBlur}
              />
          </Grid>

          {/* <Grid item> */}
      {/* <TextField
          required
          fullWidth
          name = "confirmpassword"
          label = "Confirmpassword"
          type = { showconfirmPassword ? "text" : "confirmpassword" }
          id = "confirmpassword"
          size = "small"
          autoComplete = "current-password"
          onChange = {handleChange}
          error={errors.confirmpassword ? true : false}
          helperText={errors.confirmpassword}
          // defaultValue = {state.password }
          onBlur={handleBlur}
          InputProps = {
          {
              endAdornment: ( 
              <InputAdornment position="end"> {
                  showconfirmPassword ? ( 
                  <Visibility className = "cursor_pointer"
                  onClick = {
                      toggleConfimrPasswordHide
                  }
                  />
                  ) : ( 
                  <VisibilityOff onClick = {
                    toggleConfimrPasswordHide
                  }
                  />)} 
              </InputAdornment>
              ),}}
              />
          </Grid> */}
          
        </Grid>
        </form>
 

      </>

    }
    action={
    <Grid container spacing={2} display={"flex"} direction={"column"}>
    <Grid item>
            <Button
              fullWidth
              size= 'small'
              disabled={!isValidForm}
              type="submit"
              variant="contained"
              color="primary"
              onClick={submitSignupData}
            //   className={margin}
            >
            Signup
          </Button>
        </Grid>
         <Grid container display={'flex'} spacing={1} direction={'column'}>
        <Grid item> 
        <Grid container  display={'flex'} direction={'column'}
         justifyContent={'centre'}
         alignItems="center"
        >
         <Grid item>
          
              <Link href="#login" 
              onClick={onCLickOpenSignin}
              >
               <Typography 
        sx ={{
            fontSize: 16, 
             fontWeight:'bold',
             fontFamily:'serif',
             padding:'0.25rem'
             }}
          >{"Login"}
        </Typography> 
         </Link>
        </Grid>
          </Grid>
          </Grid>
          </Grid>
          </Grid>
   

    }
  
    
    />

)}
export default Signup
