import React, { useState, useEffect } from "react";
import { bgColors } from "../../utils/bgColor";
import { useSelector, useDispatch } from "react-redux";
// import { DateList} from "./dateconst";
import { FromTodatelist } from "../../HelperFunctions/CalendarFucntions";
import { setRasterDate } from "../../redux/actions/mapaction";

// import Box
import './DateSlider.css'
const getStyle = {
  tick_style :{
    position: 'relative',
    width: '1px',
    // marginLeft:'2rem',
    height:'10px',
    marginTop:'0rem',
    color: bgColors.grey_900,
    backgroundColor: bgColors.grey_300
  }
}


const DateSlider = () => {
    const dispatch = useDispatch()
    const rasterlayerstate = useSelector(state => state.mapreducer.rasterroi)
    const activeglobalraster =  useSelector(state => state.mapreducer.globalraster)
    const datelist = FromTodatelist(24)
    // console.log(datelist)

    const chekcAnytrue = (rasterlayerstate) => {
        let result = false
        if (rasterlayerstate==='Global'|| rasterlayerstate==='UserROI'){
            result = true
        }
        return result
    }
  // console.log(activeglobalraster)
  const [show, setShow] = useState(chekcAnytrue(rasterlayerstate))
  // console.log(activeglobalraster)

  useEffect(() => {
    setShow(chekcAnytrue(rasterlayerstate))
  }, [rasterlayerstate]);

  // console.log(show)

  const values = datelist;
  const [state, setState] = useState({
    currentStepIndex: 0
  })
  const handleInputChange = (e) => {
    const paylod = values[e.currentTarget.value]
    dispatch(setRasterDate(paylod))
    setState({ currentStepIndex: e.currentTarget.value});
  };

  return (
    <>
      <div id="slider-div"  style={{
        position: 'absolute',
        bottom: '15px',
        left: 0,
        right: 0,
        margin: 'auto',
        zIndex: 100,
        color: bgColors.grey_100,
        width:'250px',
        display: show ? "block" : "none",
        
    }}
    >
       <label  style={{ backgroundColor: bgColors.grey_900}} >
            date:
        <span id="output"
          className="float-right"
          style={{
            position: 'relative',
            marginLeft: '0.5rem',
            marginRight: '0.5rem',
            height: '20px',
            width:'300px',
            alignItems:'center',
            // marginRight: '1rem',
            color: bgColors.grey_300,
            backgroundColor: bgColors.grey_900
        }}
        
        
        >{values[state.currentStepIndex][0]}</span>
        {/* <label for="date_list">Donation Amount (USD):</label> */}
        
        <input
            style={{
            display: "flex",
            width: "100%",
            height: "5px",
            color: bgColors.grey_300
          }}
          onInput={handleInputChange}
          type="range"
          min={0}
          value={state.currentStepIndex}
          max={datelist.length-1}
          step="1"
          list="date_list"
        />
        </label>
        {/* <datalist id="date_list" > */}
      <div className="sliderticks"
        style={{ color: bgColors.grey_900 }}
      > {datelist.map((item,index) => 
        <p style={getStyle.tick_style} key= {`${item}_${index}`}></p> 
        )}
         
      </div>
      
     </div>
     
     </>
    );
  
}
export default DateSlider



