import lineDistance from "@turf/line-distance"

// import { randomNumberBetween } from "@mui/x-data-grid/utils/utils"
export const location_parameters = ["State", "Dictirct", "Sub-district", "Pincode", "Village"]
export const location = {
    state_name:"Maharashtra",
    district_name:"Ahmednagar",
    subdistrict_name:"Akola",
    village_name:"Agar",
    Vilage_centroid: [74 , 28],
    Poly_centroid: [74 , 28],
    pincode:"400050",
    survey_no:"11"
}
export const landuse = ["Agri", "Non-agri"]
export const proximity_param = ["Nearest branch",
                                "Nearest mandi", 
                                "Mandi Distance",
                                "Nearest highway", 
                                "Distance from me",
                                "Distance from watersource"
                                ]
export const proximity_dist =    {
    branch:{
        name:'',
        distance:'20km'
    },
    mandi:{
        name:'',
        distance:'20km'
    },
    highway:{
        name:'',
        distance:'20km'
    },
    watersource:{
        distance:'300m'

    }
}                           
export const irrigated = ["irrigated", "Rainfed"]
export const PerformanceScore = Math.random()*10
export const IrrigationScore = Math.random()*10
export const Season = ["Summer","Rain","Winter","Annual", "Perennial", "Semi-perennial"]
export const Fy = ['2020-21', '2021-22', '2022-23']
export const performanceScore = [
    {year:"2020-21", season:"Rain", crop:"cotton", perf_score: (Math.random()*10).toFixed(2),Irrgtn_score: (Math.random()*10).toFixed(2)},
    {year:"2020-21", season:"WInter", crop:"Red-gram", perf_score: ( Math.random()*10).toFixed(2),Irrgtn_score: (Math.random()*10).toFixed(2)},
    {year:"2020-21", season:"Rain", crop:"Soybean", perf_score:  (Math.random()*10).toFixed(2),Irrgtn_score: (Math.random()*10).toFixed(2)},
    {year:"2022-22", season:"Rain", crop:"Soybean", perf_score: (Math.random()*10).toFixed(2),Irrgtn_score: (Math.random()*10).toFixed(2)},
    {year:"2022-22", season:"Summer", crop:"Potato", perf_score: (Math.random()*10).toFixed(2),Irrgtn_score: (Math.random()*10).toFixed(2)},
    {year:"2022-22", season:"Annual", crop:"Sugarcane", perf_score:  (Math.random()*10).toFixed(2),Irrgtn_score: (Math.random()*10).toFixed(2)}
] 

export const NDVI = {
 series_name: 'NDVI',
 properties : {
    '2020-2021': 
    [
        {month:'Apr', value:0.20},
        {month:'May', value:0.30},
        {month:'Jun', value:0.40},
        {month:'Jul', value:0.50},
        {month:'Aug', value:0.60},
        {month:'Sep', value:0.62},
        {month:'Oct', value:0.64},
        {month:'Nov', value:0.40},
        {month:'Dec', value:0.52},
        {month:'Jan', value:0.55},
        {month:'Feb', value:0.60},
        {month:'Mar', value:0.42},

    ],
    '2021-2022': 
    [
        {month:'Apr', value:0.20},
        {month:'May', value:0.30},
        {month:'Jun', value:0.40},
        {month:'Jul', value:0.50},
        {month:'Aug', value:0.60},
        {month:'Sep', value:0.62},
        {month:'Oct', value:0.64},
        {month:'Nov', value:0.55},
        {month:'Dec', value:0.52},
        {month:'Jan', value:0.45},
        {month:'Feb', value:0.30},
        {month:'Mar', value:0.32},

    ],
    '2022-2023': 
    [
        {month:'Apr', value:0.10},
        {month:'May', value:0.20},
        {month:'Jun', value:0.30},
        {month:'Jul', value:0.45},
        {month:'Aug', value:0.55},
        {month:'Sep', value:0.60},
        {month:'Oct', value:0.62},
        {month:'Nov', value:0.55},
        {month:'Dec', value:0.45},
        {month:'Jan', value:0.40},
        {month:'Feb', value:0.30},
        {month:'Mar', value:0.25},

    ]
}
}
 












