import { useEffect, useRef, useState } from 'react'
import { CustomDiv } from '../components/Layout/CustomDiv'
import {PopupMenuChart,DraggableBarChart } from '../components/ReactChart/DraggableBarChart.js'
import { ContextMenu } from '../UiComponent/ContextMenu'
import { getDatasetAtEvent,getElementAtEvent } from 'react-chartjs-2';
import ReactDOM from "react-dom";
import { edit_options, 
        pivot_options, 
        parallel_shift, 
        pivot_shift} from '../components/ReactChart/EditableChartComponent/container';
import { useSelector } from 'react-redux';
import { initial_dataset } from './sampleData';
import { Button } from 'reactstrap';

// import EditableDataGrid from '../components/Tables/EditableDataGrid';

const labels = ['1', '2', '3', '4', '5', '6', '7','8', '9', '10', '11', '12', '13', '14'];

const TestApp = () => {

const chartdrag_state = useSelector(state=> state.chartdrag_state)
const dragpointindex = useSelector(state=> state.chartdrag_state.index)
const datasetIndex = useSelector(state=> state.chartdrag_state.datasetIndex)
const load_forecaststate = useSelector(state=> state.loadforecast)
console.log(load_forecaststate)
const value = useSelector(state=> state.chartdrag_state.value)
const chartRef = useRef()
const [state, setState] = useState({
  edit_mode:'',
  initial_control_point:'',
  control_point_1:null,
  control_point_2:null,
})

  const [contextMenu, setContextMenu] = useState(null)
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
  };
  const handleClose = (event) => {
    // console.log(event.target)
    setState({...state,edit_mode:event.target.innerText})
    setContextMenu(null);
  };

  const [menuAnchorPosition, setMenuAnchorPosition] = useState(null) 
  const SelectControl = (event)=>{
    return event.target.innerText
  }
  const  onClick =(event)=> {
    event.preventDefault();
    // let element = getDatasetAtEvent(chartRef.current, event)
    let element = getElementAtEvent(chartRef.current, event)
    if(element && element.length>0){
      setMenuAnchorPosition({ top: event.clientY, left: event.clientX })
      setState({...state, 
        dataset_index:element[0].datasetIndex,
        initial_control_point:element[0].index
      })
    }}
  
  const handleClosePopup = (event) => {
    if(event.target.innerText==='Cnt-point-I'){
    setState({...state,
      control_point_1:state.initial_control_point
    })
    }
    if(event.target.innerText==='Cnt-point-II'){
      setState({...state,
        control_point_2:state.initial_control_point
    })
    }
    setMenuAnchorPosition(null);
  };


const [dragVal, setDragVal] = useState(chartdrag_state.value);
const prevDragVal = useRef(null);
useEffect(()=>{
  setDragVal(value)
},[value])

 useEffect(() => {
      prevDragVal.current = dragVal;
    },[value]);
let delta =  dragVal - prevDragVal.current
console.log(delta)
useEffect(()=> {
  let chart = chartRef.current
  let updated_data = []
  if(chartdrag_state && dragpointindex && datasetIndex){
  if(chart){
    let _data = chart.data.datasets[datasetIndex].data
    _data.forEach((element,index) => {
      let _updated_data = element
      let parallel_delta = 0
      let pivot1 = state.control_point_1
      let pivot2 = state.control_point_2
      if( state.edit_mode =="Shift" && pivot1 && pivot2 )
      {  parallel_delta = parallel_shift(delta,index,pivot1,pivot2,dragpointindex)
         _updated_data = parseFloat(element) + parallel_delta
      }
      if( state.edit_mode =="Pivot" && pivot1)
      { let pivot_delta = 0
        let pivot_point = pivot1
        pivot_delta =  pivot_shift(delta,index,pivot_point,dragpointindex)
        _updated_data = parseFloat(element)+pivot_delta
      }
      updated_data.push(_updated_data)
    });
    chart.data.datasets[datasetIndex].data=updated_data
    chart.update()
  }
}},[chartdrag_state,state,dragpointindex])

  
  let plot_data = {
  labels:labels,
  datasets:initial_dataset
}


console.log(state)
  return (
    <CustomDiv>
       <ContextMenu 
        contextMenu={contextMenu}
        handleContextMenu={handleContextMenu}
        handleClose={handleClose}
        options={edit_options}
        child_component={
        <>
        
        {plot_data && plot_data.datasets && 
         < DraggableBarChart
            data={plot_data}
            ref={chartRef}
            onClick={onClick}
        />

        }
        
       
      <PopupMenuChart
        options={pivot_options}
        anchorPosition={menuAnchorPosition}
        SelectControl={SelectControl}
        handleClose={handleClosePopup}
      />
      {/* <EditableDataGrid/> */}
      </>
      }  
    />
    <Button>
          Commit user_hybrid   
        </Button>
    </CustomDiv>
  )
}

export default TestApp