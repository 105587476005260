import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


// const columns = Object.keys(data[0])

// //  console.log(row_key)

export default function Basetable({data,columns}) {
  // const columns = Object.keys(data[0])
  return (
    <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
        {columns.map((column)=> 
         <TableCell align="right">{column}</TableCell>
        )}

        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row) => (
          <TableRow
            key={row.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
           {columns.map((column)=> 
             <TableCell align="right">{row[column]}</TableCell>
           )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  );
}

