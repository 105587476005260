import {useDispatch } from "react-redux";
import { fetchCurrent_ISGS_Schedule} from '../../../Apis/actions/current_ISGS_Schedule';
import { DataIntPage } from "../Layout";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
const ScheduleDetail = ({isLoading,data_table}) => {
    const dispatch = useDispatch()
    const LoadData =  async(e) => {
        await dispatch(fetchCurrent_ISGS_Schedule())
    }  
    return (    
        <DataIntPage
            isLoading={isLoading}
            data_table={data_table}
            LoadData = {LoadData}
            keep_action={true}
            name_action = {"commit"}
            action_icon={<CloudUploadIcon/>}
            >
        </DataIntPage>
    )
}
export default ScheduleDetail
