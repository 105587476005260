
// https://mui.com/material-ui/customization/palette/
export const bgColors = {
    "primary_light":'#42a5f5',
    "primary.main":'#1976d2',
    "primary.dark":'#1565c0',
    "info.light":'#03a9f4',
    "info.main":'#0288d1',
    "info.dark":"#01579b",
    "success.light":'#4caf50',
    "success.main":'#2e7d32',
    "success.dark":'#1b5e20',
    "error.light":'#ef5350',
    "error.main":'#d32f2f',
    "error.dark":'#c62828',
    "Bluegrotto":"#0091E7",
    "Royalblue":"#005BEA",
    "blue": "#0b72d9",
    "whitesmoke": "#f5f5f5",
    "babyblue":"#9bd5f2",
    "mettleblack": "#2C2C2B",
    "mettalicDarkGrey":"#565656",
    "black": "#232322",
    "jetblack": "#111010",
    "font_blue": "#4285f4",
    "danger":"#ed0707",
    "warning":"e68d20",
    "success":"#14960b",
    "info":"#1ea8e8",
    "secondary":"#676a6b",
    "default_backgroun_grey": '#E6E6E6',
    "default_background_light": '#F0F0F0',
    "default_backgroun_ffwhite": '#F5F5F5',
    "background":'#f8f9fa',
    "Default": "#81b71a",
    "OffWhite":'f2f2f2',
    "Cyan": "#37BC9B",
    "Green": "#8CC152",
    "Red": "#E9573F",
    "Yellow": "#F6BB42",
    "sidebar_grey": "#202124",
    "sidpanel_grey": "#2f3235",
    "blue_grey_darkest": "#1F3B4D",
    "dark_blue_grey": "#034168",
    "blue_grey": "#1E5C71",
    "gm2-grey-50": "#f8f9fa",
    'gm2-grey-100': '#f1f3f4',
    'gm2-grey-200': "#e8eaed",
    'gm2-grey-300': "#dadce0",
    'gm2-grey-400': "#bdc1c6",
    'gm2-grey-500': "#9aa0a6",
    'gm2-grey-600': "#80868b",
    'gm2-grey-700': "#5f6368",
    'gm2-grey-800': "#3c4043",
    'gm2-grey-900': "#202124",
    "grey_50": "#fafafa",
    "grey_100": "#f5f5f5",
    "grey_200": "#eeeeee",
    "grey_300":" #e0e0e0",
    "grey_400": "#bdbdbd",
    "grey_500": "#9e9e9e",
    "grey_600": "#757575",
    "grey_700": "#616161",
    "grey_800": "#424242",
    "grey_900": "#212121",
    "red_50": "#ffebee",
    "red_100": "#ffcdd2",
    "red_200": "#ef9a9a",
    "red_300": "#e57373",
    "red_400": "#ef5350",
    "red_500": "#f44336",
    "red_600": "#e53935",
    "red_700": "#d32f2f",
    "red_800": "#c62828",
    "red_900": "#b71c1c",
    "red_a100": "#ff8a80",
    "red_a200": "#ff5252",
    "red_a400": "#ff1744",
    "red_a700": "#d50000",
    "gm2-blue-50": " #e8f0fe",
    "gm2-blue-100": "#d2e3fc",
    "gm2-blue-200": "#aecbfa",
    "gm2-blue-300": "#8ab4f8",
    "gm2-blue-400": "#669df6",
    "gm2-blue-500": "#4285f4",
    "gm2-blue-600": "#1a73e8",
    "gm2-blue-700": "#1967d2",
    "gm2-blue-800": "#185abc",
    "gm2-blue-900": "#174ea6",
    "Ui_blue":"#0476D0",
    "gm2-red-300": " #f28b82",
    "gm2-red-600":"#d93025",
    "gm2-red-900": " #a50e0e",
    "blue_button_background": "#03a9f4",  
    "gz_blue_2": "#8ab4f8",
    "gz_blue_light":"#d2e3fc",
    "blue_100": "#c6dafc",
    "blue_300": "#7baaf7",
    "blue_500": "#4285f4",
    "blue_700": "#3367d6",

    "green_100": "#b7e1cd",
    "green_300": "#57bb8a",
    "green_500": "#0f9d58",
    "green_700": "#0b8043",

    "yellow_100": "#fce8b2",
    "yellow_300": "#f7cb4d",
    "yellow_500": "#f4b400",
    "yellow_700": "#f09300",
    
    "blue_grey_50": "#eceff1",
    "blue_grey_100": "#cfd8dc",
    "blue_grey_200": "#b0bec5",
    "blue_grey_300": "#90a4ae",
    "blue_grey_400": "#78909c",
    "blue_grey_500": "#607d8b",
    "blue_grey_600": "#546e7a",
    "blue_grey_700": "#455a64",
    "blue_grey_800": "#37474f",
    "blue_grey_900": "#263238",
    };