import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PropTypes from 'prop-types';
const  BasicSelect = ({value,options,handleChange,id,input_label,labelId,label}) => {
  return (
    <Box sx={{ minWidth: 200 }}>
      <FormControl fullWidth>
        <InputLabel id={id}> {input_label} </InputLabel>
        <Select
          labelId={labelId}
          id="demo-simple-select"
          value={value}
          label={label}
          onChange={handleChange}
        >
          {options.map((item) => {
            return <MenuItem value={item}>{item}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default BasicSelect

BasicSelect.propTypes = {

 value: PropTypes.string,
 options:PropTypes.array,
 handleChange:PropTypes.func,
 id: PropTypes.string,
 input_label:PropTypes.string,
 labelId:PropTypes.string, 
 label: PropTypes.string, 

};

BasicSelect.defaultProps = {
  value:'',
  options:[],
  id:"basic-select",
  input_label:"basic-select_label",
  labelId:"label-id",
  label:'select'
 
};



