import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
// import { fetchLFModelmaster } from '../../../Apis/actions/LoadForecastingModelMaster'
import { pythonapi } from '../../../Apis/apirootpath'
import { PopupMenuChart ,DraggableBarChart} from '../../../components/ReactChart/DraggableBarChart.js'
import { ContextMenu } from '../../../UiComponent/ContextMenu'
import {getElementAtEvent } from 'react-chartjs-2';
import { CreateNew, ForecastingPage } from '../Layout'
import { fetchLoadForecast } from '../../../Apis/actions/fetchLoadForecast'
import { prepareMultiLineData } from '../../../components/ReactChart/MultiLine/linedata'
import { TimeBlock } from '../../../container/dashboard.fn'
import { DayAhead_Load_forecasting_modellist } from '../Layout/const'
import { edit_options,pivot_options, DragDropUpdate
 } from '../../../components/ReactChart/EditableChartComponent/container';
 import { postrootpath } from '../../../Apis/apirootpath'
 import { CircularProgress, Typography } from '@mui/material'
import { HybridForecast } from './HybridForecast'
import ModelPopup from '../Layout/ModelPopup'
import NN_model from './NN_model'
import BaseDatagrid from '../../../components/Tables/BaseDatagrid'

const options = DayAhead_Load_forecasting_modellist
const Dayahead = () => {
const dispatch = useDispatch()
const labels = TimeBlock();
const chartdrag_state = useSelector(state=> state.chartdrag_state)
const dragpointindex = useSelector(state=> state.chartdrag_state.index)
const datasetIndex = useSelector(state=> state.chartdrag_state.datasetIndex)
const value = useSelector(state=> state.chartdrag_state.value)
const user_defined_model = useSelector(state=> state.userDefinedLFmodel)
const forecastCompState = useSelector(state => state.ForecastComp)
const load_forecaststate = useSelector(state=> state.loadforecast)
const for_date = useSelector(state=> state.ForecastComp.forDate)
// console.log(Forecast_comp_data)
const chartRef = useRef()
const [state, setState] = useState({
  edit_mode:'',
  initial_control_point:'',
  control_point_1:null,
  control_point_2:null,
  selectedModel:options[0],
  displayPlot:false,
  isLoadingForecast:false,
  loadForecast:[],
  multiLineDataSet:{},
  isRunningModel:false,
  modelRunResponse:{},
  openModelDialog:false,
  modelRunErro:{},
  lineDataSets:[],
  openEdit:false,
  columns:[],
  forecasttable:[]

})

const handleModelPopUp = () => setState({...state,openModelDialog:true});

const handleModelPopUpClose = () => setState({...state,openModelDialog:false});

// const handleShow = () => setShow(true);

//  const handleModelPopUp =() => {
//   setState({...state,openModelDialog: (open)=> true ? false:true} )
//  }

  const [contextMenu, setContextMenu] = useState(null)
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    );
  };
  const handleClose = (event) => {
    // console.log(event.target)
    setState({...state,edit_mode:event.target.innerText})
    setContextMenu(null);
  };

  const [menuAnchorPosition, setMenuAnchorPosition] = useState(null) 
  const SelectControl = (event)=>{
    return event.target.innerText
  }
  const  onClick =(event)=> {
    event.preventDefault();
    // let element = getDatasetAtEvent(chartRef.current, event)
    let element = getElementAtEvent(chartRef.current, event)
    if(element && element.length>0){
      setMenuAnchorPosition({ top: event.clientY, left: event.clientX })
      setState({...state, 
        dataset_index:element[0].datasetIndex,
        initial_control_point:element[0].index
      })
    }}
  
  const handleClosePopup = (event) => {
    if(event.target.innerText==='Cnt-point-I'){
    setState({...state,
      control_point_1:state.initial_control_point
    })
    }
    if(event.target.innerText==='Cnt-point-II'){
      setState({...state,
        control_point_2:state.initial_control_point
    })
    }
    setMenuAnchorPosition(null);
  };

const query_params = {
  date:forecastCompState.forDate,
  user_email:'awadhesh.kumar@quenext.com',
  state_name: 'karnataka',
  utility_name:'Karnataka',
}
 const LoadData =  async(e) => {
     await dispatch(fetchLoadForecast("GET", query_params))
 } 
 useEffect(() => {
  dispatch(fetchLoadForecast("GET", query_params))
 },[dispatch])



  const key = 'model_name'
  const value_field = 'load_forecast'
   useEffect(()=> {
    if(load_forecaststate && load_forecaststate.loadding){
      setState({...state, isLoadingForecast:true})
    }
    if(load_forecaststate && !load_forecaststate.loadding && load_forecaststate.data){
      setState({...state, 
        isLoadingForecast:false,
        loadForecast:load_forecaststate.data,
      
      })

    }
   },[load_forecaststate])


  let user_hybrid_forecast_var = HybridForecast(user_defined_model,load_forecaststate)['HybridForecastPlotData']
  let  user_hybrid_forecast = HybridForecast(user_defined_model,load_forecaststate)['hybrid_forecast']

  useEffect(()=> {
    let linedatasets
    let forecasttable =[]
    let column_list=[]
    // let labels=TimeBlock()
    if (state.loadForecast && state.loadForecast.length >0 && user_hybrid_forecast_var)
      {
      linedatasets = prepareMultiLineData(state.loadForecast,
                              key,value_field)    
      linedatasets.push(user_hybrid_forecast_var)
      let unique_series =  [...new Set(state.loadForecast.map(item => item.model_name))];
      let index_key = ['date','block_no']
      let _columnns = index_key.concat(unique_series)
      _columnns.forEach(_columnns_ => {
        let _column_dict = {}
        _column_dict.field = _columnns_
        _column_dict.header = _columnns_
        _column_dict.width = 150
        if(_columnns_==='user_hybrid'){
          _column_dict.editable = true
        }
        column_list.push(_column_dict)
      });
      let all_blocks = [...new Set(state.loadForecast.map(item => item.block_no))]
      all_blocks.forEach(_block => {
        let block_data = state.loadForecast.filter(item=> (item.block_no===_block))
        // console.log(block_data)
        let _dict = {}
        // _dict.id =parseInt(_block)
        unique_series.forEach(element => {
          let _filtered_series = block_data.filter(item=> (item.model_name===element))
          _dict.date=_filtered_series[0]['date']
          _dict.block_no = _block
         _dict[element]=parseFloat(_filtered_series[0]['load_forecast']).toFixed(0)
        })
        forecasttable.push(_dict)
      });
    
      }

      setState({...state,
        forecasttable:forecasttable,
        columns:column_list,
        lineDataSets:linedatasets
      })
  },[state.loadForecast])
 

//  console.log(state.lineDataSets)

const handleEditClose = () => {
  setState({...state,openEdit:false})
};
const toggleTableGraph=() =>{
  if(state.displayPlot === true) {
      setState({...state, displayPlot:false})
  } else if (state.displayPlot=== false) {
      setState({...state, displayPlot:true})
  }
}
const handleModelChange = (event) => {
  setState({...state,selectedModel:event.target.value})
}

const handleEditOpen = () => {
  setState({...state,openEdit:true})
  }
// const handleEditClose = () => {
//   setState({...state,openEdit:false})
// };
let plot_data
if(state.lineDataSets && state.lineDataSets.length>0){
  plot_data = {
    labels:labels,
    datasets:state.lineDataSets
  }
}
const [dragVal, setDragVal] = useState(chartdrag_state.value);
const prevDragVal = useRef(null);
useEffect(()=>{
  setDragVal(value)
},[value])

 useEffect(() => {
      prevDragVal.current = dragVal;
    },[value]);

let delta =  dragVal - prevDragVal.current
useEffect(()=> {
  let chart = chartRef.current
  let updated_data = []
  let selected_edit_mode=state.edit_mode
  let pivot1 = state.control_point_1
  let pivot2 = state.control_point_2
  if(chart && dragpointindex){
    updated_data = DragDropUpdate(chart,dragpointindex,datasetIndex,
      delta,pivot1,
      pivot2,selected_edit_mode)
    chart.data.datasets[datasetIndex].data=updated_data
    chart.update()
  }
},[chartdrag_state,dragpointindex,value])

const handleCommit=(e)=> {
  e.preventDefault();
  let chart = chartRef.current
  if(chart){
    let _data = chart.data.datasets
    let filtered_data = _data.filter(item=> (item.label==='user_hybrid'))
    let _forecast_data = filtered_data[0].data
    let _post_data = []
    _forecast_data.forEach((element,index) => {
        let _post_var = {}
        _post_var.user_email  = 'awadhesh.kumar@quenext.com'
        _post_var.state_name  = 'Karnataka'
        _post_var.utility_name = 'Karnataka'
        _post_var.model_name = 'user_hybrid'
        _post_var.date = for_date
        _post_var.block_no = index+1
        _post_var.load_forecast=parseFloat(element)
        _post_data.push(_post_var)
    }); 
    // console.log(_post_data)
    try {
      axios.post(`${postrootpath}/DayAheadHybridLoadForecast`,  _post_data ,
        {
      dataType: 'json',
      async:    true,
      }).then(function (response) {
        // console.log(response.data)
        if(response.data.status ==="Sucess")
         { alert(`No of rows inserted: ${response.data.records}`)}
      })
        .catch(err => {
          console.log(err);
        })
      // alert('schedule details were successfully submitted!');
    }
    catch (e) {
      alert(`submit failed! ${e.message}`);
    }
  }
}

  return (

    <>
   
    <CreateNew
    open={state.openEdit}
    handleClose={handleEditClose}
    model_option={options}
  />

   
    <ForecastingPage
     options={options}
     selectedmodel={state.selectedModel}
     displayPlot={state.displayPlot}
     toggleTableGraph={toggleTableGraph}
     handleCommit={handleCommit}
     handleModelChange={handleModelChange}
     handlOpenEditor={handleEditOpen}
     HandleModelPopup={handleModelPopUp}
    //  run_model={GeneratForecast}
     refreshData={LoadData}
     isLoading={state.isLoadingForecast}
     page_content={
      <>
       <ContextMenu 
        contextMenu={contextMenu}
        handleContextMenu={handleContextMenu}
        handleClose={handleClose}
        options={edit_options}
        child_component={
        <>
        
        {state.lineDataSets && state.lineDataSets.length>0 && state.displayPlot &&
       
        < DraggableBarChart
           data={plot_data}
           ref={chartRef}
           onClick={onClick}
         />

        }

      {state.loadForecast && state.loadForecast.length>0 && !state.displayPlot &&
       <BaseDatagrid
        pageSize={16}
        columns={state.columns}
        rows={state.forecasttable}
       />

       }

       
      <PopupMenuChart

        options={pivot_options}
        anchorPosition={menuAnchorPosition}
        SelectControl={SelectControl}
        handleClose={handleClosePopup}
      />
      </>
      }  
    />
      </>
    }
    />
    <ModelPopup
    // open = {true}
    modal_title={"Nn Scoring"}
    open={state.openModelDialog}
    handleClose={handleModelPopUpClose}
    CircularProgress={<CircularProgress/>}
    modal_content = {<>
        <NN_model/>
    </>
    
    }
    />
</>
  )
}

export default Dayahead