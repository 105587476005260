import React, {useState} from 'react'
export const useToken = () => {
    const getToken =() =>{
        const tokenString = sessionStorage.getItem('user')
        const userToken = JSON.parse(tokenString)
        
        return userToken?.accessToken
    } 
    const [token, setToken] = useState(getToken())
    const saveToken = userToken => {
        sessionStorage.setItem('token', JSON.stringify(token))
        setToken(userToken.accessToken)
      }
      return {
        setToken: saveToken,
        token
      }
      
    }