export const SET_EVENT_TO_EDIT = 'SET_EVENT_TO_EDIT';
export const SET_EVENT_TO_DELETE = 'SET_EVENT_TO_DELETE';
export const SET_EVENT_TO_ADD = 'SET_EVENT_TO_ADD';


export const setEventtoEdit = (eventtoedit) => ({
    type: SET_EVENT_TO_EDIT,
    payload: eventtoedit
   });

   export const setEventtoDelete = (eventtodelete) => ({
    type: SET_EVENT_TO_DELETE,
    payload: eventtodelete
   });


   export const setEventtoAdd = (eventtoadd) => ({
    type: SET_EVENT_TO_ADD,
    payload: eventtoadd
   });


