import {SET_FOR_DATE} from "../actions/ForecastCompAction"

 const initialState = {
  forDate: null
}

 const ForecastCompReducer =  (state = initialState, action) => {
   switch (action.type) {
    case SET_FOR_DATE:
    return {
        ...state,
        forDate: action['payload']
    }

    default:
       return state;
   }
 };

 export default ForecastCompReducer  


