

import axios from 'axios'
// import { fetchapi } from '../../apis/apirootpath'\
import { fetchrootpath } from '../../Apis/apirootpath'

export const DISCOM_MASTER_REQUEST = "DISCOM_MASTER_REQUEST"
export const DISCOM_MASTER_SUCCESS = "DISCOM_MASTER_SUCCESS"
export const DISCOM_MASTER_FAILURE = "DISCOM_MASTER_FAILURE"


export const getDiscomMaster = () => async dispatch => {
    dispatch({type: DISCOM_MASTER_REQUEST})
    try{ ;
        
        const res = await axios.get(`${fetchrootpath}EntDropDown_region`)
        // console.log(res.data)
        dispatch({
            type: DISCOM_MASTER_SUCCESS,
            payload: res.data
        })
    }
    catch(e){
        dispatch( {
            type: DISCOM_MASTER_FAILURE,
            payload: console.log(e),
        })
    }

}