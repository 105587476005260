import {
    API_START,
    API_END
  } from "../actions/types";

import {
  POST_EVENTLIST_SUCCESS,
  POST_EVENTLIST_ERROR,
  POST_EVENTLIST_RESPONSE
} from "../actions/postEvent"

  const initialState = {
      loading: false,
      data: {},
      error: ''
  
  }
  
  
  export default function postEventReducer(state = initialState, action) {
    //console.log("action type => ", action.type);
    switch (action.type) {
    
      case POST_EVENTLIST_RESPONSE:
        return { ...state, data: action.payload };
      case API_START:
        
        if (action.payload === POST_EVENTLIST_SUCCESS) {
          return {
            ...state,
            loading: true,
            data:{},
            error: ""
          };
        }
        return(null)
        // break;
      case API_END:
        if (action.payload === POST_EVENTLIST_SUCCESS) {
          return {
            ...state,
            loading: false,
          };
        }
        return(null)
        // break;
        case POST_EVENTLIST_ERROR:
          return {
            ...state,
            data: {},
            error: action.payload.message
          };

      default:
        return state;
    }
  }
  