import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker}  from '@mui/x-date-pickers/DatePicker';
import PropTypes from 'prop-types';

const   DatePickerYear = (props)=> {
    const {id,label,onChange,value,defaultValue} = props
  return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          views={['year']}
          id={id}
          label={label}
        //   value = {value}
          defaultValue = {defaultValue}
          slotProps={{ textField:{size:"large", fullWidth: false}}}
          onChange={onChange}
          
        //   renderInput={(params) => <TextField {...params} fullWidth />}
          
        />
   </LocalizationProvider>
  );
}
export default DatePickerYear


DatePickerYear.propTypes = {
    id: PropTypes.string,
    defaultValue:PropTypes.object,
    label: PropTypes.string,
    onChange: PropTypes.func
  };
  
  DatePickerYear.defaultProps = {
    id:"custom_date_picker",
    label: "Year",
    // textfield: {size:"Small"}
   
}
