import * as React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

const AccordianCustom =({expanded,handleChange,id,title_name, icon, children, panel, panelidcontent, panelidheader,sx}) => {
  return (
    <div>
      <Accordion id = {id} expanded={expanded === panel} 
        onChange={handleChange(panel)}
        sx ={sx}
        >
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon/>}
          aria-controls={panelidcontent} 
          id={panelidheader}>
          <Typography > {icon} {title_name} </Typography>
        </AccordionSummary>
        <AccordionDetails>
         {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
export default AccordianCustom

AccordianCustom.propTypes = {
    id: PropTypes.string,
    sx:PropTypes.object,
    expanded: PropTypes.any,
    handleChange:PropTypes.func,
    title_name: PropTypes.string,
    panel: PropTypes.string,   
    panelidcontent:PropTypes.string,
    panelidheader:PropTypes.string,
    icon: PropTypes.node,
    children: PropTypes.node
  };
  
  AccordianCustom.defaultProps = {
    id : "my custom accordian ",
    // expanded: "panel",
    title: "test-accorian",
    panel:"panel1",
    panelidcontent:"panelidcontent",
    panelidheader:"panelidcontent",
    // children:{children}
  };




