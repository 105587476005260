import React from 'react'
import mapboxgl from 'mapbox-gl';
import {PolyCord} from "../Polystr"

// selectedfeature

export function UpdatePolyRaster (map, rasterlayer, rasterlayerdate, base_url, opacity, featurecoords){

    const source_id = "Raster-source"
    const layer_id =  "Raster-source"
    const visibility = 'visible'
    if(rasterlayer==='None'){
      visibility = 'None'
    }
    if (map) {

      if (map.current.getLayer(layer_id)) {
          map.current.removeLayer(layer_id);
        }
      if (map.current.getSource(source_id)) {
           map.current.removeSource(source_id);
        }
           map.current.addSource(`${source_id}`, {
          'type': 'image',
           url:`${base_url}/${process.env.REACT_APP_SENTINELHUB_INSTANCEID}?version=1.1.1&service=WMS&request=GetMap&format=image%2Fpng&srs=EPSG%3A4326&layers=${rasterlayer}&bbox=${PolyCord(featurecoords).bbox_str}&TIME=${rasterlayerdate[0]}/${rasterlayerdate[1]}&resX=10m&resY=10m&showLogo=false&geometry=${PolyCord(featurecoords).poly}`
          ,
           coordinates: PolyCord(featurecoords).bbboxcorner,
        
          });
      map.current.addLayer({
            'id':`${layer_id}`,
            'source':`${source_id}`,
            'type': "raster",
            'layout': {
                'visibility':visibility,
              },
            'paint': {
                "raster-resampling": "nearest",
                "raster-opacity": opacity
            },
          });
    }
    
  }


//   export function handleNoneLayerRoi(map,rasterlayer){
//     const layer_id = "Raster-source"
//      if(map.getLayer(layer_id) &&  rasterlayer==='None')
//         {
//           map.setLayoutProperty(layer_id, 'visibility', 'none')
//         }
//   }


  export function handleNoneLayerRoi(map,rasterlayer){
    const source_id = "Raster-source"
    const layer_id =  "Raster-source"
    const source_id_g = "selectedraster-source"
    const layer_id_g =  "selectedraster"
    if(rasterlayer==='None'){
      if (map.current.getLayer(layer_id)) {
          map.current.removeLayer(layer_id);
        }
      if (map.current.getSource(source_id)) {
           map.current.removeSource(source_id);
        }
        if (map.current.getLayer(layer_id_g)) {
          map.current.removeLayer(layer_id_g);
        }
      if (map.current.getSource(source_id_g)) {
           map.current.removeSource(source_id_g);
        }
    }
}



// export function handleRasterLayerRoi(map,layerRoi){
//     const source_id = "Raster-source"
//     const layer_id =  "Raster-source"
//     // const visibility = 'visible'
//     // if(rasterlayer==='None'){
//     //   visibility = 'None'
//     // }
   
//   export function handleNoneLayerRoi(map){
//     const source_id = "Raster-source"
//     const layer_id =  "Raster-source"
//     // const visibility = 'visible'
//     // if(rasterlayer==='None'){
//     //   visibility = 'None'
//     // }
//       if (map.getLayer(layer_id)) {
//           map.removeLayer(layer_id);
//         }
//       if (map.getSource(source_id)) {
//            map.removeSource(source_id);
//         }
// })
//       if (map.getLayer(layer_id)) {
//           map.removeLayer(layer_id);
//         }
//       if (map.getSource(source_id)) {
//            map.removeSource(source_id);
//         }
// }




