
import {SET_FEATURE_LOCATION, 
  SET_FEATURE_CROP,
  SET_FEATURE_INDEX,
  SET_FEATURE_INDEX_HIST
} from "../actions/featurepropaction";

const initialFetureProp = {
    locprop: {},
    cropprop: {},
    indexprop: {},
    indexhist:{}

  }
const feturepropreducer =  (state = initialFetureProp, action) => {
    switch (action.type) {
      case SET_FEATURE_LOCATION:
        return {
            ...initialFetureProp,
            locprop: action.payload
          //  selectedfeature:action.payload
        }
        case SET_FEATURE_CROP:
        return {
            ...initialFetureProp,
            cropprop: action.payload
        }
        case SET_FEATURE_INDEX:
        return {
            ...initialFetureProp,
            indexprop: action.payload
         }
         case SET_FEATURE_INDEX_HIST:
          return {
              ...initialFetureProp,
              indexprop: action.payload
           }

      default:
        return state;
    }
  };

  export default feturepropreducer 